import {useField} from "formik";
import * as React from "react";
import {useEffect, useState} from "react";


export const NumberPicker = ({...selectProps}) => {
    const {className, id, name} = selectProps;
    const [field, meta, helpers] = useField(id);
    const [number, setNumber] = useState<number>(field.value || 0);


    useEffect(() => {
        helpers.setValue(number);
    }, [number])
    return (

        <input className={className} id={id} name={name} type={"number"} placeholder={"0"} value={number}
               onChange={(v: any) => {
                   if (v.target.value < 0) {
                       v.target.value = 0;
                   }
                   setNumber(v.target.value);
               }}/>

    )
}