import {LoginResponse, refreshToken} from "../api/AuthApi";
import {LocationRs} from "../api/LocationApi";
import jwt_decode from "jwt-decode";
import {getUser} from "../api/UserApi";

export type TokenUser = {
    roles: Array<"ADMIN" | "SUPERVISOR" | "OPERATOR">,
    id: string,
    lastName: string,
    firstName: string,
    exp: number
}

export type PageResponse<T> = {
    content: Array<T>,
    pageable: {
        "sort": {
            "empty": boolean,
            "sorted": boolean,
            "unsorted": boolean
        },
        "offset": number,
        "pageSize": number,
        "pageNumber": number,
        "paged": boolean,
        "unpaged": boolean
    },
    "last": boolean,
    "totalPages": number,
    "totalElements": number,
    "size": number,
    "number": number,
    "sort": {
        "empty": boolean,
        "sorted": boolean,
        "unsorted": boolean
    },
    "first": boolean,
    "numberOfElements": number,
    "empty": boolean
}

export type FileDownloaded = {
    name: string,
    data: any
}

export type Role = {
    name: string,
    auths: Array<"READ" | "WRITE">
}

export const AdminRole: Role = {name: "ADMIN", auths: ["READ", "WRITE"]}

export const OperatorAll: Role = {name: "ADMIN", auths: ["READ", "WRITE"]}
export const OperatorRead: Role = {name: "ADMIN", auths: ["READ"]}
export const SuperVisorAll: Role = {name: "ADMIN", auths: ["READ", "WRITE"]}
export const SuperVisorRead: Role = {name: "ADMIN", auths: ["READ"]}


export const isAccess = (
    userRoles: Array<"ADMIN" | "SUPERVISOR" | "OPERATOR">,
    availableRoles: Array<"ADMIN" | "SUPERVISOR" | "OPERATOR">): boolean => {


    return userRoles.some(x => availableRoles.includes(x))
}

export const isAccessRoles = (
    userRoles: Array<"ADMIN" | "SUPERVISOR" | "OPERATOR">,
    availableRoles: Array<Role>,
    operation: "WRITE" | "READ"): boolean => {


    return userRoles.some(x => availableRoles.filter(role => role.auths.includes(operation)).map(x => x.name).includes(x))
}

export const createDefaultHeaders = (token: string) => {
    return {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
    };
}

export const toEnumFormat = (value?: string) => {
    return value?.toUpperCase().replaceAll("-", "_");
}

export const isTokenNonExpired = () => {
    const user = getUserFromLocalStorage();
    return user && user.exp && user.exp * 1000 > new Date().getTime();
};

export const fetchWithResponse = (url: string, init: any) => {
    return fetch(url, init).then((x) => handleResponse(x, url, init))
}

export const fetchNoResponse = (url: string, init: any) => {
    return fetch(url, init).then(x => handleResponseNoJson(x, url, init))
}

export const handleResponse = (response: Response, url: string, init: any) => {
    if (response.status >= 200 && response.status <= 300) {
        return response.json();
    }
    if (response.status === 403 && !isTokenNonExpired()) {
        refreshToken(getRefreshTokenFromLocalStorage())
            .then((result: any) => {
                localStorage.setItem("accessToken", result.accessToken);
                localStorage.setItem("refreshToken", result.refreshToken);

                fetch(url, init)
                    .then((response) => {
                        if (response.status >= 200 && response.status <= 300) {
                            return response.json();
                        }
                    })
            })
    }
    throw Error();
}

export const handleResponseNoJson = (response: any, url: string, init: any) => {
    if (response.status >= 200 && response.status <= 300) {
        return response;
    }
    if (response.status === 403 && !isTokenNonExpired()) {
        refreshToken(getRefreshTokenFromLocalStorage())
            .then((result: any) => {
                localStorage.setItem("accessToken", result.accessToken);
                localStorage.setItem("refreshToken", result.refreshToken);

                fetch(url, init)
                    .then((response) => {
                        if (response.status >= 200 && response.status <= 300) {
                            return response;
                        }
                    })
            })
    }
    throw Error();
}

export const saveTokens = (result: LoginResponse): Promise<any> => {
    const token = result.accessToken;
    const refreshToken = result.refreshToken;
    const user: any = jwt_decode(token);

    localStorage.setItem("accessToken", token);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("user", JSON.stringify(user));

    return getUser(user.id)
        .then((result) => {
            const loadedUser = JSON.parse(localStorage.getItem("user") || "");
            loadedUser.firstName = result.firstName;
            loadedUser.lastName = result.lastName;
            localStorage.setItem("user", JSON.stringify(loadedUser))
        })
        .catch((e) => {
            clearLocalStorage();
        })

}

export const getUserFromLocalStorage = (): TokenUser => {
    const user = localStorage.getItem("user");
    return user ? JSON.parse(user) : {};
};

export const clearLocalStorage = () => {
    localStorage.setItem("accessToken", "");
    localStorage.setItem("refreshToken", "");
    localStorage.setItem("user", "");
};

export const getAccessTokenFromLocalStorage = () => {
    const token = localStorage.getItem("accessToken");
    return token ? token : "";
};

export const getLocationId = () => {
    const id = localStorage.getItem("locationId");
    return id ? id : "";
};

export const getLocationName = () => {
    const name = localStorage.getItem("locationName");
    return name ? name : "";
};

export const getLocationGeoJson = () => {
    const geoJson = localStorage.getItem("geoJson");
    return geoJson ? JSON.parse(geoJson) : "";
};

export const saveLocation = (location: LocationRs) => {
    localStorage.setItem("locationId", location.id);
    localStorage.setItem("locationName", location.name);
    localStorage.setItem("geoJson", location.geoJson);
};

export const getRefreshTokenFromLocalStorage = () => {
    const token = localStorage.getItem("refreshToken");
    return token ? token : "";
};

export const createUrlParameters = (params: any) => {
    let paramString = "";
    for (let [paramName, value] of Object.entries(params)) {
        paramString += value ? ((paramString ? "&" : "?") + paramName + "=" + value) : "";
    }

    return paramString;
}


