import * as React from 'react';
import {useEffect, useState} from 'react';
import {getLocationId, getUserFromLocalStorage, isAccessRoles, PageResponse, Role} from "../../../util/common";
import {AlarmShort, deleteAlarms, deleteAlarmsByType, getAlarm, getAlarms} from "../../../api/AlarmApi";
import {Page} from "../../common/Page/Page";
import alarmPageStyles from "./AlarmPageStyles";
import {Button} from "../../common/Button/Button";
import {formatDateTime, getDurationText} from "../../../util/dateUtils";

export type PageProps = {
    roles: Array<Role>,
    title: any,
    id?: string,
    vehicleId?: string,
    tripId?: string,
    type?: string
}

const tripTypes = ["LONG_TRIP"];
const vehicleTypes = ["BATTERY_LOW", "INSURANCE_EXPIRED"];

export function AlarmPage(props: PageProps): React.ReactElement {
    const {roles} = props
    const styles = alarmPageStyles();
    const [page, setPage] = useState<number>(0);
    const [alarms, setAlarms] = useState<PageResponse<AlarmShort>>();
    const user = getUserFromLocalStorage();

    const [alarm, setAlarm] = useState<AlarmShort>();
    const locationId = getLocationId();

    const selectedAlarms = {"LONG_TRIP": new Set()}

    const writeAccess = isAccessRoles(user.roles, roles, "WRITE");

    const deleteSelected = () => {
        // @ts-ignore
        deleteAlarms(Array.from(selectedAlarms[type]))
            .then((result) => {
                window.location.reload()
            })
    }

    const deleteAll = () => {

        deleteAlarmsByType(type as string)
            .then((result) => {
                window.location.reload()
            })
    }

    const onClickCheckbox = (val: any, alarmId: string) => {
        // @ts-ignore
        console.log(typeof selectedAlarms[type].size)
        if (type) {
            if (val.target.checked) {
                // @ts-ignore
                selectedAlarms[type].add(alarmId);
            } else {
                // @ts-ignore
                selectedAlarms[type].delete(alarmId);
            }
        }
    }

    const {id, title, vehicleId, tripId, type} = props;


    const createAlarmsHeaderRow = () => {
        return <>
            {type && tripTypes.includes(type) ? <th className=""></th> : ""}
            <th className="">Tag</th>
            {!type || vehicleTypes.includes(type) ? <th className="">Vehicle (QR code)</th> : ""}
            {!type || tripTypes.includes(type) ? <th className="">Rental</th> : ""}
            <th className="">Triggered at</th>
            <th className="">Duration</th>
        </>
    };

    const createAlarmsDataRow = (alarm: AlarmShort) => {
        return <tr key={alarm.id}>
            {type && tripTypes.includes(type)
                ? <th className={styles.iconContainer}>
                    <label className={styles.checkbox}>
                        <input type="checkbox"
                               onChange={val => onClickCheckbox(val, alarm.id)}/>
                    </label>

                </th>
                : ""
            }

            <th className=""><a href={"/alarm/" + alarm.id}>{alarm.description}</a></th>
            {!type || vehicleTypes.includes(type) ?
                <th className=""><a href={"/vehicle/" + alarm.vehicleId}>{alarm.vehicleQrCode}</a></th> : ""}
            {!type || tripTypes.includes(type) ?
                <th className=""><a href={"/trip/" + alarm.tripId}>{alarm.tripId}</a></th> : ""}
            <th className="">{formatDateTime(alarm.startTime)}</th>
            <th className="">{getDurationText(alarm.startTime, alarm.endTime)}</th>

        </tr>
    };

    useEffect(() => {
        if (id) {
            getAlarm(id).then((result: AlarmShort) => {
                setAlarm(result);
            })
        } else {
            getAlarms(locationId, type, vehicleId, tripId, page).then((result: PageResponse<AlarmShort>) => {
                setAlarms(result);
            })
        }
    }, [page, type]);

    return (<>
        <div className={styles.buttonContainer}>
            {
                // @ts-ignore
                writeAccess && type && tripTypes.includes(type) && selectedAlarms[type] && alarms?.totalElements > 0
                    ?
                    <Button title={"Delete selected alarms"} onClick={deleteSelected}/>
                    : <></>
            }
            {
                // @ts-ignore
                writeAccess && type && tripTypes.includes(type) && alarms?.totalElements > 0
                    ?
                    <Button title={"Delete all alarms"} onClick={deleteAll}/>
                    : <></>
            }
        </div>


        <Page title={title} createHeaderRow={createAlarmsHeaderRow} createDataRow={createAlarmsDataRow}
              page={page} setPage={setPage} data={id ? alarm : alarms}/>
    </>)
}

