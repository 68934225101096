import * as React from 'react';
import {useEffect, useState} from 'react';
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import {createVehicle, UpdateVehicleRequest} from "../../../api/VehicleApi";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {getVehicleModels, VehicleModelResponse} from "../../../api/VehicleModelApi";
import editVehicleStyles from "./NewVehicleStyles";
import {VehicleForm} from "../VehicleForm/VehicleForm";
import {NavigateFunction} from "react-router-dom";

let formDefaultValues = {
    vehicleModelId: '',
    externalId: "",
    qrCode: "",
    insuranceExpired: ""
}

export function NewVehicle() {
    const styles = editVehicleStyles();

    const [vehicleModels, setVehicleModels] = useState<Array<VehicleModelResponse>>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {

        getVehicleModels().then((vehicleModelResponses: Array<VehicleModelResponse>) => {
            setVehicleModels(vehicleModelResponses);
            formDefaultValues.vehicleModelId = vehicleModelResponses.length > 0 ? vehicleModelResponses[0].id : "";
            setIsLoaded(true);
        })

    }, []);
    return (
        <MainContainer>
            <TitleWrapper title={"New vehicle"}>
            </TitleWrapper>
            <div className={styles.mainWrapper}>
                <div className={styles.contentWrapper}>
                    <DefaultPageContentContainer title={"Details"}>
                        {
                            isLoaded
                                ? <VehicleForm onSubmitHandle={onSubmitHandle} vehicleModels={vehicleModels}
                                               formDefaultValues={formDefaultValues} />
                                : <img src={"/loading.gif"}/>
                        }
                    </DefaultPageContentContainer>
                </div>
            </div>

        </MainContainer>
    )
}

const onSubmitHandle = (values: UpdateVehicleRequest, navigate: NavigateFunction, id?: string) => {

    createVehicle(values)
        .then((result: any) => {

            navigate("/vehicle/" + result.id);
        })
        .catch((result: any) => {
        });

}