import * as React from 'react'
import {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import {ActionButton} from "../../common/ActionButton/ActionButton";
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {getActiveTrip, TripShort} from "../../../api/TripApi";
import {Message} from "../../common/Message/Message";
import userStyles from "./UserStyles";
import {deleteUser, getFullNameFromRs, getUser, UserRs} from "../../../api/UserApi";
import {ConfirmModal} from "../../common/ConfirmModal/ConfirmModal";
import {TripsPage} from "./Pages/TripsPage";
import {UserField} from "../../common/UserField/UserField";
import {formatDate, formatDateTime} from "../../../util/dateUtils";


const UserRsInit: UserRs = {
    id: "",
    lastName: "",
    firstName: "",
    email: "",
    phoneNumber: "",
    birthdate: new Date(),
    status: "",
    language: "",
    changedAt: new Date(),
    gender: "MALE",
    address: "",
    country: "",
    city: "",
    zip: "",
    state: "",
}

export function User() {
    const styles = userStyles();
    const navigate = useNavigate();
    const [user, setUser] = useState<UserRs>(UserRsInit);
    const [message, setMessage] = useState<string>("");
    const [isMessageError, setIsMessageError] = useState<boolean>(false);
    const [deleteModalOpened, setDeleteModalOpened] = useState<boolean>(false);
    const [activeTrip, setActiveTrip] = useState<TripShort | undefined>(undefined);
    const {id} = useParams();

    const deleteOnChange = () => {
        if (!id) {
            return;
        }
        deleteUser(id).then(() => {
            navigate("/users");
        }).catch((err) => {
            setIsMessageError(true);
            setDeleteModalOpened(false);
            setMessage("Error occurred while deleting user");
            setTimeout(() => {
                setMessage("");
            }, 3000)
        })
    }

    const delChange = () => {
        setDeleteModalOpened(true);
    }

    useEffect(() => {
        getUser(id).then((result: UserRs) => {
            setUser(result);
            getActiveTrip(id).then((result) => {
                setActiveTrip(result)
            })
        });

    }, []);
    return (
        <>
            {deleteModalOpened
                ?
                <ConfirmModal setModalOpened={setDeleteModalOpened} title={"Delete current user?"} handleConfirm={deleteOnChange}/>
                :
                <></>}
            <Message message={message} isError={isMessageError}/>
            <MainContainer>
                <TitleWrapper title={"User - " + getFullNameFromRs(user)}>
                    <ActionButton title={"Delete Account"} isLink={false}
                                  onClickHandle={delChange}/>
                    <ActionButton title={"Edit"} isLink={true} url={"/user/edit/" + id}/>
                </TitleWrapper>
                <div className={styles.contentWrapper}>
                    <div className={styles.leftColumn}>
                        <DefaultPageContentContainer title={"Details"}>
                            {/*<UserField title={"Status:"} field={createStatusStyle(user.status, styles)}/>*/}
                            <UserField title={"ID:"} field={user.id}/>
                            <UserField title={"Activated at:"} field={formatDateTime(user.firstRentalAt)}/>
                            <UserField title={"First rental at:"} field={formatDateTime(user.firstRentalAt)}/>
                            <UserField title={"Last updated:"} field={formatDateTime(user.changedAt)}/>
                            <UserField title={"Full name:"} field={getFullNameFromRs(user)}/>
                            <UserField title={"Email:"} field={user.email}/>
                            <UserField title={"Phone number:"} field={user.phoneNumber}/>
                            <UserField title={"Birthdate:"} field={formatDate(user.birthdate)}/>
                            <UserField title={"Language:"} field={user?.language}/>
                            <UserField title={"Last session:"} field={user?.lastSession}/>
                        </DefaultPageContentContainer>

                        <DefaultPageContentContainer title={"Address"}>
                            <UserField title={"Address:"} field={user.address}/>
                            <UserField title={"City:"} field={user.city}/>
                            <UserField title={"Zip:"} field={user.zip}/>
                            <UserField title={"State:"} field={user.state}/>
                            <UserField title={"Country:"} field={user.country}/>
                        </DefaultPageContentContainer>
                    </div>
                    <div className={styles.rightColumn}>

                        <TripsPage title={"Paid trips"} isSubscription={false} id={id}/>
                        <TripsPage title={"Trips by subscription"} isSubscription={true} id={id}/>
                        <DefaultPageContentContainer title={"Active rentals"}>
                            <table className={styles.dataTable}>
                                <thead>
                                <tr className={styles.headerRow}>
                                    <th className="">ID</th>
                                    <th className="">Start time</th>
                                    <th className="">Price</th>
                                </tr>
                                </thead>
                                <tbody>
                                {activeTrip
                                    ? createActiveTripDataRow(activeTrip, styles)
                                    : <tr className={styles.noDataBlock}><th>No active trips found</th></tr>}
                                </tbody>
                            </table>

                        </DefaultPageContentContainer>
                    </div>
                </div>
            </MainContainer>
        </>
    )
}

const createStatusStyle = (status: string | undefined, styles: any): React.ReactNode => {
    if (!status) {
        return <span className={styles.tag + " " + styles.redTag}>NO STATUS</span>
    }
    if (status.toLowerCase() === "active") {
        return <span className={styles.tag + " " + styles.greenTag}>Activated</span>
    }
    if (status.toLowerCase() === "pending") {
        return <span className={styles.tag + " " + styles.yellowTag}>Pending</span>
    }
    return <span className={styles.tag + " " + styles.redTag}>disabled</span>
}

const createActiveTripDataRow = (trip: TripShort, styles: any): React.ReactElement => {

    return <tr className={styles.dataRow}>
        <th className=""><a href={"/trip/" + trip.id}>{trip.id}</a></th>
        <th className="">{formatDateTime(trip.startTime)}</th>
        <th className="">{trip.vehicleId}</th>
    </tr>
}