import React, {ReactElement} from "react";
import {PromoCodeRs} from "../../../api/PromoCodeApi";
import {promoCodeCardStyles} from "./PromoCodeCardStyles";
import {Tag} from "../../common/Tags/Tag";
import {formatDate} from "../../../util/dateUtils";

export type PromoCodeCardProperties = {
    promoCode: PromoCodeRs
}

export const PromoCodeCard = (props: PromoCodeCardProperties): ReactElement => {
    const {promoCode} = props;

    const styles = promoCodeCardStyles();
    return (
        <div className={styles.promoCodeCard}>
            <a href={"/promo-code/" + promoCode.id}>
                <div className={styles.promoCodeCardHeader}>
                    <div className={styles.promoCodeCardName}>
                        <div className={styles.presentImageWrapper}>
                            <img src="/present.svg" alt=""/>
                        </div>

                        <span>{promoCode.code}</span>
                    </div>
                    <div>
                        <Tag color={getColor(promoCode)} text={getText(promoCode)}/>
                    </div>
                </div>
            </a>
            <div className={styles.promoCodeCardDescription}>
                <div className={styles.description}>
                    <p>{promoCode.description}</p>
                </div>
                <div className={styles.price}>
                    <p>€ {promoCode.discount.toFixed(2)}</p>
                </div>
            </div>
            <div className={styles.promoCodeCardValidity}>
                <div className={styles.validity}>
                    Validity: <span>{formatDate(promoCode.startDate)} - {formatDate(promoCode.endDate)}</span>
                </div>
            </div>
        </div>
    );
};

const getText = (promoCode: PromoCodeRs) => {
    if (!promoCode.active) {
        return "Unactive";
    }
    if (new Date(promoCode.endDate) < new Date()) {
        return "Expired";
    }
    return "Active";
}

const getColor = (promoCode: PromoCodeRs) => {
    if (!promoCode.active) {
        return "red";
    }
    if (new Date(promoCode.endDate) < new Date()) {
        return "grey";
    }
    return "green";
}