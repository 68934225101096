import * as React from 'react'
import {useEffect, useState} from 'react'
import editVehicleStyles from "./EditVehicleStyles";
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import {NavigateFunction, useParams} from "react-router-dom";
import {getVehicle, updateVehicle, UpdateVehicleRequest, VehicleResponse} from "../../../api/VehicleApi";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {getVehicleModels, VehicleModelResponse} from "../../../api/VehicleModelApi";
import {VehicleForm} from "../VehicleForm/VehicleForm";
import {Message} from "../../common/Message/Message";
import {LoadingBar} from "../../common/LoadingBar/LoadingBar";


let formDefaultValues = {
    vehicleModelId: '',
    externalId: "",
    qrCode: "",
    insuranceId: "",
    insuranceExpired: "",
    status: ""
}

export function EditVehicle() {
    const styles = editVehicleStyles();
    const {id} = useParams();
    const [vehicleModels, setVehicleModels] = useState<Array<VehicleModelResponse>>([]);
    const [message, setMessage] = useState<string>("");
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const onSubmitHandle = (values: UpdateVehicleRequest, navigate: NavigateFunction, id?: string) => {
        updateVehicle(values, id)
            .then((result: any) => {

                navigate("/vehicle/" + result.id);
            })
            .catch(() => {
                setMessage("Error occurred while saving vehicle");
                setTimeout(() => {
                    setMessage("")
                }, 3000)
            });
    }

    useEffect(() => {

        getVehicleModels().then((vehicleModelResponses: Array<VehicleModelResponse>) => {
            setVehicleModels(vehicleModelResponses);
            formDefaultValues.vehicleModelId = vehicleModelResponses.length > 0 ? vehicleModelResponses[0].id : "";

            getVehicle(id).then((vehicleResponse: VehicleResponse) => {
                formDefaultValues = {
                    vehicleModelId: vehicleResponse.model.id,
                    insuranceExpired: vehicleResponse.insuranceExpired || "",
                    externalId: vehicleResponse.externalId,
                    qrCode: vehicleResponse.qrCode,
                    insuranceId: vehicleResponse.insuranceId,
                    status: vehicleResponse.status
                }
                setIsLoaded(true);
            })
        })

    }, [id]);
    return (
        <>
            <Message message={message} isError={true}/>
            <MainContainer>
                <TitleWrapper title={"Edit vehicle"}>
                </TitleWrapper>
                <div className={styles.mainWrapper}>
                    <div className={styles.contentWrapper}>
                        <DefaultPageContentContainer title={"Details"}>
                            {
                                isLoaded
                                    ? <VehicleForm onSubmitHandle={onSubmitHandle} vehicleModels={vehicleModels}
                                                   formDefaultValues={formDefaultValues} id={id}/>
                                    : <LoadingBar/>
                            }
                        </DefaultPageContentContainer>
                    </div>
                </div>

            </MainContainer>
        </>
    )
}

