import React, {ReactElement, useEffect, useState} from "react";
import {deleteUserStyles} from "./DeleteUserStyles";
import {TitleWrapper} from "../common/TitleWrapper/TitleWrapper";
import {MainContainer} from "../common/MainContainer/MainContainer";
import {DefaultListContentContainer} from "../common/TableContainer/DefaultListContentContainer";
import {LoadingBar} from "../common/LoadingBar/LoadingBar";
import {changeStatus, DeleteUserRs, findAll} from "../../api/DeleteUserApi";
import {formatDateTime} from "../../util/dateUtils";

export function DeleteUser(): ReactElement {
    const styles = deleteUserStyles();
    const [deleteRequests, setDeleteRequests] = useState<Array<DeleteUserRs> | undefined>(undefined);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    useEffect(() => {
        findAll("ACTIVE").then((result: Array<DeleteUserRs>) => {
            setDeleteRequests(result);
            setDataLoaded(true);
        })
    }, []);

    const createDataHeader = (): React.ReactElement => {
        return <>
            <thead>
            <tr className={styles.headerRow}>
                <th className="">
                    ID
                </th>
                <th className="">Status</th>
                <th>Created at</th>
                <th>Changed at</th>
                <th></th>
            </tr>
            </thead>
        </>
    }

    const createDataRow = (deleteUserRequest: DeleteUserRs): React.ReactElement => {

        return <>
            <tr className={styles.dataRow}>
                <th className=""><a href={"/delete-user-request/" + deleteUserRequest.id}>{deleteUserRequest.userFullName}</a></th>
                <th className="">{deleteUserRequest.status}</th>
                <th className="">{formatDateTime(deleteUserRequest.createdAt)}</th>
                <th className="">{formatDateTime(deleteUserRequest.changedAt)}</th>

                <th className={styles.iconContainer}>
                    <div onClick={(x) => {
                        changeStatus(deleteUserRequest.id, "DONE")
                            .then((x) => {
                                window.location.reload()
                            })
                    }}><img className={styles.icon} src="/ok.svg" alt=""/></div>
                    <div  onClick={(x) => {
                        changeStatus(deleteUserRequest.id, "REJECTED")
                            .then((x) => {
                                window.location.reload()
                            })
                    }}><img className={styles.iconSmaller} src="/close.svg" alt=""/></div>
                </th>
            </tr>
        </>
    }
    
    return <>
        <MainContainer>
            <TitleWrapper title={"User deletion request"}>
                
            </TitleWrapper>
            {dataLoaded ?
                <DefaultListContentContainer title={"Deletion requests"} list={deleteRequests}>
                    <table className={styles.dataTable}>
                        {createDataHeader()}
                        <tbody>
                        {deleteRequests && deleteRequests.length > 0
                            ? deleteRequests.map((request) => createDataRow(request))
                            : <tr className={styles.noDataBlock}><th>No user deletion requests</th></tr>}
                        </tbody>
                    </table>


                </DefaultListContentContainer>
                : <LoadingBar/>
            }    
        </MainContainer>
    </>
}