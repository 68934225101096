import {
    createDefaultHeaders,
    createUrlParameters,
    fetchWithResponse,
    getAccessTokenFromLocalStorage
} from "../util/common";
import {HOST} from "../util/properties";

export type OfferWithCountRs = {
    id: string,
    description: string,
    name: string,
    price: number,
    daysDuration: number,
    tripLimitPerDay: number,
    pauseLimitPerDay: any,
    timeLimitPerDay: any,
    locationId: string,
    active: boolean,
    startDate: string,
    endDate: string,
    usedCount: string,
}

export type OfferRs = {
    id: string,
    description: string,
    name: string,
    price: number,
    daysDuration: number,
    tripLimitPerDay: number,
    pauseLimitPerDay: any,
    timeLimitPerDay: any,
    locationId: string,
    active: boolean,
    startDate: string,
    endDate: string,
}

export type OfferRq = {
    description?: string,
    name?: string,
    price?: number,
    daysDuration?: number,
    tripLimitPerDay?: number,
    pauseLimitPerDay?: any,
    timeLimitPerDay?: any,
    locationId?: string,
    active?: boolean,
    startDate?: string,
    endDate?: string,
}

export const createOffer = (values: OfferRq): Promise<OfferRs> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/offers", {
        method: 'POST',
        body: JSON.stringify(values),
        headers: createDefaultHeaders(userToken)
    })
};

export const getOffer = (id?: string): Promise<OfferRs> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/offers/" + id, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const getOffers = (locationId: string): Promise<Array<OfferWithCountRs>> => {
    const userToken = getAccessTokenFromLocalStorage();
    const urlParameters = createUrlParameters({"locationId": locationId})

    return fetchWithResponse(HOST + "/api/offers" + urlParameters, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const updateOffer = (values: OfferRq, id: string): Promise<OfferRs> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/offers/" + id, {
        method: 'PUT',
        body: JSON.stringify(values),
        headers: createDefaultHeaders(userToken)
    })
};