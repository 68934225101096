import React, {ReactElement, useEffect, useState} from 'react';
import {TitleWrapper} from "../common/TitleWrapper/TitleWrapper";
import {MainContainer} from "../common/MainContainer/MainContainer";
import {getLocationId} from "../../util/common";
import offersStyles from "./OffersStyles";
import {ActionButton} from "../common/ActionButton/ActionButton";
import {getOffers, OfferRs, OfferWithCountRs} from "../../api/OfferApi";
import {Tag} from "../common/Tags/Tag";
import {formatDate, formatDurationFromSeconds} from "../../util/dateUtils";

const Offers = (): ReactElement => {
    const styles = offersStyles();
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [offers, setOffers] = useState<OfferWithCountRs[]>([]);
    const locationId = getLocationId();

    useEffect(() => {
        getOffers(locationId).then((result: OfferWithCountRs[]) => {
            setOffers(result);
            setDataLoaded(true);
        })

    }, []);

    return (
        <MainContainer>
            <TitleWrapper title={"Offers"}>
                <ActionButton title={"New Offer"} url={"/offer/edit/new"} isLink={true}/>
            </TitleWrapper>
            <div className={styles.offerCardContainer}>
                {
                    offers.length > 0 ?
                        offers.map(x =>
                            <div className={styles.offerCard}>
                                <a href={"/offer/" + x.id}>
                                    <div className={styles.offerCardHeader}>
                                        <div className={styles.offerCardName}>
                                            <div className={styles.presentImageWrapper}>
                                                <img src="/present.svg" alt=""/>
                                            </div>

                                            <span>{x.name}</span>
                                        </div>
                                        <div>
                                            <Tag color={getColor(x)} text={getText(x)}/>
                                        </div>
                                    </div>
                                </a>
                                <div className={styles.offerCardDescription}>
                                    <div className={styles.description}>
                                        <p>{x.description}</p>
                                    </div>
                                    <div className={styles.price}>
                                        <p>€ {x.price.toFixed(2)}</p>
                                    </div>
                                </div>
                                <div className={styles.offerCardValidity}>
                                    <div className={styles.validity}>
                                        Validity: <span>{formatDate(x.startDate)} - {formatDate(x.endDate)}</span>
                                    </div>
                                </div>
                                <div className={styles.offerCardUsed}>
                                    <div className={styles.validity}>
                                        Subscriptions: {x.usedCount}
                                    </div>
                                </div>
                            </div>
                        )
                        : <div>
                            No offers yet. <a href="/offer/edit/new">Create new offer?</a>
                        </div>}
            </div>

        </MainContainer>
    );
};

export default Offers;

const getText = (offer: OfferRs) => {
    if (!offer.active) {
        return "Unactive";
    }
    if (new Date(offer.endDate) < new Date()) {
        return "Expired";
    }
    return "Active";
}

const getColor = (offer: OfferRs) => {
    if (!offer.active) {
        return "red";
    }
    if (new Date(offer.endDate) < new Date()) {
        return "grey";
    }
    return "green";
}
