import * as React from 'react'
import _ from "lodash";
import pagingSelectorStyles from "./PagingSelectorStyles";

type Props = {
    page: number,
    pageNumber: number,
    setPage: any
};

const PAGE_NUMBER_LIMIT = 6;

export const PagingSelector: React.FC<Props> = ({
                                                    page,
                                                    pageNumber,
                                                    setPage
                                                }) => {
    const styles = pagingSelectorStyles();

    const previousOnClickHandler = () => {
        if (page !== 0) {
            setPage(page - 1)
        }
    }
    const nextOnClickHandler = () => {
        if (page !== pageNumber - 1) {
            setPage(page + 1)
        }
    }

    return (
        <nav className={styles.pagePanel}>
            <div className={page === 0 ? styles.pageButtonDisabled : styles.pageButton}
                 onClick={previousOnClickHandler}>Previous
            </div>
            {PAGE_NUMBER_LIMIT > page
                ? _.range(0, pageNumber).map((index) => createButton(index, setPage, styles, page === index))
                : <>
                    {_.range(0, 3).map((index) => createButton(index, setPage, styles, page === index))}
                    <div className={styles.pageButton}>...</div>
                    {_.range(pageNumber - 3, pageNumber).map((index) => createButton(index, setPage, styles, page === index))}
                </>
            }
            <div className={page === pageNumber - 1 ? styles.pageButtonDisabled : styles.pageButton}
                 onClick={nextOnClickHandler}>Next
            </div>
        </nav>
    )
}

const createButton = (index: number, setPage: any, styles: any, isCurrentPageNumber: boolean): React.ReactNode => {

    return <div key={"page" + index} onClick={() => setPage(index)} className={styles.pageButton + " " + (isCurrentPageNumber ? styles.choosedButton : "")}>{index + 1}</div>
}