import React, {ReactElement, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {getUserFromLocalStorage, isAccessRoles, Role} from "../../../util/common";
import analyticsStyles from "../AnalyticsStyles";
import {formatMonth} from "../../../util/dateUtils";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import {LoadingBar} from "../../common/LoadingBar/LoadingBar";
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {formatIsoDate, subtractMonths} from "../../../util/dateUtils";
import {AnalyticRs, getTotalAnalytic, MonthValRs} from "../../../api/AnalyticApi";
import {Message} from "../../common/Message/Message";
import {getReport} from "../../../api/ReportApi";

export type TotalAnalyticsProperties = {
    roles: Array<Role>
}

export const Report = (props: TotalAnalyticsProperties): ReactElement => {
    const styles = analyticsStyles();
    const {roles} = props;
    const [startDate, setStartDate] = useState(formatIsoDate(subtractMonths(1)));
    const [endDate, setEndDate] = useState(formatIsoDate(new Date()));
    const [isLoaded, setIsLoaded] = useState(true);
    const {type} = useParams();
    const [message, setMessage] = useState<string>("");

    const title = "Report"

    const user = getUserFromLocalStorage();

    const writeAccess = isAccessRoles(user.roles, roles, "WRITE");

    useEffect(() => {
        

    }, [type]);

    return (
        <>
            <Message message={message} isError={true}/>
            <MainContainer>
                <TitleWrapper title={title}>
                </TitleWrapper>
                {isLoaded
                    ? <>{

                        <DefaultPageContentContainer title={"Report"}>
                            <div className={styles.dateContainer}>
                                <div className={styles.datePicker}>
                                    <div>Start date</div>
                                    <input value={startDate} type="date"
                                           onChange={(v) => setStartDate(v.target.value)}/>
                                </div>

                                <div className={styles.datePicker}>
                                    <div>End date</div>
                                    <input value={endDate} type="date" onChange={(v) => setEndDate(v.target.value)}/>
                                </div>
                            </div>
                            <div>
                                <div className={styles.submitButton} onClick={x => getReport(startDate, endDate)}>
                                    Download
                                </div>
                            </div>
                           
                            
                        </DefaultPageContentContainer>
                    }
                    </>

                    : <LoadingBar/>
                }
            </MainContainer>
        </>

    );
};