import {createUseStyles} from "react-jss";

const promoCodeFormStyles = createUseStyles({

    inputContainers: {
        paddingLeft: "25px",
        paddingRight: "25px",
        paddingTop: "25px",
        paddingBottom: "25px",
    },
    inputContainer: {
        "& label": {
            display: "block",
            paddingTop: "10px",
            paddingBottom: "5px",
            paddingLeft: "5px",
            fontSize: "12px",
            textAlign: "left",
            fontWeight: "600",
            color: "#717275",
        }
    },
    inputField: {
        margin: 'auto 0',
        display: 'block',
        width: '100%',
        borderWidth: '1px',
        borderColor: '#dfd4e7',
        textDecoration: 'none',
        padding: '5px 0 5px 5px',
        borderRadius: '1px',
        borderStyle: 'solid',
        boxSizing: 'border-box',
        '&:focus': {
            borderColor: "#7681e0",
            outline: 'none'

        },
    },
    submitButton: {
        marginTop: "30px",
        backgroundColor: "#0044EA",
        display: "block",
        cursor: "pointer",
        textAlign: "center",
        textDecoration: "none",
        border: "none",
        color: "#ffffff",
        borderRadius: "5px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "15px",
        paddingRight: "15px",
        boxSizing: "border-box",
        fontWeight: "600",
        fontSize: "14px",
    },
    checkboxContainer: {
        display: "flex"
    },
    inputFieldError: {
        borderColor: '#f33f3f',
        margin: 'auto 0',
        display: 'block',
        width: '100%',
        borderWidth: '1px',
        textDecoration: 'none',
        padding: '5px 0 5px 5px',
        borderRadius: '1px',
        borderStyle: 'solid',
        boxSizing: 'border-box',
        '&:focus': {
            borderColor: "#7681e0",
            outline: 'none'

        },
    },
    inputLabel: {
        display: "flex",
    },
});

export default promoCodeFormStyles;