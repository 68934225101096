import * as React from 'react'
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import vehicleFormStyles from "./VehicleFormStyles";

const validateSchema = Yup.object().shape({
    vehicleModelId: Yup.string().required('Required'),
    externalId: Yup.string().required('Required'),
    qrCode: Yup.string().required('Required'),
});

export function VehicleForm(props: any) {
    const {onSubmitHandle, vehicleModels, formDefaultValues, id} = props;
    const styles = vehicleFormStyles();
    const navigate = useNavigate();

    return (
        <Formik
            initialValues={formDefaultValues}
            validationSchema={validateSchema}
            onSubmit={(values => onSubmitHandle(values, navigate, id))}>
            {(props) => {
                const {errors, touched, isValid} = props;
                return (<>
                        <Form>

                            <div className={styles.inputContainers}>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="vehicleModelId">Model</label>
                                    <Field id="vehicleModelId" component="select"
                                           name="vehicleModelId"
                                           className={errors.vehicleModelId && touched.vehicleModelId ? styles.inputFieldError : styles.inputField}>
                                        {
                                            Array.isArray(vehicleModels)
                                                ? vehicleModels.map((vehicleModel) => <option
                                                    value={vehicleModel.id}>{vehicleModel.name}</option>)
                                                : ""
                                        }
                                    </Field>
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="externalId">External ID</label>
                                    <Field id="externalId"
                                           name="externalId" placeholder={""}
                                           className={errors.externalId && touched.externalId ? styles.inputFieldError : styles.inputField}
                                    />
                                </div>

                                <div className={styles.inputContainer}>
                                    <label htmlFor="qrCode">Qr code</label>
                                    <Field id="qrCode" name="qrCode"
                                           placeholder={""}
                                           className={errors.qrCode && touched.qrCode ? styles.inputFieldError : styles.inputField}
                                    />
                                </div>

                                <div className={styles.inputContainer}>
                                    <label htmlFor="insuranceId">Insurance ID</label>
                                    <Field id="insuranceId" name="insuranceId"
                                           placeholder={""}
                                           className={errors.insuranceId && touched.insuranceId ? styles.inputFieldError : styles.inputField}
                                    />
                                </div>

                                <div className={styles.inputContainer}>
                                    <label htmlFor="insuranceExpired">Insurance expired</label>
                                    <Field id="insuranceExpired"
                                           name="insuranceExpired" type="date"
                                           placeholder={""}
                                           className={styles.inputField}
                                    />
                                </div>
                                <button className={styles.submitButton} type="submit">
                                    Save
                                </button>
                            </div>

                        </Form>
                    </>
                )
            }

            }
        </Formik>
    )
}