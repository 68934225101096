import {createUseStyles} from "react-jss";
import {MAIN_COLOR} from "../../../util/properties";

const alarmPageStyles = createUseStyles({

    checkbox: {
        color: "#717275",
        paddingTop: "20px",
        paddingBottom: "20px",
        display: "block",
        fontSize: "14px",
        lineHeight: "20px"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-start",
    },
    iconContainer: {
        display: "flex",
        justifyContent: "center",
        "& div": {
            opacity: "0.8",
            cursor: "pointer"
        },
        "& div:hover": {
            borderColor: "rgb(251, 143,135)",
        }
    },
    icon: {
        minWidth: "20px",
        maxWidth: "20px",
    },

});

export default alarmPageStyles;