import React, {ReactElement, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {getUserFromLocalStorage, isAccessRoles, Role} from "../../../util/common";
import analyticsStyles from "../AnalyticsStyles";
import {LoadingBar} from "../../common/LoadingBar/LoadingBar";
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {Message} from "../../common/Message/Message";
import {MAP_STYLE, MAPBOX_PUBLIC_TOKEN} from "../../../util/properties";
import Map from "react-map-gl";

export type HeatMapProperties = {
    roles: Array<Role>
}

export const HeatMap = (props: HeatMapProperties): ReactElement => {
    const styles = analyticsStyles();
    const {roles} = props;
    const [isLoaded, setIsLoaded] = useState(false);
    const {type} = useParams();
    const [message, setMessage] = useState<string>("");

    const title = "Heat map";

    const user = getUserFromLocalStorage();

    const writeAccess = isAccessRoles(user.roles, roles, "WRITE");

    useEffect(() => {
        setIsLoaded(true);

    }, [type]);

    return (
        <>
            <Message message={message} isError={true}/>
            <MainContainer>
                <TitleWrapper title={title}>
                </TitleWrapper>
                {isLoaded
                    ? <>
                        <Map
                            initialViewState={{
                                zoom: 12
                            }}
                            mapboxAccessToken={MAPBOX_PUBLIC_TOKEN}
                            style={{width: "100%", height: 400}}
                            mapStyle={MAP_STYLE}
                        >



                        </Map>

                    </>

                    : <LoadingBar/>
                }
            </MainContainer>
        </>

    );
};