import React, {ReactElement} from 'react';
import avatarCircleStyles from "./AvatarCircleStyles";

export interface AvatarCircleProperties {
    user: any
}

const colors = ["#53b232", "#832EBFFF", "#3D81D5FF", "#BE4B7FFF", "#DE9D55FF", "#B093E8FF"]
export const AvatarCircle = (props: AvatarCircleProperties): ReactElement => {
    const {user} = props;
    const styles = avatarCircleStyles();

    const colorIndex = (user.lastName.length + user.firstName.length) % colors.length;
    return (
        <div className={styles.avatarCircle} style={{background: colors[colorIndex]}}>
            <div className={styles.textAvatar}>{user.lastName[0]}{user.firstName[0]}</div>
        </div>
    )
}