import React, {ReactElement, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Message} from "../../common/Message/Message";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {ActionButton} from "../../common/ActionButton/ActionButton";
import {UserField} from "../../common/UserField/UserField";
import alarmStyles from "./AlarmStyles";
import {AlarmShort, getAlarm, getTypeDescription} from "../../../api/AlarmApi";
import {formatDateTime} from "../../../util/dateUtils";
import {getUserFromLocalStorage, isAccessRoles, Role} from "../../../util/common";

const alarmInit: AlarmShort = {
    id: "",
    isActive: false,
    type: "",
    description: "",
    vehicleId: "",
    vehicleQrCode: "",
}

export type AlarmProperties = {
    roles: Array<Role>
}

export const Alarm = (props: AlarmProperties): ReactElement => {
    const styles = alarmStyles();
    const {roles} = props;
    const [alarm, setAlarm] = useState<AlarmShort>(alarmInit);
    const [message, setMessage] = useState<string>("");
    const [isMessageError, setIsMessageError] = useState<boolean>(false);
    const user = getUserFromLocalStorage();
    const {id} = useParams();

    const writeAccess = isAccessRoles(user.roles, roles, "WRITE");

    const createStatusTag = (alarm: AlarmShort) => {
        return alarm.isActive
            ? <span className={styles.tag + " " + styles.greenTag}>Active</span>
            : <span className={styles.tag + " " + styles.redTag}>Unactive</span>
    }

    useEffect(() => {
        getAlarm(id || "").then((result: AlarmShort) => {
            setAlarm(result);
        }).catch((reason: any) => {
            setIsMessageError(true)
            setMessage("Cannot find alarm with id " + id);
        })
    }, [id]);
    return (
        <>
            <Message message={message} isError={isMessageError}/>
            <MainContainer>
                <TitleWrapper title={"Alarm - " + getTypeDescription(alarm.type)}>
                    {writeAccess && <ActionButton title={"Create task"} isLink={true} url={"/task/edit/new/alarms/" + id}/>}
                </TitleWrapper>
                <div className={styles.contentWrapper}>
                    <DefaultPageContentContainer title={"Details"}>
                        <UserField title={"ID:"} field={alarm.id}/>
                        <UserField title={"Status:"} field={createStatusTag(alarm)}/>
                        <UserField title={"Type:"} field={getTypeDescription(alarm.type)}/>
                        <UserField title={"Vehicle (QR code):"}
                                   field={<a href={alarm.vehicleId}>{alarm.vehicleQrCode}</a>}/>
                        <UserField title={"Description: "} field={alarm.description}/>
                        <UserField title={"Trip: "} field={<a href={alarm.tripId}>{alarm.tripId}</a>}/>
                        <UserField title={"Created at: "} field={formatDateTime(alarm.startTime)}/>
                    </DefaultPageContentContainer>
                </div>
            </MainContainer>
        </>
    );
};
