import * as React from "react";
import selectFilterStyles from "./SelectFilterStyles";

type Props = {
    children?: JSX.Element | JSX.Element[],
};

export function SelectFilterContainer(props: Props): React.ReactElement {
    const styles = selectFilterStyles();
    return (
        <div className={styles.filtersContainer}>
            {props.children}
        </div>
    )
}