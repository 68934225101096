import {createUseStyles} from "react-jss";

const mainContainer = createUseStyles({
    mainContainerNoMargins: {
        paddingBottom: "45px"
    },
    mainContainer: {

        marginLeft: "45px",
        marginRight: "45px",
        paddingBottom: "45px"
    },
    '@media (max-width:500px)': {
        mainContainer: {
            marginLeft: "5px",
            marginRight: "5px",
            paddingBottom: "45px"
        },

    },
});

export default mainContainer;