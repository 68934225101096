import * as React from 'react'
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {MoneyPicker} from "../../common/MoneyPicker/MoneyPicker";
import {NumberPicker} from "../../common/NumberPicker/NumberPicker";
import promoCodeFormStyles from "./PromoCodeFormStyles";
import {PhotoPicker} from "../../common/PhotoPicker/PhotoPicker";

const validateSchema = Yup.object().shape({
    code: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    startDate: Yup.date().required("Required"),
    endDate: Yup.date().required("Required")
        .when('startDate', (startDate) => {

            if (startDate) {
                return Yup.date()
                    .min(startDate, 'End Date must be after Start Date');
            }

            return Yup.date();
        }),
});

export function PromoCodeForm(props: any) {
    const {onSubmitHandle, formDefaultValues, id} = props;
    const styles = promoCodeFormStyles();
    const navigate = useNavigate();

    return (
        <Formik
            initialValues={formDefaultValues}
            validationSchema={validateSchema}
            onSubmit={(values => onSubmitHandle(values, navigate, id))}>
            {(props) => {
                const {errors, touched, submitCount} = props;
                const isSubmitting = submitCount > 0;
                return (<>
                        <Form>

                            <div className={styles.inputContainers}>

                                <div className={styles.inputContainer}>
                                    <label htmlFor="code">Code</label>
                                    <Field id="code"
                                           name="code"
                                           placeholder={"Enter code..."}
                                           className={errors.code && (touched.code || isSubmitting)
                                               ? styles.inputFieldError
                                               : styles.inputField}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="description">Description</label>
                                    <Field id="description"
                                           name="description"
                                           placeholder={"Enter description..."}
                                           className={errors.description && (touched.description || isSubmitting)
                                               ? styles.inputFieldError
                                               : styles.inputField}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="discount">Discount</label>
                                    <Field id="discount"
                                           name="discount"
                                           component={MoneyPicker}
                                           placeholder={"1.00"}
                                           errors={errors}
                                           touched={touched}
                                           isSubmitting={isSubmitting}
                                           styles={styles}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="startDate">Start date</label>
                                    <Field id="startDate"
                                           name="startDate" type="date"
                                           placeholder={""}
                                           className={errors.startDate && (touched.startDate || isSubmitting)
                                               ? styles.inputFieldError
                                               : styles.inputField}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="endDate">End date</label>
                                    <Field id="endDate"
                                           name="endDate" type="date"
                                           placeholder={""}
                                           className={errors.endDate && (touched.endDate || isSubmitting)
                                               ? styles.inputFieldError
                                               : styles.inputField}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="userLimit">Limit for user</label>
                                    <Field id="userLimit"
                                           name="userLimit" placeholder={"1"}
                                           component={NumberPicker}
                                           className={errors.userLimit && (touched.userLimit || isSubmitting)
                                               ? styles.inputFieldError
                                               : styles.inputField}
                                    />
                                </div>
                                <div className={styles.inputContainer + " " + styles.checkboxContainer}>
                                    <label htmlFor="active">Is active</label>
                                    <Field id="active"
                                           type="checkbox"
                                           name="active" placeholder={"false"}
                                    />
                                </div>
                                <Field id="photo"
                                       name="photo"
                                       component={PhotoPicker}
                                       className={errors.photo && (touched.photo || isSubmitting)
                                           ? styles.inputFieldError
                                           : styles.inputField}
                                />

                                <button className={styles.submitButton} type="submit">
                                    Save
                                </button>
                            </div>

                        </Form>
                    </>
                )
            }

            }
        </Formik>
    )
}