import * as React from 'react'
import {useEffect, useState} from 'react'
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import {NavigateFunction} from "react-router-dom";
import {useParams} from "react-router-dom";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {Message} from "../../common/Message/Message";
import {TariffForm} from "../TariffForm/TariffForm";
import editTariffStyles from "./EditTariffStyles";
import {createTariffs, getTariff, TariffRequest, TariffResponse, updateTariffs} from "../../../api/TariffsApi";
import {getVehicleModels, VehicleModelResponse} from "../../../api/VehicleModelApi";
import {getLocationId, getUserFromLocalStorage, isAccessRoles, Role} from "../../../util/common";
import {formatIsoDate, formatTimeFromString} from "../../../util/dateUtils";

let formDefaultValues: TariffResponse = {
    id: "",
    name: "",
    vehicleModelId: '',
    startDate: formatIsoDate(new Date()),
    endDate: formatIsoDate(new Date()),
    dayOfWeeks: [],
    isDefault: false
}

export type EditTariffProperties = {
    roles: Array<Role>
}

export function EditTariff(props: EditTariffProperties) {
    const styles = editTariffStyles();
    const {id} = useParams();
    const {roles} = props;
    const user = getUserFromLocalStorage();
    const isNewTariff = id === "new";
    const locationId = getLocationId();
    const [vehicleModels, setVehicleModels] = useState<Array<VehicleModelResponse>>([]);
    const [message, setMessage] = useState<string>("");
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [initValues, setInitValues] = useState<TariffResponse>(formDefaultValues);

    const onSubmitHandle = (values: TariffRequest, navigate: NavigateFunction, id?: string) => {
        values.locationId = locationId;

        if (!isNewTariff) {
            values.id = id;
        }

        const saveTariff = isNewTariff ? createTariffs(values) : updateTariffs(values)
        saveTariff
            .then((result: any) => {
                navigate("/tariff/" + result.id);
            })
            .catch((result: any) => {
                setMessage("Error occurred while saving tariff");
                setTimeout(() => {
                    setMessage("")
                }, 3000)
            });
    }

    useEffect(() => {

        getVehicleModels().then((vehicleModelResponses: Array<VehicleModelResponse>) => {
            setVehicleModels(vehicleModelResponses);
            formDefaultValues.vehicleModelId = vehicleModelResponses.length > 0 ? vehicleModelResponses[0].id : "";

            if (!isNewTariff) {
                getTariff(id).then((tariffResponse: TariffResponse) => {
                    setInitValues({
                        id: tariffResponse.id,
                        name: tariffResponse.name,
                        vehicleModelId: tariffResponse.vehicleModelId,
                        startDate: tariffResponse.startDate,
                        endDate: tariffResponse.endDate,
                        startTime: formatTimeFromString(tariffResponse.startTime),
                        endTime: formatTimeFromString(tariffResponse.endTime),
                        startPrice: tariffResponse.startPrice,
                        pricePerMinute: tariffResponse.pricePerMinute,
                        pausePricePerMinute: tariffResponse.pausePricePerMinute,
                        dayOfWeeks: tariffResponse.dayOfWeeks,
                        isDefault: tariffResponse.isDefault
                    })
                    setIsLoaded(true);
                })
            } else {
                setIsLoaded(true);
            }

        })

    }, [id, isNewTariff]);
    return (
        <>
            <Message message={message} isError={true}/>
            <MainContainer>
                <TitleWrapper title={(isNewTariff ? "New" : "Edit") + " tariff"}>
                </TitleWrapper>
                <div className={styles.mainWrapper}>
                    <div className={styles.contentWrapper}>
                        <DefaultPageContentContainer title={"Details"}>
                            {
                                isLoaded
                                    ? <TariffForm onSubmitHandle={onSubmitHandle} vehicleModels={vehicleModels}
                                                  formDefaultValues={initValues} id={id}/>
                                    : <img src={"/loading.gif"} alt={""}/>
                            }
                        </DefaultPageContentContainer>
                    </div>
                </div>

            </MainContainer>
        </>
    )
}

