import * as React from 'react'
import {useEffect, useState} from 'react'
import {Message} from "../../common/Message/Message";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {ActionButton} from "../../common/ActionButton/ActionButton";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import {AlarmPage} from "../../Alarms/AlarmPage/AlarmPage";
import taskStyles from "./TaskStyles";
import {useParams} from "react-router-dom";
import {getTask, TaskEntry, TaskResponse, updateTask} from "../../../api/TaskApi";
import {AvatarCircle} from "../../common/AvatarCircle/AvatarCircle";
import {UserField} from "../../common/UserField/UserField";
import {Comments} from "./Comments/Comments";
import {formatDateTime, getDurationText} from "../../../util/dateUtils";
import {getFullName} from "../../../api/UserApi";
import {getTypeDescription} from "../../../api/AlarmApi";
import {getUserFromLocalStorage, isAccessRoles, Role} from "../../../util/common";

export type TaskProperties = {
    roles: Array<Role>
}

export function Task(props: TaskProperties) {
    const user = getUserFromLocalStorage();
    const {id} = useParams();
    const styles = taskStyles();
    const {roles} = props;
    const [task, setTask] = useState<TaskResponse | undefined>(undefined);
    const [isMessageError, setIsMessageError] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");

    const writeAccess = isAccessRoles(user.roles, roles, "WRITE");

    const closeTask = () => {
        const taskRequest = {
            title: task ? task.title : "",
            description: task ? task.description : "",
            priority: task ? task.priority : "",
            status: "CLOSED",
            vehicleId: task && task.vehicle && task.vehicle.id,
            alarmId: task && task.alarmId,
            assignedId: task && task.assigned && task.assigned.id
        }
        updateTask(id || "", taskRequest)
            .then(() => {
                window.location.reload()
            })
            .catch(() => {
                setMessage("Error occurred while closing task");
                setTimeout(() => {
                    setMessage("")
                }, 3000)
            });
    }

    useEffect(() => {
        getTask(id || "").then((result: TaskResponse) => {
            setTask(result)
        })
            .catch(() => {
                setIsMessageError(true);
                setMessage("Error occurred while loading task")
            })

    }, [id]);

    return (
        <>
            <Message message={message} isError={isMessageError}/>
            {task ?
                <MainContainer>
                    <TitleWrapper title={"Task - " + task.id}>
                        {writeAccess && <ActionButton title={"Close"} isLink={false} onClickHandle={closeTask}/>}
                        {writeAccess && <ActionButton title={"Edit"} isLink={true} url={"/task/edit/" + id}/>}
                    </TitleWrapper>
                    <div className={styles.contentWrapper}>
                        <div className={styles.leftColumn}>
                            <DefaultPageContentContainer title={"Details"}>
                                <UserField title={"Title:"} field={task.title}/>
                                <UserField title={"Priority:"} field={task.priority}/>
                                <UserField title={"Status:"} field={<span
                                    className={styles.tag + " " + styles.greenTag}>{task.status}</span>}/>
                                <UserField title={"Vehicle:"} field={task.vehicle && <span>Vehicle (qr code)<a
                                    href={"/vehicle/" + task.vehicle.id}>{task.vehicle.qrCode}</a></span>}/>
                                <UserField title={"Alarm:"} field={<span><a
                                    href={"/alarm/" + task.alarmId}>{getTypeDescription(task.alarmType)}</a></span>}/>
                                <UserField title={"Created at:"} field={formatDateTime(task.createdAt)}/>
                                <UserField title={"Changed at:"} field={formatDateTime(task.changedAt)}/>
                                <UserField title={"Assigned at:"} field={task.assigned &&
                                    <a href={"/user/" + task.assigned.id}>{getFullName(task.assigned)}</a>}/>
                            </DefaultPageContentContainer>

                        </div>
                        <div className={styles.rightColumn}>
                            <DefaultPageContentContainer title={"Description"}>
                                <div className={styles.taskDescriptionContainer}>
                                    {task.description}
                                </div>

                            </DefaultPageContentContainer>
                            : <></>

                            <DefaultPageContentContainer title={"Comments"}>
                                <Comments taskId={id || ""}/>
                            </DefaultPageContentContainer>

                            <DefaultPageContentContainer title={"History"}>
                                {task && task.entries && createEntries(task.entries, styles)
                                }
                            </DefaultPageContentContainer>
                            <AlarmPage roles={roles} title={"Alarm"} vehicleId={id} id={task.alarmId}/>
                            : <></>
                        </div>
                    </div>
                </MainContainer>
                : <img src={"/loading.gif"} alt={""}/>
            }
        </>
    )
}

const createEntries = (entries: Array<TaskEntry>, styles: any) => {
    if (entries.length <= 1) {
        return <div>No changes</div>
    }

    let elements = [];
    for (let i = 1; i < entries.length; i++) {
        const prev = entries[i - 1];
        const current = entries[i];

        elements.push(<div className={styles.historyEntryContent}>
            <AvatarCircle user={current.author}/>
            <div className={styles.historyEntryTextContent}>
                <div
                    className={styles.historyEntryAboutInfo}>{current.author?.lastName} {current.author?.firstName} Changed
                    task {getDurationText(current.changedAt, new Date())} ago
                </div>
                <div className={styles.historyEntryText}>
                    {current.status !== prev.status ? (<div>Status changed from <span
                        className={styles.historyEntryField}>{prev.status}</span> to <span
                        className={styles.historyEntryField}>{current.status}</span></div>) : ""}
                </div>
                <div className={styles.historyEntryText}>
                    {current.title !== prev.title ? (
                        <div>Title changed from <span className={styles.historyEntryField}>{prev.title}</span> to <span
                            className={styles.historyEntryField}>{current.title}</span></div>) : ""}
                </div>
                <div className={styles.historyEntryText}>
                    {current.priority !== prev.priority ? (<div>Priority changed from <span
                        className={styles.historyEntryField}>{prev.priority}</span> to <span
                        className={styles.historyEntryField}>{current.priority}</span></div>) : ""}
                </div>
                <div className={styles.historyEntryText}>
                    {current.description !== prev.description ? (<div>Description changed</div>) : ""}
                </div>
                {current.assigned?.id !== prev.assigned?.id
                    ? (<div>
                        Assigned changed from
                        {prev.assigned
                            ? <a href={"/user/" + prev.assigned.id}
                                 className={styles.historyEntryField}> {getFullName(prev.assigned)}</a>
                            : <span className={styles.historyEntryField}> No assignee</span>
                        } to
                        {current.assigned
                            ? <a href={"/user/" + current.assigned.id}
                                 className={styles.historyEntryField}> {getFullName(current.assigned)}</a>
                            : <span className={styles.historyEntryField}> No assignee</span>
                        }
                    </div>) : ""}
            </div>
        </div>)

    }
    return elements;
}
