import * as React from 'react'
import {useEffect, useState} from 'react'
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import {NavigateFunction, useParams} from "react-router-dom";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {Message} from "../../common/Message/Message";
import editTaskStyles from "./EditTaskStyles";
import {TaskForm} from "../TaskForm/TaskForm";
import {getAdmins, UserAdminRs} from "../../../api/UserApi";
import {getLocationId, PageResponse} from "../../../util/common";
import {createTask, getTask, TaskRequest, TaskResponse, updateTask} from "../../../api/TaskApi";
import {getVehicles, VehicleShortResponse} from "../../../api/VehicleApi";
import {AlarmShort, getAlarms} from "../../../api/AlarmApi";

let formDefaultValues = {
    title: "",
    description: "",
    priority: "",
}

export function EditTask() {
    const styles = editTaskStyles();
    const {id, type, subjectId} = useParams();
    const [message, setMessage] = useState<string>("");
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [initValues, setInitValues] = useState<any>(formDefaultValues);
    const [workgroup, setWorkgroup] = useState<Array<UserAdminRs>>([]);
    const [vehicles, setVehicles] = useState<Array<VehicleShortResponse>>([]);
    const [alarms, setAlarms] = useState<Array<AlarmShort>>([]);
    const locationId = getLocationId();
    const isNewTask = id === "new";

    const onSubmitHandle = (values: TaskRequest, navigate: NavigateFunction, id?: string) => {

        const saveTariff = isNewTask ? createTask(values) : updateTask(id || "", values)
        saveTariff
            .then((result: any) => {
                navigate("/task/" + (isNewTask ? result : id));
            })
            .catch(() => {
                setMessage("Error occurred while saving task");
                setTimeout(() => {
                    setMessage("")
                }, 3000)
            });
    }

    const loadDictionaryData = () => {
        getAdmins(0).then((admins: PageResponse<UserAdminRs>) => {
            setWorkgroup(admins.content);
            getVehicles({page: 0, size: 200}).then((result: PageResponse<VehicleShortResponse>) => {
                setVehicles(result.content);

                getAlarms(locationId, undefined, undefined, undefined, 0, undefined, 200).then((result) => {
                    setAlarms(result.content);
                    setIsLoaded(true);
                })
            })

        })
    };

    useEffect(() => {
        if (!isNewTask) {
            getTask(id || "").then((result: TaskResponse) => {
                setInitValues({
                    id: result.id,
                    title: result.title,
                    description: result.description,
                    priority: result.priority,
                    status: result.status,
                    assignedId: result.assigned && result.assigned.id,
                    vehicleId: type === "vehicles" ? subjectId : "",
                    alarmId: type === "alarms" ? subjectId : ""
                });
                loadDictionaryData()
            })
        } else {
            setInitValues({
                priority: "LOW",
                vehicleId: type === "vehicles" ? subjectId : "",
                alarmId: type === "alarms" ? subjectId : ""
            });
            loadDictionaryData()
        }
    }, []);
    return (
        <>
            <Message message={message} isError={true}/>
            <MainContainer>
                <TitleWrapper title={"Edit task"}>
                </TitleWrapper>
                <div className={styles.mainWrapper}>
                    <div className={styles.contentWrapper}>
                        <DefaultPageContentContainer title={"Details"} disableOverflow={true}>
                            {
                                isLoaded
                                    ? <TaskForm vehicles={vehicles} alarms={alarms} onSubmitHandle={onSubmitHandle}
                                                formDefaultValues={initValues} workgroup={workgroup} id={id || ""}/>
                                    : <img src={"/loading.gif"} alt={""}/>
                            }
                        </DefaultPageContentContainer>
                    </div>
                </div>

            </MainContainer>
        </>
    )
}

