import * as React from 'react'
import {useEffect, useState} from 'react'
import {TitleWrapper} from "../common/TitleWrapper/TitleWrapper";
import {MainContainer} from "../common/MainContainer/MainContainer";
import {Link, useNavigate} from "react-router-dom";
import storagesStyles from "./StoragesStyles";
import {getStorages, StorageShortRs} from "../../api/StoragesApi";
import {DefaultListContentContainer} from "../common/TableContainer/DefaultListContentContainer";
import {ActionButton} from "../common/ActionButton/ActionButton";
import {getUserFromLocalStorage, isAccessRoles, Role} from "../../util/common";
import {VehicleModelProperties} from "../VehicleModels/VehicleModel/VehicleModel";
import {LoadingBar} from "../common/LoadingBar/LoadingBar";



export type StoragesProperties = {
    roles: Array<Role>
}

export function Storages(props: StoragesProperties) {
    const styles = storagesStyles();
    const {roles} = props;
    const user = getUserFromLocalStorage();
    const [storages, setStorages] = useState<Array<StorageShortRs> | undefined>(undefined);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const writeAccess = isAccessRoles(user.roles, roles, "WRITE");

    const createDataHeader = (): React.ReactElement => {
        return <>
            <thead>
            <tr className={styles.headerRow}>
                <th className="">Name</th>
                <th className="">Vehicles number</th>
                <th></th>
            </tr>
            </thead>
        </>
    }

    const createDataRow = (storage: StorageShortRs): React.ReactElement => {
        return <>
            <tr className={styles.dataRow}>
                <th className=""><a href={"/storage/" + storage.id}>{storage.name}</a></th>
                <th className="">{storage.vehicleNumbers}</th>
                <th className={styles.iconContainer}>
                    <Link to={"/storage/" + storage.id}><img className={styles.icon} src="/eye.svg" alt=""/></Link>
                    {writeAccess && <Link to={"/storage/edit/" + storage.id}><img className={styles.icon} src="/edit.svg" alt=""/></Link>}
                </th>
            </tr>
        </>
    }

    useEffect(() => {
        getStorages()
            .then((result: Array<StorageShortRs>) => {
                setStorages(result);
                setDataLoaded(true);
            })
    }, []);

    return (
        <MainContainer>
            <TitleWrapper title={"Storages - " + (storages ? storages.length : "")}>
                {writeAccess && <ActionButton title={"New storage"} isLink={true} url={"/storage/edit/new"}/>}
            </TitleWrapper>
            {dataLoaded ?
                <DefaultListContentContainer title={"Storages"} list={storages}>
                    <table className={styles.dataTable}>
                        {createDataHeader()}
                        <tbody>
                        {storages && storages.length > 0
                            ? storages.map((storage, index) => createDataRow(storage))
                            : <tr className={styles.noDataBlock}><th>No storages found</th></tr>}
                        </tbody>
                    </table>
                </DefaultListContentContainer>
                : <LoadingBar/>
            }
        </MainContainer>
    )
}
