import {createUseStyles} from "react-jss";
import {MAIN_COLOR} from "../../../util/properties";

export const tagStyles = createUseStyles({
    tag: {
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
        backgroundColor: "rgb(253 253 253)",
        borderRadius: "9999px",
        borderWidth: "1px",
        borderStyle: "solid",
        whiteSpace: "nowrap",
        fontSize: "0.75rem",
        fontWeight: "600",
        textTransform: "uppercase",
        letterSpacing: "0.025em",
    },
    yellowTag: {
        color: "rgb(204 102 0)",
        borderColor: "rgb(204 102 0)",
    },
    greenTag: {
        color: "rgb(61 161 38)",
        borderColor: "rgb(61 161 38)",
    },
    redTag: {
        color: "rgb(178 6 6 )",
        borderColor: "rgb(178 6 6 )",
    },
});