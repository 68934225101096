import {
    createDefaultHeaders,
    createUrlParameters, fetchNoResponse,
    fetchWithResponse,
    getAccessTokenFromLocalStorage, getLocationId,
    PageResponse
} from "../util/common";
import {HOST} from "../util/properties";

export type TaskRequest = {
    title: string,
    description: string,
    priority: string,
    status?: string,
    vehicleId?: string,
    alarmId?: string,
    assignedId?: string
    locationId?: string
}

export type TaskShortResponse = {
    id: string,
    changedAt: Date,
    createdAt: Date,
    title: string,
    description: string,
    assigned?: UserRs,
    vehicle?: vehicleResponse,
    alarmId?: string,
    locationId: string,
    status: string,
    priority: string,
}

export type vehicleResponse = {
    id: string,
    qrCode: string
}

export type TaskResponse = {
    id: string,
    changedAt: Date,
    createdAt: Date,
    title: string,
    description: string,
    assigned: UserRs,
    status: string,
    priority: string,
    vehicle: vehicleResponse,
    alarmId: string,
    alarmType: string,
    locationId: string,
    comments: Array<CommentRs>,
    entries: Array<TaskEntry>
}

export type TaskEntry = {
    id: string,
    title: string,
    description: string,
    assigned: UserRs,
    author: UserRs,
    status: string,
    priority: string,
    changedAt: Date
}

export type CommentRq = {
    comment: string
}

export type CommentRs = {
    id: string,
    comment: string,
    author: UserRs,
    createdAt: Date,
    changedAt: Date
}

export type UserRs = {
    id: string,
    firstName: string,
    lastName: string,
    middleName?: string
}

export const getTask = (id: string): Promise<TaskResponse> => {
    const userToken = getAccessTokenFromLocalStorage();
    const paramString = createUrlParameters({
        "locationId": getLocationId(),
    });

    return fetchWithResponse(HOST + "/api/tasks/" + id + paramString, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const getTasks = (page?: number, assignedId?:string, status?: string, sort?: string): Promise<PageResponse<TaskShortResponse>> => {
    const userToken = getAccessTokenFromLocalStorage();
    const paramString = createUrlParameters({
        "assignedId": assignedId,
        "locationId": getLocationId(),
        "page": page,
        "sort": sort,
        "status": status ? status.toUpperCase() : ""
    });

    return fetchWithResponse(HOST + "/api/tasks" + paramString, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const getCountAssigned = () => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/tasks/assigned/count", {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
}

export const getComments = (taskId: string): Promise<Array<CommentRs>> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/tasks/" + taskId + "/comments" , {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const createTask = (values: TaskRequest): any => {
    const userToken = getAccessTokenFromLocalStorage();
    values.locationId = getLocationId();

    return fetchWithResponse(HOST + "/api/tasks", {
        method: 'POST',
        body: JSON.stringify(values),
        headers: createDefaultHeaders(userToken)
    })
};

export const updateTask = (id: string, values: TaskRequest): any => {
    const userToken = getAccessTokenFromLocalStorage();
    values.locationId = getLocationId();

    return fetchNoResponse(HOST + "/api/tasks/" + id, {
        method: 'PUT',
        body: JSON.stringify(values),
        headers: createDefaultHeaders(userToken)
    })
};

export const addComment = (taskId: string, values: CommentRq): any => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/tasks/" + taskId + "/comments", {
        method: 'POST',
        body: JSON.stringify(values),
        headers: createDefaultHeaders(userToken)
    })
};

export const updateComment = (id: string, values: CommentRq): any => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchNoResponse(HOST + "/api/tasks/comments/" + id, {
        method: 'PUT',
        body: JSON.stringify(values),
        headers: createDefaultHeaders(userToken)
    })
};

export const deleteComment = (id: string): any => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchNoResponse(HOST + "/api/tasks/comments/" + id, {
        method: 'DELETE',
        headers: createDefaultHeaders(userToken)
    })
};