import {HOST} from "../util/properties";

export type LoginResponse = {
    accessToken: string,
    refreshToken: string;
}

export const loginApi = (email: string, password: string): Promise<LoginResponse> => {
    return fetch(HOST + '/api/auth/login', {
        method: 'POST',
        body: JSON.stringify({email: email, password: password}),
        headers: {
            'Content-Type': 'application/json'
        },
    }).then((response) => {
        if (response.status === 200) {
            return response.json()
        }
        throw Error();
    })
};

export const refreshToken = (refreshToken: string): Promise<LoginResponse> => {
    return fetch(HOST + '/api/auth/refresh?refreshToken=' + refreshToken, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then((response) => {
        if (response.status === 200) {
            return response.json()
        }
        throw Error();
    })
};