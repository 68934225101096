import * as React from 'react'
import {ReactElement, useState} from 'react'
import signUpStyles from "./SignUpStyles";
import {Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import {loginApi, LoginResponse} from "../../api/AuthApi";
import jwt_decode from "jwt-decode";
import {useNavigate} from "react-router-dom";
import {NavigateFunction} from "react-router-dom";
import {getInitLocation, getLocations} from "../../api/LocationApi";
import {saveLocation, saveTokens} from "../../util/common";

type LoginRequest = {
    email: string,
    password: string
}

const SignupSchema = Yup.object().shape({
    email: Yup.string().email('Enter the correct email').required('required'),
});

export function SignUp(): ReactElement {
    const styles = signUpStyles();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<string>("");

    return (
        <div className={styles.signupBlock}>
            <div className={styles.logoContainer}>
                <img src="./logo.png" alt=""/>
            </div>
            <div>
                <Formik
                    initialValues={{email: '', password: ""}}
                    validationSchema={SignupSchema}
                    onSubmit={(values => onSubmitHandle(values, navigate, setErrorMessage))}>
                    {(props) => {
                        const {errors, touched, isValid} = props;
                        return (<>
                                <Form>
                                    <div className={styles.inputContainers}>
                                        <div className={styles.inputContainer}>
                                            <Field id="email" name="email" type="email" placeholder={"Email"}
                                                   className={errors.email && touched.email ? styles.inputFieldError : styles.inputField}/>
                                            {errors.email ? <div className={styles.inputLabel}>
                                                <div>
                                                    {errors.email}
                                                </div>
                                            </div> : null}
                                        </div>

                                        <Field id="password" name="password" placeholder={"Password"} type={"password"}
                                               className={styles.inputField}/>
                                        <label className={styles.staySigned}>
                                            <Field type="checkbox" name={`staySignedIn`}/>
                                            Stay signed in
                                        </label>
                                    </div>

                                    {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
                                    <div className={styles.submitButtonContainer}>
                                        <div className={styles.forgotPassword}>
                                            <a href="">Forgot password?</a>
                                        </div>
                                        <button type="submit" className={styles.submitButton}>
                                            Log in
                                        </button>
                                    </div>
                                </Form>
                            </>
                        )
                    }

                    }
                </Formik>

            </div>
        </div>
    )
}

const onSubmitHandle = (values: LoginRequest, navigate: NavigateFunction, setErrorMessage: any) => {
    loginApi(values.email, values.password)
        .then((result: LoginResponse) => {
            const locationId = localStorage.getItem("locationId");
            saveTokens(result).then((result) => {
                navigate("/");

                if (!locationId) {
                    getLocations().then((locations) => {
                        if (locations.length === 0) {
                            setErrorMessage("No locations in system! Contact admin");
                        } else {
                            saveLocation(locations[0]);
                        }
                    })
                }
            });

        })
        .catch((result: any) => {
            setErrorMessage("Wrong login or password");
        });
}

