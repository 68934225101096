import * as React from 'react';
import {useEffect, useState} from 'react';
import {getTrips, TripShort, TripShortPriceRs} from "../../../../api/TripApi";
import {Page} from "../../../common/Page/Page";
import {getLocationId, PageResponse} from "../../../../util/common";
import {Link} from "react-router-dom";
import {formatDateTime} from "../../../../util/dateUtils";

export type TripsPageProps = {
    id?: string,
    isSubscription: boolean,
    title: string,
}

const createTripHeaderRow = () => {
    return <>
        <th className="">ID</th>
        <th className="">Start time</th>
        <th className="">Price</th>

    </>
}

const createTripDataRow = (trip: TripShortPriceRs, navigate: any, styles: any): React.ReactElement => {
    return <tr key={trip.id}>
        <th className=""><Link to={"/trip/" + trip.id}>{trip.id}</Link></th>
        <th className="">{formatDateTime(trip.createdAt)}</th>
        <th className="">{trip.price}</th>
    </tr>
}


export function TripsPage(props: TripsPageProps): React.ReactElement {
    const {title, id, isSubscription} = props;
    const locationId = getLocationId();
    const [page, setPage] = useState<number>(0);
    const [data, setData] = useState<PageResponse<TripShort> | undefined>(undefined);
    useEffect(() => {
        getTrips(locationId, isSubscription, id).then((result: any) => {
            setData(result);
        })

    }, []);

    return (<>
        <Page title={title} createHeaderRow={createTripHeaderRow} createDataRow={createTripDataRow} page={page} setPage={setPage} data={data}/>
    </>)
}
