import { useState, useEffect, useRef, useCallback } from "react";
const StickyHeader = (defaultSticky = false) => {
    const [isSticky, setIsSticky] = useState(defaultSticky);
    const tableRef = useRef(null);
    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    const toggleSticky = useCallback(
        // @ts-ignore
        ({ top, bottom }) => {
            if (top <= 0 && bottom > 2 * 68) {
                !isSticky && setIsSticky( true);
            } else {
                isSticky && setIsSticky(false);
            }
        },
        [isSticky]
    );
    useEffect(() => {
        if (!tableRef) {
            return;
        }
        const handleScroll = () => {
            // @ts-ignore
            toggleSticky(tableRef.current.getBoundingClientRect());
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [toggleSticky]);
    return { tableRef, windowSize, isSticky };
};
export default StickyHeader;