import * as React from 'react'
import tableContainerStyles from "./TableContainerStyles";

type Props<T> = {
    children?: JSX.Element | JSX.Element[],
    title: string,
    list?: Array<T>
};

export const DefaultListContentContainer: React.FC<Props<any>> = ({
                                                    children,
                                                    title,
                                                    list
                                                }) => {
    const styles = tableContainerStyles();

    return (
        <div className={styles.contentContainer}>
            <div className={styles.contentHeaderContainer}>
                <div className={styles.contentHeader}>
                    <h2>{title}{list ? (" - " + list.length) : ""}</h2>
                </div>
            </div>
            {children}
        </div>
    )
}