import {createUseStyles} from "react-jss";

const offersStyles = createUseStyles({
    offerCardContainer: {
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        color: "#4a4a4b",
        "& a": {
            color: "#1d74d5",
            textDecoration: "none",
        }
    },
    offerCard: {
        width: "20vw",
        marginRight: "2vw",
        marginTop: "2vw",
        padding: "25px",
        background: "#ffffff",
        boxShadow: "0 0 10px rgba(150,154,213, 0.5)",
        boxSizing: "border-box",
        borderRadius: "5px",
        "& a": {
            color: "#4a4a4b",
            textDecoration: "none",
        }
    },
    presentImageWrapper: {
        width: "25px",
        "& img": {
            maxWidth: "25px"
        }
    },
    offerCardHeader: {
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: "15px",

    },
    offerCardName: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center"
    },
    offerCardDescription: {
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: "15px"
    },
    offerCardValidity: {
        display: "flex",
        justifyContent: "space-between"
    },
    description: {
        width: "80%",
        textAlign: "left"
    },
    validity: {

    },
    offerCardUsed: {
        textAlign: "left"
    },
    price: {

    }
});

export default offersStyles;