import {createUseStyles} from "react-jss";

const pageStyles = createUseStyles({
    headerRow: {
        borderBottomStyle: "solid",
        borderBottomWidth: "2px",
        borderBottomColor: "#EDF0F1"
    },
    icon: {
        minWidth: "15px",
        maxWidth: "15px",
    },
    iconContainer: {
        display: "flex",
        justifyContent: "center",
        "& a": {
            boxSizing: "border-box",
            padding: "5px",
            marginRight: "3px",
            border: "1px solid #000000",
            borderRadius: "5px",
            opacity: "0.8"
        },
        "& a:hover": {
            borderColor: "rgb(251, 143,135)",
        }
    },
    '@media (max-width:1000px)': {
        iconContainer: {
            display: "flex",
            justifyContent: "space-between",
        }
    },
    dataRow: {
        borderBottom: "2px solid #EEF0F1",
        boxSizing: "border-box",
    },
    dataTable: {
        borderCollapse: "collapse",
        width: "100%",
        fontSize: "14px",
        '& tr:nth-child(even)': {
            backgroundColor: "rgb(199 203 209 / 0.1)",
        },
        '& tr': {
            borderTop: "1px solid rgb(96 105 118 / 0.2)",
            '& th': {
                color: "rgb(96 105 118)",
                padding: '10px',
                '& a': {
                    color: "rgb(96 105 118)",
                    textDecoration: "none",
                }
            }
        }
    },
    noDataBlock: {
        paddingTop: "10px",
        paddingBottom: "10px",
    },
})

export default pageStyles;