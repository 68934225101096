import React, {ReactElement} from 'react';
import loadingBarStyles from "./LoadingBarStyles";

export const LoadingBar = (): ReactElement => {
    const styles = loadingBarStyles();
    return (
        <div className={styles.loadingBarContainer}>
            <div className={styles.imageContainer}>
                <img src={"/loading.gif"} alt={""}/>
            </div>
        </div>
    )
}