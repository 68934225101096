import * as React from 'react'
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import userFormStyles from "./UserFormStyles";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import {countryPhoneCodes} from "../../../util/CountryPhoneCodes";

const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/


const validateSchema = Yup.object().shape({
    phoneNumber: Yup.string(),//.matches(phoneRegExp, 'Phone number is not valid'),
    email: Yup.string().email(),
});

export function UserForm(props: any) {
    const {onSubmitHandle, formDefaultValues, id} = props;
    const styles = userFormStyles();
    const navigate = useNavigate();

    return (
        <Formik
            initialValues={formDefaultValues}
            validationSchema={validateSchema}
            onSubmit={(values => onSubmitHandle(values, navigate, id))}>
            {(props) => {
                const {errors, touched, isValid} = props;
                return (<>
                        <Form>

                            <div className={styles.mainWrapper}>

                                <div className={styles.contentWrapper}>

                                    <DefaultPageContentContainer title={"Details"}>
                                        <div className={styles.inputContainers}>
                                            <div className={styles.inputContainer}>
                                                <label htmlFor="language">Language</label>
                                                <Field id="language" component="select"
                                                       name="language"
                                                       className={errors.language && touched.language ? styles.inputFieldError : styles.inputField}>
                                                    <>
                                                        <option value={"DE"}>Deutsch</option>
                                                        <option value={"EN"}>English</option>
                                                        <option value={"RU"}>Russian</option>
                                                    </>
                                                </Field>
                                            </div>
                                            <div className={styles.inputContainer}>
                                                <label htmlFor="firstName">First name</label>
                                                <Field id="firstName"
                                                       name="firstName" placeholder={""}
                                                       className={errors.firstName && touched.firstName ? styles.inputFieldError : styles.inputField}
                                                />
                                            </div>

                                            <div className={styles.inputContainer}>
                                                <label htmlFor="lastName">Last name</label>
                                                <Field id="lastName"
                                                       name="lastName" placeholder={""}
                                                       className={errors.lastName && touched.lastName ? styles.inputFieldError : styles.inputField}
                                                />
                                            </div>
                                            <div className={styles.inputContainer}>
                                                <label htmlFor="middleName">Middle name</label>
                                                <Field id="middleName" name="middleName"
                                                       placeholder={""}
                                                       className={errors.middleName && touched.middleName ? styles.inputFieldError : styles.inputField}
                                                />
                                            </div>
                                            <div className={styles.inputContainer}>
                                                <label htmlFor="gender">Gender</label>
                                                <Field id="gender" component="select"
                                                       name="gender"
                                                       className={errors.gender && touched.gender ? styles.inputFieldError : styles.inputField}>
                                                    <option value="MALE">Male</option>
                                                    <option value="FEMALE">Female</option>
                                                </Field>
                                            </div>


                                            <div className={styles.inputContainer}>
                                                <label htmlFor="email">Email</label>
                                                <Field id="email" name="email"
                                                       type="email" placeholder={"Email"}
                                                       className={errors.email && touched.email ? styles.inputFieldError : styles.inputField}
                                                />
                                            </div>

                                            <div className={styles.inputContainer}>
                                                <label htmlFor="phoneNumber">Phone number</label>
                                                <Field id="phoneNumber"
                                                       name="phoneNumber" type="text"
                                                       placeholder={""}
                                                       className={errors.phoneNumber && touched.phoneNumber ? styles.inputFieldError : styles.inputField}
                                                />
                                            </div>
                                        </div>
                                    </DefaultPageContentContainer>
                                </div>
                                <div className={styles.contentWrapper}>
                                    <DefaultPageContentContainer title={"Address"}>
                                        <div className={styles.inputContainers}>
                                            <div className={styles.inputContainer}>
                                                <label htmlFor="address">Address</label>
                                                <Field id="address"
                                                       name="address" placeholder={""}
                                                       className={errors.address && touched.address ? styles.inputFieldError : styles.inputField}
                                                />
                                            </div>

                                            <div className={styles.inputContainer}>
                                                <label htmlFor="city">City</label>
                                                <Field id="city"
                                                       name="city" placeholder={""}
                                                       className={errors.city && touched.city ? styles.inputFieldError : styles.inputField}
                                                />
                                            </div>
                                            <div className={styles.inputContainer}>
                                                <label htmlFor="zip">Zip</label>
                                                <Field id="zip" name="zip"
                                                       placeholder={""}
                                                       className={errors.zip && touched.zip ? styles.inputFieldError : styles.inputField}
                                                />
                                            </div>

                                            <div className={styles.inputContainer}>
                                                <label htmlFor="state">State</label>
                                                <Field id="state" name="state"
                                                       placeholder={""}
                                                       className={errors.state && touched.state ? styles.inputFieldError : styles.inputField}
                                                />
                                            </div>

                                            <div className={styles.inputContainer}>
                                                <label htmlFor="country">Country</label>
                                                <Field id="country" name="country"
                                                       placeholder={""}
                                                       component={"select"}
                                                       className={errors.country && touched.country ? styles.inputFieldError : styles.inputField}
                                                >
                                                    {countryPhoneCodes.sort((a, b) => (a.nameEn < b.nameEn ? -1 : 1)).map((countryPhoneCode) => <option
                                                        value={countryPhoneCode.nameEn}>{countryPhoneCode.nameEn}
                                                    </option>)}
                                                </Field>
                                            </div>
                                        </div>
                                    </DefaultPageContentContainer>

                                </div>

                            </div>
                            <button className={styles.submitButton} type="submit">
                                Save
                            </button>
                        </Form>
                    </>
                )
            }

            }
        </Formik>
    )
}