import React, {ReactElement} from 'react';
import userFieldStyles from "./UserFieldStyles";

export interface UserFieldProperties {
    title: any,
    field: any
}

export const UserField = (props: UserFieldProperties): ReactElement => {
    const {title, field} = props;
    const styles = userFieldStyles();
    return (
        <div className={styles.fieldWrapper}>
            <div className={styles.fieldName}>
                {title}
            </div>
            <div className={styles.fieldValue}>
                <span>{field}</span>
            </div>
        </div>
    )
}