import React, {ReactElement, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Message} from "../../common/Message/Message";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import vehicleModelStyles from "./VehicleModelStyles";
import {getVehicleModel, VehicleModelResponse} from "../../../api/VehicleModelApi";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {ActionButton} from "../../common/ActionButton/ActionButton";
import {UserField} from "../../common/UserField/UserField";
import {getUserFromLocalStorage, isAccessRoles, Role} from "../../../util/common";

const vehicleResponseInit: VehicleModelResponse = {
    id: "",
    name: ""
}

export type VehicleModelProperties = {
    roles: Array<Role>
}

const VehicleModel = (props: VehicleModelProperties): ReactElement => {
    const styles = vehicleModelStyles();
    const {roles} = props;
    const [vehicleModel, setVehicleModel] = useState<VehicleModelResponse>(vehicleResponseInit);
    const [message, setMessage] = useState<string>("");
    const user = getUserFromLocalStorage();
    const [isMessageError, setIsMessageError] = useState<boolean>(false);
    const {id} = useParams();

    useEffect(() => {
        getVehicleModel(id).then((result: VehicleModelResponse) => {
            setVehicleModel(result);
        }).catch((reason: any) => {
            setIsMessageError(true)
            setMessage("Cannot find vehicle model with id " + id);
        })
    }, [id]);
    return (
        <>
            <Message message={message} isError={isMessageError}/>
            <MainContainer>
                <TitleWrapper title={"Vehicle - " + vehicleModel.name}>
                    {isAccessRoles(user.roles, roles, "WRITE") &&  <ActionButton title={"Edit"} isLink={true} url={"/vehicle-models/edit/" + id}/>}
                </TitleWrapper>
                <div className={styles.contentWrapper}>
                        <DefaultPageContentContainer title={"Details"}>
                            <UserField title={"ID:"} field={vehicleModel.id}/>
                            <UserField title={"Name:"} field={vehicleModel.name}/>
                        </DefaultPageContentContainer>
                </div>
            </MainContainer>
        </>
    );
};

export default VehicleModel;
