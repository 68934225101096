import {createUseStyles} from "react-jss";

const pagingSelectorStyles = createUseStyles({
    pageButton: {
        padding: "14px",
        borderRight: "1px solid #c8c8c8;",
        cursor: "pointer"
    },
    choosedButton: {
        color:  "#0047F4"
    },
    pageButtonDisabled: {
        padding: "14px",
        borderRight: "1px solid #c8c8c8;",
        color: "#c8c8c8",
    },
    pagePanel: {
        fontWeight: "500",
        backgroundColor: "#FAF6F4",
        display: "flex",
        justifyContent: "flex-start",
        borderTop: "1px solid #c8c8c8;",
    },
});

export default pagingSelectorStyles;