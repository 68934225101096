import * as React from 'react'
import {useEffect, useState} from 'react'
import {TitleWrapper} from "../common/TitleWrapper/TitleWrapper";
import vehiclesStyles from "./VehiclesStyles";
import {getVehicles, VehicleShortResponse} from "../../api/VehicleApi";
import {getUserFromLocalStorage, isAccessRoles, PageResponse, Role} from "../../util/common";
import {ActionButton} from "../common/ActionButton/ActionButton";
import {DefaultPageContentContainer} from "../common/TableContainer/DefaultPageContentContainer";
import {MainContainer} from "../common/MainContainer/MainContainer";
import {Link, useNavigate} from "react-router-dom";
import {MultipleSortButton, SortField, toSortParameters} from "../common/SortButton/MultipleSortButton";
import {formatDateTime} from "../../util/dateUtils";
import {SelectFilterContainer} from "../common/SelectFilter/SelectFilterContainer";
import {SearchInput} from "../common/SearchInput/SearchInput";
import {LoadingBar} from "../common/LoadingBar/LoadingBar";
import {Message} from "../common/Message/Message";
import {Tag} from "../common/Tags/Tag";

export type VehiclesProperties = {
    roles: Array<Role>
}

export function Vehicles(props: VehiclesProperties) {
    const styles = vehiclesStyles();
    const user = getUserFromLocalStorage();
    const {roles} = props;
    const [vehiclePage, setVehiclePage] = useState<PageResponse<VehicleShortResponse> | undefined>(undefined);
    const [page, setPage] = useState<number>(0);


    const [qrCode, setQrCode] = useState<string>("");
    const [insuranceId, setInsuranceId] = useState<string>("");

    const [sortFields, setSortFields] = useState<Array<SortField>>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");

    const writeAccess = isAccessRoles(user.roles, roles, "WRITE");

    const createDataRow = (vehicle: VehicleShortResponse): React.ReactElement => {

        return <>
            <tr className={styles.dataRow}>
                <th className=""><a href={"/vehicle/" + vehicle.id}>{vehicle.externalId}</a></th>
                <th className=""><Tag color={getStatusColor(vehicle.status)} text={vehicle.status}/></th>
                <th className=""></th>
                <th className="">{vehicle.mileage} km</th>
                <th className="">{vehicle.lastRentalAt ? vehicle.lastRentalAt.toString() : "No rentals yet"}</th>
                <th><Tag color={getChargingColor(vehicle)} text={vehicle.charging ? "YES" : "NO"}/></th>
                <th className={""}>{vehicle.stateOfCharge} %</th>
                <th className="">{vehicle.recordedAt ? formatDateTime(vehicle.recordedAt) : ""}</th>
                <th className={styles.iconContainer}>
                    <Link to={"/vehicle/" + vehicle.id}><img className={styles.icon} src="/eye.svg" alt=""/></Link>
                    {writeAccess && <Link to={"/vehicle/edit/" + vehicle.id}><img className={styles.icon} src="/edit.svg" alt=""/></Link>}
                </th>
            </tr>
        </>
    }

    const createDataHeader = (sortFields: Array<SortField>, setSortFields: any): React.ReactElement => {
        return <>
            <thead>
            <tr className={styles.headerRow}>
                <th className="">
                    <MultipleSortButton title={"External ID"} sortFields={sortFields} setSortFields={setSortFields}
                                        fieldNameTarget={"externalId"}/>
                </th>
                <th className="">Status</th>
                <th className="">Storage</th>
                <th className="">Mileage</th>
                <th className="">Last Rental at</th>
                <th className="">Charging</th>
                <th>
                    <MultipleSortButton title={"State of charge"} sortFields={sortFields} setSortFields={setSortFields}
                                        fieldNameTarget={"stateOfCharge"}/>
                </th>
                <th className="">Recorded At</th>
                <th></th>
                <th></th>
            </tr>
            </thead>
        </>
    }

    useEffect(() => {
        getVehicles({
            page: page,
            sort: toSortParameters(sortFields),
            qrCode: qrCode,
            insuranceId: insuranceId
        }).then((result: PageResponse<VehicleShortResponse>) => {
            setVehiclePage(result);
            setDataLoaded(true);
        }).catch(() => {
            setMessage("Error occurred while saving vehicle");
            setTimeout(() => {
                setMessage("")
            }, 3000)
        });
    }, [page, insuranceId, qrCode, sortFields]);
    return (
        <>
            <Message message={message} isError={true}/>
            <MainContainer>
                <TitleWrapper title={"Vehicles - " + (vehiclePage ? vehiclePage.totalElements : "")}>
                    {writeAccess && <ActionButton title={"New Vehicle"} url={"/vehicle/edit/new"} isLink={true}/>}
                </TitleWrapper>

                <DefaultPageContentContainer title={"Vehicles"}
                                             pageContent={vehiclePage}
                                             pageNumber={page}
                                             totalPages={vehiclePage && vehiclePage.totalPages}
                                             setPage={setPage}
                                             headerContent={
                                                 <SelectFilterContainer>
                                                     <SearchInput title={"Qr code: "} setValue={setQrCode}
                                                                  setDataLoaded={setDataLoaded}/>
                                                     <SearchInput title={"Insurance id:"} setValue={setInsuranceId}
                                                                  setDataLoaded={setDataLoaded}/>
                                                 </SelectFilterContainer>
                                             }>
                    <table className={styles.dataTable}>
                        {createDataHeader(sortFields, setSortFields)}
                        {
                            dataLoaded
                                ? <tbody>{
                                    vehiclePage && vehiclePage.content && vehiclePage.content.length > 0
                                        ? vehiclePage.content.map((vehicle, index) => createDataRow(vehicle))
                                        : <tr className={styles.noDataBlock}>
                                            <th>No vehicles found</th>
                                        </tr>}
                                </tbody>
                                : <LoadingBar/>
                        }
                    </table>
                </DefaultPageContentContainer>
            </MainContainer>
        </>
    )
}


const getChargingColor = (vehicle: VehicleShortResponse) => {
    return vehicle.charging ? "green" : "red"
}

const getStatusColor = (status: string) => {
    return status === "IN_MAINTENANCE"
        ? "yellow"
        : "green";
}