import {createDefaultHeaders, fetchNoResponse, fetchWithResponse, getAccessTokenFromLocalStorage} from "../util/common";
import {HOST} from "../util/properties";

export type StorageRs = {
    id: string,
    name: string,
    longitude: number,
    latitude: number,
    vehicles: Array<StorageVehicleRs>
}

export type StorageShortRs = {
    id: string,
    name: string,
    longitude: number,
    latitude: number,
    vehicleNumbers: number
}

export type StorageVehicleRs = {
    id: string,
    externalId: string,
    qrCode: string,
    status: string,
    lastChangedById?: string,
    lastChangedByFullName?: string,
    lastChangedAt?: Date
}

export type StorageRq = {
    name: string,
    longitude?: number,
    latitude?: number,
    locationId: string
}

export const createStorage = (values: StorageRq): Promise<StorageRs> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/storages", {
        method: 'POST',
        body: JSON.stringify(values),
        headers: createDefaultHeaders(userToken)
    })
};

export const getStorage = (id?: string): Promise<StorageRs> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/storages/" + id, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const getStorages = (): Promise<Array<StorageShortRs>> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/storages", {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const updateStorage = (values: StorageRq, id?: string): Promise<StorageRs> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/storages/" + id, {
        method: 'PUT',
        body: JSON.stringify(values),
        headers: createDefaultHeaders(userToken)
    })
};

export const deleteStorage = (id: string) => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchNoResponse(HOST + "/api/storages/" + id, {
        method: 'DELETE',
        headers: createDefaultHeaders(userToken)
    })
};