import {createUseStyles} from "react-jss";

const selectFilterStyles = createUseStyles({
    selectContainer: {
        marginRight: "15px",
        display: "flex",
        alignItems: "center",
        "& label": {
            marginRight: "5px"
        },
        cursor: "pointer"
    },
    label:{
        fontSize: "15px"
    },
    selectInput: {
        width: "150px",
        cursor: "pointer"
    },

    selectWrapper: {

    },
    filtersContainer: {
        display: "flex",
        justifyContent: "flex-start",
    },
})

export default selectFilterStyles;