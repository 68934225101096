import {
    createDefaultHeaders,
    createUrlParameters,
    fetchWithResponse,
    getAccessTokenFromLocalStorage
} from "../util/common";
import {HOST} from "../util/properties";

export type DeleteUserRs = {
    id: string,
    createdAt: Date,
    changedAt: Date,
    userId: string,
    userFullName: string,
    changedById: string,
    status: string
}

export const changeStatus = (id: string, status: string): Promise<Array<DeleteUserRs>> => {
    const userToken = getAccessTokenFromLocalStorage();

    const paramString = createUrlParameters({
        "status": status
    });
    
    return fetchWithResponse(HOST + "/api/delete-user-request/" + id + paramString, {
        method: 'PATCH',
        headers: createDefaultHeaders(userToken)
    })
};

export const findAll = (status: string): Promise<Array<DeleteUserRs>> => {
    const userToken = getAccessTokenFromLocalStorage();
    
    const paramString = createUrlParameters({
        "status": status
    });

    return fetchWithResponse(HOST + "/api/delete-user-request" + paramString, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};