import React, {ReactElement, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {getUserFromLocalStorage, isAccessRoles, Role} from "../../../util/common";
import analyticsStyles from "../AnalyticsStyles";
import {formatIsoDate, subtractMonths} from "../../../util/dateUtils";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import {LoadingBar} from "../../common/LoadingBar/LoadingBar";
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {AnalyticUsersRs, getUsersAnalytic} from "../../../api/AnalyticApi";
import {Message} from "../../common/Message/Message";

export type TotalAnalyticsProperties = {
    roles: Array<Role>
}

export const UsersAnalytics = (props: TotalAnalyticsProperties): ReactElement => {
    const styles = analyticsStyles();
    const {roles} = props;
    const [startDate, setStartDate] = useState(formatIsoDate(subtractMonths(1)));
    const [endDate, setEndDate] = useState(formatIsoDate(new Date()));
    const [analytics, setAnalytics] = useState<AnalyticUsersRs>()
    const [isLoaded, setIsLoaded] = useState(false);
    const {type} = useParams();
    const [message, setMessage] = useState<string>("");

    const title = "Users statistic";

    const user = getUserFromLocalStorage();

    const writeAccess = isAccessRoles(user.roles, roles, "WRITE");

    useEffect(() => {
        getUsersAnalytic(startDate, endDate).then((result: AnalyticUsersRs) => {
            setAnalytics(result);
            setIsLoaded(true);
        }).catch(() => {
            setMessage("Error occurred while loading users analytics.");
            setTimeout(() => {
                setMessage("")
            }, 3000);
            setIsLoaded(true)
        })

    }, [startDate, endDate, type]);

    return (
        <>
            <Message message={message} isError={true}/>
            <MainContainer>
                <TitleWrapper title={title}>
                </TitleWrapper>
                {isLoaded
                    ? <>{analytics &&

                        <DefaultPageContentContainer title={"Total"}>
                            <div className={styles.dateContainer}>
                                <div className={styles.datePicker}>
                                    <div>Start date</div>
                                    <input value={startDate} type="date"
                                           onChange={(v) => setStartDate(v.target.value)}/>
                                </div>

                                <div className={styles.datePicker}>
                                    <div>End date</div>
                                    <input value={endDate} type="date" onChange={(v) => setEndDate(v.target.value)}/>
                                </div>
                            </div>

                            <div className={styles.cardsContainer}>
                                <div className={styles.card}>
                                    <div>
                                        <div className={styles.cardHeader}>Active users:</div>
                                        <div
                                            className={styles.cardValue}>{analytics.activeUsersNumber}</div>
                                    </div>
                                </div>
                                <div className={styles.card}>
                                    <div>
                                        <div className={styles.cardHeader}>New users:</div>
                                        <div
                                            className={styles.cardValue}>{analytics.newUsersNumber}</div>
                                    </div>
                                </div>
                                <div className={styles.card}>
                                    <div>
                                        <div className={styles.cardHeader}>User with offers:</div>
                                        <div
                                            className={styles.cardValue}>{analytics.usersWithOffers}</div>
                                    </div>
                                </div>
                            </div>


                        </DefaultPageContentContainer>
                    }
                    </>

                    : <LoadingBar/>
                }
            </MainContainer>
        </>

    );
};