import * as React from 'react';
import {PagingSelector} from "../PagingSelector/PagingSelector";
import {DefaultPageContentContainer} from "../TableContainer/DefaultPageContentContainer";
import pageStyles from "./PageStyles";
import {PageResponse} from "../../../util/common";
import {useNavigate} from "react-router-dom";

export type PageProps<T> = {
    createHeaderRow: any,
    createDataRow: any,
    title: any,
    page: number,
    setPage: any,
    data: any | undefined,
}

export function Page(props: PageProps<any>): React.ReactElement {
    const styles = pageStyles();

    const {title, page, setPage, data, createDataRow, createHeaderRow} = props;
    const navigate = useNavigate();

    const AlarmAsSingleEntry = (): React.ReactElement => {
        return (
            <>
                {
                    data
                        ? createDataRow(data, navigate, styles)
                        : <tr className={styles.noDataBlock}>
                            <th>No items found</th>
                        </tr>
                }
            </>
        )
    }

    const AlarmAsPage = (): React.ReactElement => {
        return (
            <>
                {data && data.content && data.content.length > 0
                    ? data.content.map((value: any) => createDataRow(value, navigate, styles))
                    : <tr className={styles.noDataBlock}>
                        <th>No items found</th>
                    </tr>}
            </>
        )
    }

    return (<>
        <DefaultPageContentContainer title={title}>
            <table className={styles.dataTable}>
                <thead>

                <tr className={styles.headerRow}>
                    {createHeaderRow()}
                </tr>
                </thead>
                <tbody>


                {
                    data && data.content
                        ? <AlarmAsPage/>
                        : <AlarmAsSingleEntry/>
                }
                </tbody>
                {
                    data && data.totalPages > 1
                        ? <PagingSelector page={page} pageNumber={data.totalPages} setPage={setPage}/>
                        : <></>
                }
            </table>

        </DefaultPageContentContainer>
    </>)
}
