import * as React from 'react'
import mainContainerStyles from "./MainContainerStyles";

export type TitleProperties = {
    children: React.ReactNode | React.ReactNode[]
}

export function MainContainer(props: TitleProperties) {
    const styles = mainContainerStyles();

    return (
        <>
            <div className={styles.mainContainer}>
                {props.children}
            </div>
        </>
    )
}

export function MainContainerNoMargins(props: TitleProperties) {
    const styles = mainContainerStyles();

    return (
        <>
            <div className={styles.mainContainerNoMargins}>
                {props.children}
            </div>
        </>
    )
}