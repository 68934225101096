import * as React from 'react'
import {Field, Form, Formik, useField} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import tariffFormStyles from "./TariffFormStyles";
import Select from "react-select";

const validateSchema = Yup.object().shape({
    vehicleModelId: Yup.string().required('Required'),
    startDate: Yup.date().required("Required"),
    endDate: Yup.date().required("Required")
        .when('startDate', (startDate) => {

            if (startDate) {
                return Yup.date()
                    .min(startDate, 'End Date must be after Start Date');
            }

            return Yup.date();
        }),
    startPrice: Yup.string().required('Required'),
    pricePerMinute: Yup.string().required('Required'),
    pausePricePerMinute: Yup.string().required('Required'),
});

export function TariffForm(props: any) {
    const {onSubmitHandle, vehicleModels, formDefaultValues, id} = props;
    const styles = tariffFormStyles();
    const navigate = useNavigate();
    const {isDefault} = formDefaultValues;

    const DayOfWeeksSelect = ({...selectProps}) => {
        const [field, meta, helpers] = useField(selectProps.id)

        return (
            <Select
                defaultValue={selectProps.options.filter((elem: any) => field.value && field.value.includes(elem.value))}
                onChange={(v) => helpers.setValue(v.map((x: any) => x.value))}
                onBlur={() => helpers.setTouched(true)}
                {...selectProps}
            />
        )
    }

    return (
        <Formik
            initialValues={formDefaultValues}
            validationSchema={validateSchema}
            onSubmit={(values => onSubmitHandle(values, navigate, id))}>
            {(props) => {
                const {errors, touched, submitCount} = props;
                const isSubmitting = submitCount > 0;

                console.log(props.values.startDate)
                return (<>
                        <Form>

                            <div className={styles.inputContainers}>
                                {
                                    isDefault
                                        ? <></>
                                        : <div className={styles.inputContainer}>
                                            <label htmlFor="dayOfWeeks">Day of weeks</label>
                                            <Field id="dayOfWeeks" name="dayOfWeeks"
                                                   component={DayOfWeeksSelect}
                                                   isMulti={true}
                                                   options={[{label: "MON", value: "MONDAY"},
                                                       {label: "TUE", value: "TUESDAY"},
                                                       {label: "WED", value: "WEDNESDAY"},
                                                       {label: "THU", value: "THURSDAY"},
                                                       {label: "FRI", value: "FRIDAY"},
                                                       {label: "SAT", value: "SATURDAY"},
                                                       {label: "SUN", value: "SUNDAY"},
                                                   ]}
                                                   placeholder={""}
                                                   className={styles.inputField}
                                            />
                                        </div>
                                }
                                <div className={styles.inputContainer}>
                                    <label htmlFor="name">Name </label>
                                    <Field id="name"
                                           name="name" placeholder={""}
                                           className={errors.name && touched.name ? styles.inputFieldError : styles.inputField}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="vehicleModelId">Model</label>
                                    <Field id="vehicleModelId" component="select"
                                           name="vehicleModelId"
                                           className={errors.vehicleModelId && (touched.vehicleModelId || isSubmitting)
                                               ? styles.inputFieldError
                                               : styles.inputField}>
                                        {
                                            Array.isArray(vehicleModels)
                                                ? vehicleModels.map((vehicleModel) => <option key={vehicleModel.id}
                                                                                              value={vehicleModel.id}>{vehicleModel.name}</option>)
                                                : ""
                                        }
                                    </Field>
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="startDate">Start date</label>
                                    <Field id="startDate"
                                           name="startDate" type="date"
                                           placeholder={""}
                                           className={errors.startDate && (touched.startDate || isSubmitting)
                                               ? styles.inputFieldError
                                               : styles.inputField}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="endDate">End date</label>
                                    <Field id="endDate"
                                           name="endDate" type="date"
                                           placeholder={""}
                                           className={errors.endDate && (touched.endDate || isSubmitting)
                                               ? styles.inputFieldError
                                               : styles.inputField}
                                    />
                                </div>
                                {
                                    isDefault
                                        ? <></>
                                        : <div className={styles.inputContainer}>
                                            <label htmlFor="startTime">Start time</label>
                                            <Field id="startTime"
                                                   name="startTime" type="time"
                                                   placeholder={""}
                                                   className={styles.inputField}
                                            />
                                        </div>
                                }

                                {
                                    isDefault
                                        ? <></>
                                        : <div className={styles.inputContainer}>
                                            <label htmlFor="endTime">End time</label>
                                            <Field id="endTime"
                                                   name="endTime" type="time"
                                                   placeholder={""}
                                                   className={styles.inputField}
                                            />
                                        </div>
                                }
                                <div className={styles.inputContainer}>
                                    <label htmlFor="startPrice">Start price (EUR)</label>
                                    <Field id="startPrice"
                                           name="startPrice" placeholder={"1.0"}
                                           className={errors.startPrice && (touched.startPrice || isSubmitting)
                                               ? styles.inputFieldError
                                               : styles.inputField}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="pricePerMinute">Price per minute (EUR)</label>
                                    <Field id="pricePerMinute"
                                           name="pricePerMinute" placeholder={"0.01"}
                                           className={errors.pricePerMinute && (touched.pricePerMinute || isSubmitting)
                                               ? styles.inputFieldError
                                               : styles.inputField}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="pausePricePerMinute">Pause price per minute (EUR)</label>
                                    <Field id="pausePricePerMinute"
                                           name="pausePricePerMinute" placeholder={"0.01"}
                                           className={errors.pausePricePerMinute && (touched.pausePricePerMinute || isSubmitting)
                                               ? styles.inputFieldError
                                               : styles.inputField}
                                    />
                                </div>

                                <button className={styles.submitButton} type="submit">
                                    Save
                                </button>
                            </div>

                        </Form>
                    </>
                )
            }

            }
        </Formik>
    )
}