import * as React from 'react'
import modalStyles from "./ModalStyles";

export type ModalProperties = {
    setModalHidden: any,
    children: React.ReactNode | React.ReactNode[]
}

export function Modal(props: ModalProperties) {
    const styles = modalStyles();

    return (
        <div className={styles.modalContainer}>
            <div className={styles.modal}>
                <div className={styles.modalExitContainer}>
                    <div className={styles.exitButton} onClick={() => props.setModalHidden(false)}>
                        <img src="/close.svg" alt=""/>
                    </div>
                </div>
                <div className={styles.modalHeaderContainer}>

                </div>
                <div className={styles.modalContentContainer}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}