import React, {ReactElement} from 'react';
import {buttonStyles} from "./ButtonStyles";

export interface ButtonProperties {
    title: string,
    onClick?: any,
    type?: 'submit' | 'reset' | 'button' | undefined;
}

export const Button = (props: ButtonProperties): ReactElement => {
    const {title, onClick, type} = props;
    const styles = buttonStyles();
    return (
        <button className={styles.button} onClick={onClick} type={type || "button"}>
            {title}
        </button>
    )
}