import {createUseStyles} from "react-jss";

const actionButtonStyles = createUseStyles({
    actionButton: {
        fontSize: "15px",
        color: "#0047F4",
        borderRadius: "7px",
        padding: "5px 15px",
        lineHeight: "1.5",
        outline: "0",
        textDecoration: "none",
        border: "1px solid #0047F4",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        boxSizing: "border-box",
        fontWeight: "600",
        backgroundColor: "#ffffff",
        cursor: "pointer",
    },
    '@media (max-width:768px)': {
        actionButton: {
            marginTop: "5px",
            marginBottom: "5px",
        }
    }
})

export default actionButtonStyles;