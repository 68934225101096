import * as React from 'react'
import {useEffect, useState} from 'react'
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import {NavigateFunction, useParams} from "react-router-dom";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {Message} from "../../common/Message/Message";
import {getLocationId, Role} from "../../../util/common";
import editOfferStyles from "./EditOfferStyles";
import {createOffer, getOffer, OfferRq, OfferRs, updateOffer} from "../../../api/OfferApi";
import {OfferForm} from "../OfferForm/OfferForm";
import {LoadingBar} from "../../common/LoadingBar/LoadingBar";

export type EditOfferProperties = {
    roles: Array<Role>
}

export function EditOffer(props: EditOfferProperties) {
    const styles = editOfferStyles();
    const {roles} = props;
    const {id} = useParams();
    const isNewOffer = id === "new";
    const locationId = getLocationId();
    const [message, setMessage] = useState<string>("");
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [initValues, setInitValues] = useState<OfferRq>({});

    const onSubmitHandle = (values: OfferRq, navigate: NavigateFunction, id?: string) => {
        values.locationId = locationId;

        const saveOffer = isNewOffer ? createOffer(values) : updateOffer(values, id || "")
        saveOffer
            .then((result: any) => {
                navigate("/offer/" + result.id);
            })
            .catch((result: any) => {
                setMessage("Error occurred while saving tariff");
                setTimeout(() => {
                    setMessage("")
                }, 3000)
            });
    }

    useEffect(() => {
        if (!isNewOffer) {
            getOffer(id).then((offerResponse: OfferRs) => {
                setInitValues({
                    name: offerResponse.name,
                    description: offerResponse.description,
                    price: offerResponse.price,
                    daysDuration: offerResponse.daysDuration,
                    tripLimitPerDay: offerResponse.tripLimitPerDay,
                    pauseLimitPerDay: offerResponse.pauseLimitPerDay,
                    timeLimitPerDay: offerResponse.timeLimitPerDay,
                    locationId: offerResponse.locationId,
                    active: offerResponse.active,
                    startDate: offerResponse.startDate,
                    endDate: offerResponse.endDate,
                })
                setIsLoaded(true);
            })
        } else {
            setInitValues({
                locationId: locationId,
            })
            setIsLoaded(true);
        }

    }, [id, isNewOffer]);
    return (
        <>
            <Message message={message} isError={true}/>
            <MainContainer>
                <TitleWrapper title={(isNewOffer ? "New" : "Edit") + " offer"}>
                </TitleWrapper>
                <div className={styles.mainWrapper}>
                    <div className={styles.contentWrapper}>
                        <DefaultPageContentContainer title={"Details"}>
                            {
                                isLoaded
                                    ? <OfferForm onSubmitHandle={onSubmitHandle}
                                                  formDefaultValues={initValues} id={id}/>
                                    : <LoadingBar/>
                            }
                        </DefaultPageContentContainer>
                    </div>
                </div>

            </MainContainer>
        </>
    )
}

