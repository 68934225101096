import {createUseStyles} from "react-jss";

const modalStyles = createUseStyles({
    modalContainer: {
        width: '100%',
        margin: 'auto',
        background: '#000000',
        opacity: "0.9",
        position: 'fixed',
        right: '0',
        left: '0',
        top: '0',
        bottom: '0',
        zIndex: '501',

        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    modal: {
        width: "400px",
        backgroundColor: "#ffffff",
        padding: "5px",
        opacity: "1",
        borderRadius: '8px',
    },
    modalExitContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    exitButton: {
        cursor: 'pointer',
        paddingRight: '5px',
        paddingTop: '5px',
    },
    modalHeaderContainer: {
        textAlign: 'center',
        color: '#883eb0',
        '& h1': {
            fontSize: '50px',
        },
    },
    modalContentContainer: {
        paddingTop: '20px',
        paddingLeft: '5px',
        paddingRight: '5px',
    },

})

export default modalStyles;