export type CountryPhoneCodeInfo = {
    nameRu: string,
    nameEn: string,
    nameDe: string,
    phoneCode: string,
    countryCode: string,
}

export const countryPhoneCodes: Array<CountryPhoneCodeInfo> = [
    {
        "nameRu": "Оман",
        "nameEn": "Oman",
        "nameDe": "Oman",
        "countryCode": "om",
        "phoneCode": "+968"
    },
    {
        "nameRu": "Афганистан",
        "nameEn": "Afghanistan",
        "nameDe": "Afghanistan",
        "countryCode": "af",
        "phoneCode": "+93"
    },
    {
        "nameRu": "Намибия",
        "nameEn": "Namibia",
        "nameDe": "Namibia",
        "countryCode": "na",
        "phoneCode": "+264"
    },
    {
        "nameRu": "Албания",
        "nameEn": "Albania",
        "nameDe": "Albanien",
        "countryCode": "al",
        "phoneCode": "+355"
    },
    {
        "nameRu": "Науру",
        "nameEn": "Nauru",
        "nameDe": "Nauru",
        "countryCode": "nr",
        "phoneCode": "+674"
    },
    {
        "nameRu": "Антарктика",
        "nameEn": "Antarctica",
        "nameDe": "Antarktis",
        "countryCode": "aq",
        "phoneCode": "+672"
    },
    {
        "nameRu": "Алжир",
        "nameEn": "Algeria",
        "nameDe": "Algerien",
        "countryCode": "dz",
        "phoneCode": "+213"
    },
    {
        "nameRu": "Непал",
        "nameEn": "Nepal",
        "nameDe": "Nepal",
        "countryCode": "np",
        "phoneCode": "+977"
    },
    {
        "nameRu": "Американское Самоа",
        "nameEn": "American Samoa",
        "nameDe": "Amerikanisch-Samoa",
        "countryCode": "as",
        "phoneCode": "+1684"
    },
    {
        "nameRu": "Нидерланды",
        "nameEn": "Netherlands",
        "nameDe": "Niederlande",
        "countryCode": "nl",
        "phoneCode": "+31"
    },
    {
        "nameRu": "Андорра",
        "nameEn": "Andorra",
        "nameDe": "Andorra",
        "countryCode": "ad",
        "phoneCode": "+376"
    },
    {
        "nameRu": "Аруба",
        "nameEn": "Aruba",
        "nameDe": "Aruba",
        "countryCode": "aw",
        "phoneCode": "+297"
    },
    {
        "nameRu": "Ангола",
        "nameEn": "Angola",
        "nameDe": "Angola",
        "countryCode": "ao",
        "phoneCode": "+244"
    },
    {
        "nameRu": "Антигуа и Барбуда",
        "nameEn": "Antigua and Barbuda",
        "nameDe": "Antigua und Barbuda",
        "countryCode": "ag",
        "phoneCode": "+1268"
    },
    {
        "nameRu": "Новая Каледония",
        "nameEn": "New Caledonia",
        "nameDe": "Neukaledonien",
        "countryCode": "nc",
        "phoneCode": "+687"
    },
    {
        "nameRu": "Азербайджан",
        "nameEn": "Azerbaijan",
        "nameDe": "Aserbaidschan",
        "countryCode": "az",
        "phoneCode": "+994"
    },
    {
        "nameRu": "Аргентина",
        "nameEn": "Argentina",
        "nameDe": "Argentinien",
        "countryCode": "ar",
        "phoneCode": "+54"
    },
    {
        "nameRu": "Австралия",
        "nameEn": "Australia",
        "nameDe": "Australien",
        "countryCode": "au",
        "phoneCode": "+61"
    },
    {
        "nameRu": "Вануату",
        "nameEn": "Vanuatu",
        "nameDe": "Vanuatu",
        "countryCode": "vu",
        "phoneCode": "+678"
    },
    {
        "nameRu": "Австрия",
        "nameEn": "Austria",
        "nameDe": "Österreich",
        "countryCode": "at",
        "phoneCode": "+43"
    },
    {
        "nameRu": "Новая Зеландия",
        "nameEn": "New Zealand",
        "nameDe": "Neuseeland",
        "countryCode": "nz",
        "phoneCode": "+64"
    },
    {
        "nameRu": "Багамские Острова",
        "nameEn": "Bahamas",
        "nameDe": "Bahamas",
        "countryCode": "bs",
        "phoneCode": "+1242"
    },
    {
        "nameRu": "Никарагуа",
        "nameEn": "Nicaragua",
        "nameDe": "Nicaragua",
        "countryCode": "ni",
        "phoneCode": "+505"
    },
    {
        "nameRu": "Бахрейн",
        "nameEn": "Bahrain",
        "nameDe": "Bahrain",
        "countryCode": "bh",
        "phoneCode": "+973"
    },
    {
        "nameRu": "Бангладеш",
        "nameEn": "Bangladesh",
        "nameDe": "Bangladesch",
        "countryCode": "bd",
        "phoneCode": "+880"
    },
    {
        "nameRu": "Нигер",
        "nameEn": "Niger",
        "nameDe": "Niger",
        "countryCode": "ne",
        "phoneCode": "+227"
    },
    {
        "nameRu": "Армения",
        "nameEn": "Armenia",
        "nameDe": "Armenien",
        "countryCode": "am",
        "phoneCode": "+374"
    },
    {
        "nameRu": "Барбадос",
        "nameEn": "Barbados",
        "nameDe": "Barbados",
        "countryCode": "bb",
        "phoneCode": "+1246"
    },
    {
        "nameRu": "Нигерия",
        "nameEn": "Nigeria",
        "nameDe": "Nigeria",
        "countryCode": "ng",
        "phoneCode": "+234"
    },
    {
        "nameRu": "Бельгия",
        "nameEn": "Belgium",
        "nameDe": "Belgien",
        "countryCode": "be",
        "phoneCode": "+32"
    },
    {
        "nameRu": "Ниуэ",
        "nameEn": "Niue",
        "nameDe": "Niue",
        "countryCode": "nu",
        "phoneCode": "+683"
    },
    {
        "nameRu": "Бермуды",
        "nameEn": "Bermuda",
        "nameDe": "Bermuda",
        "countryCode": "bm",
        "phoneCode": "+1441"
    },
    {
        "nameRu": "Остров Норфолк",
        "nameEn": "Norfolk Island",
        "nameDe": "Norfolkinsel",
        "countryCode": "nf",
        "phoneCode": "+672"
    },
    {
        "nameRu": "Бутан",
        "nameEn": "Bhutan",
        "nameDe": "Bhutan",
        "countryCode": "bt",
        "phoneCode": "+975"
    },
    {
        "nameRu": "Норвегия",
        "nameEn": "Norway",
        "nameDe": "Norwegen",
        "countryCode": "no",
        "phoneCode": "+47"
    },
    {
        "nameRu": "Боливия",
        "nameEn": "Bolivia",
        "nameDe": "Bolivien",
        "countryCode": "bo",
        "phoneCode": "+591"
    },
    {
        "nameRu": "Северные Марианские Острова",
        "nameEn": "Northern Mariana Islands",
        "nameDe": "Nördliche Marianen",
        "countryCode": "mp",
        "phoneCode": "+1670"
    },
    {
        "nameRu": "Босния и Герцеговина",
        "nameEn": "Bosnia and Herzegovina",
        "nameDe": "Bosnien und Herzegowina",
        "countryCode": "ba",
        "phoneCode": "+387"
    },
    {
        "nameRu": "Микронезия",
        "nameEn": "Micronesia",
        "nameDe": "Mikronesien",
        "countryCode": "fm",
        "phoneCode": "+691"
    },
    {
        "nameRu": "Ботсвана",
        "nameEn": "Botswana",
        "nameDe": "Botswana",
        "countryCode": "bw",
        "phoneCode": "+267"
    },
    {
        "nameRu": "Маршалловы Острова",
        "nameEn": "Marshall Islands",
        "nameDe": "Marshallinseln",
        "countryCode": "mh",
        "phoneCode": "+692"
    },
    {
        "nameRu": "Палау",
        "nameEn": "Palau",
        "nameDe": "Palau",
        "countryCode": "pw",
        "phoneCode": "+680"
    },
    {
        "nameRu": "Пакистан",
        "nameEn": "Pakistan",
        "nameDe": "Pakistan",
        "countryCode": "pk",
        "phoneCode": "+92"
    },
    {
        "nameRu": "Бразилия",
        "nameEn": "Brazil",
        "nameDe": "Brasilien",
        "countryCode": "br",
        "phoneCode": "+55"
    },
    {
        "nameRu": "Панама",
        "nameEn": "Panama",
        "nameDe": "Panama",
        "countryCode": "pa",
        "phoneCode": "+507"
    },
    {
        "nameRu": "Белиз",
        "nameEn": "Belize",
        "nameDe": "Belize",
        "countryCode": "bz",
        "phoneCode": "+501"
    },
    {
        "nameRu": "Британская территория в Индийском океане",
        "nameEn": "British Indian Ocean Territory",
        "nameDe": "Britisches Territorium im Indischen Ozean",
        "countryCode": "io",
        "phoneCode": "+246"
    },
    {
        "nameRu": "Папуа — Новая Гвинея",
        "nameEn": "Papua New Guinea",
        "nameDe": "Papua-Neuguinea",
        "countryCode": "pg",
        "phoneCode": "+675"
    },
    {
        "nameRu": "Парагвай",
        "nameEn": "Paraguay",
        "nameDe": "Paraguay",
        "countryCode": "py",
        "phoneCode": "+595"
    },
    {
        "nameRu": "Соломоновы Острова",
        "nameEn": "Solomon Islands",
        "nameDe": "Salomonen",
        "countryCode": "sb",
        "phoneCode": "+677"
    },
    {
        "nameRu": "Виргинские Острова (Великобритания)",
        "nameEn": "Virgin Islands (British)",
        "nameDe": "Britische Jungferninseln",
        "countryCode": "vg",
        "phoneCode": "+1284"
    },
    {
        "nameRu": "Перу",
        "nameEn": "Peru",
        "nameDe": "Peru",
        "countryCode": "pe",
        "phoneCode": "+51"
    },
    {
        "nameRu": "Бруней",
        "nameEn": "Brunei Darussalam",
        "nameDe": "Brunei",
        "countryCode": "bn",
        "phoneCode": "+673"
    },
    {
        "nameRu": "Филиппины",
        "nameEn": "Philippines",
        "nameDe": "Philippinen",
        "countryCode": "ph",
        "phoneCode": "+63"
    },
    {
        "nameRu": "Болгария",
        "nameEn": "Bulgaria",
        "nameDe": "Bulgarien",
        "countryCode": "bg",
        "phoneCode": "+359"
    },
    {
        "nameRu": "Острова Питкэрн",
        "nameEn": "Pitcairn",
        "nameDe": "Pitcairninseln",
        "countryCode": "pn",
        "phoneCode": "+872"
    },
    {
        "nameRu": "Мьянма",
        "nameEn": "Myanmar",
        "nameDe": "Myanmar",
        "countryCode": "mm",
        "phoneCode": "+95"
    },
    {
        "nameRu": "Польша",
        "nameEn": "Poland",
        "nameDe": "Polen",
        "countryCode": "pl",
        "phoneCode": "+48"
    },
    {
        "nameRu": "Бурунди",
        "nameEn": "Burundi",
        "nameDe": "Burundi",
        "countryCode": "bi",
        "phoneCode": "+257"
    },
    {
        "nameRu": "Португалия",
        "nameEn": "Portugal",
        "nameDe": "Portugal",
        "countryCode": "pt",
        "phoneCode": "+351"
    },
    {
        "nameRu": "Белоруссия",
        "nameEn": "Belarus",
        "nameDe": "Belarus",
        "countryCode": "by",
        "phoneCode": "+375"
    },
    {
        "nameRu": "Гвинея-Бисау",
        "nameEn": "Guinea-Bissau",
        "nameDe": "Guinea-Bissau",
        "countryCode": "gw",
        "phoneCode": "+245"
    },
    {
        "nameRu": "Восточный Тимор",
        "nameEn": "Timor-Leste",
        "nameDe": "Osttimor",
        "countryCode": "tl",
        "phoneCode": "+670"
    },
    {
        "nameRu": "Камбоджа",
        "nameEn": "Cambodia",
        "nameDe": "Kambodscha",
        "countryCode": "kh",
        "phoneCode": "+855"
    },
    {
        "nameRu": "Пуэрто-Рико",
        "nameEn": "Puerto Rico",
        "nameDe": "Puerto Rico",
        "countryCode": "pr",
        "phoneCode": "+1939"
    },
    {
        "nameRu": "Камерун",
        "nameEn": "Cameroon",
        "nameDe": "Kamerun",
        "countryCode": "cm",
        "phoneCode": "+237"
    },
    {
        "nameRu": "Катар",
        "nameEn": "Qatar",
        "nameDe": "Katar",
        "countryCode": "qa",
        "phoneCode": "+974"
    },
    {
        "nameRu": "Канада",
        "nameEn": "Canada",
        "nameDe": "Kanada",
        "countryCode": "ca",
        "phoneCode": "+1"
    },
    {
        "nameRu": "Реюньон",
        "nameEn": "Réunion",
        "nameDe": "Réunion",
        "countryCode": "re",
        "phoneCode": "+262"
    },
    {
        "nameRu": "Румыния",
        "nameEn": "Romania",
        "nameDe": "Rumänien",
        "countryCode": "ro",
        "phoneCode": "+40"
    },
    {
        "nameRu": "Россия",
        "nameEn": "Russian Federation",
        "nameDe": "Russland",
        "countryCode": "ru",
        "phoneCode": "+7"
    },
    {
        "nameRu": "Кабо-Верде",
        "nameEn": "Cabo Verde",
        "nameDe": "Kap Verde",
        "countryCode": "cv",
        "phoneCode": "+238"
    },
    {
        "nameRu": "Руанда",
        "nameEn": "Rwanda",
        "nameDe": "Ruanda",
        "countryCode": "rw",
        "phoneCode": "+250"
    },
    {
        "nameRu": "Острова Кайман",
        "nameEn": "Cayman Islands",
        "nameDe": "Kaimaninseln",
        "countryCode": "ky",
        "phoneCode": "+ 345"
    },
    {
        "nameRu": "Сен-Бартелеми",
        "nameEn": "Saint Barthélemy",
        "nameDe": "Saint-Barthélemy",
        "countryCode": "bl",
        "phoneCode": "+590"
    },
    {
        "nameRu": "ЦАР",
        "nameEn": "Central African Republic",
        "nameDe": "Zentralafrikanische Republik",
        "countryCode": "cf",
        "phoneCode": "+236"
    },
    {
        "nameRu": "Острова Святой Елены, Вознесения и Тристан-да-Кунья",
        "nameEn": "Saint Helena, Ascension and Tristan da Cunha",
        "nameDe": "St. Helena, Ascension und Tristan da Cunha",
        "countryCode": "sh",
        "phoneCode": "+290"
    },
    {
        "nameRu": "Шри-Ланка",
        "nameEn": "Sri Lanka",
        "nameDe": "Sri Lanka",
        "countryCode": "lk",
        "phoneCode": "+94"
    },
    {
        "nameRu": "Сент-Китс и Невис",
        "nameEn": "Saint Kitts and Nevis",
        "nameDe": "St. Kitts und Nevis",
        "countryCode": "kn",
        "phoneCode": "+1869"
    },
    {
        "nameRu": "Ангилья",
        "nameEn": "Anguilla",
        "nameDe": "Anguilla",
        "countryCode": "ai",
        "phoneCode": "+1264"
    },
    {
        "nameRu": "Чад",
        "nameEn": "Chad",
        "nameDe": "Tschad",
        "countryCode": "td",
        "phoneCode": "+235"
    },
    {
        "nameRu": "Сент-Люсия",
        "nameEn": "Saint Lucia",
        "nameDe": "St. Lucia",
        "countryCode": "lc",
        "phoneCode": "+1758"
    },
    {
        "nameRu": "Сен-Мартен",
        "nameEn": "Saint Martin (French part)",
        "nameDe": "Saint-Martin (französischer Teil)",
        "countryCode": "mf",
        "phoneCode": "+590"
    },
    {
        "nameRu": "Чили",
        "nameEn": "Chile",
        "nameDe": "Chile",
        "countryCode": "cl",
        "phoneCode": "+56"
    },
    {
        "nameRu": "Сен-Пьер и Микелон",
        "nameEn": "Saint Pierre and Miquelon",
        "nameDe": "Saint-Pierre und Miquelon",
        "countryCode": "pm",
        "phoneCode": "+508"
    },
    {
        "nameRu": "Китай (Китайская Народная Республика)",
        "nameEn": "China",
        "nameDe": "Volksrepublik China",
        "countryCode": "cn",
        "phoneCode": "+86"
    },
    {
        "nameRu": "Китайская Республика",
        "nameEn": "Taiwan, Province of China",
        "nameDe": "Republik China",
        "countryCode": "tw",
        "phoneCode": "+886"
    },
    {
        "nameRu": "Сент-Винсент и Гренадины",
        "nameEn": "Saint Vincent and the Grenadines",
        "nameDe": "St. Vincent und die Grenadinen",
        "countryCode": "vc",
        "phoneCode": "+1784"
    },
    {
        "nameRu": "Остров Рождества",
        "nameEn": "Christmas Island",
        "nameDe": "Weihnachtsinsel",
        "countryCode": "cx",
        "phoneCode": "+61"
    },
    {
        "nameRu": "Сан-Марино",
        "nameEn": "San Marino",
        "nameDe": "San Marino",
        "countryCode": "sm",
        "phoneCode": "+378"
    },
    {
        "nameRu": "Кокосовые острова",
        "nameEn": "Cocos (Keeling) Islands",
        "nameDe": "Kokosinseln",
        "countryCode": "cc",
        "phoneCode": "+61"
    },
    {
        "nameRu": "Сан-Томе и Принсипи",
        "nameEn": "Sao Tome and Principe",
        "nameDe": "São Tomé und Príncipe",
        "countryCode": "st",
        "phoneCode": "+239"
    },
    {
        "nameRu": "Колумбия",
        "nameEn": "Colombia",
        "nameDe": "Kolumbien",
        "countryCode": "co",
        "phoneCode": "+57"
    },
    {
        "nameRu": "Саудовская Аравия",
        "nameEn": "Saudi Arabia",
        "nameDe": "Saudi-Arabien",
        "countryCode": "sa",
        "phoneCode": "+966"
    },
    {
        "nameRu": "Коморы",
        "nameEn": "Comoros",
        "nameDe": "Komoren",
        "countryCode": "km",
        "phoneCode": "+269"
    },
    {
        "nameRu": "Сенегал",
        "nameEn": "Senegal",
        "nameDe": "Senegal",
        "countryCode": "sn",
        "phoneCode": "+221"
    },
    {
        "nameRu": "Майотта",
        "nameEn": "Mayotte",
        "nameDe": "Mayotte",
        "countryCode": "yt",
        "phoneCode": "+262"
    },
    {
        "nameRu": "Сербия",
        "nameEn": "Serbia",
        "nameDe": "Serbien",
        "countryCode": "rs",
        "phoneCode": "+381"
    },
    {
        "nameRu": "Республика Конго",
        "nameEn": "Congo",
        "nameDe": "Kongo, Republik",
        "countryCode": "cg",
        "phoneCode": "+242"
    },
    {
        "nameRu": "Сейшельские Острова",
        "nameEn": "Seychelles",
        "nameDe": "Seychellen",
        "countryCode": "sc",
        "phoneCode": "+248"
    },
    {
        "nameRu": "ДР Конго",
        "nameEn": "Congo, Democratic Republic of the",
        "nameDe": "Kongo, Demokratische Republik",
        "countryCode": "cd",
        "phoneCode": "+243"
    },
    {
        "nameRu": "Сьерра-Леоне",
        "nameEn": "Sierra Leone",
        "nameDe": "Sierra Leone",
        "countryCode": "sl",
        "phoneCode": "+232"
    },
    {
        "nameRu": "Острова Кука",
        "nameEn": "Cook Islands",
        "nameDe": "Cookinseln",
        "countryCode": "ck",
        "phoneCode": "+682"
    },
    {
        "nameRu": "Коста-Рика",
        "nameEn": "Costa Rica",
        "nameDe": "Costa Rica",
        "countryCode": "cr",
        "phoneCode": "+506"
    },
    {
        "nameRu": "Сингапур",
        "nameEn": "Singapore",
        "nameDe": "Singapur",
        "countryCode": "sg",
        "phoneCode": "+65"
    },
    {
        "nameRu": "Словакия",
        "nameEn": "Slovakia",
        "nameDe": "Slowakei",
        "countryCode": "sk",
        "phoneCode": "+421"
    },
    {
        "nameRu": "Хорватия",
        "nameEn": "Croatia",
        "nameDe": "Kroatien",
        "countryCode": "hr",
        "phoneCode": "+385"
    },
    {
        "nameRu": "Вьетнам",
        "nameEn": "Viet Nam",
        "nameDe": "Vietnam",
        "countryCode": "vn",
        "phoneCode": "+84"
    },
    {
        "nameRu": "Куба",
        "nameEn": "Cuba",
        "nameDe": "Kuba",
        "countryCode": "cu",
        "phoneCode": "+53"
    },
    {
        "nameRu": "Словения",
        "nameEn": "Slovenia",
        "nameDe": "Slowenien",
        "countryCode": "si",
        "phoneCode": "+386"
    },
    {
        "nameRu": "Сомали",
        "nameEn": "Somalia",
        "nameDe": "Somalia",
        "countryCode": "so",
        "phoneCode": "+252"
    },
    {
        "nameRu": "Кипр",
        "nameEn": "Cyprus",
        "nameDe": "Zypern",
        "countryCode": "cy",
        "phoneCode": "+357"
    },
    {
        "nameRu": "ЮАР",
        "nameEn": "South Africa",
        "nameDe": "Südafrika",
        "countryCode": "za",
        "phoneCode": "+27"
    },
    {
        "nameRu": "Чехия",
        "nameEn": "Czechia",
        "nameDe": "Tschechien",
        "countryCode": "cz",
        "phoneCode": "+420"
    },
    {
        "nameRu": "Бенин",
        "nameEn": "Benin",
        "nameDe": "Benin",
        "countryCode": "bj",
        "phoneCode": "+229"
    },
    {
        "nameRu": "Зимбабве",
        "nameEn": "Zimbabwe",
        "nameDe": "Simbabwe",
        "countryCode": "zw",
        "phoneCode": "+263"
    },
    {
        "nameRu": "Дания",
        "nameEn": "Denmark",
        "nameDe": "Dänemark",
        "countryCode": "dk",
        "phoneCode": "+45"
    },
    {
        "nameRu": "Доминика",
        "nameEn": "Dominica",
        "nameDe": "Dominica",
        "countryCode": "dm",
        "phoneCode": "+1767"
    },
    {
        "nameRu": "Испания",
        "nameEn": "Spain",
        "nameDe": "Spanien",
        "countryCode": "es",
        "phoneCode": "+34"
    },
    {
        "nameRu": "Доминиканская Республика",
        "nameEn": "Dominican Republic",
        "nameDe": "Dominikanische Republik",
        "countryCode": "do",
        "phoneCode": "+1849"
    },
    {
        "nameRu": "Южный Судан",
        "nameEn": "South Sudan",
        "nameDe": "Südsudan",
        "countryCode": "ss",
        "phoneCode": "+211"
    },
    {
        "nameRu": "Судан",
        "nameEn": "Sudan",
        "nameDe": "Sudan",
        "countryCode": "sd",
        "phoneCode": "+249"
    },
    {
        "nameRu": "Эквадор",
        "nameEn": "Ecuador",
        "nameDe": "Ecuador",
        "countryCode": "ec",
        "phoneCode": "+593"
    },
    {
        "nameRu": "Сальвадор",
        "nameEn": "El Salvador",
        "nameDe": "El Salvador",
        "countryCode": "sv",
        "phoneCode": "+503"
    },
    {
        "nameRu": "Экваториальная Гвинея",
        "nameEn": "Equatorial Guinea",
        "nameDe": "Äquatorialguinea",
        "countryCode": "gq",
        "phoneCode": "+240"
    },
    {
        "nameRu": "Суринам",
        "nameEn": "Suriname",
        "nameDe": "Suriname",
        "countryCode": "sr",
        "phoneCode": "+597"
    },
    {
        "nameRu": "Эфиопия",
        "nameEn": "Ethiopia",
        "nameDe": "Äthiopien",
        "countryCode": "et",
        "phoneCode": "+251"
    },
    {
        "nameRu": "Шпицберген и Ян-Майен",
        "nameEn": "Svalbard and Jan Mayen",
        "nameDe": "Spitzbergen und Jan Mayen",
        "countryCode": "sj",
        "phoneCode": "+47"
    },
    {
        "nameRu": "Эритрея",
        "nameEn": "Eritrea",
        "nameDe": "Eritrea",
        "countryCode": "er",
        "phoneCode": "+291"
    },
    {
        "nameRu": "Эстония",
        "nameEn": "Estonia",
        "nameDe": "Estland",
        "countryCode": "ee",
        "phoneCode": "+372"
    },
    {
        "nameRu": "Фарерские острова",
        "nameEn": "Faroe Islands",
        "nameDe": "Färöer",
        "countryCode": "fo",
        "phoneCode": "+298"
    },
    {
        "nameRu": "Эсватини",
        "nameEn": "Eswatini",
        "nameDe": "Eswatini",
        "countryCode": "sz",
        "phoneCode": "+268"
    },
    {
        "nameRu": "Фолклендские острова",
        "nameEn": "Falkland Islands (Malvinas)",
        "nameDe": "Falklandinseln",
        "countryCode": "fk",
        "phoneCode": "+500"
    },
    {
        "nameRu": "Южная Георгия и Южные Сандвичевы Острова",
        "nameEn": "South Georgia and the South Sandwich Islands",
        "nameDe": "Südgeorgien und die Südlichen Sandwichinseln",
        "countryCode": "gs",
        "phoneCode": "+500"
    },
    {
        "nameRu": "Швеция",
        "nameEn": "Sweden",
        "nameDe": "Schweden",
        "countryCode": "se",
        "phoneCode": "+46"
    },
    {
        "nameRu": "Фиджи",
        "nameEn": "Fiji",
        "nameDe": "Fidschi",
        "countryCode": "fj",
        "phoneCode": "+679"
    },
    {
        "nameRu": "Швейцария",
        "nameEn": "Switzerland",
        "nameDe": "Schweiz",
        "countryCode": "ch",
        "phoneCode": "+41"
    },
    {
        "nameRu": "Финляндия",
        "nameEn": "Finland",
        "nameDe": "Finnland",
        "countryCode": "fi",
        "phoneCode": "+358"
    },
    {
        "nameRu": "Аландские острова",
        "nameEn": "Åland Islands",
        "nameDe": "Åland",
        "countryCode": "ax",
        "phoneCode": "+358"
    },
    {
        "nameRu": "Сирия",
        "nameEn": "Syrian Arab Republic",
        "nameDe": "Syrien",
        "countryCode": "sy",
        "phoneCode": "+963"
    },
    {
        "nameRu": "Таджикистан",
        "nameEn": "Tajikistan",
        "nameDe": "Tadschikistan",
        "countryCode": "tj",
        "phoneCode": "+992"
    },
    {
        "nameRu": "Франция",
        "nameEn": "France",
        "nameDe": "Frankreich",
        "countryCode": "fr",
        "phoneCode": "+33"
    },
    {
        "nameRu": "Таиланд",
        "nameEn": "Thailand",
        "nameDe": "Thailand",
        "countryCode": "th",
        "phoneCode": "+66"
    },
    {
        "nameRu": "Гвиана",
        "nameEn": "French Guiana",
        "nameDe": "Französisch-Guayana",
        "countryCode": "gf",
        "phoneCode": "+594"
    },
    {
        "nameRu": "Того",
        "nameEn": "Togo",
        "nameDe": "Togo",
        "countryCode": "tg",
        "phoneCode": "+228"
    },
    {
        "nameRu": "Французская Полинезия",
        "nameEn": "French Polynesia",
        "nameDe": "Französisch-Polynesien",
        "countryCode": "pf",
        "phoneCode": "+689"
    },
    {
        "nameRu": "Токелау",
        "nameEn": "Tokelau",
        "nameDe": "Tokelau",
        "countryCode": "tk",
        "phoneCode": "+690"
    },
    {
        "nameRu": "Джибути",
        "nameEn": "Djibouti",
        "nameDe": "Dschibuti",
        "countryCode": "dj",
        "phoneCode": "+253"
    },
    {
        "nameRu": "Тонга",
        "nameEn": "Tonga",
        "nameDe": "Tonga",
        "countryCode": "to",
        "phoneCode": "+676"
    },
    {
        "nameRu": "Габон",
        "nameEn": "Gabon",
        "nameDe": "Gabun",
        "countryCode": "ga",
        "phoneCode": "+241"
    },
    {
        "nameRu": "Грузия",
        "nameEn": "Georgia",
        "nameDe": "Georgien",
        "countryCode": "ge",
        "phoneCode": "+995"
    },
    {
        "nameRu": "Тринидад и Тобаго",
        "nameEn": "Trinidad and Tobago",
        "nameDe": "Trinidad und Tobago",
        "countryCode": "tt",
        "phoneCode": "+1868"
    },
    {
        "nameRu": "Гамбия",
        "nameEn": "Gambia",
        "nameDe": "Gambia",
        "countryCode": "gm",
        "phoneCode": "+220"
    },
    {
        "nameRu": "ОАЭ",
        "nameEn": "United Arab Emirates",
        "nameDe": "Vereinigte Arabische Emirate",
        "countryCode": "ae",
        "phoneCode": "+971"
    },
    {
        "nameRu": "Государство Палестина",
        "nameEn": "Palestine, State of",
        "nameDe": "Palästina",
        "countryCode": "ps",
        "phoneCode": "+970"
    },
    {
        "nameRu": "Германия",
        "nameEn": "Germany",
        "nameDe": "Deutschland",
        "countryCode": "de",
        "phoneCode": "+49"
    },
    {
        "nameRu": "Тунис",
        "nameEn": "Tunisia",
        "nameDe": "Tunesien",
        "countryCode": "tn",
        "phoneCode": "+216"
    },
    {
        "nameRu": "Турция",
        "nameEn": "Turkey",
        "nameDe": "Türkei",
        "countryCode": "tr",
        "phoneCode": "+90"
    },
    {
        "nameRu": "Туркмения",
        "nameEn": "Turkmenistan",
        "nameDe": "Turkmenistan",
        "countryCode": "tm",
        "phoneCode": "+993"
    },
    {
        "nameRu": "Теркс и Кайкос",
        "nameEn": "Turks and Caicos Islands",
        "nameDe": "Turks- und Caicosinseln",
        "countryCode": "tc",
        "phoneCode": "+1649"
    },
    {
        "nameRu": "Тувалу",
        "nameEn": "Tuvalu",
        "nameDe": "Tuvalu",
        "countryCode": "tv",
        "phoneCode": "+688"
    },
    {
        "nameRu": "Гана",
        "nameEn": "Ghana",
        "nameDe": "Ghana",
        "countryCode": "gh",
        "phoneCode": "+233"
    },
    {
        "nameRu": "Уганда",
        "nameEn": "Uganda",
        "nameDe": "Uganda",
        "countryCode": "ug",
        "phoneCode": "+256"
    },
    {
        "nameRu": "Гибралтар",
        "nameEn": "Gibraltar",
        "nameDe": "Gibraltar",
        "countryCode": "gi",
        "phoneCode": "+350"
    },
    {
        "nameRu": "Украина",
        "nameEn": "Ukraine",
        "nameDe": "Ukraine",
        "countryCode": "ua",
        "phoneCode": "+380"
    },
    {
        "nameRu": "Северная Македония",
        "nameEn": "North Macedonia",
        "nameDe": "Nordmazedonien",
        "countryCode": "mk",
        "phoneCode": "+389"
    },
    {
        "nameRu": "Кирибати",
        "nameEn": "Kiribati",
        "nameDe": "Kiribati",
        "countryCode": "ki",
        "phoneCode": "+686"
    },
    {
        "nameRu": "Греция",
        "nameEn": "Greece",
        "nameDe": "Griechenland",
        "countryCode": "gr",
        "phoneCode": "+30"
    },
    {
        "nameRu": "Гренландия",
        "nameEn": "Greenland",
        "nameDe": "Grönland",
        "countryCode": "gl",
        "phoneCode": "+299"
    },
    {
        "nameRu": "Египет",
        "nameEn": "Egypt",
        "nameDe": "Ägypten",
        "countryCode": "eg",
        "phoneCode": "+20"
    },
    {
        "nameRu": "Гренада",
        "nameEn": "Grenada",
        "nameDe": "Grenada",
        "countryCode": "gd",
        "phoneCode": "+1473"
    },
    {
        "nameRu": "Гваделупа",
        "nameEn": "Guadeloupe",
        "nameDe": "Guadeloupe",
        "countryCode": "gp",
        "phoneCode": "+590"
    },
    {
        "nameRu": "Великобритания",
        "nameEn": "United Kingdom of Great Britain and Northern Ireland",
        "nameDe": "Vereinigtes Königreich",
        "countryCode": "gb",
        "phoneCode": "+44"
    },
    {
        "nameRu": "Гуам",
        "nameEn": "Guam",
        "nameDe": "Guam",
        "countryCode": "gu",
        "phoneCode": "+1671"
    },
    {
        "nameRu": "Гернси",
        "nameEn": "Guernsey",
        "nameDe": "Guernsey (Kanalinsel)",
        "countryCode": "gg",
        "phoneCode": "+44"
    },
    {
        "nameRu": "Гватемала",
        "nameEn": "Guatemala",
        "nameDe": "Guatemala",
        "countryCode": "gt",
        "phoneCode": "+502"
    },
    {
        "nameRu": "Джерси",
        "nameEn": "Jersey",
        "nameDe": "Jersey (Kanalinsel)",
        "countryCode": "je",
        "phoneCode": "+44"
    },
    {
        "nameRu": "Остров Мэн",
        "nameEn": "Isle of Man",
        "nameDe": "Insel Man",
        "countryCode": "im",
        "phoneCode": "+44"
    },
    {
        "nameRu": "Танзания",
        "nameEn": "Tanzania, United Republic of",
        "nameDe": "Tansania",
        "countryCode": "tz",
        "phoneCode": "+255"
    },
    {
        "nameRu": "Гвинея",
        "nameEn": "Guinea",
        "nameDe": "Guinea",
        "countryCode": "gn",
        "phoneCode": "+224"
    },
    {
        "nameRu": "Гайана",
        "nameEn": "Guyana",
        "nameDe": "Guyana",
        "countryCode": "gy",
        "phoneCode": "+595"
    },
    {
        "nameRu": "США",
        "nameEn": "United States of America",
        "nameDe": "Vereinigte Staaten",
        "countryCode": "us",
        "phoneCode": "+1"
    },
    {
        "nameRu": "Гаити",
        "nameEn": "Haiti",
        "nameDe": "Haiti",
        "countryCode": "ht",
        "phoneCode": "+509"
    },
    {
        "nameRu": "Ватикан",
        "nameEn": "Holy See",
        "nameDe": "Vatikanstadt",
        "countryCode": "va",
        "phoneCode": "+379"
    },
    {
        "nameRu": "Виргинские Острова (США)",
        "nameEn": "Virgin Islands (U.S.)",
        "nameDe": "Amerikanische Jungferninseln",
        "countryCode": "vi",
        "phoneCode": "+1340"
    },
    {
        "nameRu": "Гондурас",
        "nameEn": "Honduras",
        "nameDe": "Honduras",
        "countryCode": "hn",
        "phoneCode": "+504"
    },
    {
        "nameRu": "Буркина-Фасо",
        "nameEn": "Burkina Faso",
        "nameDe": "Burkina Faso",
        "countryCode": "bf",
        "phoneCode": "+226"
    },
    {
        "nameRu": "Гонконг",
        "nameEn": "Hong Kong",
        "nameDe": "Hongkong",
        "countryCode": "hk",
        "phoneCode": "+852"
    },
    {
        "nameRu": "Уругвай",
        "nameEn": "Uruguay",
        "nameDe": "Uruguay",
        "countryCode": "uy",
        "phoneCode": "+598"
    },
    {
        "nameRu": "Венгрия",
        "nameEn": "Hungary",
        "nameDe": "Ungarn",
        "countryCode": "hu",
        "phoneCode": "+36"
    },
    {
        "nameRu": "Узбекистан",
        "nameEn": "Uzbekistan",
        "nameDe": "Usbekistan",
        "countryCode": "uz",
        "phoneCode": "+998"
    },
    {
        "nameRu": "Венесуэла",
        "nameEn": "Venezuela (Bolivarian Republic of)",
        "nameDe": "Venezuela",
        "countryCode": "ve",
        "phoneCode": "+58"
    },
    {
        "nameRu": "Исландия",
        "nameEn": "Iceland",
        "nameDe": "Island",
        "countryCode": "is",
        "phoneCode": "+354"
    },
    {
        "nameRu": "Индия",
        "nameEn": "India",
        "nameDe": "Indien",
        "countryCode": "in",
        "phoneCode": "+91"
    },
    {
        "nameRu": "Индонезия",
        "nameEn": "Indonesia",
        "nameDe": "Indonesien",
        "countryCode": "id",
        "phoneCode": "+62"
    },
    {
        "nameRu": "Иран",
        "nameEn": "Iran (Islamic Republic of)",
        "nameDe": "Iran",
        "countryCode": "ir",
        "phoneCode": "+98"
    },
    {
        "nameRu": "Уоллис и Футуна",
        "nameEn": "Wallis and Futuna",
        "nameDe": "Wallis und Futuna",
        "countryCode": "wf",
        "phoneCode": "+681"
    },
    {
        "nameRu": "Ирак",
        "nameEn": "Iraq",
        "nameDe": "Irak",
        "countryCode": "iq",
        "phoneCode": "+964"
    },
    {
        "nameRu": "Самоа",
        "nameEn": "Samoa",
        "nameDe": "Samoa",
        "countryCode": "ws",
        "phoneCode": "+685"
    },
    {
        "nameRu": "Ирландия",
        "nameEn": "Ireland",
        "nameDe": "Irland",
        "countryCode": "ie",
        "phoneCode": "+353"
    },
    {
        "nameRu": "Йемен",
        "nameEn": "Yemen",
        "nameDe": "Jemen",
        "countryCode": "ye",
        "phoneCode": "+967"
    },
    {
        "nameRu": "Израиль",
        "nameEn": "Israel",
        "nameDe": "Israel",
        "countryCode": "il",
        "phoneCode": "+972"
    },
    {
        "nameRu": "Италия",
        "nameEn": "Italy",
        "nameDe": "Italien",
        "countryCode": "it",
        "phoneCode": "+39"
    },
    {
        "nameRu": "Замбия",
        "nameEn": "Zambia",
        "nameDe": "Sambia",
        "countryCode": "zm",
        "phoneCode": "+260"
    },
    {
        "nameRu": "Кот-д’Ивуар",
        "nameEn": "Côte d'Ivoire",
        "nameDe": "Elfenbeinküste",
        "countryCode": "ci",
        "phoneCode": "+225"
    },
    {
        "nameRu": "Ямайка",
        "nameEn": "Jamaica",
        "nameDe": "Jamaika",
        "countryCode": "jm",
        "phoneCode": "+1876"
    },
    {
        "nameRu": "Япония",
        "nameEn": "Japan",
        "nameDe": "Japan",
        "countryCode": "jp",
        "phoneCode": "+81"
    },
    {
        "nameRu": "Казахстан",
        "nameEn": "Kazakhstan",
        "nameDe": "Kasachstan",
        "countryCode": "kz",
        "phoneCode": "+77"
    },
    {
        "nameRu": "Иордания",
        "nameEn": "Jordan",
        "nameDe": "Jordanien",
        "countryCode": "jo",
        "phoneCode": "+962"
    },
    {
        "nameRu": "Кения",
        "nameEn": "Kenya",
        "nameDe": "Kenia",
        "countryCode": "ke",
        "phoneCode": "+254"
    },
    {
        "nameRu": "КНДР (Корейская Народно-Демократическая Республика)",
        "nameEn": "Korea (Democratic People's Republic of)",
        "nameDe": "Korea, Nord",
        "countryCode": "kp",
        "phoneCode": "+850"
    },
    {
        "nameRu": "Республика Корея",
        "nameEn": "Korea, Republic of",
        "nameDe": "Korea, Süd",
        "countryCode": "kr",
        "phoneCode": "+82"
    },
    {
        "nameRu": "Кувейт",
        "nameEn": "Kuwait",
        "nameDe": "Kuwait",
        "countryCode": "kw",
        "phoneCode": "+965"
    },
    {
        "nameRu": "Киргизия",
        "nameEn": "Kyrgyzstan",
        "nameDe": "Kirgisistan",
        "countryCode": "kg",
        "phoneCode": "+996"
    },
    {
        "nameRu": "Лаос",
        "nameEn": "Lao People's Democratic Republic",
        "nameDe": "Laos",
        "countryCode": "la",
        "phoneCode": "+856"
    },
    {
        "nameRu": "Ливан",
        "nameEn": "Lebanon",
        "nameDe": "Libanon",
        "countryCode": "lb",
        "phoneCode": "+961"
    },
    {
        "nameRu": "Лесото",
        "nameEn": "Lesotho",
        "nameDe": "Lesotho",
        "countryCode": "ls",
        "phoneCode": "+266"
    },
    {
        "nameRu": "Латвия",
        "nameEn": "Latvia",
        "nameDe": "Lettland",
        "countryCode": "lv",
        "phoneCode": "+371"
    },
    {
        "nameRu": "Либерия",
        "nameEn": "Liberia",
        "nameDe": "Liberia",
        "countryCode": "lr",
        "phoneCode": "+231"
    },
    {
        "nameRu": "Ливия",
        "nameEn": "Libya",
        "nameDe": "Libyen",
        "countryCode": "ly",
        "phoneCode": "+218"
    },
    {
        "nameRu": "Лихтенштейн",
        "nameEn": "Liechtenstein",
        "nameDe": "Liechtenstein",
        "countryCode": "li",
        "phoneCode": "+423"
    },
    {
        "nameRu": "Литва",
        "nameEn": "Lithuania",
        "nameDe": "Litauen",
        "countryCode": "lt",
        "phoneCode": "+370"
    },
    {
        "nameRu": "Люксембург",
        "nameEn": "Luxembourg",
        "nameDe": "Luxemburg",
        "countryCode": "lu",
        "phoneCode": "+352"
    },
    {
        "nameRu": "Макао",
        "nameEn": "Macao",
        "nameDe": "Macau",
        "countryCode": "mo",
        "phoneCode": "+853"
    },
    {
        "nameRu": "Мадагаскар",
        "nameEn": "Madagascar",
        "nameDe": "Madagaskar",
        "countryCode": "mg",
        "phoneCode": "+261"
    },
    {
        "nameRu": "Малави",
        "nameEn": "Malawi",
        "nameDe": "Malawi",
        "countryCode": "mw",
        "phoneCode": "+265"
    },
    {
        "nameRu": "Малайзия",
        "nameEn": "Malaysia",
        "nameDe": "Malaysia",
        "countryCode": "my",
        "phoneCode": "+60"
    },
    {
        "nameRu": "Мальдивы",
        "nameEn": "Maldives",
        "nameDe": "Malediven",
        "countryCode": "mv",
        "phoneCode": "+960"
    },
    {
        "nameRu": "Мали",
        "nameEn": "Mali",
        "nameDe": "Mali",
        "countryCode": "ml",
        "phoneCode": "+223"
    },
    {
        "nameRu": "Мальта",
        "nameEn": "Malta",
        "nameDe": "Malta",
        "countryCode": "mt",
        "phoneCode": "+356"
    },
    {
        "nameRu": "Мартиника",
        "nameEn": "Martinique",
        "nameDe": "Martinique",
        "countryCode": "mq",
        "phoneCode": "+596"
    },
    {
        "nameRu": "Мавритания",
        "nameEn": "Mauritania",
        "nameDe": "Mauretanien",
        "countryCode": "mr",
        "phoneCode": "+222"
    },
    {
        "nameRu": "Маврикий",
        "nameEn": "Mauritius",
        "nameDe": "Mauritius",
        "countryCode": "mu",
        "phoneCode": "+230"
    },
    {
        "nameRu": "Мексика",
        "nameEn": "Mexico",
        "nameDe": "Mexiko",
        "countryCode": "mx",
        "phoneCode": "+52"
    },
    {
        "nameRu": "Монако",
        "nameEn": "Monaco",
        "nameDe": "Monaco",
        "countryCode": "mc",
        "phoneCode": "+377"
    },
    {
        "nameRu": "Монголия",
        "nameEn": "Mongolia",
        "nameDe": "Mongolei",
        "countryCode": "mn",
        "phoneCode": "+976"
    },
    {
        "nameRu": "Молдавия",
        "nameEn": "Moldova, Republic of",
        "nameDe": "Moldau",
        "countryCode": "md",
        "phoneCode": "+373"
    },
    {
        "nameRu": "Черногория",
        "nameEn": "Montenegro",
        "nameDe": "Montenegro",
        "countryCode": "me",
        "phoneCode": "+382"
    },
    {
        "nameRu": "Монтсеррат",
        "nameEn": "Montserrat",
        "nameDe": "Montserrat",
        "countryCode": "ms",
        "phoneCode": "+1664"
    },
    {
        "nameRu": "Марокко",
        "nameEn": "Morocco",
        "nameDe": "Marokko",
        "countryCode": "ma",
        "phoneCode": "+212"
    },
    {
        "nameRu": "Мозамбик",
        "nameEn": "Mozambique",
        "nameDe": "Mosambik",
        "countryCode": "mz",
        "phoneCode": "+258"
    }
];