import {createUseStyles} from "react-jss";
import {MAIN_COLOR} from "../../util/properties";

const signUpStyles = createUseStyles({
    "@global": {
        body: {
            backgroundColor: "#FAF6F4",
            minHeight: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
        },
    },
    errorMessage: {
        paddingBottom: "15px",
        color: '#f33f3f',
    },
    signupBlock: {
        backgroundColor: "#FFFFFF",
        width: "384px",
        boxShadow: '0 0 10px rgba(150,154,213, 0.5)',
        boxSizing: 'border-box',
        borderRadius: '10px',

    },
    logoContainer: {
        paddingTop: "40px",
        paddingBottom: "40px",
        '& img': {
            maxWidth: "150px",
        }
    },
    inputContainers: {
        paddingLeft: "25px",
        paddingRight: "25px",
    },
    inputContainer: {
        marginBottom: "15px",
    },
    inputField: {
        margin: 'auto 0',
        display: 'block',
        width: '100%',
        borderWidth: '1px',
        borderColor: '#dfd4e7',
        textDecoration: 'none',
        padding: '10px 0 10px 16px',
        borderRadius: '4px',
        borderStyle: 'solid',
        boxSizing: 'border-box',
        height: '36px',
        '&:focus': {
            borderColor: "#7681e0",
            outline: 'none'

        },
    },
    inputFieldError: {
        borderColor: '#f33f3f',
        margin: 'auto 0',
        display: 'block',
        width: '100%',
        borderWidth: '2px',
        textDecoration: 'none',
        padding: '10px 0 10px 16px',
        borderRadius: '8px',
        borderStyle: 'solid',
        boxSizing: 'border-box',
        height: '42px',
        '&:focus': {
            borderColor: "#7681e0",
            outline: 'none'

        },
    },
    inputLabel: {
        display: "flex",
        fontSize: "16px",
    },
    staySigned: {
        color: "#717275",
        paddingTop: "20px",
        paddingBottom: "20px",
        display: "block",
        fontSize: "16px",
        lineHeight: "20px"
    },
    line: {
        background: '#ebeaee',
        height: '1px',
        width: '100%'
    },
    submitButtonContainer: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        borderTopWidth: "2px",
        borderTopColor: "#ebeaee",
        borderTopStyle: "solid",
        alignItems: "center",
        minHeight: "100%",
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingLeft: "25px",
        paddingRight: "25px"
    },
    forgotPassword: {
        "& a": {
            color: "#717275",
        }
    },
    submitButton: {
        backgroundColor: MAIN_COLOR,
        display: "block",
        cursor: "pointer",
        textAlign: "center",
        textDecoration: "none",
        border: "none",
        color: "#ffffff",
        borderRadius: "5px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "15px",
        paddingRight: "15px",
        boxSizing: "border-box",
        fontWeight: "600",
        fontSize: "14px",
    },

    '@media (max-width:368px)': {
        '@global' : {
            body: {
                '& > div:last-child': {
                    flexGrow: "1",
                }
            },

        },
        signupBlock: {
            width: "100%",
        }
    }
});

export default signUpStyles;