import * as React from 'react'
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import adminFormStyles from "./AdminFormStyles";
import {MultiSelect} from "../../common/MultiSelect/MultiSelect";

const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/


const validateSchema = Yup.object().shape({
    phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    email: Yup.string().email(),
});

export function AdminForm(props: any) {
    const {onSubmitHandle, formDefaultValues, isNew, id} = props;
    const styles = adminFormStyles();
    const navigate = useNavigate();

    return (
        <Formik
            initialValues={formDefaultValues}
            validationSchema={validateSchema}
            onSubmit={(values => onSubmitHandle(values, navigate, id))}>
            {(props) => {
                const {errors, touched, isValid, values} = props;
                return (<>
                        <Form>

                            <div className={styles.mainWrapper}>

                                <div className={styles.contentWrapper}>

                                    <DefaultPageContentContainer title={"Details"}>
                                        <div className={styles.inputContainers}>
                                            <div className={styles.inputContainer}>
                                                <label htmlFor="language">Language</label>
                                                <Field id="language" component="select"
                                                       name="language"
                                                       className={errors.language && touched.language ? styles.inputFieldError : styles.inputField}>
                                                    <>
                                                        <option value={"DE"}>Deutsch</option>
                                                        <option value={"EN"}>English</option>
                                                        <option value={"RU"}>Russian</option>
                                                    </>
                                                </Field>
                                            </div>
                                            <div className={styles.inputContainer}>
                                                <label htmlFor="firstName">First name</label>
                                                <Field id="firstName"
                                                       name="firstName" placeholder={""}
                                                       className={errors.firstName && touched.firstName ? styles.inputFieldError : styles.inputField}
                                                />
                                            </div>

                                            <div className={styles.inputContainer}>
                                                <label htmlFor="lastName">Last name</label>
                                                <Field id="lastName"
                                                       name="lastName" placeholder={""}
                                                       className={errors.lastName && touched.lastName ? styles.inputFieldError : styles.inputField}
                                                />
                                            </div>
                                            <div className={styles.inputContainer}>
                                                <label htmlFor="middleName">Middle name</label>
                                                <Field id="middleName" name="middleName"
                                                       placeholder={""}
                                                       className={errors.middleName && touched.middleName ? styles.inputFieldError : styles.inputField}
                                                />
                                            </div>


                                            <div className={styles.inputContainer}>
                                                <label htmlFor="email">Email</label>
                                                <Field id="email" name="email"
                                                       type="email" placeholder={"Email"}
                                                       className={errors.email && touched.email ? styles.inputFieldError : styles.inputField}
                                                />
                                            </div>

                                            <div className={styles.inputContainer}>
                                                <label htmlFor="phoneNumber">Phone number</label>
                                                <Field id="phoneNumber"
                                                       name="phoneNumber" type="text"
                                                       placeholder={""}
                                                       className={errors.phoneNumber && touched.phoneNumber ? styles.inputFieldError : styles.inputField}
                                                />
                                            </div>

                                            {
                                                !values.roles.includes("ADMIN") &&
                                                <div className={styles.inputContainer}>
                                                    <label htmlFor="roles">Roles</label>
                                                    <Field id="roles" name="roles"
                                                           component={MultiSelect}
                                                           isMulti={true}
                                                           options={[{label: "Admin", value: "ADMIN"},
                                                               {label: "Supervisor", value: "SUPERVISOR"},
                                                               {label: "Operator", value: "OPERATOR"},
                                                           ]}
                                                           placeholder={""}
                                                           className={styles.inputField}
                                                    />
                                                </div>
                                            }


                                            {
                                                isNew &&
                                                <div className={styles.inputContainer}>
                                                    <label htmlFor="roles">Temporary password</label>
                                                    <Field id="password" name="password" placeholder={"Password"}
                                                           type={"password"}
                                                           className={styles.inputField}/>
                                                </div>
                                            }
                                        </div>
                                    </DefaultPageContentContainer>
                                </div>

                            </div>
                            <button className={styles.submitButton} type="submit">
                                Save
                            </button>
                        </Form>
                    </>
                )
            }

            }
        </Formik>
    )
}