import * as React from 'react';
import sortButtonStyles from "./SortButtonStyles";
import {useState} from "react";

export type SortButtonProperties = {
    sortFields: Array<SortField>,
    setSortFields: any,
    fieldNameTarget: string,
    title: string,
};

export type SortField = {
    name: string,
    direction: "" | "ASC" | "DESC"
};

export const toSortParameters = (sortFields: Array<SortField>): string => {
    return sortFields && sortFields.length > 0
        ? sortFields
            .map(value => value.name + "," + value.direction)
            .reduce((accumulator, value) => accumulator + (accumulator ? "&" : "?") + "sort=" + value)
        : "";
}

export function MultipleSortButton(props: SortButtonProperties): React.ReactElement {
    const {sortFields, setSortFields, fieldNameTarget, title} = props;
    const styles = sortButtonStyles();
    const [direction, setDirection] = useState<"" | "ASC" | "DESC">("");

    const currentSortField = sortFields.find(x => x.name === fieldNameTarget);
    const currentSortFieldIndex = sortFields.findIndex(x => x.name === fieldNameTarget);

    const handleOnClick = () => {
        const directionValue = switchSort(direction);
        setDirection(directionValue);
        setSortFields(updateFields(sortFields, directionValue));
    }

    const updateFields = (sortFields: Array<SortField>, direction: string) => {
        if (direction && !currentSortField) {
            return [...sortFields, {name: fieldNameTarget, direction: direction}]
        }
        else if (direction && currentSortField) {
            return sortFields.map(field => field.name === fieldNameTarget ? {name: fieldNameTarget, direction: direction} : field)
        }
        return sortFields.filter(field => field.name !== fieldNameTarget);
    };

    return (<>
       <span className={styles.sortField} onClick={handleOnClick}>
           <div
               className={sortFields.some(field => field.name === fieldNameTarget) && currentSortField && currentSortField.direction === "ASC" ? styles.sortAscActive : styles.sortAsc}/>
           {title}
           <div
               className={sortFields.some(field => field.name === fieldNameTarget) && currentSortField && currentSortField.direction === "DESC" ? styles.sortDescActive : styles.sortDesc}/>
           <span className={styles.sortNumber}>{currentSortFieldIndex !== -1 && sortFields.length > 1 ? currentSortFieldIndex + 1 : ""}</span>
       </span>
    </>)
}

const switchSort = (sort: string) => {
    if (sort === "") {
        return "ASC";
    }
    if (sort === "ASC") {
        return "DESC";
    }
    if (sort === "DESC") {
        return "";
    }

    return "";
};
