import * as React from 'react'
import {useEffect, useState} from 'react'
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import {NavigateFunction} from "react-router-dom";
import {useParams} from "react-router-dom";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {Message} from "../../common/Message/Message";
import editStorage from "./EditStorageStyles";
import {StorageForm} from "../StorageForm/StorageForm";
import {createStorage, getStorage, StorageRq, StorageRs, updateStorage} from "../../../api/StoragesApi";
import {getLocationId} from "../../../util/common";

let formDefaultValues: StorageRq = {
    name: "",
    locationId: ""
}

export function EditStorage() {
    const styles = editStorage();
    const {id} = useParams();
    const isNewStorage = id === "new";
    const [message, setMessage] = useState<string>("");
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [initValues, setInitValues] = useState<StorageRq>(formDefaultValues);
    const locationId = getLocationId();

    const onSubmitHandle = (values: StorageRq, navigate: NavigateFunction, id?: string) => {

        const saveStorage = isNewStorage ? createStorage(values) : updateStorage(values, id)
        saveStorage
            .then((result: any) => {
                navigate("/storage/" + result.id);
            })
            .catch((result: any) => {
                setMessage("Error occurred while saving storage");
                setTimeout(() => {
                    setMessage("")
                }, 3000)
            });
    }

    useEffect(() => {

        if (!isNewStorage) {
            getStorage(id).then((storageRs: StorageRs) => {
                setInitValues({
                    name: storageRs.name,
                    longitude: storageRs.longitude,
                    latitude: storageRs.latitude,
                    locationId: locationId
                })
                setIsLoaded(true);
            })
        } else {
            setInitValues({
                name: "",
                locationId: locationId
            })
            setIsLoaded(true);
        }


    }, [id, isNewStorage]);
    return (
        <>
            <Message message={message} isError={true}/>
            <MainContainer>
                <TitleWrapper title={(isNewStorage ? "New" : "Edit") + " storage"}>
                </TitleWrapper>
                <div className={styles.mainWrapper}>
                    <div className={styles.contentWrapper}>
                        <DefaultPageContentContainer title={"Details"}>
                            {
                                isLoaded
                                    ? <StorageForm onSubmitHandle={onSubmitHandle}
                                                   formDefaultValues={initValues} id={id}/>
                                    : <img src={"/loading.gif"} alt={""}/>
                            }
                        </DefaultPageContentContainer>
                    </div>
                </div>

            </MainContainer>
        </>
    )
}