import React, {ReactElement, useEffect, useState} from 'react';
import {TitleWrapper} from "../common/TitleWrapper/TitleWrapper";
import {MainContainer} from "../common/MainContainer/MainContainer";
import alarmsStyles from "./AlarmsStyles";
import {getActiveAlarmCountByTypes} from "../../api/AlarmApi";
import {AlarmPage} from "./AlarmPage/AlarmPage";
import {getLocationId, getUserFromLocalStorage, isAccessRoles, Role} from "../../util/common";

export type AlarmsProperties = {
    roles: Array<Role>
}

const Alarms = (props: AlarmsProperties): ReactElement => {
    const styles = alarmsStyles();
    const {roles} = props;
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [alarmType, setAlarmType] = useState<string>("BATTERY_LOW");
    const [alarmCounts, setAlarmCounts] = useState<any>({});
    const locationId = getLocationId();

    useEffect(() => {
        getActiveAlarmCountByTypes(locationId).then((result: any) => {
            setAlarmCounts(result);
            setDataLoaded(true);
        })

    }, [alarmType, locationId]);

    return (
        <MainContainer>
            {
                dataLoaded ? <>
                        <div className={styles.bannerWrapper}>
                            <div
                                className={styles.banner + (alarmType === "BATTERY_LOW" ? (" " + styles.bannerActive) : "")}
                                onClick={() => setAlarmType("BATTERY_LOW")}>
                                <div>
                                    <div className={styles.bannerNumber}>
                                        {alarmCounts["BATTERY_LOW"] ? alarmCounts["BATTERY_LOW"] : 0}
                                    </div>
                                    <div className={styles.bannerText}>
                                        Low battery percentage
                                    </div>
                                </div>

                            </div>
                            <div
                                className={styles.banner + (alarmType === "LONG_TRIP" ? (" " + styles.bannerActive) : "")}
                                onClick={() => setAlarmType("LONG_TRIP")}>
                                <div>
                                    <div className={styles.bannerNumber}>
                                        {alarmCounts["LONG_TRIP"] ? alarmCounts["LONG_TRIP"] : 0}
                                    </div>
                                    <div className={styles.bannerText}>
                                        Long trips
                                    </div>
                                </div>

                            </div>
                            <div
                                className={styles.banner + (alarmType === "INSURANCE_EXPIRED" ? (" " + styles.bannerActive) : "")}
                                onClick={() => setAlarmType("INSURANCE_EXPIRED")}>
                                <div>
                                    <div className={styles.bannerNumber}>
                                        {alarmCounts["INSURANCE_EXPIRED"] ? alarmCounts["INSURANCE_EXPIRED"] : 0}
                                    </div>
                                    <div className={styles.bannerText}>
                                        Insurance expired
                                    </div>
                                </div>

                            </div>
                            <div
                                className={styles.banner + (alarmType === "FINISHED_WITH_ERROR_TRIP" ? (" " + styles.bannerActive) : "")}
                                onClick={() => setAlarmType("FINISHED_WITH_ERROR_TRIP")}>
                                <div>
                                    <div className={styles.bannerNumber}>
                                        {alarmCounts["FINISHED_WITH_ERROR_TRIP"] ? alarmCounts["FINISHED_WITH_ERROR_TRIP"] : 0}
                                    </div>
                                    <div className={styles.bannerText}>
                                        Trips finished with errors
                                    </div>
                                </div>

                            </div>
                            <div
                                className={styles.banner + (alarmType === "FAILED_LOCK_VEHICLE" ? (" " + styles.bannerActive) : "")}
                                onClick={() => setAlarmType("FAILED_LOCK_VEHICLE")}>
                                <div>
                                    <div className={styles.bannerNumber}>
                                        {alarmCounts["FAILED_LOCK_VEHICLE"] ? alarmCounts["FAILED_LOCK_VEHICLE"] : 0}
                                    </div>
                                    <div className={styles.bannerText}>
                                        Failed lock/unlock Segway Api operation
                                    </div>
                                </div>

                            </div>
                            <div
                                className={styles.banner + (alarmType === "EXPIRED_VEHICLE_STATE" ? (" " + styles.bannerActive) : "")}
                                onClick={() => setAlarmType("EXPIRED_VEHICLE_STATE")}>
                                <div>
                                    <div className={styles.bannerNumber}>
                                        {alarmCounts["EXPIRED_VEHICLE_STATE"] ? alarmCounts["EXPIRED_VEHICLE_STATE"] : 0}
                                    </div>
                                    <div className={styles.bannerText}>
                                        Could not get fresh vehicle info
                                    </div>
                                </div>

                            </div>
                        </div>
                        <TitleWrapper title={""}>

                        </TitleWrapper>
                        <AlarmPage title={""} roles={roles} type={alarmType}/>
                    </>
                    : <img src={"/loading.gif"} alt={""}/>
            }

        </MainContainer>
    );
};

export default Alarms;
