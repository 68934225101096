import {
    createDefaultHeaders,
    createUrlParameters, fetchNoResponse,
    fetchWithResponse,
    getAccessTokenFromLocalStorage, getLocationId, PageResponse
} from "../util/common";
import {HOST} from "../util/properties";

export type PromoCodeRs = {
    id: string,
    active?: boolean,
    startDate: string,
    endDate: string,
    code: string,
    description?: string,
    discount: number,
    approved?: boolean,
    photo?: any,
    photoName?: string,
    userLimit: number
}

export type PromoCodeRq = {
    active?: boolean,
    startDate?: string,
    endDate?: string,
    code?: string,
    description?: string,
    discount?: number,
    approved?: boolean,
    photo?: any,
    userLimit?: number
}

export const createPromoCode = (values: FormData): Promise<PromoCodeRs> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/promo-codes", {
        method: 'POST',
        body: values,
        headers: {
            'Authorization': 'Bearer ' + userToken,
        }
    })
};

export const getPromoCode = (id?: string): Promise<PromoCodeRs> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/promo-codes/" + id, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export type PromoCodesParams = {
    page?: number,
    sort?: string,
    size?: number,
    code?: string,
    startDate?: Date,
    endDate?: Date,
    active?: Date,
    approved?: Date,
}

export const getPromoCodes = (props: PromoCodesParams): Promise<PageResponse<PromoCodeRs>> => {
    const {page, sort, size, code, startDate, endDate, active, approved} = props;

    const userToken = getAccessTokenFromLocalStorage();
    const paramString = createUrlParameters({
        "page": page ? page : 0,
        "sort": sort,
        "code": code,
        "size": size ? size : 20,
        "startDate": startDate,
        "endDate": endDate,
        "active": active,
        "approved": approved,
    });

    return fetchWithResponse(HOST + "/api/promo-codes" + paramString, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const updatePromoCode = (values: FormData, id: string): Promise<PromoCodeRs> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchNoResponse(HOST + "/api/promo-codes/" + id, {
        method: 'PUT',
        body: values,
        headers: {
            'Authorization': 'Bearer ' + userToken,
        }
    })
};