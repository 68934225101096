import React, {ReactElement, useEffect, useState} from 'react';
import {TitleWrapper} from "../common/TitleWrapper/TitleWrapper";
import {MainContainer} from "../common/MainContainer/MainContainer";
import {PageResponse} from "../../util/common";
import {ActionButton} from "../common/ActionButton/ActionButton";
import promoCodesStyles from "./PromoCodesStyles";
import {getPromoCodes, PromoCodeRs} from "../../api/PromoCodeApi";
import {CardPageContainer} from "../common/TableContainer/CardPageContainer";
import {PromoCodeCard} from "./PromoCodeCard/PromoCodeCard";
import {Message} from "../common/Message/Message";

const PromoCodes = (): ReactElement => {
    const styles = promoCodesStyles();
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [promoCodes, setPromoCodes] = useState<PageResponse<PromoCodeRs> | undefined>();
    const [message, setMessage] = useState<string>("");

    useEffect(() => {
        getPromoCodes({})
            .then((result: PageResponse<PromoCodeRs>) => {
                setPromoCodes(result);
                setDataLoaded(true);
            })
            .catch(() => {
                setMessage("Error occurred while loading promo codes");
                setDataLoaded(true);
            })

    }, []);

    return (
        <>
            <Message message={message} isError={true}/><MainContainer>
            <TitleWrapper title={"Promo codes"}>
                <ActionButton title={"New promo code"} url={"/promo-code/edit/new"} isLink={true}/>
            </TitleWrapper>
            {
                dataLoaded && <CardPageContainer title={"Active"}>
                    {
                        promoCodes && promoCodes.content.length > 0 ?
                            promoCodes.content.map(promoCode => <PromoCodeCard promoCode={promoCode}/>)
                            : <div>
                                No promo codes yet. <a href="/promo-code/edit/new">Create new promo code?</a>
                            </div>}
                </CardPageContainer>
            }
        </MainContainer>
        </>
    );
};

export default PromoCodes;




