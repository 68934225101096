import {createUseStyles} from "react-jss";

const addStorageModalStyles = createUseStyles({
    modalButtonConfirm: {
        marginRight: '5px',
        fontSize: "15px",
        color: "#0047F4",
        borderRadius: "7px",
        padding: "5px 15px",
        lineHeight: "1.5",
        outline: "0",
        textDecoration: "none",
        border: "2px solid #0047F4",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        boxSizing: "border-box",
        fontWeight: "600",
        backgroundColor: "#ffffff",
        cursor: "pointer",
    },
    modalButtonCancel: {
        marginRight: '5px',
        fontSize: "15px",
        color: "#0047F4",
        borderRadius: "7px",
        padding: "5px 15px",
        lineHeight: "1.5",
        outline: "0",
        textDecoration: "none",
        border: "2px solid #94A0BDFF",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        boxSizing: "border-box",
        fontWeight: "600",
        backgroundColor: "#ffffff",
        cursor: "pointer",
    },
    modalText: {
        fontWeight: "600",
        marginBottom: "40px"
    },
    modalButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-start'
    },
    inputContainers: {
        paddingLeft: "25px",
        paddingRight: "25px",
        paddingTop: "25px",
        paddingBottom: "25px",
    },
    inputContainer: {
        "& label": {
            display: "block",
            paddingTop: "10px",
            paddingBottom: "5px",
            paddingLeft: "5px",
            fontSize: "12px",
            textAlign: "left",
            fontWeight: "600",
            color: "#717275",
        }
    },
    inputField: {
        margin: 'auto 0',
        display: 'block',
        width: '100%',
        borderWidth: '1px',
        borderColor: '#dfd4e7',
        textDecoration: 'none',
        padding: '5px 0 5px 5px',
        borderRadius: '1px',
        borderStyle: 'solid',
        boxSizing: 'border-box',
        '&:focus': {
            borderColor: "#7681e0",
            outline: 'none'

        },
    },
    submitButton: {
        marginTop: "15px",
        backgroundColor: "#0044EA",
        display: "block",
        cursor: "pointer",
        textAlign: "center",
        textDecoration: "none",
        border: "none",
        color: "#ffffff",
        borderRadius: "5px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "15px",
        paddingRight: "15px",
        boxSizing: "border-box",
        fontWeight: "600",
        fontSize: "14px",
        marginBottom: "30px",
    },
    inputFieldError: {
        borderColor: '#f33f3f',
        margin: 'auto 0',
        display: 'block',
        width: '100%',
        borderWidth: '1px',
        textDecoration: 'none',
        padding: '5px 0 5px 5px',
        borderRadius: '1px',
        borderStyle: 'solid',
        boxSizing: 'border-box',
        '&:focus': {
            borderColor: "#7681e0",
            outline: 'none'

        },
    },
    inputLabel: {
        display: "flex",
    },
})

export default addStorageModalStyles;