import * as React from 'react'
import {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {getEndPoint, getStartPoint, getTrip, Point, TripFragment, TripRs} from "../../../api/TripApi";
import Map, {Layer, Source} from "react-map-gl";
import tripStyles from "./TripStyles";
import {MAP_STYLE, MAPBOX_PUBLIC_TOKEN} from '../../../util/properties';
import {AlarmPage} from "../../Alarms/AlarmPage/AlarmPage";
import {UserField} from "../../common/UserField/UserField";
import {calcDuration, calcMinutesBetweenDates, formatDateTime, formatTime} from "../../../util/dateUtils";
import {getUserFromLocalStorage, Role} from "../../../util/common";


const TripRsInit: TripRs = {
    id: "",
    status: "",
    userId: "",
    finishedWithError: false,
    userFullName: "",
    vehicleId: "",
    fragments: [],
}

export type TripProperties = {
    roles: Array<Role>
}

export function Trip(props: TripProperties) {
    const styles = tripStyles();
    const {roles} = props;
    const user = getUserFromLocalStorage();
    const [trip, setTrip] = useState<TripRs>(TripRsInit);
    const {id} = useParams();

    const startPoint = getStartPoint(trip);
    const endPoint = getEndPoint(trip);

    useEffect(() => {
        getTrip(id).then((result: TripRs) => {
            setTrip(result);
        });

    }, []);

    return (
        <>
            <MainContainer>
                <TitleWrapper title={"Trip - " + id}>
                </TitleWrapper>
                <div className={styles.contentWrapper}>
                    <div className={styles.leftColumn}>
                        <DefaultPageContentContainer title={"Details"}>
                            <UserField title={"Status:"} field={createStatusStyle(styles, trip.finishedWithError, trip.status)}/>
                            <UserField title={"User:"} field={trip.userFullName}/>
                            <UserField title={"Vehicle:"}
                                       field={<a href={"/vehicle/" + trip.vehicleId}>{trip.vehicleId}</a>}/>
                            {trip.startPoint ? <UserField title={"Start:"}
                                                          field={createPointLabel(trip.startPoint, trip.startTime, styles)}/> : <></>}
                            {trip.endPoint ? <UserField title={"End:"}
                                                        field={createPointLabel(trip.endPoint, trip.endTime, styles)}/> : <></>}
                            <UserField title={"Duration:"} field={<span>
                                        {trip.startTime && !trip.endTime ? "Trip is active" : calcDuration(trip.startTime, trip.endTime)}
                                    </span>}/>
                            <UserField title={"Distance:"} field={trip.distance + "km"}/>
                            <UserField title={"Subscription:"} field={trip.subscriptionId}/>
                            <UserField title={"Price:"} field={trip.price ? trip.price : "no payment"}/>
                        </DefaultPageContentContainer>

                        <DefaultPageContentContainer title={"Address"}>

                            {
                                startPoint &&

                                <Map
                                    initialViewState={{
                                        longitude: startPoint.longitude,
                                        latitude: startPoint.latitude,
                                        zoom: 12
                                    }}
                                    mapboxAccessToken={MAPBOX_PUBLIC_TOKEN}
                                    style={{width: "100%", height: 400}}
                                    mapStyle={MAP_STYLE}
                                >

                                    {createMapLines("lines", "rgba(3, 170, 238, 0.5)", trip.fragments.flatMap(x => x.points.map(y => [y.longitude, y.latitude])))}
                                    {createMapPoint("startPoint", "rgba(0, 220, 38, 1)", [startPoint.longitude, startPoint.latitude])}
                                    {trip.startPoint && trip.fragments.length > 0 && trip.fragments[0].points.length > 0 &&
                                        createMapPoint("pauses", "rgba(20, 20, 38, 1)", trip.fragments.filter(x => x.type === "PAUSE").flatMap(p => [p.points[0].longitude, p.points[0].latitude]))
                                    }
                                    {endPoint &&
                                        createMapPoint("finish", "rgba(220, 20, 38, 1)", [
                                            endPoint.longitude,
                                            endPoint.latitude])
                                    }

                                </Map>
                            }

                        </DefaultPageContentContainer>


                    </div>
                    <div className={styles.rightColumn}>
                        <DefaultPageContentContainer title={"Timeline"}>

                            <>
                                {createTimeLine(styles, trip.fragments)}
                            </>

                        </DefaultPageContentContainer>
                        {
                            trip && trip.photo &&
                            <DefaultPageContentContainer title={"Photo"}>
                                <div className={styles.photoContainer}>
                                    <img src={`data:image/jpeg;base64,${trip.photo.data}`}/>
                                </div>
                            </DefaultPageContentContainer>
                        }
                        <DefaultPageContentContainer title={"Associated tasks"}>
                            <table className={styles.dataTable}>
                                <thead>
                                <tr className={styles.headerRow}>
                                    <th className="">Updated</th>
                                    <th className="">Title</th>
                                    <th className="">Assignee</th>
                                    <th className="">Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr className={styles.noDataBlock}>
                                    <th>No tasks found</th>
                                </tr>
                                </tbody>
                            </table>

                        </DefaultPageContentContainer>
                        <AlarmPage roles={roles} title={"Alarms"} tripId={id}/>
                    </div>
                </div>
            </MainContainer>
        </>
    )
}

type TimeLinePoint = {
    time: Date,
    type: string,
}

const createTimeLine = (styles: any, tripFragments: Array<TripFragment>): React.ReactNode => {


    if (tripFragments.length === 0) {
        return <div>No trip fragments</div>
    }

    let linePoints = new Array<TimeLinePoint>();
    for (let i = 0; i < tripFragments.length; i++) {
        const fragment = tripFragments[i];
        if (fragment.startTime) {
            let type = "POINT";

            if (i === 0) {
                type = "START";
            } else if (fragment.type === "PAUSE") {
                type = "PAUSE";
            }

            linePoints.push({
                time: fragment.startTime,
                type: type
            })
        }
        if (tripFragments.length - 1 === i) {
            linePoints.push({
                time: fragment.endTime ? fragment.endTime : new Date(),
                type: "END"
            })
        }
    }

    const minutesBetweenFragments = linePoints.length > 1 ? calcMinutesBetweenDates(linePoints[0].time, linePoints[linePoints.length - 1].time) : 0;

    return <div className={styles.timelineContainer}>
        <div className={styles.timeline}>

            {linePoints.map((point, index) => {
                    const leftOffset = calculatePxOnTimeline(linePoints[0], point, minutesBetweenFragments);
                    const offsetBetweenPoints = index === linePoints.length - 1 ? 0 : calculatePxOnTimeline(linePoints[index], linePoints[index + 1], minutesBetweenFragments);
                    return <>
                        <div className={styles.startPoint}
                             style={{left: leftOffset}}>
                            <span
                                className={offsetBetweenPoints < 30 && offsetBetweenPoints > 1 ? styles.upperPointLabel : styles.downPointLabel}>
                                {minutesBetweenFragments > 3600
                                    ? formatDateTime(point.time)
                                    : formatTime(point.time)
                                }
                            </span>
                        </div>
                        {
                            point.type === "PAUSE"
                                ?
                                <div className={styles.pauseTimeline}
                                     style={{
                                         left: leftOffset,
                                         width: offsetBetweenPoints
                                     }}>

                                </div>
                                :
                                <></>
                        }
                    </>
                }
            )}
        </div>

    </div>
}

const calculatePxOnTimeline = (start: TimeLinePoint, end: TimeLinePoint, minutes: number): number => {
    const minutesBetween = calcMinutesBetweenDates(start.time, end.time);

    if (minutesBetween === 0 || minutes === 0) {
        return 0;
    }

    return minutesBetween / minutes * 300;
}

const createStatusStyle = (styles: any, finishedWithError: boolean, status?: string): React.ReactNode => {
    if (!status) {
        return <span className={styles.tag + " " + styles.redTag}>NO STATUS</span>
    }
    if (status.toLowerCase() === "active") {
        return <span className={styles.tag + " " + styles.greenTag}>Active</span>
    }
    if (status.toLowerCase() === "paused") {
        return <span className={styles.tag + " " + styles.greenTag}>Paused</span>
    }
    if (status.toLowerCase() === "finished" && !finishedWithError) {
        return <span className={styles.tag + " " + styles.greenTag}>Finished</span>
    }
    if (status.toLowerCase() === "finished" && finishedWithError) {
        return <span className={styles.tag + " " + styles.redTag}>Finished with error</span>
    }
    if (status.toLowerCase() === "cancelled") {
        return <span className={styles.tag + " " + styles.yellowTag}>Cancelled</span>
    }
    return <span className={styles.tag + " " + styles.redTag}>Unknown</span>
}

const createPointLabel = (point: Point, date: Date | undefined, styles: any): React.ReactElement => {
    return <>
        <div>
            <span><img className={styles.storageLogo} src="/storage.svg" alt=""/></span>
            <span
                className={styles.mapPoint}>{(point ? point.latitude.toPrecision(7) : "") + ", " + (point ? point.longitude.toPrecision(7) : "")} </span>
        </div>
        <div>
            <span>{formatDateTime(date)}</span>
        </div>
    </>
}

const createMapLines = (name: string, color: string, coordinates: any) => {
    return <Source id={name} type="geojson" data={{
        type: "Feature",
        properties: {},
        geometry: {
            type: "LineString",
            coordinates: coordinates
        }
    }}>

        <Layer
            id="lineLayer"
            type="line"
            source="my-data"
            layout={{
                "line-join": "round",
                "line-cap": "round"
            }}
            paint={{
                "line-color": color,
                "line-width": 5
            }}
        />
    </Source>
}

const createMapPoint = (name: string, color: string, coordinates: any) => {
    return <Source id={name} type="geojson" data={{
        type: "Feature",
        properties: {},
        geometry: {
            type: "Point",
            coordinates: coordinates
        }
    }}>
        <Layer
            id="PointLayers"
            type="circle"
            source="my-data"
            paint={{
                "circle-color": color,
                "circle-radius": 12
            }}
        />
    </Source>
}