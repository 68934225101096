import {createUseStyles} from "react-jss";

const userFieldStyles = createUseStyles({
    fieldWrapper: {
        paddingTop: "10px",
        paddingBottom: "10px",
        fontSize: "14px",
        fontWeight: "600",
        wordBreak: "break-word",
        "& span": {
            fontWeight: "400"
        }
    },
    fieldName: {
        textAlign: "right",
        float: "left",
        clear: "both",
        minWidth: "25%",
        fontSize: "14px",
        wordBreak: "break-word",
        fontWeight: "600",
        marginTop: "5px",
        marginBottom: "5px",
    },
    fieldValue: {
        marginTop: "5px",
        marginBottom: "5px",
        float: "left",
        paddingLeft: "10px",
        fontSize: "14px",
        wordBreak: "break-word",
        fontWeight: "600",
        "& span": {
            fontWeight: "400"
        },
        "& a": {
            color: "#3f3f3f",
            textDecoration: "none",
        }
    },
});

export default userFieldStyles;