import React, {ReactElement, useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {Page} from "../common/Page/Page";
import {TitleWrapper} from "../common/TitleWrapper/TitleWrapper";
import {MainContainer} from "../common/MainContainer/MainContainer";
import {getLocationId, PageResponse, toEnumFormat} from "../../util/common";
import {getTrips, TripShort} from "../../api/TripApi";
import {formatDateTime} from "../../util/dateUtils";

const Trips = (): ReactElement => {
    const {status} = useParams();
    const locationId = getLocationId();
    
    const [tripPage, setTripPage] = useState<PageResponse<TripShort> | undefined>(undefined);
    const [page, setPage] = useState<number>(0);
    const mainTitle = "Trips " + (status ? ("(" + status + ")") : "") + "- " + (tripPage ? tripPage.totalElements.toString() : "");

    useEffect(() => {
        getTrips(locationId, undefined, undefined, toEnumFormat(status), page).then((result: any) => {
            setTripPage(result);
        })
    }, [page, status]);

    return (
        <MainContainer>
            <TitleWrapper title={mainTitle}>

            </TitleWrapper>
            <Page title={"Trips - "} createHeaderRow={createTripHeaderRow} createDataRow={createTripDataRow} page={page} setPage={setPage} data={tripPage}/>
        </MainContainer>
    );
};

const createTripHeaderRow = () => {
    return <>
        <th className="">ID</th>
        <th className="">Start time</th>
        <th className="">Status</th>
        <th className="">User</th>
        <th className="">Vehicle</th>
        <th></th>
    </>
}

const createTripDataRow = (trip: TripShort, navigate: any, styles: any): React.ReactElement => {

    return <tr>
        <th className=""><a href={"/trip/" + trip.id}>{trip.id}</a></th>
        <th className="">{formatDateTime(trip.startTime)}</th>
        <th className="">{trip.status}</th>
        <th className=""><a href={"/user/" + trip.userId}>{trip.userFullName}</a></th>
        <th className=""><a href={"/vehicle/" + trip.vehicleId}>{trip.vehicleId}</a></th>
        <th className={styles.iconContainer}>
            <Link to={"/trip/" + trip.id}><img className={styles.icon} src="/eye.svg" alt=""/></Link>
        </th>
    </tr>
}

export default Trips;
