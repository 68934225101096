import {createUseStyles} from "react-jss";

const loadingBarStyles = createUseStyles({
    loadingBarContainer: {
        display: "flex",
        justifyContent: "center"
    },
    imageContainer: {
        width: "100px"
    }
})

export default loadingBarStyles;