import {useField} from "formik";
import * as React from "react";
import {useEffect, useState} from "react";
import {durationPickerStyles} from "./DurationPickerStyles";
import {getHoursFromSeconds, getMinutesExceptHoursFromSeconds} from "../../../util/dateUtils";


export const DurationPicker = ({...selectProps}) => {
    const styles = durationPickerStyles();
    const [field, meta, helpers] = useField(selectProps.id);

    const initHours = getHoursFromSeconds(field.value);
    const initMinutes = getMinutesExceptHoursFromSeconds(field.value);

    const [hours, setHours] = useState<number>(initHours);
    const [minutes, setMinutes] = useState<number>(initMinutes);

    useEffect(() => {
        const value = (hours * 3600) + (minutes * 60);

        console.log(value)
        helpers.setValue(value);
    }, [hours, minutes]);

    return (
        <div className={styles.durationContainer}>
            <div>
                <label htmlFor={selectProps.id + "Hours"}>Hours</label>
                <input id={selectProps.id + "Hours"} type={"number"} placeholder={"1"} value={hours}
                       onChange={(v: any) => {
                           if (v.target.value < 0) {
                               v.target.value = 0;
                           }
                           if (v.target.value < 24) {
                               setHours(v.target.value);
                           }
                       }}/>
            </div>

            <div>
                <label htmlFor={selectProps.id + "Minutes"}>Minutes</label>
                <input id={selectProps.id + "Minutes"} type={"number"} placeholder={"0"} value={minutes}
                       onChange={(v: any) => {
                           if (v.target.value < 0) {
                               v.target.value = 0;
                           }
                           if (v.target.value < 60) {
                               setMinutes(v.target.value);
                           }
                       }}/>
            </div>

        </div>

    )
}