import {useField} from "formik";
import * as React from "react";
import {useEffect, useState} from "react";


export const MoneyPicker = ({...selectProps}) => {
    const [field, meta, helpers] = useField(selectProps.id);

    const {errors, touched, isSubmitting, styles} = selectProps;

    const [money, setMoney] = useState<number>(field.value || 0.00);

    const addDecimals = (money: number) => {
        return money.toFixed(2)
    }

    useEffect(() => {
        helpers.setValue(money);
    }, [money]);

    return (

        <div>
            <input id={selectProps.id + "Minutes"} type={"text"} placeholder={"0"} value={addDecimals(money)}
                   className={errors.price && (touched.price || isSubmitting)
                       ? styles.inputFieldError
                       : styles.inputField}
                   onChange={(v: any) => {
                       if (!v.target.value) {
                           setMoney(0);
                           return;
                       }
                       if (Number.isInteger(v.target.value.charAt(0))) {
                           setMoney(money);
                           return;
                       }

                       setMoney(parseFloat(toValidNumber(v.target.value)))
                   }}/>
        </div>


    )
}

const toValidNumber = (str: string) => {
    let num = "";
    let dotUsedCount = 0;

    for (let i = 0; i < str.length; i++) {
        if (str[i] === "." && dotUsedCount < 1) {
            dotUsedCount++;
            num += str[i];
        }
        if (isCharNumber(str[i])) {
            num += str[i];
        }
    }

    return num || "0";
}

const isCharNumber = (c: string) => c >= '0' && c <= '9';
