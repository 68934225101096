import React, {ReactElement, useEffect, useState} from 'react';
import dashboardStyles from './DashboardStyles';
import Map, {Layer, Marker, Source} from 'react-map-gl';
import {MAPBOX_PUBLIC_TOKEN, MAP_STYLE} from '../../util/properties';
import {getVehicles, VehicleShortResponse} from '../../api/VehicleApi';
import {getLocationGeoJson, PageResponse} from '../../util/common';
import "mapbox-gl/dist/mapbox-gl.css";
import {UserField} from "../common/UserField/UserField";

export const Dashboard = (): ReactElement => {
    const styles = dashboardStyles();
    const geoJson = getLocationGeoJson();

    const [viewport, setViewport] = useState({});
    const [activeMarker, setActiveMarker] = useState<VehicleShortResponse | undefined>(undefined);
    const [vehiclePage, setVehiclePage] = useState<PageResponse<VehicleShortResponse> | undefined>(undefined);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((pos) => {
            setViewport({
                ...viewport,
                latitude: pos.coords.latitude,
                longitude: pos.coords.longitude,
                zoom: 10.5,
            });
        })
        setInterval(() => {
            getVehicles({page: 0, size: 1000}).then((result: PageResponse<VehicleShortResponse>) => {
                setVehiclePage(result);
            });
        }, 5000);


    }, []);
    return (
        <div className={styles.mapContainer}>
            <div className={styles.leftMenu + " " + (activeMarker ? styles.openedMenu : "")}>

                <div className={styles.leftMenuTopMenu}>
                    <div className={styles.closeButton} onClick={() => {
                        setActiveMarker(undefined)
                    }}>
                        <img src="/close.svg" alt=""/>
                    </div>

                </div>
                <h4>Vehicle - {activeMarker?.qrCode}</h4>
                <div className={styles.leftMenuContent}>
                    <UserField title={"External ID:"} field={activeMarker?.externalId}/>
                    <UserField title={"QR code:"} field={activeMarker?.qrCode}/>
                    <UserField title={"State of charge:"} field={createStateOfChargeStyle(activeMarker?.stateOfCharge, styles)}/>
                    <UserField title={"Mileage:"} field={activeMarker?.mileage + " m"}/>
                    <UserField title={"Status:"} field={activeMarker?.status}/>
                </div>
            </div>
            <div className={styles.mapWrapper}>
                <Map
                    initialViewState={viewport}
                    style={{width: "100%", height: "100%"}}
                    mapboxAccessToken={MAPBOX_PUBLIC_TOKEN}
                    mapStyle={MAP_STYLE}
                >
                    {
                        geoJson && geoJson.geometries
                        ? <Source id={"back"} type="geojson" data={{
                                                type: "Feature",
                                                properties: {},
                                                geometry: {
                                                    'type': 'Polygon',
                                                    'coordinates':
                                                        geoJson.geometries.flatMap((x: { coordinates: any; }) => x.coordinates).flatMap((x: any) => x)

                                                }
                                            }
                          }>
                                                <Layer
                                                    id="polygonLayer"
                                                    type="fill"
                                                    source="region"
                                                    layout={{}}
                                                    paint={{
                                                        'fill-outline-color': '#000000',
                                                        'fill-color': '#ffffff',
                                                        'fill-opacity': 0.34
                                                    }}
                                                />
                                                <Layer
                                                    id="polygonLayers"
                                                    type="background"
                                                    source="back"
                                                    layout={{}}
                                                    paint={{
                                                        'background-color': '#657fd2',
                                                        'background-opacity': 0.05,
                                                    }}

                                                />

                                            </Source>
                        :
                        <></>
                    }


                    {
                        vehiclePage && vehiclePage.content && vehiclePage.content.length > 0
                            ? vehiclePage.content.map((vehicle, index) =>
                                <Marker
                                    longitude={vehicle.longitude}
                                    latitude={vehicle.latitude}
                                    anchor="bottom"
                                    onClick={() => {
                                        setActiveMarker(vehicle)
                                    }}
                                >
                                    <img
                                        src={activeMarker && vehicle.id === activeMarker.id ? "marker_selected.png" : "marker.png"}
                                        alt=""/>

                                </Marker>
                            )
                            : ""
                    }

                </Map>
                <div className={styles.infoWrapper}>
                    <div className={styles.info}>
                        <div className={styles.infoContent}>
                            <div className={styles.number}>{
                                getAvailableVehicles(vehiclePage)?.length
                            }
                            </div>
                            <div>
                                Available vehicles
                            </div>
                        </div>

                    </div>
                    <div className={styles.info}>
                        <div className={styles.infoContent}>
                            <div className={styles.number}>{getRentedVehicles(vehiclePage)?.length}</div>
                            <div>
                                Active rentals
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

const getAvailableVehicles = (vehicles: PageResponse<VehicleShortResponse> | undefined): Array<VehicleShortResponse> | undefined => {
    if (!vehicles || !vehicles.content || vehicles.content.length === 0) {
        return [];
    }
    return vehicles.content.filter((vehicle, index) => vehicle.status.toLowerCase() === "available");
}

const getRentedVehicles = (vehicles: PageResponse<VehicleShortResponse> | undefined): Array<VehicleShortResponse> | undefined => {
    if (!vehicles || !vehicles.content || vehicles.content.length === 0) {
        return [];
    }
    return vehicles.content.filter((vehicle, index) => vehicle.status.toLowerCase() === "rented");
}

const createStateOfChargeStyle = (value: number | undefined, styles: any): React.ReactNode => {
    if (!value) {
        return <span className={styles.tag + " " + styles.redTag}>NO INFO</span>
    }
    if (value >= 59 && value <= 100) {
        return <span className={styles.tag + " " + styles.greenTag}>{value}%</span>
    }
    if (value >= 20 && value < 59) {
        return <span className={styles.tag + " " + styles.yellowTag}>{value}%</span>
    }
    if (value >= 0 && value <= 20) {
        return <span className={styles.tag + " " + styles.redTag}>{value}%</span>
    }
    return <span className={styles.tag + " " + styles.redTag}>{value}</span>
}