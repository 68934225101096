import React from 'react';
import {BrowserRouter, Routes} from "react-router-dom";
import {Route} from "react-router";
import './App.css';
import {SignUp} from "./component/SignUp/SignUp";
import {createUseStyles, ThemeProvider} from 'react-jss';
import SecuredRoute from "./component/SecuredRoute/SecuredRoute";
import {Vehicles} from "./component/Vehicles/Vehicles";
import Header from "./component/Header/Header";
import {EditVehicle} from "./component/Vehicles/EditVehicle/EditVehicle";
import {Vehicle} from "./component/Vehicles/Vehicle/Vehicle";
import {NewVehicle} from "./component/Vehicles/NewVehicle/NewVehicle";
import {Users} from "./component/Users/Users";
import {UserEdit} from "./component/Users/UserEdit/UserEdit";
import {User} from "./component/Users/User/User";
import Trips from "./component/Trips/Trips";
import {Trip} from "./component/Trips/Trip/Trip";
import VehicleModels from "./component/VehicleModels/VehicleModels";
import VehicleModel from "./component/VehicleModels/VehicleModel/VehicleModel";
import {EditVehicleModel} from "./component/VehicleModels/EditVehicleModel/EditVehicleModel";
import {NewVehicleModel} from "./component/VehicleModels/NewVehicleModel/NewVehicleModel";
import {Tariffs} from "./component/Tariffs/Tariffs";
import {EditTariff} from "./component/Tariffs/EditTariff/EditTariff";
import {Tariff} from "./component/Tariffs/Tariff/Tariff";
import {Storages} from "./component/Storages/Storages";
import {EditStorage} from "./component/Storages/EditStorage/EditStorage";
import {Storage} from "./component/Storages/Storage/Storage";
import {Dashboard} from "./component/dashboard/Dashboard";
import Alarms from "./component/Alarms/Alarms";
import {Tasks} from "./component/Tasks/Tasks";
import {EditTask} from "./component/Tasks/EditTask/EditTask";
import {Task} from "./component/Tasks/Task/Task";
import {Alarm} from "./component/Alarms/Alarm/Alarm";
import {EditOffer} from "./component/Offers/EditOffer/EditOffer";
import Offers from "./component/Offers/Offers";
import {Offer} from "./component/Offers/Offer/Offer";
import PromoCodes from "./component/PromoCodes/PromoCodes";
import {PromoCode} from "./component/PromoCodes/PromoCode/PromoCode";
import {EditPromoCode} from "./component/PromoCodes/EditPromoCode/EditPromoCode";
import {AdminRole, OperatorRead, SuperVisorAll, SuperVisorRead} from "./util/common";
import {Admins} from "./component/Admins/Admins";
import {Admin} from "./component/Admins/Admin/Admin";
import {EditAdmin} from "./component/Admins/EditAdmin/EditAdmin";
import {Analytics} from "./component/Analytics/Analytics";
import StickyHeader from "./component/common/hooks/StickyHeader";
import {DeleteUser} from "./component/DeleteUser/DeleteUser";

const useStyles = createUseStyles({
    "@global": {
        html: {
            height: "100%",
            backgroundColor: "#FAF6F4",
        },
        body: {
            overflowX: "scroll",
            fontSize: "16px",
        },
        input: {
            fontSize: "inherit"
        },
        textarea: {
            fontSize: "inherit"
        }
    }
});

function App() {
    useStyles();
    const { tableRef, isSticky } = StickyHeader();
    return (
        <>

            <div className="App" ref={tableRef}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/login" element={<SignUp/>}/>

                        <Route path='/vehicle-models'
                               element={<SecuredRoute authorities={["ADMIN", "OPERATOR", "SUPERVISOR"]}/>}>
                            <Route path='/vehicle-models' element={<><Header isSticky={isSticky}/>
                                <VehicleModels roles={[AdminRole, SuperVisorAll, OperatorRead]}/>
                            </>}/>
                        </Route>
                        <Route path='/vehicle-models/:id'
                               element={<SecuredRoute authorities={["ADMIN", "OPERATOR", "SUPERVISOR"]}/>}>
                            <Route path='/vehicle-models/:id' element={<><Header isSticky={isSticky}/><VehicleModel
                                roles={[AdminRole, SuperVisorAll, OperatorRead]}/></>}/>
                        </Route>
                        <Route path='/vehicle-models/edit/new'
                               element={<SecuredRoute authorities={["ADMIN", "SUPERVISOR"]}/>}>
                            <Route path='/vehicle-models/edit/new' element={<><Header isSticky={isSticky}/><NewVehicleModel/></>}/>
                        </Route>
                        <Route path='/vehicle-models/edit/:id'
                               element={<SecuredRoute authorities={["ADMIN", "SUPERVISOR"]}/>}>
                            <Route path='/vehicle-models/edit/:id' element={<><Header isSticky={isSticky}/><EditVehicleModel/></>}/>
                        </Route>

                        <Route path='/vehicle/edit/new' element={<SecuredRoute authorities={["ADMIN", "SUPERVISOR"]}/>}>
                            <Route path='/vehicle/edit/new' element={<><Header isSticky={isSticky}/><NewVehicle/></>}/>
                        </Route>
                        <Route path='/vehicle/edit/:id' element={<SecuredRoute authorities={["ADMIN", "SUPERVISOR"]}/>}>
                            <Route path='/vehicle/edit/:id' element={<><Header isSticky={isSticky}/><EditVehicle/></>}/>
                        </Route>
                        <Route path='/vehicle/:id'
                               element={<SecuredRoute authorities={["ADMIN", "OPERATOR", "SUPERVISOR"]}/>}>
                            <Route path='/vehicle/:id'
                                   element={<><Header isSticky={isSticky}/><Vehicle roles={[AdminRole, SuperVisorAll, OperatorRead]}/></>}/>
                        </Route>

                        <Route path='/vehicles/:status'
                               element={<SecuredRoute authorities={["ADMIN", "OPERATOR", "SUPERVISOR"]}/>}>
                            <Route path='/vehicles/:status' element={<><Header isSticky={isSticky}/><Vehicles
                                roles={[AdminRole, SuperVisorAll, OperatorRead]}/></>}/>
                        </Route>
                        <Route path='/vehicles'
                               element={<SecuredRoute authorities={["ADMIN", "OPERATOR", "SUPERVISOR"]}/>}>
                            <Route path='/vehicles' element={<><Header isSticky={isSticky}/><Vehicles
                                roles={[AdminRole, SuperVisorAll, OperatorRead]}/></>}/>
                        </Route>

                        <Route path='/user/:id' element={<SecuredRoute authorities={["ADMIN", "SUPERVISOR"]}/>}>
                            <Route path='/user/:id' element={<><Header isSticky={isSticky}/><User/></>}/>
                        </Route>
                        <Route path='/user/edit/:id' element={<SecuredRoute authorities={["ADMIN", "SUPERVISOR"]}/>}>
                            <Route path='/user/edit/:id' element={<><Header isSticky={isSticky}/><UserEdit/></>}/>
                        </Route>

                        <Route path='/users' element={<SecuredRoute authorities={["ADMIN", "SUPERVISOR"]}/>}>
                            <Route path='/users' element={<><Header isSticky={isSticky}/><Users/></>}/>
                        </Route>

                        <Route path='/trips/:status' element={<SecuredRoute authorities={["ADMIN", "SUPERVISOR"]}/>}>
                            <Route path='/trips/:status' element={<><Header isSticky={isSticky}/><Trips/></>}/>
                        </Route>
                        <Route path='/trips' element={<SecuredRoute authorities={["ADMIN", "SUPERVISOR"]}/>}>
                            <Route path='/trips' element={<><Header isSticky={isSticky}/><Trips/></>}/>
                        </Route>
                        <Route path='/trip/:id' element={<SecuredRoute authorities={["ADMIN", "SUPERVISOR"]}/>}>
                            <Route path='/trip/:id' element={<><Header isSticky={isSticky}/><Trip roles={[AdminRole, SuperVisorAll]}/></>}/>
                        </Route>

                        <Route path='/tariffs/:status' element={<SecuredRoute authorities={["ADMIN", "SUPERVISOR"]}/>}>
                            <Route path='/tariffs/:status'
                                   element={<><Header isSticky={isSticky}/><Tariffs roles={[AdminRole, SuperVisorAll, OperatorRead]}/></>}/>
                        </Route>
                        <Route path='/tariff/edit/:id' element={<SecuredRoute authorities={["ADMIN", "SUPERVISOR"]}/>}>
                            <Route path='/tariff/edit/:id'
                                   element={<><Header isSticky={isSticky}/><EditTariff roles={[AdminRole, SuperVisorRead]}/></>}/>
                        </Route>
                        <Route path='/tariff/:id' element={<SecuredRoute authorities={["ADMIN", "SUPERVISOR"]}/>}>
                            <Route path='/tariff/:id'
                                   element={<><Header isSticky={isSticky}/><Tariff roles={[AdminRole, SuperVisorRead]}/></>}/>
                        </Route>

                        <Route path='/storages'
                               element={<SecuredRoute authorities={["ADMIN", "OPERATOR", "SUPERVISOR"]}/>}>
                            <Route path='/storages' element={<><Header isSticky={isSticky}/><Storages
                                roles={[AdminRole, SuperVisorAll, OperatorRead]}/></>}/>
                        </Route>
                        <Route path='/storage/edit/:id' element={<SecuredRoute authorities={["ADMIN"]}/>}>
                            <Route path='/storage/edit/:id' element={<><Header isSticky={isSticky}/><EditStorage/></>}/>
                        </Route>
                        <Route path='/storage/:id'
                               element={<SecuredRoute authorities={["ADMIN", "OPERATOR", "SUPERVISOR"]}/>}>
                            <Route path='/storage/:id'
                                   element={<><Header isSticky={isSticky}/><Storage roles={[AdminRole, SuperVisorAll, OperatorRead]}/></>}/>
                        </Route>

                        <Route path='/dashboard'
                               element={<SecuredRoute authorities={["ADMIN", "OPERATOR", "SUPERVISOR"]}/>}>
                            <Route path='/dashboard' element={<><Header isSticky={isSticky}/><Dashboard/></>}/>
                        </Route>

                        <Route path='/alarms'
                               element={<SecuredRoute authorities={["ADMIN", "OPERATOR", "SUPERVISOR"]}/>}>
                            <Route path='/alarms'
                                   element={<><Header isSticky={isSticky}/><Alarms roles={[AdminRole, SuperVisorAll, OperatorRead]}/></>}/>
                        </Route>
                        <Route path='/alarm/:id'
                               element={<SecuredRoute authorities={["ADMIN", "OPERATOR", "SUPERVISOR"]}/>}>
                            <Route path='/alarm/:id'
                                   element={<><Header isSticky={isSticky}/><Alarm roles={[AdminRole, SuperVisorAll, OperatorRead]}/></>}/>
                        </Route>


                        <Route path='/tasks'
                               element={<SecuredRoute authorities={["ADMIN", "OPERATOR", "SUPERVISOR"]}/>}>
                            <Route path='/tasks'
                                   element={<><Header isSticky={isSticky}/><Tasks roles={[AdminRole, SuperVisorAll, OperatorRead]}/></>}/>
                        </Route>
                        <Route path='/task/edit/:id' element={<SecuredRoute authorities={["ADMIN", "SUPERVISOR"]}/>}>
                            <Route path='/task/edit/:id' element={<><Header isSticky={isSticky}/><EditTask/></>}/>
                        </Route>
                        <Route path='/task/edit/:id/:type/:subjectId'
                               element={<SecuredRoute authorities={["ADMIN", "SUPERVISOR"]}/>}>
                            <Route path='/task/edit/:id/:type/:subjectId' element={<><Header isSticky={isSticky}/><EditTask/></>}/>
                        </Route>
                        <Route path='/task/:id'
                               element={<SecuredRoute authorities={["ADMIN", "OPERATOR", "SUPERVISOR"]}/>}>
                            <Route path='/task/:id'
                                   element={<><Header isSticky={isSticky}/><Task roles={[AdminRole, SuperVisorAll, OperatorRead]}/></>}/>
                        </Route>

                        <Route path='/offers' element={<SecuredRoute authorities={["ADMIN", "SUPERVISOR"]}/>}>
                            <Route path='/offers' element={<><Header isSticky={isSticky}/><Offers/></>}/>
                        </Route>
                        <Route path='/offer/:id' element={<SecuredRoute authorities={["ADMIN", "SUPERVISOR"]}/>}>
                            <Route path='/offer/:id'
                                   element={<><Header isSticky={isSticky}/><Offer roles={[AdminRole, SuperVisorRead]}/></>}/>
                        </Route>
                        <Route path='/offer/edit/:id' element={<SecuredRoute authorities={["ADMIN", "SUPERVISOR"]}/>}>
                            <Route path='/offer/edit/:id'
                                   element={<><Header isSticky={isSticky}/><EditOffer roles={[AdminRole, SuperVisorRead]}/></>}/>
                        </Route>

                        <Route path='/promo-codes' element={<SecuredRoute authorities={["ADMIN", "SUPERVISOR"]}/>}>
                            <Route path='/promo-codes' element={<><Header isSticky={isSticky}/><PromoCodes/></>}/>
                        </Route>
                        <Route path='/promo-code/:id' element={<SecuredRoute authorities={["ADMIN", "SUPERVISOR"]}/>}>
                            <Route path='/promo-code/:id'
                                   element={<><Header isSticky={isSticky}/><PromoCode roles={[AdminRole, SuperVisorRead]}/></>}/>
                        </Route>
                        <Route path='/promo-code/edit/:id'
                               element={<SecuredRoute authorities={["ADMIN", "SUPERVISOR"]}/>}>
                            <Route path='/promo-code/edit/:id'
                                   element={<><Header isSticky={isSticky}/><EditPromoCode roles={[AdminRole, SuperVisorRead]}/></>}/>
                        </Route>

                        <Route path='/admins' element={<SecuredRoute authorities={["ADMIN", "SUPERVISOR"]}/>}>
                            <Route path='/admins' element={<><Header isSticky={isSticky}/><Admins roles={[AdminRole, SuperVisorRead]}/></>}/>
                        </Route>
                        <Route path='/admin/:id' element={<SecuredRoute authorities={["ADMIN"]}/>}>
                            <Route path='/admin/:id'
                                   element={<><Header isSticky={isSticky}/><Admin roles={[AdminRole]}/></>}/>
                        </Route>
                        <Route path='/admin/edit/:id'
                               element={<SecuredRoute authorities={["ADMIN"]}/>}>
                            <Route path='/admin/edit/:id'
                                   element={<><Header isSticky={isSticky}/><EditAdmin/></>}/>
                        </Route>


                        <Route path='/analytics/:type' element={<SecuredRoute authorities={["ADMIN"]}/>}>
                            <Route path='/analytics/:type' element={<><Header isSticky={isSticky}/><Analytics roles={[AdminRole, SuperVisorRead]}/></>}/>
                        </Route>

                        <Route path='/delete-user-request'
                               element={<SecuredRoute authorities={["ADMIN"]}/>}>
                            <Route path='/delete-user-request' element={<><Header isSticky={isSticky}/>
                                <DeleteUser/>
                            </>}/>
                        </Route>

                        <Route path='/' element={<SecuredRoute authorities={["ADMIN"]}/>}>
                            <Route path='/' element={<><Header isSticky={isSticky}/></>}/>
                        </Route>

                        <Route path='/*' element={<SecuredRoute authorities={["ADMIN"]}/>}>
                            <Route path='/*' element={<>not found</>}/>
                        </Route>
                    </Routes>
                </BrowserRouter>

            </div>
        </>
    );
}

export default App;
