import {createUseStyles} from "react-jss";

const adminsStyles = createUseStyles({
    noDataBlock: {
        paddingTop: "10px",
        paddingBottom: "10px",
    },
    headerRow: {
        borderBottomStyle: "solid",
        borderBottomWidth: "2px",
        borderBottomColor: "#EDF0F1"
    },
    contentHeader: {
        color: "rgb(96 105 118)",
        '& h2': {
            margin: "18px",
            fontWeight: "600",
        },
    },
    dataRow: {
        borderBottom: "2px solid #EEF0F1",
        boxSizing: "border-box",
    },
    tag: {
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
        backgroundColor: "rgb(253 253 253)",
        borderRadius: "9999px",
        borderWidth: "1px",
        whiteSpace: "nowrap",
        borderStyle: "solid",
        fontWeight: "600",
        textTransform: "uppercase",
        letterSpacing: "0.025em",
    },
    red: {
        color: "rgb(178 6 6 )",
        borderColor: "rgb(178 6 6 )",
    },
    green: {
        color: "rgb(61 161 38)",
        borderColor: "rgb(61 161 38)",
    },
    sortField: {
        position: "relative",
        textDecoration: "none",
        display: "block",
        padding: "10px",
        paddingRight: "30px",
        margin: "10px",
    },
    sortAscActive: {
        borderBottomColor: "#0047F4",
        border: "5px solid transparent",
        display: "block",
        height: "0",
        right: "10px",
        top: "50%",
        position: "absolute",
        width: "0",
        cursor: "pointer",
        marginTop: "-12px"
    },
    sortDescActive: {
        borderTopColor: "#0047F4",
        border: "5px solid transparent",
        display: "block",
        height: "0",
        right: "10px",
        top: "50%",
        position: "absolute",
        width: "0",
        cursor: "pointer",
        marginTop: "2px"
    },
    sortAsc: {
        borderBottomColor: "#a6a7a9",
        border: "5px solid transparent",
        display: "block",
        height: "0",
        right: "10px",
        top: "50%",
        position: "absolute",
        width: "0",
        cursor: "pointer",
        marginTop: "-12px"
    },
    sortDesc: {
        borderTopColor: "#a6a7a9",
        border: "5px solid transparent",
        display: "block",
        height: "0",
        right: "10px",
        top: "50%",
        position: "absolute",
        width: "0",
        cursor: "pointer",
        marginTop: "2px"
    },
    dataTable: {
        borderCollapse: "collapse",
        width: "100%",
        fontSize: "14px",
        '& tr:nth-child(even)': {
            backgroundColor: "rgb(199 203 209 / 0.1)",
        },
        '& tr': {
            borderTop: "1px solid rgb(96 105 118 / 0.2)",
            '& th': {
                color: "rgb(96 105 118)",
                padding: '10px',
                '& a': {
                    color: "rgb(96 105 118)",
                    textDecoration: "none",
                }
            }
        }
    },
    icon: {
        minWidth: "15px",
        maxWidth: "15px",
    },
    filtersContainer: {
        display: "flex",
        justifyContent: "flex-start",
    },
    iconContainer: {
        display: "flex",
        justifyContent: "center",
        "& a": {
            boxSizing: "border-box",
            padding: "5px",
            marginRight: "3px",
            border: "1px solid #000000",
            borderRadius: "5px",
            opacity: "0.8"
        },
        "& a:hover": {
            borderColor: "rgb(251, 143,135)",
        }
    },
    '@media (max-width:1000px)': {
        iconContainer: {
            display: "flex",
            justifyContent: "space-between",
        }
    }
});

export default adminsStyles;