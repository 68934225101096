import {useParams} from "react-router-dom";
import * as React from "react";
import {useEffect, useState} from "react";
import {Message} from "../../common/Message/Message";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import {ActionButton} from "../../common/ActionButton/ActionButton";
import {UserField} from "../../common/UserField/UserField";
import {formatDate, formatDurationFromSeconds} from "../../../util/dateUtils";
import {offerStyles} from "./OfferStyles";
import {getOffer, OfferRs} from "../../../api/OfferApi";
import {UserOfferPage} from "./UserOfferPage/UserOfferPage";
import {getUserFromLocalStorage, isAccessRoles, Role} from "../../../util/common";

export type OfferProperties = {
    roles: Array<Role>
}
export function Offer(props: OfferProperties) {
    const {roles} = props;
    const styles = offerStyles();
    const {id} = useParams();
    const [offer, setOffer] = useState<OfferRs>();
    const [message, setMessage] = useState<string>("");
    const [isMessageError, setIsMessageError] = useState<boolean>(false);

    const user = getUserFromLocalStorage();

    const writeAccess = isAccessRoles(user.roles, roles, "WRITE");

    useEffect(() => {
        getOffer(id || "")
            .then((result: OfferRs) => {
                setOffer(result);
            })

    }, []);
    return (
        <>
            <Message message={message} isError={isMessageError}/>
            <MainContainer>
                {
                    offer
                        ? <>
                            <TitleWrapper title={"Offer " + offer.name}>
                                <ActionButton title={"Edit"} isLink={true} url={"/offer/edit/" + id}/>
                                {writeAccess && <ActionButton title={"Approve"} isLink={false}
                                              onClickHandle={() => {console.log("APPROVED")}}/>}

                            </TitleWrapper>
                            <div className={styles.contentWrapper}>
                                <div className={styles.leftColumn}>
                                    <DefaultPageContentContainer title={"Details"}>
                                        <div className={styles.fieldsWrapper}>
                                            <UserField title={"Status:"} field={createStatusStyle(styles, offer)}/>
                                            <UserField title={"Name:"} field={offer.name}/>
                                            <UserField title={"Duration (days): "} field={offer.daysDuration}/>
                                            <UserField title={"Trip limit per day:"} field={offer.tripLimitPerDay}/>
                                            <UserField title={"Time limit per day:"} field={formatDurationFromSeconds(offer.timeLimitPerDay) + " hours"}/>
                                            <UserField title={"Pause limit per day:"} field={formatDurationFromSeconds(offer.pauseLimitPerDay) + " hours"}/>
                                            <UserField title={"Validity:"}
                                                       field={formatDate(offer.startDate) + " - " + formatDate(offer.endDate)}/>
                                            <UserField title={"Price:"} field={"€ " + offer.price.toFixed(2)}/>
                                        </div>


                                    </DefaultPageContentContainer>
                                </div>
                                <div className={styles.rightColumn}>
                                    <UserOfferPage id={id || ""}/>
                                </div>
                            </div>
                        </>
                        : <img src={"/loading.gif"} alt={""}/>
                }

            </MainContainer>
        </>
    )
}
const createStatusStyle = (styles: any, offer: OfferRs): React.ReactNode => {
    if (!offer.active) {
        return <span className={styles.tag}>Unactive</span>
    }
    const isActiveOnToday = new Date(offer.endDate) > new Date();
    if (isActiveOnToday) {
        return <span className={styles.tag + " " + styles.greenTag}>Active</span>
    }
    if (!isActiveOnToday) {
        return <span className={styles.tag + " " + styles.yellowTag}>Expired</span>
    }

    return <span className={styles.tag + " " + styles.redTag}>Wrong status</span>
}