import * as React from 'react'
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import storageFormStyles from "./StorageFormStyles";

const validateSchema = Yup.object().shape({
    name: Yup.string().required(),
    longitude: Yup.string().required(),
    latitude: Yup.string().required(),
});

export function StorageForm(props: any) {
    const {onSubmitHandle, formDefaultValues, id} = props;
    const styles = storageFormStyles();
    const navigate = useNavigate();

    return (
        <Formik
            initialValues={formDefaultValues}
            validationSchema={validateSchema}
            onSubmit={(values => onSubmitHandle(values, navigate, id))}>
            {(props) => {
                const {errors, touched, isValid} = props;
                return (<>
                        <Form>
                            <div className={styles.inputContainers}>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="name">Name</label>
                                    <Field id="name"
                                           name="name" placeholder={""}
                                           className={errors.name && touched.name ? styles.inputFieldError : styles.inputField}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="longitude">Longitude</label>
                                    <Field id="longitude"
                                           name="longitude" placeholder={""}
                                           className={errors.longitude && touched.longitude ? styles.inputFieldError : styles.inputField}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="latitude">Latitude</label>
                                    <Field id="latitude"
                                           name="latitude" placeholder={""}
                                           className={errors.latitude && touched.latitude ? styles.inputFieldError : styles.inputField}
                                    />
                                </div>
                                <button className={styles.submitButton} type="submit">
                                    Save
                                </button>
                            </div>
                        </Form>
                    </>
                )
            }

            }
        </Formik>
    )
}