import * as React from 'react'
import {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import {ActionButton} from "../../common/ActionButton/ActionButton";
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {getActiveTrip, TripShort} from "../../../api/TripApi";
import {Message} from "../../common/Message/Message";
import {deleteAdmin, deleteUser, getAdmin, getFullName, UserAdminRs} from "../../../api/UserApi";
import {ConfirmModal} from "../../common/ConfirmModal/ConfirmModal";
import {UserField} from "../../common/UserField/UserField";
import {formatDateTime} from "../../../util/dateUtils";
import {getUserFromLocalStorage, isAccessRoles, Role} from "../../../util/common";
import adminStyles from "./AdminStyles";
import {LoadingBar} from "../../common/LoadingBar/LoadingBar";
import {Tag} from "../../common/Tags/Tag";


export type AdminProperties = {
    roles: Array<Role>
}

export function Admin(props: AdminProperties) {
    const styles = adminStyles();
    const {roles} = props;
    const navigate = useNavigate();
    const user = getUserFromLocalStorage();
    const [admin, setAdmin] = useState<UserAdminRs>();
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [deleteModalOpened, setDeleteModalOpened] = useState<boolean>(false);
    const {id} = useParams();

    const writeAccess = isAccessRoles(user.roles, roles, "WRITE");

    const deleteOnChange = () => {
        if (!id) {
            return;
        }
        deleteAdmin(id).then(() => {
            navigate("/admins");
        }).catch((err) => {
            setDeleteModalOpened(false);
            setMessage("Error occurred while deleting user");
            setTimeout(() => {
                setMessage("");
            }, 3000)
        })
    }

    const delChange = () => {
        setDeleteModalOpened(true);
    }

    useEffect(() => {
        getAdmin(id || "")
            .then((result: UserAdminRs) => {
                setAdmin(result);
                setDataLoaded(true);
            })
            .catch((e) => {
                setMessage("Error occurred while loading user. Try again later");
                setDataLoaded(true);
            });

    }, []);
    return (
        <>
            {deleteModalOpened
                ?
                <ConfirmModal setModalOpened={setDeleteModalOpened} title={"Delete current user?"}
                              handleConfirm={deleteOnChange}/>
                :
                <></>}
            <Message message={message} isError={true}/>
            {dataLoaded ?
                <>
                    {admin &&
                        <MainContainer>
                            <TitleWrapper title={"User - " + getFullName(admin)}>
                                {writeAccess && admin.id !== user.id && <ActionButton title={"Delete Account"} isLink={false}
                                                              onClickHandle={delChange}/>}
                                {writeAccess && <ActionButton title={"Edit"} isLink={true} url={"/admin/edit/" + id}/>}
                            </TitleWrapper>
                            <div className={styles.contentWrapper}>
                                <div className={styles.leftColumn}>
                                    <DefaultPageContentContainer title={"Details"}>
                                        <UserField title={"ID:"} field={admin.id}/>
                                        <UserField title={"Full name:"} field={getFullName(admin)}/>
                                        <UserField title={"Email:"} field={admin.email}/>
                                        <UserField title={"Last sign in:"} field={formatDateTime(admin?.lastSignIn)}/>
                                        <UserField title={"Roles:"} field={admin.roles.map(x => <><Tag text={x} color={"green"}/>  </>)}/>
                                    </DefaultPageContentContainer>
                                </div>
                                <div className={styles.rightColumn}>
                                </div>
                            </div>
                        </MainContainer>
                    }
                </>
                : <LoadingBar/>
            }
        </>
    )
}