import {createUseStyles} from "react-jss";
import {MAIN_COLOR} from "../../../util/properties";

const storageStyles = createUseStyles({
    contentWrapper: {
    },
    noDataBlock: {
        paddingTop: "10px",
        paddingBottom: "10px",
    },
    storageLogo: {
        maxWidth: "15px",
        paddingRight: "5px"
    },
    mapPoint: {
        color: "#717275",
    },
    dataTable: {
        borderCollapse: "collapse",
        width: "100%",
        fontSize: "14px",
        '& tr:nth-child(even)': {
            backgroundColor: "rgb(199 203 209 / 0.1)",
        },
        '& tr': {
            borderTop: "1px solid rgb(96 105 118 / 0.2)",
            '& th': {
                color: "rgb(96 105 118)",
                padding: '10px',
                '& a': {
                    color: "rgb(96 105 118)",
                    textDecoration: "none",
                }
            }
        }
    },
    releaseButton: {
        backgroundColor: MAIN_COLOR,
        display: "block",
        cursor: "pointer",
        textAlign: "center",
        textDecoration: "none",
        border: "none",
        color: "#ffffff",
        borderRadius: "5px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "15px",
        paddingRight: "15px",
        boxSizing: "border-box",
        fontWeight: "600",
        fontSize: "14px",
        marginLeft: "10px",
        marginBottom: "10px"
    },
    checkbox: {
        color: "#717275",
        paddingTop: "20px",
        paddingBottom: "20px",
        display: "block",
        fontSize: "14px",
        lineHeight: "20px"
    },
    fieldWrapper: {
    },
    fieldName: {
        textAlign: "right",
        float: "left",
        clear: "both",
        minWidth: "33%",
        fontSize: "14px",
        wordBreak: "break-word",
        fontWeight: "600",
        marginTop: "5px",
        marginBottom: "5px",
    },
    fieldValue: {
        marginTop: "5px",
        marginBottom: "5px",
        float: "left",
        paddingLeft: "10px",
        fontSize: "14px",
        wordBreak: "break-word",
        fontWeight: "600",
        "& a": {
            textDecoration: "none",
        },
        "& span": {
            fontWeight: "400"
        }
    },
    leftColumn: {
        width: "33.333%",
        paddingRight: "25px",
    },
    tag: {
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
        backgroundColor: "rgb(253 253 253)",
        borderRadius: "9999px",
        borderWidth: "1px",
        borderStyle: "solid",
        whiteSpace: "nowrap",
        fontSize: "0.75rem",
        fontWeight: "600",
        textTransform: "uppercase",
        letterSpacing: "0.025em",
    },
    headerRow: {
        borderBottomStyle: "solid",
        borderBottomWidth: "2px",
        borderBottomColor: "#EDF0F1"
    },
    contentHeader: {
        color: "rgb(96 105 118)",
        '& h2': {
            margin: "18px",
            fontWeight: "600",
        },
    },
    dataRow: {
        borderBottom: "2px solid #EEF0F1",
        boxSizing: "border-box",
    },
    yellowTag: {
        color: "rgb(204 102 0)",
        borderColor: "rgb(204 102 0)",
    },
    greenTag: {
        color: "rgb(61 161 38)",
        borderColor: "rgb(61 161 38)",
    },
    redTag: {
        color: "rgb(178 6 6 )",
        borderColor: "rgb(178 6 6 )",
    },
    rightColumn: {
        width: "66.6666%",
        paddingLeft: "25px",
    },
    '@media (max-width:768px)': {
        contentWrapper: {
            display: "block",
        },
        leftColumn: {
            width: "100%",
            paddingRight: "0",
        },
        rightColumn: {
            width: "100%",
            paddingLeft: "0",
        }
    },
});

export default storageStyles;