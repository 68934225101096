import * as React from 'react'
import {useEffect, useState} from 'react'
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import {NavigateFunction, useParams} from "react-router-dom";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {Message} from "../../common/Message/Message";
import {getLocationId, getUserFromLocalStorage, isAccessRoles, Role} from "../../../util/common";
import editPromoCodeStyles from "./EditPromoCodeStyles";
import {PromoCodeForm} from "../PromoCodeForm/PromoCodeForm";
import {createPromoCode, getPromoCode, PromoCodeRq, PromoCodeRs, updatePromoCode} from "../../../api/PromoCodeApi";
import {LoadingBar} from "../../common/LoadingBar/LoadingBar";

export type EditPromoCodeProperties = {
    roles: Array<Role>
}

export function EditPromoCode(props: EditPromoCodeProperties) {
    const styles = editPromoCodeStyles();
    const {roles} = props;
    const user = getUserFromLocalStorage();
    const {id} = useParams();
    const isNewOffer = id === "new";
    const locationId = getLocationId();
    const [message, setMessage] = useState<string>("");
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [initValues, setInitValues] = useState<PromoCodeRq>({});

    const writeAccess = isAccessRoles(user.roles, roles, "WRITE");

    const onSubmitHandle = (values: PromoCodeRq, navigate: NavigateFunction, id?: string) => {

        const photo = values.photo;
        const data = Object.assign({}, values);
        delete data.photo;


        let formdata = new FormData();
        formdata.append("data", new Blob([JSON.stringify(data)], {
            type: 'application/json'
        }));
        if (photo) {
            formdata.append("photo", photo);
        }
        const saveOffer = isNewOffer ? createPromoCode(formdata) : updatePromoCode(formdata, id || "")
        saveOffer
            .then((result: any) => {
                navigate("/promo-code/" + (isNewOffer ? result.id : id));
            })
            .catch((result: any) => {
                setMessage("Error occurred while saving promo code");
                setTimeout(() => {
                    setMessage("")
                }, 3000)
            });
    }

    useEffect(() => {
        if (!isNewOffer) {

            getPromoCode(id).then(async (response: PromoCodeRs) => {
                const base64Response = await fetch(`data:image/jpeg;base64,${response.photo}`);
                const blob = await base64Response.blob();

                setInitValues({
                    code: response.code,
                    description: response.description,
                    discount: response.discount,
                    photo: response.photo && response.photoName ? new File([blob], response.photoName, {type: "image/jpeg"}) : null,
                    userLimit: response.userLimit,
                    //approved: response.approved,
                    startDate: response.startDate,
                    endDate: response.endDate,
                })
                setIsLoaded(true);
            })
        } else {
            setIsLoaded(true);
        }

    }, [id, isNewOffer]);
    return (
        <>
            <Message message={message} isError={true}/>
            <MainContainer>
                <TitleWrapper title={(isNewOffer ? "New" : "Edit") + " promo code"}>
                </TitleWrapper>
                <div className={styles.mainWrapper}>
                    <div className={styles.contentWrapper}>
                        <DefaultPageContentContainer title={"Details"}>
                            {
                                isLoaded
                                    ? <PromoCodeForm onSubmitHandle={onSubmitHandle}
                                                     formDefaultValues={initValues} id={id}/>
                                    : <LoadingBar/>
                            }
                        </DefaultPageContentContainer>
                    </div>
                </div>

            </MainContainer>
        </>
    )
}

