import {HOST} from "../util/properties";
import {createDefaultHeaders, fetchWithResponse, getAccessTokenFromLocalStorage} from "../util/common";

export type VehicleModelResponse = {
    id: string,
    name: string;
}

export type VehicleModelUpdateRequest = {
    vehicleModelId: string,
    name: string;
}

export type VehicleModelRequest = {
    name: string;
}

export const getVehicleModel = (id?: string): Promise<VehicleModelResponse> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/vehicle-models/" + id, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const createVehicleModel = (values: VehicleModelRequest): Promise<VehicleModelResponse> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/vehicle-models/", {
        method: 'POST',
        body: JSON.stringify(values),
        headers: createDefaultHeaders(userToken)
    })
};

export const updateVehicleModel = (values: VehicleModelUpdateRequest): Promise<VehicleModelResponse> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/vehicle-models/", {
        method: 'PATCH',
        body: JSON.stringify(values),
        headers: createDefaultHeaders(userToken)
    })
};


export const getVehicleModels = (): Promise<Array<VehicleModelResponse>> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/vehicle-models", {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};
