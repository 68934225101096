import {createUseStyles} from "react-jss";
import {MAIN_COLOR} from "../../../../util/properties";

export const commentsStyles = createUseStyles({
    formContainer: {
        paddingBottom: "15px",
        paddingLeft: "15px",
        paddingRight: "15px",
    },
    iconsContainer: {
        marginRight: "15px",
    },
    comment: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid",
        borderRadius: "5px",
        borderColor: "#d0d0d0",
        marginBottom: "15px"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-start"
    },
    commentTextContent: {
        marginLeft: "15px"
    },
    commentContent: {
        display: "flex",
        alignItems: "flex-start",
        padding: "15px",
    },
    commentAboutInfo: {
        color: "#676767"
    },
    commentText: {
        paddingTop: "5px",
        textAlign: "left"
    },
    commentContainer: {
        padding: "15px"
    },
    inputsContainer: {
        marginBottom: "15px",
        boxSizing: "border-box",
        border: "1px solid",
        borderRadius: "5px",
        borderColor: "#94A0BDFF"
    },
    focused: {
        borderColor: MAIN_COLOR
    },
    newCommentInput: {
        boxSizing: "border-box",
        width: "100%",
        padding: "15px",
        resize: "none",
        border: 0,
        overflow: "auto",
        outline: "none",
    },
    editCommentInput: {
        boxSizing: "border-box",
        width: "100%",
        paddingTop: "15px",
        resize: "none",
        border: 0,
        overflow: "auto",
        outline: "none",
    }
});