import * as React from "react";
import {tagStyles} from "./TagStyles";

export type TagProperties = {
    text: string,
    color: "green" | "yellow" | "red" | "grey"
}

export const Tag = (props: TagProperties) => {
    const {text, color} = props;
    const styles = tagStyles();

    return (
        <>
            <span className={getStyle(color, styles)}>{text}</span>
        </>
    )
}

const getStyle = (color: string, styles: any) => {
    if (color === "green") {
        return styles.tag + " " + styles.greenTag;
    }
    if (color === "yellow") {
        return styles.tag + " " + styles.yellowTag;
    }
    if (color === "red") {
        return styles.tag + " " + styles.redTag;
    }
    return styles.tag;
}