import {HOST} from "../util/properties";
import {
    createDefaultHeaders,
    createUrlParameters,
    fetchNoResponse,
    fetchWithResponse,
    getAccessTokenFromLocalStorage,
    getLocationId,
    PageResponse
} from "../util/common";

export type UpdateVehicleRequest = {
    id?: string,
    vehicleModelId: string
    externalId: string,
    telematicId: string,
    vehicleId: string,
    qrCodeId: string,
    insuranceExpired: string,
    status?: string
    locationId: string
}

export type UpdateVehicleStorageRequest = {
    storageId?: string,
    status: string
}

export type VehicleShortResponse = {
    charging: boolean
    externalId: string
    id: string
    lastRentalAt: Date
    latitude: number
    longitude: number
    mileage: number
    recordedAt: Date
    stateOfCharge: number
    status: string
    insuranceId: string,
    qrCode: string
}
export type VehicleModel = {
    id: string,
    name: string,
}

export type VehicleResponse = {
    id: string,
    externalId: string,
    qrCode: string,
    insuranceId: string,
    insuranceExpired?: string,
    createdAt: string,
    model: VehicleModel,
    status: string,
    data?: VehicleData,
    storage?: VehicleStorageResponse
}

export type VehicleStorageResponse = {
    id: string,
    name: string
}

export type VehicleData = {
    online: boolean,
    locked: boolean,
    mileage: number,
    speed: number,
    longitude: number,
    latitude: number,
    battery: BatteryData
}

export type BatteryData = {
    charging: boolean,
    stateOfCharge: number,
    range: number,
}

export enum VehicleStatus {
    AVAILABLE = "AVAILABLE",
    RENTED = "RENTED",
    IN_MAINTENANCE = "IN_MAINTENANCE"
}

export const createVehicle = (values: UpdateVehicleRequest): Promise<PageResponse<VehicleShortResponse>> => {
    const userToken = getAccessTokenFromLocalStorage();
    values.locationId = getLocationId();

    return fetchWithResponse(HOST + "/api/vehicles", {
        method: 'POST',
        body: JSON.stringify(values),
        headers: createDefaultHeaders(userToken)
    });
};

export const updateVehicle = (values: UpdateVehicleRequest, id?: string): Promise<PageResponse<VehicleShortResponse>> => {
    const userToken = getAccessTokenFromLocalStorage();
    values.locationId = getLocationId();

    return fetchWithResponse(HOST + "/api/vehicles/" + id, {
        method: 'PUT',
        body: JSON.stringify(values),
        headers: createDefaultHeaders(userToken)
    })
};

export const updateVehicleStorage = (values: UpdateVehicleStorageRequest, id?: string): Promise<VehicleResponse> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/vehicles/" + id + "/storage", {
        method: 'PATCH',
        body: JSON.stringify(values),
        headers: createDefaultHeaders(userToken)
    })
};

export type GetVehiclesProps = {
    page?: number,
    sort?: string,
    size?: number,
    qrCode?: string,
    insuranceId?: string
}

export const getVehicles = (props: GetVehiclesProps): Promise<PageResponse<VehicleShortResponse>> => {
    const {page, sort, size, insuranceId, qrCode} = props;

    const userToken = getAccessTokenFromLocalStorage();
    const paramString = createUrlParameters({
        "page": page ? page : 0,
        "sort": sort,
        "locationId": getLocationId(),
        "size": size ? size : 20,
        "insuranceId": insuranceId,
        "qrCode": qrCode
    });

    return fetchWithResponse(HOST + "/api/vehicles/admin" + paramString, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const getVehicle = (id?: string): Promise<VehicleResponse> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/vehicles/" + id, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const deleteVehicle = (id?: string): Promise<PageResponse<VehicleShortResponse>> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchNoResponse(HOST + "/api/vehicles/" + id, {
        method: 'DELETE',
        headers: createDefaultHeaders(userToken)
    })
};

export const lockVehicle = (lock: boolean, id?: string) => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/vehicles/" + id + "/lock?lock=" + lock, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + userToken,
            'Content-Type': 'application/json'
        },
    })
};

export const releaseVehicles = (vehicleIds: Array<string>) => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/vehicles/release", {
        method: 'POST',
        body: JSON.stringify(vehicleIds),
        headers: createDefaultHeaders(userToken)
    })
};