import * as React from 'react'
import titleWrapperStyles from "./TitleWrapperStyles";

export interface TitleProperties {
    title: string,
    children?: React.ReactNode | React.ReactNode[]
}

export function TitleWrapper(props: TitleProperties) {
    const styles = titleWrapperStyles();

    return (
        <>
            <div className={styles.titleBarContainer}>
                <div className={styles.titleBar}>
                    <h2>{props.title}</h2>
                </div>
                <div className={styles.actionButtonWrapper}>
                    {props.children}
                </div>
            </div>
        </>
    )
}