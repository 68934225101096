import {createUseStyles} from "react-jss";
import {MAIN_COLOR} from "../../util/properties";

const alarmsStyles = createUseStyles({
    bannerWrapper: {
        display: "flex",
        justifyContent: "space-around",
        marginTop: "50px"
    },
    banner: {
        width: "200px",
        height: "150px",
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: '0 0 10px rgba(150,154,213, 0.5)',
        borderRadius: "5px",
        cursor: "pointer",
    },
    bannerActive: {
        borderColor: "#3e6de3",
        border: "1px solid",
        color: "#3e6de3"
    },
    bannerNumber: {
        fontSize: "46px"
    },
    bannerText: {
        fontSize: "14px",
    }
});

export default alarmsStyles;