import React, {ReactElement, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {getUserFromLocalStorage, isAccessRoles, Role} from "../../../util/common";
import analyticsStyles from "../AnalyticsStyles";
import {formatMonth} from "../../../util/dateUtils";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import {LoadingBar} from "../../common/LoadingBar/LoadingBar";
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {AnalyticRs, getTotalAnalytic, MonthValRs} from "../../../api/AnalyticApi";
import {Message} from "../../common/Message/Message";
import {Line} from "react-chartjs-2";
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';

export type TotalAnalyticsProperties = {
    roles: Array<Role>
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const createOptions = (title: string) => {
    return {
        scales: {
            y: {
                ticks: {
                    stepSize: 1
                },
                beginAtZero: true,
            }
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: title,
            },
        },
    }
};
export const createData = (list: Array<MonthValRs>, title: string) => {
    return {
        labels: list.filter((profit) => profit.month).map((profit) => formatMonth(profit.month)),
        datasets: [
            {
                label: title,
                data: list.map((profit) => profit.value),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    }
};

export const TotalAnalytics = (props: TotalAnalyticsProperties): ReactElement => {
    const styles = analyticsStyles();
    const {roles} = props;
    const [analytics, setAnalytics] = useState<AnalyticRs>()
    const [isLoaded, setIsLoaded] = useState(false);
    const {type} = useParams();
    const [message, setMessage] = useState<string>("");

    const title = "Total statistic";

    const user = getUserFromLocalStorage();

    const writeAccess = isAccessRoles(user.roles, roles, "WRITE");

    useEffect(() => {
        getTotalAnalytic().then((result: AnalyticRs) => {
            setAnalytics(result);
            setIsLoaded(true);
        }).catch(() => {
            setMessage("Error occurred while loading trip analytics.");
            setTimeout(() => {
                setMessage("")
            }, 3000);
            setIsLoaded(true)
        })

    }, [type]);

    return (
        <>
            <Message message={message} isError={true}/>
            <MainContainer>
                <TitleWrapper title={title}>
                </TitleWrapper>
                {isLoaded
                    ? <>{analytics &&

                        <DefaultPageContentContainer title={"Total"}>
                            <div className={styles.cardsContainer}>

                                <div className={styles.chart}>
                                    <Line options={createOptions("Profit by months (€)")}
                                          data={createData(analytics.monthProfit, "profit")}/>
                                </div>
                                <div className={styles.chart}>
                                    <Line options={createOptions("Trips by months")}
                                          data={createData(analytics.monthTrips, "trips")}/>
                                </div>
                            </div>

                            <h2 className={styles.title}>Total profit (Last month):</h2>
                            <div className={styles.cardsContainer}>
                                {
                                    analytics.monthProfit && analytics.monthProfit.length > 0 &&
                                    <div className={styles.card}>
                                        <div>
                                            <div className={styles.cardHeader}>Profit:</div>
                                            <div
                                                className={styles.cardValue}>€{analytics.monthProfit[analytics.monthProfit.length - 1]?.value}</div>
                                        </div>
                                    </div>
                                }

                            </div>

                            <h2 className={styles.title}>Trips (Last month):</h2>
                            <div className={styles.cardsContainer}>
                                <div className={styles.card}>
                                    <div>
                                        <div className={styles.cardHeader}>Total trips:</div>
                                        <div
                                            className={styles.cardValue}>{analytics.unsuccessfulTrips + analytics.successfulTrips}</div>
                                    </div>
                                </div>
                                <div className={styles.card}>
                                    <div>
                                        <div className={styles.cardHeader}>Successful trips:</div>
                                        <div
                                            className={styles.cardValue}>{analytics.successfulTrips}</div>
                                    </div>
                                </div>
                                <div className={styles.card}>
                                    <div>
                                        <div className={styles.cardHeader}>Unsuccessful trips:</div>
                                        <div
                                            className={styles.cardValue}>{analytics.unsuccessfulTrips}</div>
                                    </div>
                                </div>
                            </div>

                            <h2 className={styles.title}>Users (Last month):</h2>
                            <div className={styles.cardsContainer}>
                                <div className={styles.card}>
                                    <div>
                                        <div className={styles.cardHeader}>Active users:</div>
                                        <div
                                            className={styles.cardValue}>{analytics.activeUsersNumber}</div>
                                    </div>
                                </div>
                                <div className={styles.card}>
                                    <div>
                                        <div className={styles.cardHeader}>New users:</div>
                                        <div
                                            className={styles.cardValue}>{analytics.newUsersNumber}</div>
                                    </div>
                                </div>
                            </div>

                            <h2 className={styles.title}>Alarms (Last month):</h2>
                            <div className={styles.cardsContainer}>
                                <div className={styles.card}>
                                    <div>
                                        <div className={styles.cardHeader}>Alarms:</div>
                                        <div
                                            className={styles.cardValue}>{analytics.alarmsNumber}</div>
                                    </div>
                                </div>
                            </div>

                            <h2 className={styles.title}>Offers and Promo (Last month):</h2>
                            <div className={styles.cardsContainer}>
                                <div className={styles.card}>
                                    <div>
                                        <div className={styles.cardHeader}>Bought offers:</div>
                                        <div
                                            className={styles.cardValue}>{analytics.userOffersNumber}</div>
                                    </div>
                                </div>
                            </div>

                            <h2 className={styles.title}>Most popular offers and promo (Last month):</h2>
                            <div className={styles.cardsContainer}>
                                {analytics.popularOffer && <div className={styles.card}>
                                    <div>
                                        <div className={styles.cardHeader}>Offer:</div>
                                        <div
                                            className={styles.cardValue}><a href={"/offers" + analytics.popularOffer.id}>{analytics.popularOffer.name}</a></div>
                                    </div>
                                </div>}
                                {analytics.popularPromoCode && <div className={styles.card}>
                                    <div>
                                        <div className={styles.cardHeader}>Promo code:</div>
                                        <div
                                            className={styles.cardValue}><a href={"/promo-code/" + analytics.popularPromoCode.id}>{analytics.popularPromoCode.name}</a></div>
                                    </div>
                                </div>}
                            </div>
                        </DefaultPageContentContainer>
                    }
                    </>

                    : <LoadingBar/>
                }
            </MainContainer>
        </>

    );
};