import {
    createDefaultHeaders,
    createUrlParameters,
    fetchWithResponse,
    getAccessTokenFromLocalStorage
} from "../util/common";
import {HOST} from "../util/properties";

export type UserOfferRs = {
    id: string,
    startDate: string,
    endDate: string,
    userId: string,
    userFullName: string
}

export const getUserOffers = (offerId: string): Promise<Array<UserOfferRs>> => {
    const userToken = getAccessTokenFromLocalStorage();
    const urlParameters = createUrlParameters({"offerId": offerId})

    return fetchWithResponse(HOST + "/api/user-offers/offer" + urlParameters, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};