import * as React from 'react';
import sortButtonStyles from "./SortButtonStyles";
import {useState} from "react";

export type SortButtonProperties = {
    sortField?: SortField,
    setSortField: any,
    fieldNameTarget: string,
    title: string,
};

export type SortField = {
    name: string,
    direction: "" | "ASC" | "DESC"
};

export const createSortString = (sortField?: SortField): string => {
    return sortField
        ? (sortField.name + "," + sortField.direction)
        : "";
}

export function SortButton(props: SortButtonProperties): React.ReactElement {
    const {sortField, setSortField, fieldNameTarget, title} = props;
    const styles = sortButtonStyles();
    const [direction, setDirection] = useState<"" | "ASC" | "DESC">("");


    const handleOnClick = () => {
        const isFieldChanged = !sortField || sortField.name !== fieldNameTarget;


        const directionValue = switchSort(isFieldChanged ? "" : direction);
        setDirection(directionValue);
        setSortField(directionValue && {name: fieldNameTarget, direction: directionValue});
    }

    return (<>
       <span className={styles.sortField} onClick={handleOnClick}>
           <div
               className={sortField && sortField.name === fieldNameTarget && direction === "ASC" ? styles.sortAscActive : styles.sortAsc}/>
           {title}
           <div
               className={sortField && sortField.name === fieldNameTarget && direction === "DESC" ? styles.sortDescActive : styles.sortDesc}/>
       </span>
    </>)
}

const switchSort = (sort: string) => {
    if (sort === "") {
        return "ASC";
    }
    if (sort === "ASC") {
        return "DESC";
    }
    if (sort === "DESC") {
        return "";
    }

    return "";
};
