import * as React from 'react'
import {useEffect, useState} from 'react'
import {TitleWrapper} from "../common/TitleWrapper/TitleWrapper";
import {getLocationId, getUserFromLocalStorage, isAccessRoles, PageResponse, Role} from "../../util/common";
import {ActionButton} from "../common/ActionButton/ActionButton";
import {DefaultPageContentContainer} from "../common/TableContainer/DefaultPageContentContainer";
import {PagingSelector} from "../common/PagingSelector/PagingSelector";
import {MainContainer} from "../common/MainContainer/MainContainer";
import {Link, useParams} from "react-router-dom";
import tariffsStyles from "./TariffsStyles";
import {getCustomTariffs, getDefaultTariffs, TariffResponse} from "../../api/TariffsApi";
import {DefaultListContentContainer} from "../common/TableContainer/DefaultListContentContainer";
import {formatDate, formatTimeFromString} from "../../util/dateUtils";
import {LoadingBar} from "../common/LoadingBar/LoadingBar";
import {getVehicleModels, VehicleModelResponse} from "../../api/VehicleModelApi";
import {SelectFilter} from "../common/SelectFilter/SelectFilter";
import Select from "react-select";


export type TariffsProperties = {
    roles: Array<Role>
}


export function Tariffs(props: TariffsProperties) {
    const styles = tariffsStyles();
    const {roles} = props;
    const user = getUserFromLocalStorage();
    const [tariffPage, setTariffPage] = useState<PageResponse<TariffResponse> | undefined>(undefined);
    const [defaultTariffs, setDefaultTariffs] = useState<PageResponse<TariffResponse> | undefined>(undefined);
    const [page, setPage] = useState<number>(0);
    const [sortDirection, setSortDirection] = useState<string>("");
    const [sortField, setSortField] = useState<string>("");
    const [pageLoaded, setPageLoaded] = useState<boolean>(false);
    const [defaultTariffLoaded, setDefaultTariffLoaded] = useState<boolean>(false);
    const [vehicleModels, setVehicleModels] = useState<Array<VehicleModelResponse>>([]);
    const [vehicleModelId, setVehicleModelId] = useState<string>("");
    const locationId = getLocationId();
    const {status} = useParams();

    const writeAccess = isAccessRoles(user.roles, roles, "WRITE");

    const createDataRow = (tariff: TariffResponse): React.ReactElement => {
        return <>
            <tr className={styles.dataRow}>
                <th className=""><a href={"/tariff/" + tariff.id}>{tariff.id}</a></th>
                <th className="">{tariff.name}</th>
                <th className="">{formatDate(tariff.startDate) + " - " + formatDate(tariff.endDate)}</th>
                <th className="">{formatTimeFromString(tariff.startTime) + " - " + formatTimeFromString(tariff.endTime)}</th>
                <th className="">{tariff.dayOfWeeks ? tariff.dayOfWeeks.toString() : ""}</th>
                <th className="">{tariff.startPrice}</th>
                <th className="">{tariff.pricePerMinute}</th>
                <th className="">{tariff.pausePricePerMinute}</th>
                <>
                    {
                        tariff.status?.toLowerCase() === "archive"
                            ? <></>
                            : <th className={styles.iconContainer}>
                                <Link to={"/tariff/" + tariff.id}><img className={styles.icon} src="/eye.svg"
                                                                       alt=""/></Link>
                                <Link to={"/tariff/edit/" + tariff.id}><img className={styles.icon} src="/edit.svg"
                                                                            alt=""/></Link>
                            </th>
                    }
                </>
            </tr>
        </>
    }

    const createDataHeader = (sortField: string, setSortField: any, sortDirection: string, setSortDirection: any): React.ReactElement => {
        return <>
            <thead>
            <tr className={styles.headerRow}>
                <th className="">ID</th>
                <th className="">Name</th>
                <th className="">Work period</th>
                <th className="">Time</th>
                <th className="">Day of weeks</th>
                <th className="">Start price</th>
                <th className="">Price per min</th>
                <th className="">Pause price per min</th>
                <th></th>
                <th></th>
            </tr>
            </thead>
        </>
    }

    useEffect(() => {
        getVehicleModels()
            .then((vehicleModelResponses: Array<VehicleModelResponse>) => {
                setVehicleModels(vehicleModelResponses);
                setVehicleModelId(vehicleModelResponses[0].id)
            })

    }, []);

    useEffect(() => {
        getCustomTariffs(page, vehicleModelId, locationId, status, sortField && sortDirection ? sortField + "," + sortDirection : undefined)
            .then((result: PageResponse<TariffResponse>) => {
                setTariffPage(result);
                setPageLoaded(true);
            });
        getDefaultTariffs(vehicleModelId, locationId, status)
            .then((result: PageResponse<TariffResponse>) => {
                setDefaultTariffs(result);
                setDefaultTariffLoaded(true);
            })
            .catch((reason) => {
                setDefaultTariffLoaded(true);
            });

    }, [vehicleModelId, locationId, page, status, sortDirection, sortField]);

    return (

        <MainContainer>
            <TitleWrapper title={createTitle(status)}>
                {writeAccess && <ActionButton title={"New Tariff"} url={"/tariff/edit/new"} isLink={true}/>}
            </TitleWrapper>
            {
                vehicleModelId && vehicleModels &&
                <div>
                    {/*<Select*/}
                    {/*    id={"Vehicle model:"}*/}
                    {/*    defaultValue={vehicleModelId}*/}
                    {/*    onChange={(v: any) => setVehicleModelId(v.value)}*/}
                    {/*    options={vehicleModels.map((vehicleModel) => {*/}
                    {/*        return {*/}
                    {/*            label: vehicleModel.name,*/}
                    {/*            value: vehicleModel.id*/}
                    {/*        }*/}
                    {/*    })}*/}
                    {/*/>*/}
                    <SelectFilter title={"Vehicle model:"} value={vehicleModelId}
                                  setValue={setVehicleModelId}
                                  options={
                                      vehicleModels.map((vehicleModel) => {
                                          return {
                                              label: vehicleModel.name,
                                              value: vehicleModel.id
                                          }
                                      })
                                  }/>
                </div>
            }
            {
                defaultTariffLoaded
                    ? <DefaultListContentContainer title={"Default active tariff"} list={Array.of(tariffPage)}>
                        <table className={styles.dataTable}>
                            <thead>
                            <tr className={styles.headerRow}>
                                <th className="">ID</th>
                                <th className="">Name</th>
                                <th className="">Work period</th>
                                <th className="">Start price</th>
                                <th className="">Price per min</th>
                                <th className="">Pause price per min</th>
                                <th></th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {defaultTariffs && defaultTariffs.content && defaultTariffs.content.length > 0
                                ? defaultTariffs.content.map((defaultTariff, index) =>

                                    <tr className={styles.dataRow}>
                                        <th className=""><a href={"/tariff/" + defaultTariff.id}>{defaultTariff.id}</a></th>
                                        <th className="">{defaultTariff.name}</th>
                                        <th className="">{formatDate(defaultTariff.startDate) + " - " + formatDate(defaultTariff.endDate)}</th>
                                        <th className="">{defaultTariff.startPrice}</th>
                                        <th className="">{defaultTariff.pricePerMinute}</th>
                                        <th className="">{defaultTariff.pausePricePerMinute}</th>
                                        {
                                            defaultTariff.status?.toLowerCase() === "active"
                                                ? <th className={styles.iconContainer}>
                                                    <Link to={"/tariff/" + defaultTariff.id}><img className={styles.icon}
                                                                                                  src="/eye.svg"
                                                                                                  alt=""/></Link>
                                                    {writeAccess && <Link to={"/tariff/edit/" + defaultTariff.id}><img
                                                        className={styles.icon}
                                                        src="/edit.svg"
                                                        alt=""/></Link>}
                                                </th>
                                                : <></>
                                        }

                                    </tr>
                                )
                                : <tr className={styles.noDataBlock}>
                                    <th>No default tariff found!!!</th>
                                </tr>}
                            </tbody>
                        </table>
                    </DefaultListContentContainer>
                    : <img src={"/loading.gif"} alt={""}/>
            }
            {pageLoaded ?
                <DefaultPageContentContainer title={"Custom Tariffs"} pageContent={tariffPage}>
                    <table className={styles.dataTable}>
                        {createDataHeader(sortField, setSortField, sortDirection, setSortDirection)}
                        <tbody>
                        {tariffPage && tariffPage.content && tariffPage.content.length > 0
                            ? tariffPage.content.map((tariff, index) => createDataRow(tariff))
                            : <tr className={styles.noDataBlock}>
                                <th>No vehicles found</th>
                            </tr>}
                        </tbody>
                    </table>
                    {
                        tariffPage && tariffPage.totalPages > 1
                            ? <PagingSelector page={page} pageNumber={tariffPage.totalPages} setPage={setPage}/>
                            : <></>
                    }


                </DefaultPageContentContainer>
                : <LoadingBar/>
            }
        </MainContainer>

    )
}

const createTitle = (status?: string) => {
    let title = "Tariffs";
    if (status?.toLowerCase() === "active") {
        title = "Active tariffs";
    }
    if (status?.toLowerCase() === "archive") {
        title = "Archived tariffs";
    }
    return title;
}

