import {
    createDefaultHeaders,
    createUrlParameters,
    fetchNoResponse,
    fetchWithResponse,
    getAccessTokenFromLocalStorage,
    PageResponse
} from "../util/common";
import {HOST} from "../util/properties";

export type TariffRequest = {
    id?: string,
    startDate: Date,
    endDate: Date,
    dayOfWeeks: Array<string>,
    startTime: Date,
    endTime: Date,
    startPrice: number,
    pricePerMinute: number,
    pausePricePerMinute: number
    vehicleModelId: string,
    locationId: string
}

export type TariffResponse = {
    id: string,
    name: string,
    startDate: string,
    endDate: string,
    startPrice?: number,
    pricePerMinute?: number,
    pausePricePerMinute?: number,
    status?: string,
    vehicleModelId: string,
    startTime?: Date | string,
    endTime?: Date | string,
    dayOfWeeks?: Array<string>,
    isDefault: boolean
}

export const getCustomTariffs = (page: number, vehicleModelId?: string, locationId?:string, status?: string, sort?: string): Promise<PageResponse<TariffResponse>> => {
    const userToken = getAccessTokenFromLocalStorage();
    const paramString = createUrlParameters({
        "vehicleModelId": vehicleModelId,
        "locationId": locationId,
        "page": page,
        "sort": sort,
        "status": status ? status.toUpperCase() : ""
    });

    return fetchWithResponse(HOST + "/api/tariffs" + paramString, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const getDefaultTariffs = (vehicleModelId?: string, locationId?:string, status?: string): Promise<PageResponse<TariffResponse>> => {
    const userToken = getAccessTokenFromLocalStorage();
    const paramString = createUrlParameters({
        "vehicleModelId": vehicleModelId,
        "locationId": locationId,
        "status": status ? status.toUpperCase() : ""
    });

    return fetchWithResponse(HOST + "/api/tariffs/default" + paramString, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const getTariff = (id?: string): Promise<TariffResponse> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/tariffs/" + id, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const createTariffs = (values: TariffRequest): Promise<PageResponse<TariffResponse>> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/tariffs", {
        method: 'POST',
        body: JSON.stringify(values),
        headers: createDefaultHeaders(userToken)
    })
};

export const updateTariffs = (values: TariffRequest): Promise<PageResponse<TariffResponse>> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/tariffs", {
        method: 'PUT',
        body: JSON.stringify(values),
        headers: createDefaultHeaders(userToken)
    })
};

export const archiveTariff = (id: string): Promise<any> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchNoResponse(HOST + "/api/tariffs/archive/" + id, {
        method: 'PATCH',
        headers: createDefaultHeaders(userToken)
    })
};