import * as React from 'react'
import {useEffect, useState} from 'react'
import {NavigateFunction} from "react-router-dom";
import {useParams} from "react-router-dom";
import {Message} from "../../common/Message/Message";
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {UserForm} from "../UserForm/UserForm";
import {getUser, updateUser, UserRq} from "../../../api/UserApi";
import {getLanguages} from "../../../api/TripApi";


let formDefaultValues: UserRq = {
    lastName: "",
    firstName: "",
    email: "",
    phoneNumber: "",
    language: "",
    birthdate: new Date(),
    gender: "MALE",
    address: "",
    country: "",
    city: "",
    zip: "",
}

export function UserEdit() {
    const {id} = useParams();
    const [message, setMessage] = useState<string>("");
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const onSubmitHandle = (values: UserRq, navigate: NavigateFunction, id?: string) => {
        updateUser(values, id)
            .then((result: any) => {
                navigate("/user/" + result.id);
            })
            .catch((result: any) => {
                setMessage("Error occurred while updating user");
                setTimeout(() => {
                    setMessage("")
                }, 3000)
            });
    }

    useEffect(() => {
        getUser(id)
            .then((result) => {
                formDefaultValues = {
                    lastName: result.lastName,
                    firstName: result.firstName,
                    middleName: result.middleName,
                    email: result.email,
                    phoneNumber: result.phoneNumber,
                    language: result.language,
                    birthdate: result.birthdate,
                    gender: result.gender,
                    address: result.address,
                    country: result.country,
                    city: result.city,
                    zip: result.zip,
                    state: result.state || "",
                }
                setIsLoaded(true);

            })
    }, []);

    return (
        <>
            <Message message={message} isError={true}/>
            <MainContainer>
                <TitleWrapper title={"Edit user"}/>
                {
                    isLoaded
                        ? <UserForm onSubmitHandle={onSubmitHandle}
                                    formDefaultValues={formDefaultValues} id={id}/>
                        : <img src={"/loading.gif"} alt={""}/>
                }
            </MainContainer>
        </>
    )
}

