import { createUseStyles } from "react-jss";

const dashboardStyles = createUseStyles({
    mapContainer: {
        position: "relative"
    },
    mapWrapper: {
        height: "85vh",
    },
    leftMenu: {
        position: "absolute",
        height: "800px",
        zIndex: "123",
        left: "0",
        width: "0",
        display: "none",
        backgroundColor: "#ffffff",
    },
    openedMenu: {
        width: "400px",
        display: "block",
        transform: 'translateX(0%)'
    },
    redTag: {
        color: "rgb(178 6 6 )",
        borderColor: "rgb(178 6 6 )",
    },
    yellowTag: {
        color: "rgb(204 102 0)",
        borderColor: "rgb(204 102 0)",
    },
    greenTag: {
        color: "rgb(61 161 38)",
        borderColor: "rgb(61 161 38)",
    },
    tag: {
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
        backgroundColor: "rgb(253 253 253)",
        borderRadius: "9999px",
        borderWidth: "1px",
        borderStyle: "solid",
        whiteSpace: "nowrap",
        fontSize: "0.75rem",
        fontWeight: "600",
        textTransform: "uppercase",
        letterSpacing: "0.025em",
    },
    leftMenuTopMenu: {
        padding: "10px",
        display: "flex",
        justifyContent: "flex-end"
    },
    closeButton: {
        cursor: "pointer",
    },
    leftMenuContent: {
        paddingLeft: "25px",
        paddingRight: "25px",
    },
    infoWrapper: {
        position: "absolute",
        top: "65vh",
        opacity: "0.8",
        display: "flex",
        justifyContent: "center",
        width: "100%"
    },
    infoContent: {
        width: "100%",
    },
    info: {
        width: "10vw",
        height: "100px",
        borderRadius: "5px",
        color: "#ffffff",
        backgroundColor: "#24292F",
        display: "flex",
        alignItems: "center",
        marginLeft: "15px",
        marginRight: "15px",
    },
    number: {
        fontSize: "20px"
    },

    '@media (max-width:568px)': {
        leftMenu: {
            position: "absolute",
            height: "220px",
            zIndex: "123",
            bottom: "0",
            width: "100%",
            backgroundColor: "#ffffff",
        },
        info: {
            width: "25vw",
            height: "15vh",
            borderRadius: "5px",
            color: "#ffffff",
            backgroundColor: "#24292F",
            display: "flex",
            alignItems: "center",
            marginLeft: "15px",
            marginRight: "15px",
        },
        "& h4": {
            marginTop: "5px",
            marginBottom: "5px"
        },
        fieldName: {
            textAlign: "right",
            float: "left",
            clear: "both",
            minWidth: "25%",
            fontSize: "14px",
            wordBreak: "break-word",
            fontWeight: "600",
            marginTop: "2px",
            marginBottom: "2px",
        },
        fieldValue: {
            marginTop: "2px",
            marginBottom: "2px",
            float: "left",
            paddingLeft: "10px",
            fontSize: "14px",
            wordBreak: "break-word",
            fontWeight: "600",
            "& span": {
                fontWeight: "400"
            }
        },
    }
});

export default dashboardStyles;