import * as React from 'react';
import {useEffect, useState} from 'react';
import addStorageModalStyles from "./AddStorageModalStyles";
import {Modal} from "../../../common/Modal/Modal";
import {getStorages, StorageShortRs} from "../../../../api/StoragesApi";
import {Field, Form, Formik} from "formik";
import {
    updateVehicleStorage,
    UpdateVehicleStorageRequest,
    VehicleResponse,
    VehicleStatus
} from "../../../../api/VehicleApi";
import {VehicleModelRequest} from "../../../../api/VehicleModelApi";

const initValues = {
    storageId: "",
}

export type ConfirmModalProperties = {
    id?: string,
    title: string,
    storageId?: string,
    setVehicle: any,
    setModalOpened: any
}

export function AddStorageModal(props: ConfirmModalProperties): React.ReactElement {
    const styles = addStorageModalStyles();
    const {id, title, storageId, setVehicle, setModalOpened} = props;
    const [storages, setStorages] = useState<Array<StorageShortRs>>();
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    if (storageId) {
        initValues.storageId = storageId;
    }

    const handleSubmit = (values: any) => {
        const request: UpdateVehicleStorageRequest = {status: VehicleStatus.IN_MAINTENANCE};
        if (values.storageId) {
            request.storageId = values.storageId;
        }
        updateVehicleStorage(request, id)
            .then((result: VehicleResponse) => {
                setVehicle(result);
                setModalOpened(false);
            })
    }

    const handleRelease = () => {
        const request: UpdateVehicleStorageRequest = {status: VehicleStatus.AVAILABLE};

        updateVehicleStorage(request, id)
            .then((result: VehicleResponse) => {
                setVehicle(result);
                setModalOpened(false);
            })
    }

    useEffect(() => {
        getStorages().then((result: Array<StorageShortRs>) => {
            setStorages(result);
            if (!storageId && result.length > 0) {
                initValues.storageId = result[0].id;
            }
            setDataLoaded(true);
        })

    }, []);

    return (<>
        <Modal setModalHidden={setModalOpened}>
            <div className={styles.modalText}>{title}</div>
            <div className={styles.inputContainer}>
                {dataLoaded ?
                    <Formik initialValues={initValues} onSubmit={handleSubmit}>
                        {(props) => {
                            const {errors, touched, submitCount} = props;

                            return (
                                <Form>
                                    <label htmlFor="storageId">Storage</label>
                                    <Field id="storageId" component="select"
                                           name="storageId"
                                           className={errors.storageId && touched.storageId ? styles.inputFieldError : styles.inputField}>
                                        {
                                            Array.isArray(storages)
                                                ? storages.map((storage) => <option
                                                    value={storage.id}>{storage.name}</option>)
                                                : ""
                                        }
                                    </Field>
                                    <button className={styles.submitButton} type={"submit"}>
                                        Save storage
                                    </button>
                                </Form>)
                        }}
                    </Formik>
                    : <img src={"/loading.gif"}/>
                }

            </div>
            <div className={styles.modalButtonContainer}>
                <div className={styles.modalButtonCancel} onClick={() => props.setModalOpened(false)}>Cancel</div>
                {storageId ?
                    <div className={styles.modalButtonConfirm} onClick={handleRelease}>Release vehicle</div> : <></>}
            </div>

        </Modal>
    </>)
}
