import * as React from 'react';
import {useEffect, useState} from 'react';
import {getTrips, TripShort, TripShortPriceRs} from "../../../../api/TripApi";
import {Page} from "../../../common/Page/Page";
import {getLocationId, PageResponse} from "../../../../util/common";
import {Link} from "react-router-dom";
import {formatDate, formatDateTime} from "../../../../util/dateUtils";
import {getUserOffers, UserOfferRs} from "../../../../api/UserOfferApi";

export type TripsPageProps = {
    id: string,
}

const createOfferHeaderRow = () => {
    return <>
        <th className="">User</th>
        <th className="">Active at</th>
        <th></th>
    </>
}

const createOfferDataRow = (userOffer: UserOfferRs, navigate: any, styles: any) => {
    return <tr>
        <th className=""><Link to={userOffer.userId}>{userOffer.userFullName}</Link></th>
        <th className="">{formatDate(userOffer.startDate)} - {formatDate(userOffer.endDate)}</th>
        <th></th>
    </tr>
}

export function UserOfferPage(props: TripsPageProps): React.ReactElement {
    const {id} = props;
    const [page, setPage] = useState<number>(0);
    const [data, setData] = useState<PageResponse<UserOfferRs> | undefined>(undefined);
    useEffect(() => {
        getUserOffers(id).then((result: any) => {
            setData(result);
        })

    }, [id]);

    return (<>
        <Page title={"User offers"} createHeaderRow={createOfferHeaderRow} createDataRow={createOfferDataRow}
              page={page} setPage={setPage} data={data}/>
    </>)
}