import * as React from 'react'
import {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import vehicleStyles from "./VehicleStyles";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import {ActionButton} from "../../common/ActionButton/ActionButton";
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {deleteVehicle, getVehicle, lockVehicle, VehicleResponse} from "../../../api/VehicleApi";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {getActiveTrip, TripShort} from "../../../api/TripApi";
import {Message} from "../../common/Message/Message";
import Map, {Marker} from 'react-map-gl';
import {ConfirmModal} from "../../common/ConfirmModal/ConfirmModal";
import {AddStorageModal} from "./AddStorageModal/AddStorageModal";
import {MAP_STYLE, MAPBOX_PUBLIC_TOKEN} from '../../../util/properties';
import {AlarmPage} from "../../Alarms/AlarmPage/AlarmPage";
import {UserField} from "../../common/UserField/UserField";
import {formatDateTime} from "../../../util/dateUtils";
import {getUserFromLocalStorage, isAccessRoles, Role} from "../../../util/common";


const vehicleResponseInit: VehicleResponse = {
    id: "",
    externalId: "",
    qrCode: "",
    insuranceId: "",
    insuranceExpired: undefined,
    createdAt: "",
    model: {id: "", name: ""},
    status: "",
    data: undefined,
}

const TripShortInit = {
    id: "",
    startTime: undefined,
    status: "",
    userId: "",
    userFullName: "",
    vehicleId: ""
}

export type VehicleProperties = {
    roles: Array<Role>
}
export function Vehicle(props: VehicleProperties) {
    const styles = vehicleStyles();
    const user = getUserFromLocalStorage();
    const {roles} = props;
    const navigate = useNavigate();
    const [vehicle, setVehicle] = useState<VehicleResponse>(vehicleResponseInit);
    const [message, setMessage] = useState<string>("");
    const [isMessageError, setIsMessageError] = useState<boolean>(false);
    const [deleteModalOpened, setDeleteModalOpened] = useState<boolean>(false);
    const [addStorageModalOpened, setAddStorageModalOpened] = useState<boolean>(false);
    const [activeTrip, setActiveTrip] = useState<TripShort>(TripShortInit);

    const {id} = useParams();

    const writeAccess = isAccessRoles(user.roles, roles, "WRITE")
    const lockOnClickHandle = (id: string | undefined, lock: boolean) => {
        lockVehicle(lock, id).then(() => {
            setIsMessageError(false)
            setMessage("Vehicle " + (lock ? "lock" : "unlock") + " successfully");
            setTimeout(() => {
                setMessage("");
            }, 3000)
        }).catch(() => {
            setIsMessageError(true)
            setMessage("Error occurred while " + (lock ? "locking" : "unlocking"));
            setTimeout(() => {
                setMessage("");
            }, 3000)
        })
    }

    const deleteOnChange = () => {
        if (!id) {
            return;
        }
        deleteVehicle(id).then(() => {
            navigate("/vehicles");
        }).catch((err) => {
            setIsMessageError(true);
            setDeleteModalOpened(false);
            setMessage("Error occurred while deleting vehicle");
            setTimeout(() => {
                setMessage("");
            }, 3000)
        })
    }

    useEffect(() => {
        getVehicle(id).then((result: VehicleResponse) => {
            setVehicle(result);
        })
        getActiveTrip(undefined, id).then((result: TripShort) => {
            setActiveTrip(result)
        })

    }, [id]);
    return (
        <>
            {addStorageModalOpened
                ?
                <AddStorageModal
                    id={id}
                    title={"Select storage"}
                    storageId={vehicle.storage?.id}
                    setVehicle={setVehicle}
                    setModalOpened={setAddStorageModalOpened}/>
                :
                <></>}
            {deleteModalOpened
                ?
                <ConfirmModal title={"Delete vehicle?"}
                              setModalOpened={setDeleteModalOpened}
                              handleConfirm={deleteOnChange}/>
                :
                <></>}
            <Message message={message} isError={isMessageError}/>
            <MainContainer>
                <TitleWrapper title={"Vehicle - " + vehicle.externalId}>
                    <ActionButton title={vehicle.storage ? "Change storage" : "Add to storage"} isLink={false}
                                  onClickHandle={() => {
                                      setAddStorageModalOpened(true)
                                  }}/>
                    <ActionButton title={"Lock"} isLink={false}
                                  onClickHandle={() => lockOnClickHandle(id, true)}/>
                    <ActionButton title={"Unlock"} isLink={false}
                                  onClickHandle={() => lockOnClickHandle(id, false)}/>
                    <ActionButton title={"Sync"} isLink={false}/>
                    <ActionButton title={"Unlock Battery"} isLink={false}/>
                    <ActionButton title={"Create task"} isLink={true} url={"/task/edit/new/vehicles/" + id}/>
                    {writeAccess && <ActionButton title={"Edit"} isLink={true} url={"/vehicle/edit/" + id}/>}
                    {writeAccess && <ActionButton title={"Delete"} isLink={false} onClickHandle={() => {
                        setDeleteModalOpened(true)
                    }}/>}
                </TitleWrapper>
                <div className={styles.contentWrapper}>
                    <div className={styles.leftColumn}>
                        <DefaultPageContentContainer title={"Details"}>
                            <UserField title={"Status:"} field={createStatusStyle(vehicle.status, styles)}/>
                            <UserField title={"ID:"} field={vehicle.id}/>
                            <UserField title={"External ID:"} field={vehicle.externalId}/>
                            <UserField title={"Qr Code:"} field={vehicle.qrCode}/>
                            <UserField title={"Model:"} field={vehicle.model ? vehicle.model.name : ""}/>
                            <UserField title={"Insurance ID:"} field={vehicle.insuranceId}/>
                            <UserField title={"Insurance expried:"} field={formatDateTime(vehicle.insuranceExpired)}/>
                            <UserField title={"Created At:"} field={formatDateTime(vehicle.createdAt)}/>
                        </DefaultPageContentContainer>

                        {vehicle.data ?
                            <DefaultPageContentContainer title={"Vehicle Data"}>
                                <UserField title={"Online:"} field={createBooleanFieldStyle(vehicle.data.online, styles)}/>
                                <UserField title={"Locked:"} field={createBooleanFieldStyle(vehicle.data.locked, styles)}/>
                                <UserField title={"Speed:"} field={vehicle.data.speed}/>
                                <UserField title={"Storage:"} field={vehicle.data.latitude + ", " + vehicle.data.longitude}/>
                            </DefaultPageContentContainer>
                            : <></>
                        }
                        {vehicle.data && vehicle.data.battery ?
                            <DefaultPageContentContainer title={"Vehicle Data"}>
                                <UserField title={"Charging:"} field={createBooleanFieldStyle(vehicle.data.battery.charging, styles)}/>
                                <UserField title={"State of charge:"} field={createStateOfChargeStyle(vehicle.data.battery.stateOfCharge, styles)}/>
                                <UserField title={"Range:"} field={vehicle.data.battery.range}/>
                            </DefaultPageContentContainer>
                            : <></>
                        }
                    </div>
                    <div className={styles.rightColumn}>
                        {activeTrip ?
                            <DefaultPageContentContainer title={"Active rental"}>
                                <table className={styles.dataTable}>
                                    <thead>
                                    <tr className={styles.headerRow}>
                                        <th className="">ID</th>
                                        <th className="">Start time</th>
                                        <th className="">Status</th>
                                        <th className="">Full name</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr className={styles.dataRow}>
                                        <th className=""><a href={"/trip/" + activeTrip.id}>{activeTrip.id}</a></th>
                                        <th className="">{formatDateTime(activeTrip.startTime)}</th>
                                        <th className="">{activeTrip.status}</th>
                                        <th className="">{activeTrip.userFullName}</th>
                                    </tr>
                                    </tbody>
                                </table>

                            </DefaultPageContentContainer>
                            : <></>
                        }
                        <DefaultPageContentContainer title={"Vehicles"}>
                            {
                                vehicle && vehicle.data && <Map
                                    initialViewState={{
                                        longitude: vehicle?.data?.longitude,
                                        latitude: vehicle?.data?.latitude,
                                        zoom: 12.5
                                    }}
                                    mapboxAccessToken={MAPBOX_PUBLIC_TOKEN}
                                    style={{width: "100%", height: 400}}
                                    mapStyle={MAP_STYLE}
                                >
                                    <Marker
                                        longitude={vehicle.data.longitude}
                                        latitude={vehicle.data.latitude}
                                        anchor="bottom"
                                    >
                                        <img src={"/marker.png"} alt=""/>

                                    </Marker>


                                </Map>
                            }

                        </DefaultPageContentContainer>
                        <AlarmPage roles={roles} title={"Alarms"} vehicleId={id}/>
                        : <></>
                    </div>
                </div>
            </MainContainer>
        </>
    )
}


const createStatusStyle = (status: string | undefined, styles: any): React.ReactNode => {
    if (!status) {
        return <span className={styles.tag + " " + styles.redTag}>NO STATUS</span>
    }
    if (status.toLowerCase() === "available") {
        return <span className={styles.tag + " " + styles.greenTag}>Available</span>
    }
    if (status.toLowerCase() === "in_maintenance") {
        return <span className={styles.tag + " " + styles.yellowTag}>In maintenance</span>
    }
    if (status.toLowerCase() === "rented") {
        return <span className={styles.tag + " " + styles.greenTag}>Rented</span>
    }
    return <span className={styles.tag + " " + styles.redTag}>disabled</span>
}

const createBooleanFieldStyle = (value: boolean | undefined, styles: any): React.ReactNode => {
    if (!value) {
        return <span className={styles.tag + " " + styles.redTag}>NO</span>
    }
    return <span className={styles.tag + " " + styles.greenTag}>YES</span>
}

const createStateOfChargeStyle = (value: number | undefined, styles: any): React.ReactNode => {
    if (!value) {
        return <span className={styles.tag + " " + styles.redTag}>NO INFO</span>
    }
    if (value >= 70 && value <= 100) {
        return <span className={styles.tag + " " + styles.greenTag}>{value}</span>
    }
    if (value >= 40 && value < 70) {
        return <span className={styles.tag + " " + styles.yellowTag}>{value}</span>
    }
    if (value >= 0 && value <= 40) {
        return <span className={styles.tag + " " + styles.redTag}>{value}</span>
    }
    return <span className={styles.tag + " " + styles.redTag}>{value}</span>
}