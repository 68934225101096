import {HOST} from "../util/properties";
import {
    createDefaultHeaders,
    createUrlParameters,
    fetchNoResponse,
    fetchWithResponse,
    getAccessTokenFromLocalStorage,
    PageResponse
} from "../util/common";

export type UserShortRs = {
    id: string,
    lastName: string,
    firstName: string,
    middleName?: string,
    status: string,
    lastRentalAt?: Date,
    createdAt?: Date,
    changedAt?: Date,
}

export type UserAdminRs = {
    id: string,
    lastName: string,
    firstName: string,
    middleName: string,
    changedAt: Date,
    locationIds: Array<string>,
    email: string,
    lastSignIn?: Date,
    phoneNumber: string,
    language: string,
    roles: Array<string>
}

export type UserRq = {
    lastName: string
    firstName: string
    middleName?: string
    email: string
    phoneNumber: string
    birthdate: Date
    language: string
    gender: string
    address: string
    country: string
    city: string
    zip: string
    state?: string
}

export type AdminRq = {
    lastName: string
    firstName: string
    middleName?: string
    email: string
    phoneNumber: string
    birthdate: Date
    language: string
    gender: string,
    roles: Array<string>
}

export type UserRs = {
    id: string
    lastName: string
    firstName: string
    middleName?: string
    email: string
    phoneNumber: string
    birthdate: Date
    status: string
    language: string
    activatedAt?: Date
    firstRentalAt?: Date
    changedAt: Date
    lastSession?: string
    gender: string
    address: string
    country: string
    city: string
    zip: string
    state?: string
}

export const getFullName = (user: any): string => {
    return user.lastName + " " + user.firstName + (user.middleName ? (" " + user.middleName) : "");
}

export const getFullNameFromRs = (user: UserRs): string => {
    return user.lastName + " " + user.firstName + (user.middleName ? (" " + user.middleName) : "");
}

export const getUsers = (page: number, sort?: string): Promise<PageResponse<UserShortRs>> => {
    const userToken = getAccessTokenFromLocalStorage();
    const paramString = createUrlParameters({
        "page": page,
        "sort": sort,
    });

    return fetchWithResponse(HOST + "/api/users" + paramString, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const getUser = (id?: string): Promise<UserRs> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/users/" + id, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const deleteUser = (id: string) => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchNoResponse(HOST + "/api/users/" + id, {
        method: 'DELETE',
        headers: createDefaultHeaders(userToken)
    })
};

export const updateUser = (values: UserRq, id?: string) => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/users/" + id, {
        method: 'PUT',
        body: JSON.stringify(values),
        headers: {
            'Authorization': 'Bearer ' + userToken,
            'Content-Type': 'application/json'
        },
    })
};

export const getAdmins = (page: number, sort?: string, role?: string): Promise<PageResponse<UserAdminRs>> => {
    const userToken = getAccessTokenFromLocalStorage();
    const paramString = createUrlParameters({
        "page": page,
        "sort": sort,
        "role": role,
    });

    return fetchWithResponse(HOST + "/api/users/admins" + paramString, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const getAdmin = (id: string): Promise<UserAdminRs> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/users/admins/" + id, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const createAdmin = (values: AdminRq) => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/users/admins", {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
            'Authorization': 'Bearer ' + userToken,
            'Content-Type': 'application/json'
        },
    })
};

export const updateAdmin = (values: AdminRq, id?: string) => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchNoResponse(HOST + "/api/users/admins/" + id, {
        method: 'PUT',
        body: JSON.stringify(values),
        headers: {
            'Authorization': 'Bearer ' + userToken,
            'Content-Type': 'application/json'
        },
    })
};

export const deleteAdmin = (id: string) => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchNoResponse(HOST + "/api/users/admins/" + id, {
        method: 'PATCH',
        headers: createDefaultHeaders(userToken)
    })
};