import React, {useCallback, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import photoPickerStyles from "./PhotoPickerStyles";
import {useField} from "formik";

export function PhotoPicker({...selectProps}) {
    const [field, meta, helpers] = useField(selectProps.id);

    const [file, setFile] = useState<any>(field.value);

    const onDrop = useCallback((acceptedFiles: any) => {
        setFile(acceptedFiles[0])
        // Do something with the files

        helpers.setValue(acceptedFiles[0]);
    }, [])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
    const styles = photoPickerStyles();

    return (
        <>
            <div className={styles.container} {...getRootProps()}>
                <input id={selectProps.id} {...getInputProps()} />
                {
                    isDragActive ?
                        <p>Drop the files here ...</p> :
                        <p>Drag 'n' drop photo here, <span className={styles.link}>or click to select file</span></p>
                }
            </div>
            {file && <div className={styles.attachedPhoto}>
                <div className={styles.closeButton}>
                    <img onClick={() => setFile(null)} className={styles.imageClose} src="/close.svg" alt=""/>
                </div>
                <div className={styles.name}>
                    <span >{file.name}</span>
                </div>

            </div>
            }
        </>
    )
}