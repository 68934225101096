import * as React from "react";
import searchInputStyles from "./SearchInputStyles";


export type SelectFilterProperties = {
    title: string,
    setValue: any,
    setDataLoaded: any
};

export function SearchInput(props: SelectFilterProperties): React.ReactElement {
    const {title, setValue, setDataLoaded} = props;
    const styles = searchInputStyles();

    return (<div className={styles.selectContainer}>
        <label className={styles.label} htmlFor={title + "Filter"}>{title}</label>
        <input
            className={styles.searchSelect}
            id={title + "Filter"}
            onChange={(v: any) => {
                setDataLoaded(false);
                setTimeout(() => {
                    setValue(v.target.value);
                }, 1000);
            }}
        />
    </div>)
}
