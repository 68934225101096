import {createUseStyles} from "react-jss";

const searchInputStyles = createUseStyles({
    selectContainer: {
        marginRight: "15px",
        display: "flex",
        alignItems: "center",
        "& label": {
            marginRight: "5px"
        },
        cursor: "pointer"
    },
    label:{
        fontSize: "15px"
    },
    searchSelect: {
        width: "150px",
        cursor: "pointer",
        borderRadius: "4px",
        padding: "7px",
        border: "1px solid #CCCCCCFF"
    },

    selectWrapper: {
        marginRight: "15px"
    },
    filtersContainer: {
        display: "flex",
        justifyContent: "flex-start",
    },
})

export default searchInputStyles;