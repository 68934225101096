import * as React from 'react'
import {useEffect, useState} from 'react'
import {AdminRq, createAdmin, getAdmin, updateAdmin} from "../../../api/UserApi";
import {useNavigate, useParams} from "react-router-dom";
import {Message} from "../../common/Message/Message";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {AdminForm} from "../AdminForm/AdminForm";
import {LoadingBar} from "../../common/LoadingBar/LoadingBar";
import editAdminStyles from "./EditAdminStyles";

let formDefaultValues: {
    lastName: string,
    firstName: string,
    middleName?: string,
    email: string,
    phoneNumber: string,
    language: string,
    roles: Array<string>
}

export function EditAdmin() {
    const {id} = useParams();
    const styles = editAdminStyles();
    const navigate = useNavigate();
    const [message, setMessage] = useState<string>("");
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const isNewAdmin = id === "new";

    const onSubmitHandle = (values: AdminRq) => {
        const saveAdmin = isNewAdmin ? createAdmin(values) : updateAdmin(values, id)
        saveAdmin
            .then((result: any) => {
                navigate("/admin/" + (isNewAdmin ? result.id : id));
            })
            .catch((result: any) => {
                setMessage("Error occurred while updating user");
                setTimeout(() => {
                    setMessage("")
                }, 3000)
            });
    }
    useEffect(() => {
        formDefaultValues = {
            lastName: "",
            firstName: "",
            middleName: "",
            email: "",
            phoneNumber: "",
            language: "DE",
            roles: []
        }
        if (!isNewAdmin) {
            getAdmin(id || "")
                .then((result) => {
                    formDefaultValues = {
                        lastName: result.lastName,
                        firstName: result.firstName,
                        middleName: result.middleName,
                        email: result.email,
                        phoneNumber: result.phoneNumber,
                        language: result.language,
                        roles: result.roles
                    }
                    setIsLoaded(true);

                })
        } else {
            setIsLoaded(true);
        }
    }, [id, isNewAdmin]);

    return (
        <>
            <Message message={message} isError={true}/>
            <MainContainer>
                <TitleWrapper title={"Edit admin user"}/>
                <div className={styles.mainWrapper}>
                    <div className={styles.contentWrapper}>
                        {
                            isLoaded
                                ? <AdminForm onSubmitHandle={onSubmitHandle} isNew={isNewAdmin}
                                             formDefaultValues={formDefaultValues} id={id}/>
                                : <LoadingBar/>
                        }
                    </div>
                </div>
            </MainContainer>
        </>
    )
}

