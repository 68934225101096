import {createUseStyles} from "react-jss";
import {MAIN_COLOR} from "../../util/properties";

const headerStyles = createUseStyles({
    navigation: {
        display: "flex",
        justifyContent: "space-between",
        background: MAIN_COLOR,
        color: "#ffffff",
        alignItems: "center",
        paddingLeft: "20px",
        paddingRight: "20px",
        height: "45px",
    },
    sticky: {
        position: "fixed",
        zIndex: "667",
      top: 0,
      left: 0,
      right: 0
    },
    menuButton: {
        visibility: "hidden",
        display: "none",
        zIndex: "900",
    },
    dropDownWrapper: {
        position: "relative",
    },

    logoContainer: {
        display: "block",
        width: "30px",
        paddingRight: "20px",
    },
    logoContainerMobile: {
        display: "none",
    },
    menuContainer: {
        display: "flex",
        justifyContent: "flex-start",
        marginTop: "0px",
        marginBottom: "0px",
        paddingLeft: "0px",
        paddingRight: "0px",
        alignItems: "center",
        '& a:hover': {
            borderBottomColor: "#ffffff",
        }

    },
    counter: {
        backgroundColor: "#ff5510",
        borderRadius: "5px",
        padding: "2px",
    },
    menuElementContainer: {
        position: "relative",
        '&:hover > ul': {
            display: "block",
        },

    },
    dropDownElement: {
        textAlign: "left",
        marginTop: "5px",
        marginBottom: "5px",
        cursor: "pointer",
        display: "block",
        textDecoration: "none",
        color: "#ffffff"
    },
    menuElement: {
        backgroundColor: MAIN_COLOR,
        display: "block",
        fontSize: "13px",
        marginLeft: "12px",
        marginRight: "12px",
        textDecoration: "none",
        fontWeight: "400",
        borderStyle: "solid",
        borderWidth: "2px",
        borderColor: MAIN_COLOR,
        textAlign: "center",
        color: "#FFFFFF",
        boxSizing: "border-box",
        paddingTop: "7px",
        paddingBottom: "7px",
        "& :hover": {
            dropDownMenuContainer: {
                display: "block",
            }
        }
    },
    dropDownMenuContainer: {
        display: "none",
        position: "absolute",
        margin: "0",
        padding: "5px 0 10px",
        zIndex: "21",
        listStyle: "none",
        paddingLeft: "20px",
        paddingRight: "20px",
        backgroundColor: "rgb(0 18 60)",
    },
    avatarCircleContainer: {
        display: "none",
        position: "absolute",
        margin: "0",
        right: "0",
        padding: "5px 0 10px",
        zIndex: "21",
        listStyle: "none",
        paddingLeft: "20px",
        paddingRight: "20px",
        backgroundColor: "rgb(0 18 60)",
    },
    rightMenuContainer: {
        zIndex: "900",
        display: "flex",
        alignItems: "center"
    },
    locationWrapper: {
        marginRight: "55px",
    },
    locationText: {
        //lineHeight: "45px",
    },
    avatarCircle: {
        background: "#53b232",
        borderRadius: "50%",
        display: "inlineFlex",
        justifyContent: "center",
        alignItems: "center",
        flexShrink: "0",
        width: "1em",
        height: "1em",
        lineHeight: "1em",
        overflow: "hidden",
        textAlign: "center",
        fontSize: "2.2em",
        verticalAlign: "middle",
    },
    textAvatar: {
        fontSize: "15px",
    },
    openedMenuElement: {
    },
    '@media (max-width:568px)': {
        menuElementContainer: {
            '&:hover > ul': {

            },
        },
        logoContainerMobile: {
            display: "block",
        },
        dropDownMenuContainer: {
            display: "none",
            "& :hover": {
                dropDownMenuContainer: {
                    display: "none",
                }
            }
        },
        menuContainer: {
            borderRadius: '0 15px 15px 0',
            visibility: 'visible',
            position: 'absolute',
            top: '0',
            left: '0',
            bottom: '0',
            right: '0',
            zIndex: '700',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            maxWidth: '100%',
            marginTop: '0px',
            paddingRight: '0px',
            alignItems: 'stretch',
            backgroundColor: MAIN_COLOR,
            paddingTop: '100px',
            transform: 'translateX(-100%)',
            transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
            '& a:hover': {
                backgroundColor: MAIN_COLOR,
                color: '#ffffff',
            }
        },
        openedMenuElement: {

            position: "relative",
            textAlign: "center",
            '& a': {
                textAlign: "center"
            }
        },
        menuElement: {
            paddingRight: "0px",
            cursor: "pointer"
        },
        logoContainer: {
            display: "none",
        },
        menuButton: {
            display: "block",
            visibility: 'visible',
        },
    },
});

export default headerStyles;