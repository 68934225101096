import {createUseStyles} from "react-jss";

export const promoCodeStyles = createUseStyles({
    contentWrapper: {
        display: "flex",
        justifyContent: "space-between",
    },
    noDataBlock: {
        paddingTop: "10px",
        paddingBottom: "10px",
    },
    photoPreviewContainer: {
        padding: "25px",
        "& img": {
            maxWidth: "400px",
            maxHeight: "400px",
        }
    },
    dataTable: {
        borderCollapse: "collapse",
        width: "100%",
        fontSize: "14px",
        '& tr:nth-child(even)': {
            backgroundColor: "rgb(199 203 209 / 0.1)",
        },
        '& tr': {
            borderTop: "1px solid rgb(96 105 118 / 0.2)",
            '& th': {
                color: "rgb(96 105 118)",
                padding: '10px',
                '& a': {
                    color: "rgb(96 105 118)",
                    textDecoration: "none",
                }
            }
        }
    },
    leftColumn: {
        width: "33.333%",
        paddingRight: "25px",
    },
    tag: {
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
        backgroundColor: "rgb(253 253 253)",
        borderRadius: "9999px",
        borderWidth: "1px",
        borderStyle: "solid",
        whiteSpace: "nowrap",
        fontSize: "0.75rem",
        fontWeight: "600",
        textTransform: "uppercase",
        letterSpacing: "0.025em",
    },
    headerRow: {
        borderBottomStyle: "solid",
        borderBottomWidth: "2px",
        borderBottomColor: "#EDF0F1"
    },
    contentHeader: {
        color: "rgb(96 105 118)",
        '& h2': {
            margin: "18px",
            fontWeight: "600",
        },
    },
    dataRow: {
        borderBottom: "2px solid #EEF0F1",
        boxSizing: "border-box",
    },
    yellowTag: {
        color: "rgb(204 102 0)",
        borderColor: "rgb(204 102 0)",
    },
    greenTag: {
        color: "rgb(61 161 38)",
        borderColor: "rgb(61 161 38)",
    },
    redTag: {
        color: "rgb(178 6 6 )",
        borderColor: "rgb(178 6 6 )",
    },
    rightColumn: {
        width: "66.6666%",
        paddingLeft: "25px",
    },
    '@media (max-width:768px)': {
        contentWrapper: {
            display: "block",
        },
        leftColumn: {
            width: "100%",
            paddingRight: "0",
        },
        rightColumn: {
            width: "100%",
            paddingLeft: "0",
        }
    },
});