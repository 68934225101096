import {Link, useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {useEffect, useState} from "react";
import {getVehicleModels, VehicleModelResponse} from "../../../api/VehicleModelApi";
import {archiveTariff, getTariff, TariffResponse} from "../../../api/TariffsApi";
import {Message} from "../../common/Message/Message";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import tariffStyles from "./TariffStyles";
import {ConfirmModal} from "../../common/ConfirmModal/ConfirmModal";
import {ActionButton} from "../../common/ActionButton/ActionButton";
import {UserField} from "../../common/UserField/UserField";
import {formatDate, formatTimeFromString} from "../../../util/dateUtils";
import {getUserFromLocalStorage, Role} from "../../../util/common";

const TariffInit: TariffResponse = {
    id: "",
    name: "",
    startDate: "",
    endDate: "",
    startPrice: 0,
    pricePerMinute: 0,
    pausePricePerMinute: 0,
    status: "",
    vehicleModelId: "",
    dayOfWeeks: [],
    isDefault: false
}

export type TariffProperties = {
    roles: Array<Role>
}

export function Tariff(props: TariffProperties) {
    const styles = tariffStyles();
    const {id} = useParams();
    const {roles} = props;
    const user = getUserFromLocalStorage();
    const navigate = useNavigate();
    const [tariff, setTariff] = useState<TariffResponse>(TariffInit);
    const [message, setMessage] = useState<string>("");
    const [vehicleModels, setVehicleModels] = useState<Array<VehicleModelResponse>>([]);
    const [isMessageError, setIsMessageError] = useState<boolean>(false);
    const [deleteModalOpened, setDeleteModalOpened] = useState<boolean>(false);

    const createTitle = () => {
        if (tariff && tariff.isDefault) {
            return "Default tariff";
        }
        if (tariff && !tariff.isDefault) {
            return "Tariff - " + tariff.id;
        }
        return "Tariff";
    }
    const deleteOnChange = () => {
        if (!id) {
            return;
        }
        archiveTariff(id).then(() => {
            navigate("/tariffs/active");
        }).catch((err) => {
            setIsMessageError(true);
            setDeleteModalOpened(false);
            setMessage("Error occurred while deleting tariff");
            setTimeout(() => {
                setMessage("");
            }, 3000)
        })
    }

    const delChange = () => {
        setDeleteModalOpened(true);
    }

    useEffect(() => {
        getVehicleModels().then((vehicleModelResponses: Array<VehicleModelResponse>) => {
            setVehicleModels(vehicleModelResponses);
            getTariff(id).then((tariff: TariffResponse) => {
                setTariff(tariff)
            });
        })

    }, []);
    return (
        <>
            {deleteModalOpened
                ?
                <ConfirmModal title={"Archive tariff?"} setModalOpened={setDeleteModalOpened}
                              handleConfirm={deleteOnChange}/>
                :
                <></>}
            <Message message={message} isError={isMessageError}/>
            <MainContainer>
                <TitleWrapper title={createTitle()}>
                    {tariff && !tariff.isDefault
                        ? <>
                            <ActionButton title={"Delete tariff"} isLink={false}
                                          onClickHandle={delChange}/>
                            <ActionButton title={"Edit"} isLink={true} url={"/tariff/edit/" + id}/>
                        </>
                        : <></>
                    }

                </TitleWrapper>
                <div className={styles.contentWrapper}>
                    <div className={styles.leftColumn}>
                        <DefaultPageContentContainer title={"Details"}>
                            <UserField title={"Status:"} field={createStatusStyle(styles, tariff.status)}/>
                            <UserField title={"ID:"} field={tariff.id}/>
                            <UserField title={"Name:"} field={tariff.name}/>
                            <UserField title={"Work period:"}
                                       field={formatDate(tariff.startDate) + "-" + formatDate(tariff.endDate)}/>
                            <UserField title={"Time interval:"} field={<span>{(!tariff.startTime && !tariff.endTime)
                                ? "All day"
                                : (formatTimeFromString(tariff.startTime) + "-" + formatTimeFromString(tariff.endTime))}
                                    </span>}/>
                            <UserField title={"Start price:"} field={tariff.startPrice}/>
                            <UserField title={"Price per minute:"} field={tariff.pricePerMinute + " EUR"}/>
                            <UserField title={"Pause price per minute:"} field={tariff.pausePricePerMinute + " EUR"}/>
                            <UserField title={"Vehicle model:"}
                                       field={<Link to={"/vehicle-models/" + tariff.vehicleModelId}>
                                           <span>{getVehicleModelsName(vehicleModels, tariff.vehicleModelId)}</span>
                                       </Link>}/>
                            <UserField title={"Day of weeks:"} field={
                                <span>{tariff.dayOfWeeks && tariff.dayOfWeeks.length > 0 ? tariff.dayOfWeeks.toString() : "Every day"}</span>}/>
                        </DefaultPageContentContainer>
                    </div>
                    <div className={styles.rightColumn}>


                    </div>
                </div>
            </MainContainer>
        </>
    )
}

const getVehicleModelsName = (vehicleModels: Array<VehicleModelResponse>, id: string) => {
    const filterVehicleModels = vehicleModels.filter(vm => vm.id === id);

    return filterVehicleModels.length === 0 ? "" : filterVehicleModels[0].name;
}

const createStatusStyle = (styles: any, status?: string): React.ReactNode => {
    if (!status) {
        return <span className={styles.tag + " " + styles.redTag}>Wrong status</span>
    }
    if (status.toLowerCase() === "active") {
        return <span className={styles.tag + " " + styles.greenTag}>Active</span>
    }
    if (status.toLowerCase() === "archive") {
        return <span className={styles.tag + " " + styles.yellowTag}>Archive</span>
    }

    return <span className={styles.tag + " " + styles.redTag}>Wrong status</span>
}