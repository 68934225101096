import * as React from 'react';
import messageStyles from "./MessageStyles";

export type MessageProperties = {
    message: string,
    isError: boolean,
}

export function Message(props: MessageProperties) {
    const styles = messageStyles();
    return (<>

        {
            props.message ?
                <div className={styles.message + " " + (props.isError ? styles.error : styles.ok)}>
                    <span>{props.message}</span>
                </div>
                : ""
        }
    </>)
}
