import {createUseStyles} from "react-jss";
import {MAIN_COLOR} from "../../../util/properties";

const photoPickerStyles = createUseStyles({
    link: {
        color: MAIN_COLOR
    },
    container: {
        borderRadius: "5px",
        borderStyle: "dashed",
        borderColor: "#333333",
        borderWidth: "1.2px",
        backgroundColor: "#f8f8f8",
        padding: "50px",
        color: "#3a3a3a",
        cursor: "pointer",
        "&:hover": {
            borderColor: MAIN_COLOR,
        }
    },
    name: {
        fontSize: "12px",
        marginTop: "15px",
    },
    imageClose: {
        cursor: "pointer",
        paddingTop: "5px",
        paddingRight: "5px",
        maxWidth: "15px",
    },
    closeButton: {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
    },
    attachedPhoto: {
        marginTop: "20px",
        borderRadius: "5px",
        width: "100px",
        height: "100px",
        borderColor: "#b7b7b7",
        borderWidth: "1px",
        borderStyle: "solid",
        backgroundColor: "#f8f8f8",
    }
})

export default photoPickerStyles;