import {useParams} from "react-router-dom";
import * as React from "react";
import {useEffect, useState} from "react";
import {Message} from "../../common/Message/Message";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import {ActionButton} from "../../common/ActionButton/ActionButton";
import {UserField} from "../../common/UserField/UserField";
import {formatDate} from "../../../util/dateUtils";
import {promoCodeStyles} from "./PromoCodeStyles";
import {getPromoCode, PromoCodeRs} from "../../../api/PromoCodeApi";
import {getUserFromLocalStorage, isAccessRoles, Role} from "../../../util/common";

export type PromoCodesProperties = {
    roles: Array<Role>
}

export function PromoCode(props: PromoCodesProperties) {
    const styles = promoCodeStyles();
    const user = getUserFromLocalStorage();
    const {id} = useParams();
    const {roles} = props;
    const [promoCode, setPromoCode] = useState<PromoCodeRs>();
    const [message, setMessage] = useState<string>("");
    const [isMessageError, setIsMessageError] = useState<boolean>(false);

    const writeAccess = isAccessRoles(user.roles, roles, "WRITE")

    useEffect(() => {
        getPromoCode(id || "")
            .then((result: PromoCodeRs) => {
                setPromoCode(result);
            })
            .catch(() => {
                setIsMessageError(true);
                setMessage("Error occurred while loading promo code");
                setTimeout(() => {
                    setMessage("")
                    setIsMessageError(false);
                })
            })

    }, [id]);
    return (
        <>
            <Message message={message} isError={isMessageError}/>
            <MainContainer>
                {
                    promoCode
                        ? <>
                            <TitleWrapper title={"Promo code " + promoCode.code}>
                                <ActionButton title={"Edit"} isLink={true} url={"/promo-code/edit/" + id}/>
                                {writeAccess && <ActionButton title={"Approve"} isLink={false}
                                                              onClickHandle={() => {console.log("APPROVED")}}/>}
                            </TitleWrapper>
                            <div className={styles.contentWrapper}>
                                <div className={styles.leftColumn}>
                                    <DefaultPageContentContainer title={"Details"}>
                                        <UserField title={"Code:"} field={promoCode.code}/>
                                        <UserField title={"Validity:"}
                                                   field={formatDate(promoCode.startDate) + " - " + formatDate(promoCode.endDate)}/>
                                        <UserField title={"Discount:"} field={"€ " + promoCode.discount.toFixed(2)}/>
                                        <UserField title={"User limit:"} field={promoCode.userLimit}/>
                                        <UserField title={"Active:"} field={promoCode.active ? "Yes" : "No"}/>
                                        <UserField title={"Approved:"} field={promoCode.active ? "Yes" : "No"}/>

                                    </DefaultPageContentContainer>
                                </div>
                                <div className={styles.rightColumn}>
                                    <DefaultPageContentContainer title={"Preview"}>
                                        <div className={styles.photoPreviewContainer}>
                                            <img src={`data:image/jpeg;base64,${promoCode.photo}`} alt=""/>
                                        </div>
                                    </DefaultPageContentContainer>
                                </div>
                            </div>
                        </>
                        : <img src={"/loading.gif"} alt={""}/>
                }

            </MainContainer>
        </>
    )
}