import * as React from 'react'
import {useEffect, useState} from 'react'
import {TitleWrapper} from "../common/TitleWrapper/TitleWrapper";
import {MainContainer} from "../common/MainContainer/MainContainer";
import {Link} from "react-router-dom";
import {ActionButton} from "../common/ActionButton/ActionButton";
import tasksStyles from "./TasksStyles";
import {getTasks, TaskShortResponse} from "../../api/TaskApi";
import {getUserFromLocalStorage, isAccessRoles, PageResponse, Role} from "../../util/common";
import {DefaultPageContentContainer} from "../common/TableContainer/DefaultPageContentContainer";
import {PagingSelector} from "../common/PagingSelector/PagingSelector";
import {formatDateTime} from "../../util/dateUtils";
import {SortField} from "../common/SortButton/MultipleSortButton";
import {SelectFilter} from "../common/SelectFilter/SelectFilter";
import {getFullName, getAdmins, UserAdminRs} from "../../api/UserApi";
import {createSortString, SortButton} from "../common/SortButton/SortButton";
import {LoadingBar} from "../common/LoadingBar/LoadingBar";
import {Tag} from "../common/Tags/Tag";

export type TasksProperties = {
    roles: Array<Role>
}

export function Tasks(props: TasksProperties) {
    const styles = tasksStyles();
    const {roles} = props;

    const [taskPage, setTasksPage] = useState<PageResponse<TaskShortResponse> | undefined>(undefined);
    const [myTaskPage, setMyTasksPage] = useState<PageResponse<TaskShortResponse> | undefined>(undefined);
    const [tasksLoaded, setTasksLoaded] = useState<boolean>(false);
    const [myTaskLoaded, setMyTaskLoaded] = useState<boolean>(false);

    const [filterStatus, setFilterStatus] = useState<string>("");
    const [filterMyStatus, setFilterMyStatus] = useState<string>("");
    const [filterAssigned, setFilterAssigned] = useState<string>("");
    const [workgroup, setWorkgroup] = useState<Array<UserAdminRs>>();

    const [page, setPage] = useState<number>(0);
    const [pageAssigned, setPageAssigned] = useState<number>(0);
    const [sortField, setSortField] = useState<SortField>();
    const [sortFieldAssigned, setSortFieldAssigned] = useState<SortField>();

    const user = getUserFromLocalStorage();

    const writeAccess = isAccessRoles(user.roles, roles, "WRITE");

    const mainTitle = "Tasks - " + (taskPage ? taskPage.totalElements.toString() : "");

    const createDataHeader = (): React.ReactElement => {
        return <>
            <thead>
            <tr className={styles.headerRow}>
                <th className="">
                    <SortButton title={"Updated"} sortField={sortField} setSortField={setSortField}
                                fieldNameTarget={"changedAt"}/>
                </th>
                <th className="">
                    <SortButton title={"Created at"} sortField={sortField} setSortField={setSortField}
                                fieldNameTarget={"createdAt"}/>
                </th>
                <th className="">
                    <SortButton title={"Title"} sortField={sortField} setSortField={setSortField}
                                fieldNameTarget={"title"}/>
                </th>
                <th className="">Subject</th>
                <th className="">Assigned at</th>
                <th className="">Status</th>
                <th></th>
                <th></th>
            </tr>
            </thead>
        </>
    }

    const createDataRow = (task: TaskShortResponse): React.ReactElement => {

        return <>
            <tr className={styles.dataRow}>
                <th className="">{formatDateTime(task.changedAt)}</th>
                <th className="">{formatDateTime(task.createdAt)}</th>
                <th className="">{task.title}</th>
                <th className="">{createSubjectTitle(task)}</th>
                <th className="">{createAssignedAtLabel(task)}</th>
                <th className=""><Tag color={"green"} text={task.status}/></th>
                <th className={styles.iconContainer}>
                    <Link to={"/task/" + task.id}><img className={styles.icon} src="/eye.svg" alt=""/></Link>
                    <Link to={"/task/edit/" + task.id}><img className={styles.icon} src="/edit.svg" alt=""/></Link>
                </th>
            </tr>

        </>
    }

    useEffect(() => {
        getAdmins(0).then((workgroup: PageResponse<UserAdminRs>) => {
            setWorkgroup(workgroup.content);
        });
        getTasks(pageAssigned, user.id, filterMyStatus, createSortString(sortFieldAssigned))
            .then((result: PageResponse<TaskShortResponse>) => {
                setMyTasksPage(result);
                setMyTaskLoaded(true);
            })
        getTasks(page, filterAssigned, filterStatus, createSortString(sortField))
            .then((result: PageResponse<TaskShortResponse>) => {
                setTasksPage(result);
                setTasksLoaded(true);
            })
    }, [page, filterAssigned, pageAssigned, sortField, filterStatus, filterMyStatus, sortFieldAssigned, user.id]);

    return (
        <MainContainer>
            <TitleWrapper title={mainTitle}>
                {writeAccess && <ActionButton title={"New task"} isLink={true} url={"/task/edit/new"}/>}
            </TitleWrapper>
            {myTaskLoaded ?
                <DefaultPageContentContainer title={"Assigned to me"}
                                             pageContent={myTaskPage}
                                             headerContent={
                                                 <div className={styles.filtersContainer}>
                                                     <div className={styles.selectWrapper}>
                                                         <SelectFilter title={"Status:"} value={filterMyStatus}
                                                                       setValue={setFilterMyStatus}
                                                                       options={[{label: "ALL", value: ""}, {
                                                                           label: "Active",
                                                                           value: "ACTIVE"
                                                                       }, {
                                                                           label: "Closed",
                                                                           value: "CLOSED"
                                                                       }]}/>
                                                     </div>
                                                 </div>

                                             }>
                    <table className={styles.dataTable}>
                        {createDataHeader()}
                        <tbody>
                        {myTaskPage && myTaskPage.content && myTaskPage.content.length > 0
                            ? myTaskPage.content.map((task) => createDataRow(task))
                            : <tr className={styles.noDataBlock}>
                                <th>No tasks found</th>
                            </tr>}
                        </tbody>
                    </table>
                    {
                        myTaskPage && myTaskPage.totalPages > 1
                            ? <PagingSelector page={pageAssigned} pageNumber={myTaskPage.totalPages}
                                              setPage={setPageAssigned}/>
                            : <></>
                    }
                </DefaultPageContentContainer>
                : <LoadingBar/>
            }
            {writeAccess && <>
                {tasksLoaded ?
                    <DefaultPageContentContainer title={"Tasks"}
                                                 pageContent={taskPage}
                                                 headerContent={
                                                     <div className={styles.filtersContainer}>
                                                         <SelectFilter title={"Status:"} value={filterStatus}
                                                                       setValue={setFilterStatus}
                                                                       options={[{label: "ALL", value: ""}, {
                                                                           label: "Active",
                                                                           value: "ACTIVE"
                                                                       },
                                                                           {
                                                                               label: "Closed",
                                                                               value: "CLOSED"
                                                                           }]}/>

                                                         {workgroup &&
                                                             <SelectFilter title={"Assignee:"} value={filterAssigned}
                                                                           setValue={setFilterAssigned}
                                                                           options={[{label: "All", value: ""},
                                                                               ...workgroup.map(x => {
                                                                                   return {
                                                                                       label: getFullName(x),
                                                                                       value: x.id
                                                                                   }
                                                                               })]}/>

                                                         }
                                                     </div>

                                                 }>
                        <table className={styles.dataTable}>
                            {createDataHeader()}
                            <tbody>
                            {taskPage && taskPage.content && taskPage.content.length > 0
                                ? taskPage.content.map((task) => createDataRow(task))
                                : <tr className={styles.noDataBlock}>
                                    <th>No tasks found</th>
                                </tr>}
                            </tbody>
                        </table>
                        {
                            taskPage && taskPage.totalPages > 1
                                ? <PagingSelector page={page} pageNumber={taskPage.totalPages} setPage={setPage}/>
                                : <></>
                        }
                    </DefaultPageContentContainer>
                    : <LoadingBar/>
                }
            </>
            }
        </MainContainer>
    )
}

const createSubjectTitle = (task: TaskShortResponse) => {
    if (task.vehicle) {
        return <>Vehicle (QR code): <Link to={"/vehicle/" + task.vehicle.id}>{task.vehicle.qrCode}</Link></>
    }
    if (task.alarmId) {
        return <>Alarm: <Link to={"/alarm/" + task.alarmId}>{task.alarmId}</Link></>
    }
    return ""
}

const createAssignedAtLabel = (task: TaskShortResponse) => {
    return task.assigned ? getFullName(task.assigned) : "";
}