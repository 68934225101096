import {createDefaultHeaders, createUrlParameters,handleResponseNoJson, fetchNoResponse, fetchWithResponse, getAccessTokenFromLocalStorage} from "../util/common";
import {HOST} from "../util/properties";

export const getReport = (startDate?: any, endDate?: any): Promise<any> => {
    const userToken = getAccessTokenFromLocalStorage();

    const urlParams = createUrlParameters({
        "startDate": startDate,
        "endDate": endDate
    })

    console.log("ASF")
    return fetchNoResponse(HOST + "/api/reports" + urlParams, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + userToken,
        }
    })
    .then((response) => {
        console.log(response)
        return response.blob()
    }).then((blob) => {
        console.log(blob)
        const fileURL = URL.createObjectURL(new Blob([blob], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
        window.open(fileURL);
        //Страшнейшая костылина с https://medium.com/yellowcode/download-api-files-with-react-fetch-393e4dae0d9e
        //не верю что нельзя по человечески, но пока так
        /*const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.setAttribute("target", "_blank");
        link.href = url;
        link.setAttribute('download', "резюме.pdf");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);*/
    })
    .catch((error) => {
        console.log(error);
    });
};