import * as React from 'react';
import actionButtonStyles from "./ActionButtonStyles";
import {Link} from "react-router-dom";
import {MouseEventHandler} from "react";

export type ActionButtonProperties = {
    title: any,
    isLink?: boolean,
    url?: any,
    onClickHandle?: MouseEventHandler
}

export function ActionButton(props: ActionButtonProperties): React.ReactElement {
    const styles = actionButtonStyles();
    return (<>
        {props.isLink ?
            <Link to={props.url} className={styles.actionButton}>
                <span>{props.title}</span>
            </Link>
            :
            <div onClick={props.onClickHandle} className={styles.actionButton}>
                <span>{props.title}</span>
            </div>
        }
    </>)
}
