
import {createUseStyles} from "react-jss";

export const iconsStyles = createUseStyles({
    icon: {
        minWidth: "15px",
        maxWidth: "15px",
    },
    iconBorder: {
        cursor: "pointer",
        boxSizing: "border-box",
        padding: "5px",
        marginRight: "3px",
        border: "1px solid #000000",
        borderRadius: "5px",
        opacity: "0.8",
        "&:hover": {
            borderColor: "rgb(251, 143,135)",
        }
    },
    iconContainer: {
        display: "flex",
        justifyContent: "center",
        "& a": {
            boxSizing: "border-box",
            padding: "5px",
            marginRight: "3px",
            border: "1px solid #000000",
            borderRadius: "5px",
            opacity: "0.8"
        },
        "& a:hover": {
            borderColor: "rgb(251, 143,135)",
        }
    },
    '@media (max-width:1000px)': {
        iconContainer: {
            display: "flex",
            justifyContent: "space-between",
        }
    }
});