import {createUseStyles} from "react-jss";

const avatarCircleStyles = createUseStyles({
    avatarCircle: {
        borderRadius: "50%",
        display: "inlineFlex",
        justifyContent: "center",
        alignItems: "center",
        flexShrink: "0",
        width: "1em",
        height: "1em",
        lineHeight: "1em",
        overflow: "hidden",
        textAlign: "center",
        fontSize: "2.2em",
        verticalAlign: "middle",
    },
    textAvatar: {
        fontSize: "15px",
        color: "#ffffff"
    },
})

export default avatarCircleStyles;