import React, {ReactElement, useEffect, useReducer, useState} from 'react';
import {commentsStyles} from "./CommentsStyles";
import {addComment, CommentRq, CommentRs, deleteComment, getComments, updateComment} from "../../../../api/TaskApi";
import {AvatarCircle} from "../../../common/AvatarCircle/AvatarCircle";
import {Button} from "../../../common/Button/Button";
import {getUserFromLocalStorage} from "../../../../util/common";
import {Icons} from "../../../common/Icons/Icons";
import {ConfirmModal} from "../../../common/ConfirmModal/ConfirmModal";
import {setCaretAtEnd} from "../../../../util/formUtils";
import {getDurationText} from "../../../../util/dateUtils";

export interface AvatarCircleProperties {
    taskId: string
}

interface TextAreaProps {
    value: any,
    onChange: any
}

type State = {
    editedComments: Array<CommentState>
}

type CommentState = {
    id: string,
    comment: string
}

type CommentAction = {
    type: string,
    id: string,
    comment: string
}

const initialState: State = {editedComments: []}

const reducer = (state = initialState, action: CommentAction) => {
    switch (action.type) {
        case 'ADD':
            return {
                editedComments:
                    state.editedComments.some((element) => element.id === action.id)
                        ? state.editedComments
                        : [...state.editedComments, {id: action.id, comment: action.comment}]
            };
        case 'CHANGE':
            return {
                editedComments: state.editedComments.map(comment => {
                    if (comment.id === action.id) {
                        comment.comment = action.comment;
                    }
                    return comment;
                })
            };
        case 'REMOVE':
            return {editedComments: state.editedComments.filter(comment => action.id !== comment.id)};
        default:
            throw new Error();
    }
}

export const Comments = (props: AvatarCircleProperties): ReactElement => {
    const {taskId} = props;
    const styles = commentsStyles();
    const [comments, setComments] = useState<Array<CommentRs>>([]);
    const [newComment, setNewComment] = useState<string>("");
    const [isFocused, setIsFocused] = useState<boolean>();
    const [commentIdToDelete, setCommentIdToDelete] = useState<string>("");

    const [editCommentsState, dispatch] = useReducer<React.Reducer<State, CommentAction>>(reducer, initialState)
    const user = getUserFromLocalStorage();

    useEffect(() => {
        getComments(taskId).then((result: Array<CommentRs>) => {
            setComments(result);
        })
    }, []);

    const handleDeleteComment = (commentId: string) => {
        deleteComment(commentId).then(() => {
            setComments(comments.filter(comment => comment.id !== commentId))
        })
    }

    const handleUpdateComment = (commentId: string, values: CommentRq) => {
        updateComment(commentId, values).then(() => {
            setComments(comments.map(comment => {
                if (comment.id === commentId) {
                    comment.comment = values.comment;
                }
                return comment;
            }))
            dispatch({id: commentId, comment: "", type: "REMOVE"})
        })
    }

    const handleSubmit = () => {
        addComment(taskId, {comment: newComment})
            .then((result: string) => {
                setComments([...comments, {
                    id: result,
                    comment: newComment,
                    author: {
                        id: user.id,
                        firstName: user.firstName,
                        lastName: user.lastName,
                    },
                    createdAt: new Date(),
                    changedAt: new Date()
                }])
                setNewComment("")
            })
    }

    const getEditedComment = (id: string) => {
        const foundComment = editCommentsState.editedComments.find((elem) => elem.id === id);
        if (foundComment) {
            return foundComment.comment;
        }
        return "";
    }

    return (
        <>
            {commentIdToDelete
                ?
                <ConfirmModal title={"Delete comment?"}
                              setModalOpened={() => setCommentIdToDelete("")}
                              handleConfirm={() => {
                                  handleDeleteComment(commentIdToDelete);
                                  setCommentIdToDelete("");
                              }}/>
                :
                <></>}
            <div className={styles.commentContainer}>
                {
                    comments
                        ? comments.length > 0
                            ? comments.map((comment) =>
                                <div key={comment.id} className={styles.comment}>
                                    <div className={styles.commentContent}>
                                        <AvatarCircle user={comment.author}/>
                                        <div className={styles.commentTextContent}>
                                            <div
                                                className={styles.commentAboutInfo}>{comment.author.lastName} {comment.author.firstName} Posted {getDurationText(comment.changedAt, new Date())} ago
                                            </div>
                                            {
                                                editCommentsState.editedComments.some((element) => element.id === comment.id)
                                                    ? <>
                                                    <textarea  autoFocus={true}
                                                        onFocus={(e) => {
                                                            setCaretAtEnd(e)
                                                            setIsFocused(true)
                                                        }}
                                                              onBlur={() => setIsFocused(false)}
                                                              placeholder={"Write your comment..."}
                                                              className={styles.editCommentInput}
                                                              value={getEditedComment(comment.id)} onChange={(e: any) => dispatch({
                                                        id: comment.id,
                                                        comment: e.target.value,
                                                        type: "CHANGE"
                                                    })}/>


                                                        <div className={styles.buttonContainer}>
                                                            <Button title={"Add comment"}
                                                                    onClick={() => handleUpdateComment(comment.id,
                                                                        {
                                                                            comment: getEditedComment(comment.id)
                                                                        })}/>
                                                            <Button title={"Cancel edit"} onClick={() => dispatch({
                                                                id: comment.id,
                                                                comment: "",
                                                                type: "REMOVE"
                                                            })}/>
                                                        </div>

                                                    </>
                                                    : <div className={styles.commentText}>{comment.comment}</div>
                                            }

                                        </div>

                                    </div>
                                    {
                                        user.id === comment.author.id
                                            ? <div className={styles.iconsContainer}>
                                                <Icons isTable={false} links={
                                                    [
                                                        {
                                                            onClick: () => setCommentIdToDelete(comment.id),
                                                            img: "delete"
                                                        },
                                                        {
                                                            onClick: () => dispatch({
                                                                id: comment.id,
                                                                comment: comment.comment,
                                                                type: "ADD"
                                                            }),
                                                            img: "edit"
                                                        }
                                                    ]
                                                }/>
                                            </div>
                                            : <></>
                                    }
                                </div>
                            )
                            : <span>No comments</span>
                        : <img src={"/loading.gif"} alt={""}/>
                }
            </div>

            <form className={styles.formContainer} onSubmit={handleSubmit}>
                <div className={styles.inputsContainer + (isFocused ? (" " + styles.focused) : "")}>
                     <textarea onFocus={() => setIsFocused(true)}
                               onBlur={() => setIsFocused(false)}
                               placeholder={"Write your comment..."}
                               className={styles.newCommentInput}
                               value={newComment} onChange={(e: any) => setNewComment(e.target.value)}/>
                </div>

                <Button title={"Add comment"} onClick={handleSubmit}/>
            </form>

        </>
    )
}


