import * as React from 'react'
import tableContainerStyles from "./TableContainerStyles";
import {PageResponse} from "../../../util/common";
import {VehicleShortResponse} from "../../../api/VehicleApi";
import {PagingSelector} from "../PagingSelector/PagingSelector";

type Props<T> = {
    children?: JSX.Element | JSX.Element[],
    title?: any,
    disableOverflow?: boolean,
    pageContent?: PageResponse<T>,
    pageNumber?: number,
    totalPages?: number,
    setPage?: any
    headerContent?: any
};

export const DefaultPageContentContainer: React.FC<Props<any>> = ({
                                                                      children,
                                                                      title,
                                                                      disableOverflow,
                                                                      pageContent,
                                                                      pageNumber,
                                                                      totalPages,
                                                                      setPage,
                                                                      headerContent
                                                                  }) => {
    const styles = tableContainerStyles();

    return (
        <div className={styles.contentContainer} style={disableOverflow ? {} : {overflow: "auto",}}>
            {title
                ? <div className={styles.contentHeaderContainer}>
                    <div className={styles.contentHeader}>
                        <h2>{title}{pageContent ? (" - " + pageContent.content.length) : ""}</h2>
                        <div className={styles.headerContent}>{headerContent}</div>
                    </div>
                </div>
                : ""
            }

            {children}
            {
                totalPages && totalPages > 1 && setPage
                    ? <PagingSelector page={pageNumber || 0} pageNumber={totalPages} setPage={setPage}/>
                    : <></>
            }
        </div>
    )
}