import React, {ReactElement, useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {TitleWrapper} from "../common/TitleWrapper/TitleWrapper";
import {ActionButton} from "../common/ActionButton/ActionButton";
import {MainContainer} from "../common/MainContainer/MainContainer";
import vehicleModelsStyles from "./VehicleModelsStyles";
import {getVehicleModels, VehicleModelResponse} from "../../api/VehicleModelApi";
import {DefaultListContentContainer} from "../common/TableContainer/DefaultListContentContainer";
import {LoadingBar} from "../common/LoadingBar/LoadingBar";
import {getUserFromLocalStorage, isAccessRoles, Role} from "../../util/common";

export type VehicleModelsProperties = {
    roles: Array<Role>
}
const VehicleModels = (props: VehicleModelsProperties): ReactElement => {
    const {roles} = props;
    const styles = vehicleModelsStyles();
    const navigate = useNavigate();
    const user = getUserFromLocalStorage();
    const [vehicleModels, setVehicleModels] = useState<Array<VehicleModelResponse> | undefined>(undefined);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    useEffect(() => {
        getVehicleModels().then((result: Array<VehicleModelResponse>) => {
            setVehicleModels(result);
            setDataLoaded(true);
        })
    }, []);

    const createDataHeader = (): React.ReactElement => {
        return <>
            <thead>
            <tr className={styles.headerRow}>
                <th className="">
                    ID
                </th>
                <th className="">Name</th>
                <th></th>
                <th></th>
            </tr>
            </thead>
        </>
    }

    const createDataRow = (vehicle: VehicleModelResponse): React.ReactElement => {

        return <>
            <tr className={styles.dataRow}>
                <th className=""><a href={"/vehicle-models/" + vehicle.id}>{vehicle.id}</a></th>
                <th className="">{vehicle.name}</th>

                <th className={styles.iconContainer}>
                    <Link to={"/vehicle-models/" + vehicle.id}><img className={styles.icon} src="/eye.svg" alt=""/></Link>
                    {isWriteAvailable && <Link to={"/vehicle-models/edit/" + vehicle.id}><img className={styles.icon} src="/edit.svg" alt=""/></Link>}
                </th>
            </tr>
        </>
    }

    const isWriteAvailable = isAccessRoles(user.roles, roles, "WRITE")
    return (
        <MainContainer>
            <TitleWrapper title={"Vehicle models - " + (vehicleModels ? vehicleModels.length : "")}>
                {isWriteAvailable && <ActionButton title={"New Vehicle Model"} url={"/vehicle-models/edit/new"} isLink={true}/>}
            </TitleWrapper>
            {dataLoaded ?
                <DefaultListContentContainer title={"Vehicles"} list={vehicleModels}>
                    <table className={styles.dataTable}>
                        {createDataHeader()}
                        <tbody>
                        {vehicleModels && vehicleModels.length > 0
                            ? vehicleModels.map((vehicle) => createDataRow(vehicle))
                            : <tr className={styles.noDataBlock}><th>No vehicle models found</th></tr>}
                        </tbody>
                    </table>


                </DefaultListContentContainer>
                : <LoadingBar/>
            }
        </MainContainer>
    );
};





export default VehicleModels;
