import {createUseStyles} from "react-jss";

const tableContainerStyles = createUseStyles({
    contentContainer: {
        backgroundColor: "#FDFDFD",
        boxShadow: '0 0 10px rgba(150,154,213, 0.5)',
        boxSizing: 'border-box',
        borderRadius: '10px',
        marginBottom: "40px",
        paddingBottom: "15px"
    },
    contentHeaderContainer: {
        display: "flex",
        justifyContent: "flex-start",
        borderBottom: "2px solid #EEF0F1",
        boxSizing: "border-box",
    },
    card: {
        width: "20vw",
        marginRight: "2vw",
        marginTop: "2vw",
        padding: "25px",
        background: "#ffffff",
        boxShadow: "0 0 10px rgba(150,154,213, 0.5)",
        boxSizing: "border-box",
        borderRadius: "5px",
        "& a": {
            color: "#4a4a4b",
            textDecoration: "none",
        }
    },
    cardContainer: {
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        color: "#4a4a4b",
        "& a": {
            color: "#1d74d5",
            textDecoration: "none",
        }
    },
    cardTitleContainer: {
        textAlign: "left",
        fontSize: "25px"
    },
    contentHeader: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "12px",
        color: "rgb(96 105 118)",
        margin: "15px",
        fontWeight: "600",
    },
    headerContent: {
       // paddingTop: "15px",
        paddingLeft: "30px"
    },
});

export default tableContainerStyles;