export const formatIsoDate = (date?: any): string => {
    if (!date) {
        return "";
    }
    if (typeof date === "string") {
        date = new Date(date);
    }
    const month = date.getMonth() < 9 ? ("0" + (date.getMonth() + 1)) : (date.getMonth() + 1);
    const day = date.getDate() < 10 ? ("0" + date.getDate()) : date.getDate();
    return date.getFullYear() + "-" + month + "-" + day;
}

export const formatMonth = (date?: any): string => {
    if (!date) {
        return "";
    }
    if (typeof date === "string") {
        date = new Date(date);
    }
    const month = date.getMonth() < 9 ? ("0" + (date.getMonth() + 1)) : (date.getMonth() + 1);
    return date.getFullYear() + "-" + month;
}

export const formatDate = (date?: any): string => {
    if (!date) {
        return "";
    }
    if (typeof date === "string") {
        date = new Date(date);
    }
    const month = date.getMonth() < 9 ? ("0" + (date.getMonth() + 1)) : (date.getMonth() + 1);
    const day = date.getDate() < 10 ? ("0" + date.getDate()) : date.getDate();
    return day + "." + month + "." + date.getFullYear();
}

export const formatDateTime = (date?: any): string => {
    if (!date) {
        return "";
    }
    if (typeof date === "string") {
        date = new Date(date);
    }
    const month = date.getMonth() < 9 ? ("0" + (date.getMonth() + 1)) : (date.getMonth() + 1);
    const day = date.getDate() < 10 ? ("0" + date.getDate()) : date.getDate();
    const hours = date.getHours() < 10 ? ("0" + date.getHours()) : date.getHours();
    const minutes = date.getMinutes() < 10 ? ("0" + date.getMinutes()) : date.getMinutes();
    return day + "." + month + "." + date.getFullYear() + " " + hours + ":" + minutes;
}

export const formatTime = (date?: any): string => {
    if (!date) {
        return "";
    }
    if (typeof date === "string") {
        date = new Date(date);
    }

    const hours = date.getHours() < 10 ? ("0" + date.getHours()) : date.getHours();
    const minutes = date.getMinutes() < 10 ? ("0" + date.getMinutes()) : date.getMinutes();
    return hours + ":" + minutes;
}

export const formatTimeFromString = (time?: any): string => {
    if (!time) {
        return "";
    }

    const timeParts = time.split(":");
    if (timeParts < 2) {
        return "";
    }
    return timeParts[0] + ":" + timeParts[1];
}

export const formatDurationFromSeconds = (end: any): string => {

    const firstDate = new Date("1970-01-01T00:00:00.000Z");
    if (typeof end === "string") {
        end = new Date(parseInt(end) * 1000);
    }
    if (typeof end === "number") {
        end = new Date(end);
    }

    // @ts-ignore
    const duration = new Date(end - firstDate);

    const hours = duration.getUTCHours() < 10 ? ("0" + (duration.getUTCHours())) : (duration.getUTCHours());
    const minutes = duration.getUTCMinutes() < 10 ? ("0" + duration.getUTCMinutes()) : duration.getUTCMinutes();
    return hours + ":" + minutes;
}

export const calcDuration = (start?: any, end?: any): string => {
    if (!start || !end) {
        return "";
    }
    if (typeof start === "string") {
        start = new Date(start);
    }
    if (typeof end === "string") {
        end = new Date(end);
    }
    const duration = new Date(end - start);

    const hours = duration.getUTCHours() <= 10 ? ("0" + (duration.getUTCHours())) : (duration.getUTCHours());
    const minutes = duration.getUTCMinutes() <= 10 ? ("0" + duration.getUTCMinutes()) : duration.getUTCMinutes();
    const seconds = duration.getUTCSeconds() <= 10 ? ("0" + duration.getUTCSeconds()) : duration.getUTCSeconds();
    return hours + ":" + minutes + ":" + seconds;
}

export const getDurationText = (start?: any, end?: any): string => {
    if (!start || !end) {
        return "";
    }
    if (typeof start === "string") {
        start = new Date(start);
    }
    if (typeof end === "string") {
        end = new Date(end);
    }
    const duration = new Date(end - start);

    if (duration.getUTCFullYear() - 1970 > 0) {
        return duration.getUTCFullYear() + " years";
    }
    if (duration.getUTCMonth() > 0) {
        return duration.getUTCFullYear() + " months";
    }
    if (duration.getUTCDate() > 1) {
        return duration.getUTCDate() + " days";
    }
    if (duration.getUTCHours() > 0) {
        return duration.getUTCHours() + " hours";
    }
    if (duration.getUTCMinutes() > 0) {
        return duration.getUTCMinutes() + " minutes";
    }
    if (duration.getUTCSeconds() > 0) {
        return duration.getUTCSeconds() + " seconds";
    }
    return "";
}

export const calcMinutesBetweenDates = (start?: any, end?: any): number => {
    if (!start || !end) {
        return 0;
    }
    if (typeof start === "string") {
        start = new Date(start);
    }
    if (typeof end === "string") {
        end = new Date(end);
    }

    let diffBetween = end.getTime() - start.getTime();

    return diffBetween / (1000 * 60);
}

export const subtractMonths = (numOfMonths: any, date = new Date()): Date => {
    date.setMonth(date.getMonth() - numOfMonths);

    return date;
}

export const formatDurationFromMillis = (value: number):number => {
    return value ? value / 1000 : 0;
}

export const getHoursFromSeconds = (seconds: number): number => {
    return seconds ? Math.floor(seconds / 3_600) : 0;
}

export const getMinutesExceptHoursFromSeconds = (seconds: number): number => {
    return seconds ? Math.floor( seconds % 3_600 / 60) : 0;

}