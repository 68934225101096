import * as React from 'react';
import confirmModalStyles from "./ConfirmModalStyles";
import {Modal} from "../Modal/Modal";

export type ConfirmModalProperties = {
    title: string,
    setModalOpened: any,
    handleConfirm: any
}

export function ConfirmModal(props: ConfirmModalProperties): React.ReactElement {
    const styles = confirmModalStyles();
    return (<>
        <Modal setModalHidden={props.setModalOpened}>
            <div className={styles.modalText}>{props.title}</div>
            <div className={styles.modalButtonContainer}>
                <div className={styles.modalButtonCancel} onClick={() => props.setModalOpened(false)}>Cancel</div>
                <div className={styles.modalButtonConfirm} onClick={props.handleConfirm}>Confirm</div>
            </div>

        </Modal>
    </>)
}
