import {createUseStyles} from "react-jss";

const editPromoCodeStyles = createUseStyles({
    contentWrapper: {
        width: "50%",
    },
    mainWrapper: {
        display: "flex",
        justifyContent: "center"
    },
    '@media (max-width:768px)': {
        contentWrapper: {
            width: "100%"
        },
    },
});

export default editPromoCodeStyles;