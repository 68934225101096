import * as React from 'react'
import tableContainerStyles from "./TableContainerStyles";
import {PageResponse} from "../../../util/common";
import {PagingSelector} from "../PagingSelector/PagingSelector";

type Props<T> = {
    children?: JSX.Element | JSX.Element[],
    title?: any,
    disableOverflow?: boolean,
    pageContent?: PageResponse<T>,
    pageNumber?: number,
    totalPages?: number,
    setPage?: any
    headerContent?: any
};

export const CardPageContainer: React.FC<Props<any>> = ({
                                                            children,
                                                            title,
                                                            disableOverflow,
                                                            pageContent,
                                                            pageNumber,
                                                            totalPages,
                                                            setPage,
                                                            headerContent
                                                        }) => {
    const styles = tableContainerStyles();

    return (
        <>
            {title
                ? <div className={styles.cardTitleContainer}>
                    {title}
                </div>
                : ""
            }
            <div className={styles.cardContainer} style={disableOverflow ? {} : {overflow: "auto",}}>
                {children}
                {
                    totalPages && totalPages > 1 && setPage
                        ? <PagingSelector page={pageNumber || 0} pageNumber={totalPages} setPage={setPage}/>
                        : <></>
                }
            </div>
        </>

    )
}