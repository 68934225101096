import {createUseStyles} from "react-jss";

const titleWrapperStyles = createUseStyles({
    actionButtonWrapper: {
      display: "flex",
      justifyContent: "space-around",
        "& div": {
          marginLeft: "5px",
          marginRight: "5px",
        }
    },
    titleBarContainer: {
        alignItems: "center",
        marginTop: "35px",
        marginBottom: "35px",
        display: "flex",
        justifyContent: "space-between",
    },
    titleBar: {
        '& h2': {
            fontSize: "30px",
            fontWeight: "normal"
        }
    },
    '@media (max-width:768px)': {
        titleBarContainer: {
            display: "block"
        },
        titleBar: {
            textAlign: "left",
        },
        actionButtonWrapper: {
            flexWrap: "wrap",
            display: "flex",
            justifyContent: "flex-start",
        },
    },
});

export default titleWrapperStyles;