import React, {ReactElement, useEffect, useState} from 'react';
import headerStyles from "./HeaderStyles";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
    clearLocalStorage,
    getLocationId,
    getLocationName,
    getUserFromLocalStorage,
    isAccess,
    saveLocation
} from "../../util/common";
import {getActiveAlarmCount} from "../../api/AlarmApi";
import {getLocations, LocationRs} from "../../api/LocationApi";
import {AvatarCircle} from "../common/AvatarCircle/AvatarCircle";
import {getCountAssigned} from "../../api/TaskApi";

export type HeaderProps = {
    isSticky: boolean,
}

const Header = (props: HeaderProps): ReactElement => {
    const {isSticky} = props;
    const styles = headerStyles();
    const user = getUserFromLocalStorage();
    const locationName = getLocationName();
    const locationId = getLocationId();
    const navigate = useNavigate();

    const [alarms, setAlarms] = useState<number>(0);
    const [assigned, setAssigned] = useState<number>(0);
    //These states are used in mobile menu
    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
    const [active, setActive] = useState<string>("");

    const [locationOpened, setLocationOpened] = useState<boolean>(false);
    const [locations, setLocations] = useState<Array<LocationRs>>([]);

    //Storage needed to re-render header after changing location to close mobile menu, if it opened
    const location = useLocation();

    useEffect(() => {
        getActiveAlarmCount(locationId).then((result: number) => {
            setAlarms(result);
        })
        getCountAssigned().then((result: number) => {
            setAssigned(result);
        })
        getLocations().then((result: Array<LocationRs>) => {
            setLocations(result);
        })
        setIsMenuOpened(false);
    }, [location, locationId]);

    return (
        <nav className={styles.navigation + (isSticky ? (" " + styles.sticky) : "")}>
            <div className={styles.menuButton} onClick={() => setIsMenuOpened(!isMenuOpened)}>
                <img src="/ham.svg" alt=""/>
            </div>
            <div className={styles.logoContainerMobile}>
                <img src="/someLogo.svg" alt=""/>
            </div>
            <div className={styles.menuContainer} style={isMenuOpened ? {transform: 'translateX(0%)'} : {}}>
                <div className={styles.logoContainer}>
                    <img src="/someLogo.svg" alt=""/>
                </div>

                <MenuElement userRoles={user.roles}
                             availableRoles={["ADMIN", "SUPERVISOR", "OPERATOR"]}
                             url={"/tasks"}
                             title={<>Tasks {assigned ? <span className={styles.counter}>{assigned}</span> : ""}</>}
                             styles={styles}
                />

                <MenuElement
                    userRoles={user.roles}
                    availableRoles={["ADMIN", "SUPERVISOR", "OPERATOR"]}
                    url={"/alarms"}
                    title={<>Alarms {alarms ? <span className={styles.counter}>{alarms}</span> : ""}</>}
                    styles={styles}/>
                <MenuElement userRoles={user.roles}
                             availableRoles={["ADMIN", "SUPERVISOR"]}
                             url={"/users"}
                             title={"Users"} styles={styles}/>
                <MenuElement userRoles={user.roles}
                             availableRoles={["ADMIN"]}
                             url={"/delete-user-request"} title={"Delete requests"} styles={styles}/>
                <DropDownMenuElement userRoles={user.roles}
                                     availableRoles={["ADMIN", "SUPERVISOR", "OPERATOR"]}
                                     url={"vehicles"}
                                     title={"Vehicles"}
                                     isMenuOpened={isMenuOpened}
                                     active={active}
                                     setActive={setActive}
                                     links={[{url: "", title: "All"}, {url: "active", title: "Available"}]}
                                     styles={styles}/>
                <DropDownMenuElement userRoles={user.roles}
                                     availableRoles={["ADMIN", "SUPERVISOR"]}
                                     url={"trips"}
                                     title={"Trips"}
                                     isMenuOpened={isMenuOpened}
                                     active={active}
                                     setActive={setActive}
                                     links={[
                                         {url: "", title: "All"},
                                         {url: "active", title: "Available"},
                                         {url: "paused", title: "Paused"},
                                         {url: "finished", title: "Finished"},
                                         {url: "cancelled", title: "Cancelled"},
                                     ]}
                                     styles={styles}/>
                <MenuElement userRoles={user.roles}
                             availableRoles={["ADMIN", "SUPERVISOR", "OPERATOR"]}
                             url={"/vehicle-models"} title={"Vehicle models"} styles={styles}/>
                <MenuElement userRoles={user.roles}
                             availableRoles={["ADMIN", "SUPERVISOR", "OPERATOR"]}
                             url={"/storages"} title={"Storages"} styles={styles}/>
                <MenuElement userRoles={user.roles}
                             availableRoles={["ADMIN", "SUPERVISOR", "OPERATOR"]}
                             url={"/dashboard"} title={"Dashboard"} styles={styles}/>
                <DropDownMenuElement userRoles={user.roles}
                                     availableRoles={["ADMIN", "SUPERVISOR"]}
                                     url={"tariffs"}
                                     customUrl={"tariffs/active"}
                                     title={"Tariffs"}
                                     isMenuOpened={isMenuOpened}
                                     setActive={setActive}
                                     active={active}
                                     links={[{url: "active", title: "Active"}, {url: "archive", title: "Archive"}]}
                                     styles={styles}/>
                <MenuElement userRoles={user.roles}
                             availableRoles={["ADMIN", "SUPERVISOR"]}
                             url={"/offers"} title={"Offers"} styles={styles}/>
                <MenuElement userRoles={user.roles}
                             availableRoles={["ADMIN", "SUPERVISOR"]}
                             url={"/promo-codes"} title={"Promo codes"} styles={styles}/>
                <MenuElement userRoles={user.roles}
                             availableRoles={["ADMIN"]}
                             url={"/analytics/total"} title={"Analytics"} styles={styles}/>
                <MenuElement userRoles={user.roles}
                             availableRoles={["ADMIN"]}
                             url={"/admins"} title={"Admins"} styles={styles}/>
                

            </div>

            <div className={styles.rightMenuContainer}>
                <div className={styles.menuElementContainer + " " + styles.locationWrapper}
                     onClick={() => setLocationOpened(true)}>
                    <span className={styles.locationText}>{locationName}</span>
                    <ul className={styles.dropDownMenuContainer}
                        style={locationOpened ? {"display": "block"} : {}}
                    >
                        {locations && locations.map(loc =>
                            <span key={loc.id} className={styles.dropDownElement} onClick={() => {
                                saveLocation(loc);
                                window.location.reload()
                            }}>
                                {loc.name}
                            </span>)
                        }
                    </ul>
                </div>
                <div className={styles.menuElementContainer}
                     onClick={() => setLocationOpened(true)}>
                    <AvatarCircle user={user}/>
                    <ul className={styles.avatarCircleContainer}
                        style={locationOpened ? {"display": "block"} : {}}
                    >
                        <span className={styles.dropDownElement} onClick={() => {
                            clearLocalStorage();
                            navigate("login");
                        }}>
                            Logout
                        </span>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Header;

interface MenuElementProperties {
    url: string,
    title: any,

    availableRoles: Array<"ADMIN" | "SUPERVISOR" | "OPERATOR">,

    userRoles: Array<"ADMIN" | "SUPERVISOR" | "OPERATOR">,
    styles: any
}

const MenuElement = (props: MenuElementProperties): ReactElement => {
    const {url, userRoles, availableRoles, title, styles} = props;
    return (<>
            {
                isAccess(userRoles, availableRoles)
                    ? <div className={styles.menuElementContainer}>
                        <Link className={styles.menuElement} to={url}>{title}</Link>
                    </div>
                    : <></>
            }
        </>
    )
}

interface DropDownMenuElementProperties {
    url: string,
    customUrl?: string,
    title: any,
    isMenuOpened: boolean,
    active: any,
    setActive: any,
    links: Array<any>,
    availableRoles: Array<"ADMIN" | "SUPERVISOR" | "OPERATOR">,
    userRoles: Array<"ADMIN" | "SUPERVISOR" | "OPERATOR">,
    styles: any
}

const DropDownMenuElement = (props: DropDownMenuElementProperties): ReactElement => {
    const {url, customUrl, title, isMenuOpened, active, setActive, links, userRoles, availableRoles, styles} = props;
    return (
        <>
            {
                isAccess(userRoles, availableRoles)
                    ? <div className={styles.menuElementContainer}>
                        {isMenuOpened
                            ? <div className={styles.menuElement}
                                   onClick={() => isMenuOpened ? setActive(url) : ""}>{title}</div>
                            : <Link className={styles.menuElement} to={"/" + (customUrl ? customUrl : url)}>{title}</Link>
                        }
                        <ul className={styles.dropDownMenuContainer + (isMenuOpened && active === url ? (" " + styles.openedMenuElement) : "")}>
                            {links && links.length > 0
                                ? links.map((link, index) => <Link key={title + index} to={"/" + url + "/" + link.url}
                                                                   className={styles.dropDownElement}>{link.title}</Link>)
                                : ""
                            }
                        </ul>
                    </div>
                    : <></>
            }
        </>

    )
}