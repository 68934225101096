import {createUseStyles} from "react-jss";
import {MAIN_COLOR} from "../../../util/properties";

export const buttonStyles = createUseStyles({
    button: {
        backgroundColor: MAIN_COLOR,
        display: "block",
        cursor: "pointer",
        textAlign: "center",
        textDecoration: "none",
        border: "none",
        color: "#ffffff",
        borderRadius: "5px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "15px",
        paddingRight: "15px",
        boxSizing: "border-box",
        fontWeight: "600",
        fontSize: "14px",
        marginRight: "10px",
        marginBottom: "10px"
    },
});