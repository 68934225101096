import * as React from 'react'
import {useEffect, useState} from 'react'
import {TitleWrapper} from "../common/TitleWrapper/TitleWrapper";
import {PageResponse} from "../../util/common";
import {DefaultPageContentContainer} from "../common/TableContainer/DefaultPageContentContainer";
import {PagingSelector} from "../common/PagingSelector/PagingSelector";
import {MainContainer} from "../common/MainContainer/MainContainer";
import {Link, useNavigate} from "react-router-dom";
import usersStyles from "./UsersStyles";
import {getFullName, getUsers, UserShortRs} from "../../api/UserApi";
import {SortField} from "../common/SortButton/MultipleSortButton";
import {formatDateTime} from "../../util/dateUtils";
import {createSortString, SortButton} from "../common/SortButton/SortButton";
import {LoadingBar} from "../common/LoadingBar/LoadingBar";


export function Users() {
    const styles = usersStyles();
    const navigate = useNavigate();
    const [usersPage, setUsersPage] = useState<PageResponse<UserShortRs> | undefined>(undefined);
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<SortField>();
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    useEffect(() => {
        getUsers(page, createSortString(sortField))
            .then((result: PageResponse<UserShortRs>) => {
                setUsersPage(result);
                setDataLoaded(true);
            })
    }, [page, sortField]);

    return (
        <MainContainer>
            <TitleWrapper title={"Users - " + (usersPage ? usersPage.totalElements : "")}>

            </TitleWrapper>
            {dataLoaded ?
                <DefaultPageContentContainer title={"Users"} pageContent={usersPage}>
                    <table className={styles.dataTable}>
                        {createDataHeader(styles, setSortField, sortField)}
                        <tbody>
                        {usersPage && usersPage.content && usersPage.content.length > 0
                            ? usersPage.content.map((user, index) => createDataRow(user, navigate, styles))
                            : <tr className={styles.noDataBlock}>
                                <th>No users found</th>
                            </tr>}
                        </tbody>
                    </table>
                    {
                        usersPage && usersPage.totalPages > 1
                            ? <PagingSelector page={page} pageNumber={usersPage.totalPages} setPage={setPage}/>
                            : <></>
                    }

                </DefaultPageContentContainer>
                : <LoadingBar/>
            }
        </MainContainer>
    )
}

const createDataRow = (user: UserShortRs, navigate: any, styles: any): React.ReactElement => {

    return <>

        <tr className={styles.dataRow}>

            <th className=""><a href={"/user/" + user.id}>{getFullName(user)}</a></th>
            <th className="">{formatDateTime(user.lastRentalAt)}</th>
            <th className="">{formatDateTime(user.createdAt)} km</th>
            <th className="">{formatDateTime(user.changedAt)}</th>


            <th className={styles.iconContainer}>
                <Link to={"/user/" + user.id}><img className={styles.icon} src="/eye.svg" alt=""/></Link>
                <Link to={"/user/edit/" + user.id}><img className={styles.icon} src="/edit.svg" alt=""/></Link>
            </th>
        </tr>


    </>
}

const createDataHeader = (styles: any, setSortField: any, sortField?: SortField): React.ReactElement => {
    return <>
        <thead>
        <tr className={styles.headerRow}>
            <th className="">
                <SortButton title={"Full name"} sortField={sortField} setSortField={setSortField}
                            fieldNameTarget={"fullName"}/>
            </th>
            <th className="">
                <SortButton title={"Last Rental At"} sortField={sortField} setSortField={setSortField}
                            fieldNameTarget={"last_rental_at"}/>
            </th>
            <th className="">Created at</th>
            <th className="">
                <SortButton title={"Changed at"} sortField={sortField} setSortField={setSortField}
                            fieldNameTarget={"changedAt"}/>
            </th>
            <th></th>
        </tr>
        </thead>
    </>
}