import {createUseStyles} from "react-jss";

const analyticsStyles = createUseStyles({
    submitButton: {
        width: "200px",
        marginTop: "30px",
        backgroundColor: "#0044EA",
        display: "block",
        cursor: "pointer",
        textAlign: "center",
        textDecoration: "none",
        border: "none",
        color: "#ffffff",
        borderRadius: "5px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "15px",
        paddingRight: "15px",
        boxSizing: "border-box",
        fontWeight: "600",
        fontSize: "14px",
    },
    sideBar: {
        background: "#212020",
        position: 'fixed',
        overflow: "auto",
        height: "100%",
        width: "300px",
        color: "#ffffff",
        paddingTop: "75px",
        paddingLeft: "25px",
    },
    menuAnalyticsContainer: {
        display: "flex",
        justifyContent: "flex-start"
    },
    contentWrapper: {
        width: "100%",
        marginLeft: "300px"
    },
    chart: {
        width: "500px",
        height: "350px",
        paddingLeft: "25px",
    },
    cardsContainer: {
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap"
    },
    title: {
        textAlign: "left",
        paddingLeft: "25px",
    },
    card: {
        width: "150px",
        height: "150px",
        backgroundColor: "#FDFDFD",
        boxShadow: '0 0 10px rgba(150,154,213, 0.5)',
        boxSizing: 'border-box',
        borderRadius: '10px',
        margin: "25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    cardHeader: {

    },
    cardValue: {
        fontSize: "35px",
        "& a": {
            textDecoration: "none",
        }
    },
    datePicker: {
        marginLeft: "25px",
        marginRight: "25px",
    },
    dateContainer: {
        display: "flex"
    },
    menuWrapper: {
        textAlign: "left",

    },
    link: {
        textDecoration: "none",
        display: "block",
        marginTop: "10px",
        marginBottom: "10px",
        color: "#797979",
    },
    activeLink: {
        color: "#fffFFF",
    },
    menuHeader: {
        textAlign: "left",
    },
    '@media (max-width:500px)': {
        sideBar: {
            background: "#212020",
            top: 0,
            position: 'relative',
            height: "200px",
            width: "100%",
            color: "#ffffff",
            paddingTop: "45px",
            paddingLeft: "25px",
        },
        contentWrapper: {
            width: "100%",
            marginLeft: "0",
        },
        menuAnalyticsContainer: {
            display: "block",
        },
    },
});

export default analyticsStyles;