import React, {ReactElement} from 'react';
import {MainContainerNoMargins} from "../common/MainContainer/MainContainer";
import {getUserFromLocalStorage, isAccessRoles, Role} from "../../util/common";
import analyticsStyles from "./AnalyticsStyles";
import {Link, useParams} from "react-router-dom";
import {TripsAnalytics} from "./TripsAnalytics/TripsAnalytics";
import {TotalAnalytics} from "./TotalAnalytics/TotalAnalytics";
import {Report} from "./Report/Report";
import {UsersAnalytics} from "./UsersAnalytics/UsersAnalytics";
import {HeatMap} from "./HeatMap/HeatMap";

export type AlarmsProperties = {
    roles: Array<Role>
}

export const Analytics = (props: AlarmsProperties): ReactElement => {
    const {type} = useParams();
    const styles = analyticsStyles();
    const {roles} = props;

    const pageByAnalyticType = () => {
        if (type === "report") {
            return <Report roles={roles}/>;
        }
        if (type === "total") {
            return <TotalAnalytics roles={roles}/>;
        }
        if (type === "trips") {
            return <TripsAnalytics roles={roles}/>
        }
        if (type === "users") {
            return <UsersAnalytics roles={roles}/>
        }
        if (type === "heatmap") {
            return <HeatMap roles={roles}/>
        }
        return <></>
    }

    return (
        <MainContainerNoMargins>
            <div className={styles.menuAnalyticsContainer}>
                <div className={styles.sideBar}>
                    <h3 className={styles.menuHeader}>Analytics</h3>
                    <div className={styles.menuWrapper}>
                    <Link className={styles.link + " " + (type === "report" ? styles.activeLink : "")}
                              to={"/analytics/report"}>Download report</Link>
                        <Link className={styles.link + " " + (type === "total" ? styles.activeLink : "")}
                              to={"/analytics/total"}>Total</Link>
                        <Link className={styles.link + " " + (type === "users" ? styles.activeLink : "")}
                              to={"/analytics/users"}>By users</Link>
                        <Link className={styles.link + " " + (type === "trips" ? styles.activeLink : "")}
                              to={"/analytics/trips"}>By trips</Link>
                        <Link className={styles.link + " " + (type === "heatmap" ? styles.activeLink : "")}
                              to={"/analytics/heatmap"}>Heatmap</Link>
                        <div></div>
                    </div>
                </div>
                <div className={styles.contentWrapper}>
                    {pageByAnalyticType()}
                </div>

            </div>

        </MainContainerNoMargins>
    );
};