import * as React from 'react'
import {useState} from 'react'
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import {NavigateFunction, useNavigate, useParams} from "react-router-dom";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {createVehicleModel, VehicleModelRequest} from "../../../api/VehicleModelApi";
import {Field, Form, Formik} from "formik";
import newVehicleModelStyles from "./NewVehicleModelStyles";


let formDefaultValues = {
    name: '',
}

export function NewVehicleModel() {
    const styles = newVehicleModelStyles();
    const {id} = useParams();
    const navigate = useNavigate();
    const [message, setMessage] = useState<string>("");

    const onSubmitHandle = (values: VehicleModelRequest, navigate: NavigateFunction, id?: string) => {
        createVehicleModel(values)
            .then((result: any) => {

                navigate("/vehicle-models/" + result.id);
            })
            .catch((result: any) => {
                setMessage("Error occurred while saving vehicle");
                setTimeout(() => {
                    setMessage("")
                }, 3000)
            });
    }

    return (
        <>
            <MainContainer>
                <TitleWrapper title={"New vehicle"}>
                </TitleWrapper>
                <div className={styles.mainWrapper}>
                    <div className={styles.contentWrapper}>
                        <DefaultPageContentContainer title={"Details"}>
                                    <Formik
                                        initialValues={formDefaultValues}
                                        onSubmit={(values => onSubmitHandle(values, navigate))}>
                                        {(props) => {
                                            const {errors, touched, isValid} = props;
                                            return (<>
                                                    <Form>

                                                        <div className={styles.inputContainers}>
                                                            <div className={styles.inputContainer}>
                                                                <label htmlFor="name">Name</label>
                                                                <Field id="name"
                                                                       name="name" placeholder={""}
                                                                       className={errors.name && touched.name ? styles.inputFieldError : styles.inputField}
                                                                />
                                                            </div>


                                                            <button className={styles.submitButton} type="submit">
                                                                Save
                                                            </button>
                                                        </div>

                                                    </Form>
                                                </>
                                            )
                                        }

                                        }
                                    </Formik>

                        </DefaultPageContentContainer>
                    </div>
                </div>

            </MainContainer>
        </>
    )
}

