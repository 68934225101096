import {HOST} from "../util/properties";
import {
    createDefaultHeaders,
    createUrlParameters,
    fetchWithResponse,
    getAccessTokenFromLocalStorage,
    PageResponse
} from "../util/common";

export type TripShort = {
    id: string,
    startTime?: Date,
    status: string,
    userId: string,
    userFullName: string,
    vehicleId: string,
}

export type TripShortPriceRs = {
    id: string,
    createdAt: Date,
    price: number
}

export type Point = {
    longitude: number,
    latitude: number
}

export type TripRs = {
    id: string,
    status: string,
    startTime?: Date,
    endTime?: Date,
    changedAt?: Date,
    finishedWithError: boolean,
    userId: string,
    userFullName: string,
    vehicleId: string,
    price?: number,
    startPoint?: Point,
    endPoint?: Point,
    distance?: number,
    subscriptionId?: string,
    fragments: Array<TripFragment>,
    photo?: PhotoRs
}

export type PhotoRs = {
    id: string,
    data: any,
    tripId: string,
    createdAt: Date
}

export type TripPoint = {
    id: string,
    tripId: string,
    longitude: number,
    latitude: number,
    createdAt: Date,
}

export type TripFragment = {
    id: string,
    type: string,
    trip_id: string,
    startTime?: Date,
    endTime?: Date,
    points: Array<TripPoint>
}

export const getStartPoint = (trip: TripRs): TripPoint | undefined => {
    if (trip && trip.fragments && trip.fragments.length > 0 && trip.fragments[0].points.length > 0) {
        return trip.fragments[0].points[0];
    }
}

export const getEndPoint = (trip: TripRs): TripPoint | undefined => {
    if (trip && trip.fragments && trip.fragments.length > 0 && trip.fragments[trip.fragments.length - 1].points.length > 0) {
        const lastFragment = trip.fragments[trip.fragments.length - 1];
        if (lastFragment.endTime) {
            return lastFragment.points[lastFragment.points.length - 1];
        }
    }
}

export const getActiveTrip = (userId?: string, vehicleId?: string): Promise<TripShort> => {
    const userToken = getAccessTokenFromLocalStorage();
    const paramString = createUrlParameters({
        "userId": userId,
        "vehicleId": vehicleId
    });
    return fetchWithResponse(HOST + "/api/trips/active" + paramString, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

// export const getActiveTrips = (vehicleId?: string): Promise<TripShort> => {
//     const userToken = getAccessTokenFromLocalStorage();
//
//     return fetch(HOST + "/api/trips/active/?vehicleId=" + vehicleId, {
//         method: 'GET',
//         headers: createDefaultHeaders(userToken)
//     }).then(handleResponse)
// };

export const getTrips = (locationId?:string, isSubscription?: boolean, userId?: string, status?: string, page?: number): Promise<PageResponse<TripShort>> => {
    const userToken = getAccessTokenFromLocalStorage();
    const paramString = createUrlParameters({
        "locationId": locationId,
        "isSubscription": isSubscription,
        "userId": userId,
        "page": page,
        "status": status
    });

    return fetchWithResponse(HOST + "/api/trips" + paramString, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    });
}

export const getTrip = (id?: string): Promise<TripRs> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/trips/" + id, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    });
}

export const getLanguages = (): Promise<Array<string>> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/dict/languages", {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    });
}