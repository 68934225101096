import * as React from 'react'
import {Field, Form, Formik, useField} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import taskFormStyles from "./TaskFormStyles";
import Select from "react-select";
import {UserAdminRs} from "../../../api/UserApi";
import {VehicleShortResponse} from "../../../api/VehicleApi";
import {AlarmShort} from "../../../api/AlarmApi";

const validateSchema = Yup.object().shape({
    title: Yup.string().required(),
    priority: Yup.string().required(),
});

export interface TaskFormProperties {
    onSubmitHandle: any,
    formDefaultValues: any,
    workgroup: Array<UserAdminRs>,
    vehicles: Array<VehicleShortResponse>,
    alarms: Array<AlarmShort>,
    id: string
}

export function TaskForm(props: TaskFormProperties) {
    const {onSubmitHandle, formDefaultValues, workgroup, alarms, vehicles, id} = props;
    const styles = taskFormStyles();
    const navigate = useNavigate();

    const getAlarmLabel = (alarm: AlarmShort) => {
        if (alarm.vehicleId) {
            return `Vehicle (QR code) ${alarm.vehicleQrCode}: ${alarm.description}`;
        }
        if (alarm.tripId) {
            return `Trip ${alarm.description}`;
        }
        return "";
    };

    const alarmsFormatted = alarms.map(alarm => {
        return {label: getAlarmLabel(alarm), value: alarm.id}
    });


    const vehiclesFormatted = vehicles.map(vehicle => {
        return {label: ("QR code: " + vehicle.qrCode), value: vehicle.id}
    })

    const AssignedSelect = ({...selectProps}) => {
        const [field, meta, helpers] = useField(selectProps.id)

        return (
            <Select
                defaultValue={selectProps.options.find((elem: any) => elem.value === field.value) || selectProps.options[0]}
                onChange={(v) => helpers.setValue(v.value)}
                onBlur={() => helpers.setTouched(true)}
                {...selectProps}
            />
        )
    }
    return (
        <Formik
            initialValues={formDefaultValues}
            validationSchema={validateSchema}
            onSubmit={(values => onSubmitHandle(values, navigate, id))}>
            {(props) => {
                const {errors, touched, isValid, setFieldValue} = props;
                return (<>
                        <Form>

                            <div className={styles.inputContainers}>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="title">Title</label>
                                    <Field id="title" placeholder={""}
                                           name="title"
                                           className={errors.title && touched.title ? styles.inputFieldError : styles.inputField}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="description">Description</label>
                                    <Field id="description"
                                           name="description" placeholder={""}
                                           className={errors.externalId && touched.externalId ? styles.inputFieldError : styles.inputField}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="alarmId">Link task with alarm</label>
                                    <Field id="alarmId" component={AssignedSelect}
                                           name="alarmId"
                                           isMulti={false}
                                           options={[{label: "No alarm", value: ""}, ...alarmsFormatted]}>
                                        setFieldValue={setFieldValue}

                                    </Field>

                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="vehicleId">Link task with vehicle</label>
                                    <Field id="vehicleId" component={AssignedSelect}
                                           name="vehicleId"
                                           isMulti={false}
                                           options={[{label: "No vehicle", value: ""}, ...vehiclesFormatted]}>
                                           setFieldValue={setFieldValue}

                                    </Field>
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="priority">Priority</label>
                                    <Field id="priority" component={AssignedSelect}
                                           name="priority"
                                           isMulti={false}
                                           options={[
                                               {label: "Low", value: "LOW"},
                                               {label: "High", value: "HIGH"},
                                               {label: "Critical", value: "CRITICAL"},
                                           ]}
                                           setFieldValue={setFieldValue}>
                                    </Field>
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="assignedId">Assigned at</label>
                                    <Field id="assignedId"
                                           component={AssignedSelect}
                                           options={[{label: "No assignee", value: ""}, ...workgroup.map(wg => {
                                               return {label: (wg.lastName + wg.firstName), value: wg.id}
                                           })]}
                                           isMulti={false}
                                           name="assignedId" placeholder={""}
                                           setFieldValue={setFieldValue}
                                    />
                                </div>
                                <button className={styles.submitButton} type="submit">
                                    Save
                                </button>
                            </div>

                        </Form>
                    </>
                )
            }

            }
        </Formik>
    )
}
