import {HOST} from "../util/properties";
import {
    createDefaultHeaders,
    createUrlParameters,
    fetchNoResponse,
    fetchWithResponse,
    getAccessTokenFromLocalStorage,
    PageResponse
} from "../util/common";

export type AlarmShort = {
    id: string,
    type: "BATTERY_LOW" | "INSURANCE_EXPIRED" | "LONG_TRIP" | "",
    description: string,
    vehicleId: string,
    vehicleQrCode: string,
    isActive: boolean,
    tripId?: string,
    startTime?: Date,
    endTime?: Date,
}

export const getTypeDescription = (type: string) => {
    if (type === "BATTERY_LOW") {
        return "Low battery";
    }
    if (type === "INSURANCE_EXPIRED") {
        return "Insurance expired";
    }
    if (type === "LONG_TRIP") {
        return "Long trip";
    }
    return "";
}

export const getActiveAlarmCount = (locationId?: string): Promise<number> => {
    const userToken = getAccessTokenFromLocalStorage();
    const paramString = locationId ? ("?locationId=" + locationId) : "";

    return fetchWithResponse(HOST + "/api/alarms/count" + paramString, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const getActiveAlarmCountByTypes = (locationId?: string): Promise<any> => {
    const userToken = getAccessTokenFromLocalStorage();
    const paramString = locationId ? ("?locationId=" + locationId) : "";

    return fetchWithResponse(HOST + "/api/alarms/count/types" + paramString, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const getAlarm = (id: string): Promise<AlarmShort> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/alarms/" + id, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const getAlarms = (locationId?: string, type?: string, vehicleId?: string, tripId?: string, page?: number, sort?: string, size?: number): Promise<PageResponse<AlarmShort>> => {
    const userToken = getAccessTokenFromLocalStorage();

    const paramString = createUrlParameters({
        "locationId": locationId,
        "type": type,
        "vehicleId": vehicleId,
        "tripId": tripId,
        "page": page ? page : 0,
        "sort": sort,
        "size": size ? size : 20
    });

    return fetchWithResponse(HOST + "/api/alarms" + paramString, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const deleteAlarms = (ids: Array<string>) => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchNoResponse(HOST + "/api/alarms/" , {
        method: 'DELETE',
        body: JSON.stringify(ids),
        headers: createDefaultHeaders(userToken)
    })
};

export const deleteAlarmsByType = (type: string) => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchNoResponse(HOST + "/api/alarms/?type=" + type , {
        method: 'DELETE',
        headers: createDefaultHeaders(userToken)
    })
};