import {createUseStyles} from "react-jss";

const sortButtonStyles = createUseStyles({
    sortField: {
        position: "relative",
        textDecoration: "none",
        display: "block",
        padding: "10px",
        paddingRight: "30px",
        margin: "10px",
        cursor: "pointer",
    },
    sortAscActive: {
        borderBottomColor: "#0047F4",
        border: "5px solid transparent",
        display: "block",
        height: "0",
        right: "10px",
        top: "50%",
        position: "absolute",
        width: "0",
        marginTop: "-12px"
    },
    sortDescActive: {
        borderTopColor: "#0047F4",
        border: "5px solid transparent",
        display: "block",
        height: "0",
        right: "10px",
        top: "50%",
        position: "absolute",
        width: "0",
        marginTop: "2px"
    },
    sortAsc: {
        borderBottomColor: "#a6a7a9",
        border: "5px solid transparent",
        display: "block",
        height: "0",
        right: "10px",
        top: "50%",
        position: "absolute",
        width: "0",
        cursor: "pointer",
        marginTop: "-12px"
    },
    sortDesc: {
        borderTopColor: "#a6a7a9",
        border: "5px solid transparent",
        display: "block",
        height: "0",
        right: "10px",
        top: "50%",
        position: "absolute",
        width: "0",
        cursor: "pointer",
        marginTop: "2px"
    },
    sortNumber: {
        marginTop: "-10px",
        marginRight: "-6px",
        right: "10px",
        top: "50%",
        position: "absolute",
        width: "0",
        cursor: "pointer",
    },
})

export default sortButtonStyles;