import React, {ReactElement} from 'react';

import {difference} from "lodash";
import {
    getRefreshTokenFromLocalStorage,
    getUserFromLocalStorage,
    isTokenNonExpired, saveTokens
} from "../../util/common";
import {Navigate, Outlet} from "react-router-dom";
import {refreshToken} from "../../api/AuthApi";

export type SecuredRouteProps = {
    authorities: Array<"ADMIN" | "SUPERVISOR" | "OPERATOR">
}

const SecuredRoute = (props: SecuredRouteProps): ReactElement => {

    const {authorities} = props;
    const savedRefreshToken = getRefreshTokenFromLocalStorage();
    const tokenNonExpired = isTokenNonExpired();

    const isAuth = tokenNonExpired && isAccessGranted(authorities);

    if (!tokenNonExpired && savedRefreshToken) {
        refreshToken(savedRefreshToken)
            .then(saveTokens)

        return <Outlet/>
    }

    return isAuth ? <Outlet/> : <Navigate to={"/login"}/>
};

const isAccessGranted = (requiredAuthorities: Array<string>) => {
    const user = getUserFromLocalStorage();
    const userAuthorities: Array<string> = user ? user.roles : [];

    return userAuthorities.some(x => requiredAuthorities.includes(x));
};

export default SecuredRoute;
