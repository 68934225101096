import * as React from 'react'
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import offerFormStyles from "./OfferFormStyles";
import {DurationPicker} from "../../common/DurationPicker/DurationPicker";
import {MoneyPicker} from "../../common/MoneyPicker/MoneyPicker";
import {NumberPicker} from "../../common/NumberPicker/NumberPicker";

const validateSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    startDate: Yup.date().required("Required"),
    endDate: Yup.date().required("Required")
        .when('startDate', (startDate) => {

            if (startDate) {
                return Yup.date()
                    .min(startDate, 'End Date must be after Start Date');
            }

            return Yup.date();
        }),
});

export function OfferForm(props: any) {
    const {onSubmitHandle, formDefaultValues, id} = props;
    const styles = offerFormStyles();
    const navigate = useNavigate();

    return (
        <Formik
            initialValues={formDefaultValues}
            validationSchema={validateSchema}
            onSubmit={(values => onSubmitHandle(values, navigate, id))}>
            {(props) => {
                const {errors, touched, submitCount} = props;
                const isSubmitting = submitCount > 0;
                return (<>
                        <Form>

                            <div className={styles.inputContainers}>

                                <div className={styles.inputContainer}>
                                    <label htmlFor="name">Name</label>
                                    <Field id="name"
                                           name="name"
                                           placeholder={"Enter name..."}
                                           className={errors.description && (touched.description || isSubmitting)
                                               ? styles.inputFieldError
                                               : styles.inputField}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="description">Description</label>
                                    <Field id="description"
                                           name="description"
                                           placeholder={"Enter description..."}
                                           className={errors.description && (touched.description || isSubmitting)
                                               ? styles.inputFieldError
                                               : styles.inputField}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="price">Price</label>
                                    <Field id="price"
                                           name="price"
                                           component={MoneyPicker}
                                           placeholder={"1.00"}
                                           errors={errors}
                                           touched={touched}
                                           isSubmitting={isSubmitting}
                                           styles={styles}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="startDate">Start date</label>
                                    <Field id="startDate"
                                           name="startDate" type="date"
                                           placeholder={""}
                                           className={errors.startDate && (touched.startDate || isSubmitting)
                                               ? styles.inputFieldError
                                               : styles.inputField}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="endDate">End date</label>
                                    <Field id="endDate"
                                           name="endDate" type="date"
                                           placeholder={""}
                                           className={errors.endDate && (touched.endDate || isSubmitting)
                                               ? styles.inputFieldError
                                               : styles.inputField}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="daysDuration">Offer days duration</label>
                                    <Field id="daysDuration"
                                           name="daysDuration" placeholder={"1"}
                                           component={NumberPicker}
                                           className={errors.daysDuration && (touched.daysDuration || isSubmitting)
                                               ? styles.inputFieldError
                                               : styles.inputField}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="tripLimitPerDay">Trip limits per day (number)</label>
                                    <Field id="tripLimitPerDay"
                                           name="tripLimitPerDay" placeholder={"1"}
                                           className={errors.tripLimitPerDay && (touched.tripLimitPerDay || isSubmitting)
                                               ? styles.inputFieldError
                                               : styles.inputField}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="timeLimitPerDay">Time limit per day</label>
                                    <Field id="timeLimitPerDay"
                                           name="timeLimitPerDay" placeholder={"0.01"}
                                           component={DurationPicker}
                                           className={errors.timeLimitPerDay && (touched.timeLimitPerDay || isSubmitting)
                                               ? styles.inputFieldError
                                               : styles.inputField}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="pauseLimitPerDay">Pause limit per day</label>
                                    <Field id="pauseLimitPerDay"
                                           name="pauseLimitPerDay" placeholder={"0.01"}
                                           component={DurationPicker}
                                           className={errors.pauseLimitPerDay && (touched.pauseLimitPerDay || isSubmitting)
                                               ? styles.inputFieldError
                                               : styles.inputField}
                                    />
                                </div>
                                <div className={styles.inputContainer + " " + styles.checkboxContainer}>
                                    <label htmlFor="active">Is active</label>
                                    <Field id="active"
                                           type="checkbox"
                                           name="active" placeholder={"false"}
                                    />
                                </div>


                                <button className={styles.submitButton} type="submit">
                                    Save
                                </button>
                            </div>

                        </Form>
                    </>
                )
            }

            }
        </Formik>
    )
}