import {createDefaultHeaders, fetchWithResponse, getAccessTokenFromLocalStorage} from "../util/common";
import {HOST} from "../util/properties";

export type LocationRs = {
    id: string,
    name: string,
    currency: string,
    geoJson: string
}

export const getInitLocation = (): Promise<LocationRs> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/locations/first", {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const getLocations = (): Promise<Array<LocationRs>> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/locations", {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};