import {createUseStyles} from "react-jss";

const messageStyles = createUseStyles({
    message: {
        width: "100%",
        paddingTop: "15px",
        paddingBottom: "15px",
        textAlign: "center",
        opacity: "0.8",
        color: "#f2f2f2"
    },
    ok: {
        backgroundColor: "#10b03f"
    },
    error: {
        backgroundColor: "#db2a20",
    }
})

export default messageStyles;