import React, {ReactElement} from 'react';
import {iconsStyles} from "./IconsStyles";
import {Link} from "react-router-dom";

export interface IconsProperties {
    isTable: boolean,
    links: Array<IconParams>
}

export type IconParams = {
    url?: string,
    onClick?: any,
    img: "view" | "edit" | "delete"
}

export const Icons = (props: IconsProperties): ReactElement => {
    const {isTable, links} = props;
    const styles = iconsStyles();
    return (
        <>
            {
                isTable
                    ? <th className={styles.iconContainer}>
                        {
                            links.map((link) =>

                                link.url
                                    ? <Link to={link.url} className={styles.iconBorder}><img className={styles.icon} src={getLink(link.img)} alt=""/></Link>
                                    : <span onClick={link.onClick} className={styles.iconBorder}><img className={styles.icon} src={getLink(link.img)} alt=""/></span>

                            )
                        }
                    </th>
                    : <div className={styles.iconContainer}>
                        {
                            links.map((link) =>

                                link.url
                                    ? <Link to={link.url} className={styles.iconBorder}><img className={styles.icon} src={getLink(link.img)} alt=""/></Link>
                                    : <span onClick={link.onClick} className={styles.iconBorder}><img className={styles.icon} src={getLink(link.img)} alt=""/></span>

                            )
                        }
                    </div>
            }
        </>


    )
}

const getLink = (img: string) => {
    if (img === "view") {
        return "/eye.svg";
    }
    if (img === "edit") {
        return "/edit.svg";
    }
    if (img === "delete") {
        return "/delete.svg";
    }
    return "";
}