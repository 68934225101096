import {useField} from "formik";
import Select from "react-select";
import * as React from "react";

export const MultiSelect = ({...selectProps}) => {
    const [field, meta, helpers] = useField(selectProps.id)

    return (
        <Select
            defaultValue={selectProps.options.filter((elem: any) => field.value && field.value.includes(elem.value))}
            onChange={(v) => helpers.setValue(v.map((x: any) => x.value))}
            onBlur={() => helpers.setTouched(true)}
            {...selectProps}
        />
    )
}