import {createUseStyles} from "react-jss";

const confirmModalStyles = createUseStyles({
    modalButtonConfirm: {
        marginRight: '5px',
        fontSize: "15px",
        color: "#0047F4",
        borderRadius: "7px",
        padding: "5px 15px",
        lineHeight: "1.5",
        outline: "0",
        textDecoration: "none",
        border: "2px solid #0047F4",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        boxSizing: "border-box",
        fontWeight: "600",
        backgroundColor: "#ffffff",
        cursor: "pointer",
    },
    modalButtonCancel: {
        marginRight: '5px',
        fontSize: "15px",
        color: "#0047F4",
        borderRadius: "7px",
        padding: "5px 15px",
        lineHeight: "1.5",
        outline: "0",
        textDecoration: "none",
        border: "2px solid #94A0BDFF",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        boxSizing: "border-box",
        fontWeight: "600",
        backgroundColor: "#ffffff",
        cursor: "pointer",
    },
    modalText: {
        fontWeight: "600",
        marginBottom: "40px"
    },
    modalButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-start'
    },
})

export default confirmModalStyles;