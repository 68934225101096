import {
    createDefaultHeaders,
    createUrlParameters,
    fetchWithResponse,
    getAccessTokenFromLocalStorage
} from "../util/common";
import {HOST} from "../util/properties";

export type AnalyticUsersRs = {
    activeUsersNumber: number,
    newUsersNumber: number,
    usersWithOffers: number
}

export type AnalyticTripsRs = {
    successfulTrips: number,
    failedTrips: number,
    profit: number,
    averageTripPrice: number,
    averageTripDuration: any
}

export type MoneyRs = {
    id: string,
    name: string
}

export type MonthValRs = {
    month: Date,
    value: any
};

export type AnalyticRs = {
    monthProfit: Array<MonthValRs>,
    monthTrips: Array<MonthValRs>,
    activeUsersNumber: number,
    newUsersNumber: number,
    alarmsNumber: number,
    averagePrice: number,
    successfulTrips: number,
    unsuccessfulTrips: number,
    userOffersNumber: number,
    popularOffer: MoneyRs,
    popularPromoCode: MoneyRs
}

export const getTotalAnalytic = (): Promise<AnalyticRs> => {
    const userToken = getAccessTokenFromLocalStorage();

    return fetchWithResponse(HOST + "/api/analytics/total", {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const getTripsAnalytic = (startDate?: any, endDate?: any): Promise<AnalyticTripsRs> => {
    const userToken = getAccessTokenFromLocalStorage();
    const urlParams = createUrlParameters({
        "startDate": startDate,
        "endDate": endDate
    })

    return fetchWithResponse(HOST + "/api/analytics/trips" + urlParams, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};

export const getUsersAnalytic = (startDate?: any, endDate?: any): Promise<AnalyticUsersRs> => {
    const userToken = getAccessTokenFromLocalStorage();

    const urlParams = createUrlParameters({
        "startDate": startDate,
        "endDate": endDate
    })

    return fetchWithResponse(HOST + "/api/analytics/users" + urlParams, {
        method: 'GET',
        headers: createDefaultHeaders(userToken)
    })
};