import * as React from 'react';
import Select from "react-select";
import selectFilterStyles from "./SelectFilterStyles";

export type SelectFilterProperties = {
    title: string,
    value: string,
    setValue: any,
    options: Array<any>
};

export type OptionEntry = {
    label: string,
    value: string
}

export function SelectFilter(props: SelectFilterProperties): React.ReactElement {
    const {title, value, setValue, options} = props;
    const styles = selectFilterStyles();

    return (<div className={styles.selectContainer}>
        <label className={styles.label} htmlFor={title + "Filter"}>{title}</label>
        <Select
            id={title + "Filter"}
            defaultValue={value}
            onChange={(v: any) => setValue(v.value)}
            options={options}
            className={styles.selectInput}
        />
    </div>)
}
