import React, {ReactElement, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {getUserFromLocalStorage, isAccessRoles, Role} from "../../../util/common";
import analyticsStyles from "../AnalyticsStyles";
import {formatIsoDate, subtractMonths} from "../../../util/dateUtils";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import {LoadingBar} from "../../common/LoadingBar/LoadingBar";
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {AnalyticTripsRs, getTripsAnalytic} from "../../../api/AnalyticApi";
import {Message} from "../../common/Message/Message";

export type TripsAnalyticsProperties = {
    roles: Array<Role>
}

export const TripsAnalytics = (props: TripsAnalyticsProperties): ReactElement => {
    const styles = analyticsStyles();
    const {roles} = props;
    const [startDate, setStartDate] = useState(formatIsoDate(subtractMonths(1)));
    const [endDate, setEndDate] = useState(formatIsoDate(new Date()));
    const [analytics, setAnalytics] = useState<AnalyticTripsRs>()
    const [isLoaded, setIsLoaded] = useState(false);
    const {type} = useParams();
    const [message, setMessage] = useState<string>("");

    const title = "Trips statistic by " + (startDate ? startDate : "") + (startDate && endDate ? (" - " + endDate) : "");

    const user = getUserFromLocalStorage();

    const writeAccess = isAccessRoles(user.roles, roles, "WRITE");


    useEffect(() => {
        getTripsAnalytic(startDate, endDate).then((result) => {
            setAnalytics(result);
            setIsLoaded(true);
        }).catch(() => {
            setMessage("Error occurred while loading trip analytics.");
            setTimeout(() => {
                setMessage("")
            }, 3000);
            setIsLoaded(true)
        })

    }, [startDate, endDate, type]);

    return (
        <>
            <Message message={message} isError={true}/>
            <MainContainer>
                <TitleWrapper title={title}>
                </TitleWrapper>
                {isLoaded
                    ? <>{analytics &&

                        <DefaultPageContentContainer title={"Trips"}>
                            <div className={styles.dateContainer}>
                                <div className={styles.datePicker}>
                                    <div>Start date</div>
                                    <input value={startDate} type="date"
                                           onChange={(v) => setStartDate(v.target.value)}/>
                                </div>

                                <div className={styles.datePicker}>
                                    <div>End date</div>
                                    <input value={endDate} type="date" onChange={(v) => setEndDate(v.target.value)}/>
                                </div>
                            </div>

                            <div className={styles.cardsContainer}>
                                <div className={styles.card}>
                                    <div>
                                        <div className={styles.cardHeader}>Total trips:</div>
                                        <div className={styles.cardValue}>{analytics.successfulTrips + analytics.failedTrips}</div>
                                    </div>
                                </div>
                                <div className={styles.card}>
                                    <div>
                                        <div className={styles.cardHeader}>Successful trips:</div>
                                        <div className={styles.cardValue}>{analytics.successfulTrips}</div>
                                    </div>
                                </div>
                                <div className={styles.card}>
                                    <div>
                                        <div className={styles.cardHeader}>Failed trips:</div>
                                        <div className={styles.cardValue}>{analytics.failedTrips}</div>
                                    </div>
                                </div>
                                <div className={styles.card}>
                                    <div>
                                        <div className={styles.cardHeader}>Total profit:</div>
                                        <div className={styles.cardValue}>{analytics.profit}</div>
                                    </div>
                                </div>
                                <div className={styles.card}>
                                    <div>
                                        <div className={styles.cardHeader}>Average trip price:</div>
                                        <div className={styles.cardValue}>{analytics.averageTripPrice}</div>
                                    </div>
                                </div>
                            </div>

                        </DefaultPageContentContainer>
                    }
                    </>

                    : <LoadingBar/>
                }
            </MainContainer>
        </>

    );
};