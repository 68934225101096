import {Link, useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {useEffect, useState} from "react";
import {VehicleModelResponse} from "../../../api/VehicleModelApi";
import {Message} from "../../common/Message/Message";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import {ConfirmModal} from "../../common/ConfirmModal/ConfirmModal";
import {ActionButton} from "../../common/ActionButton/ActionButton";
import {TripShort} from "../../../api/TripApi";
import storageStyles from "./StorageStyles";
import {deleteStorage, getStorage, StorageRs} from "../../../api/StoragesApi";
import {releaseVehicles, VehicleStatus} from "../../../api/VehicleApi";
import {Button} from "../../common/Button/Button";
import {formatDateTime} from "../../../util/dateUtils";
import {getUserFromLocalStorage, isAccessRoles, Role} from "../../../util/common";
import {StoragesProperties} from "../Storages";

const StorageRsInit: StorageRs = {
    id: "",
    name: "",
    longitude: 0,
    latitude: 0,
    vehicles: []
}

export type StorageProperties = {
    roles: Array<Role>
}

export function Storage(props: StorageProperties) {
    const styles = storageStyles();
    const {roles} = props;
    const user = getUserFromLocalStorage();
    const {id} = useParams();
    const navigate = useNavigate();
    const [storage, setStorage] = useState<StorageRs>(StorageRsInit);
    const [message, setMessage] = useState<string>("");
    const [vehicleIds, setVehicleIds] = useState<Set<string>>(new Set([]));
    const [isMessageError, setIsMessageError] = useState<boolean>(false);
    const [deleteModalOpened, setDeleteModalOpened] = useState<boolean>(false);

    const writeAccess = isAccessRoles(user.roles, roles, "WRITE");

    const deleteOnChange = () => {
        if (!id) {
            return;
        }
        deleteStorage(id).then(() => {
            navigate("/storages");
        }).catch((err) => {
            setIsMessageError(true);
            setDeleteModalOpened(false);
            setMessage("Error occurred while deleting storage");
            setTimeout(() => {
                setMessage("");
            }, 3000)
        })
    }

    const onClickCheckbox = (val: any, vehicleId: string) => {
        if (val.target.checked) {
            vehicleIds.add(vehicleId);
        } else {
            vehicleIds.delete(vehicleId);
        }
    }

    const delChange = () => {
        setDeleteModalOpened(true);
    }

    const releaseChosenVehicles = () => {
        releaseVehicles(Array.from(vehicleIds))
            .then((result) => {
                window.location.reload()
            })
    }

    useEffect(() => {
        getStorage(id).then((storage: StorageRs) => {
            setStorage(storage)
        });
    }, [id]);
    return (
        <>
            {deleteModalOpened
                ?
                <ConfirmModal title={"Delete storage? All linked vehicles will be switched to active status"}
                              setModalOpened={setDeleteModalOpened}
                              handleConfirm={deleteOnChange}/>
                :
                <></>}
            <Message message={message} isError={isMessageError}/>
            <MainContainer>
                <TitleWrapper title={"Storage - " + storage.name}>
                    {writeAccess && <ActionButton title={"Delete storage"} isLink={false}
                                  onClickHandle={delChange}/>}
                    {writeAccess && <ActionButton title={"Edit"} isLink={true} url={"/storage/edit/" + id}/>}
                </TitleWrapper>

                <div className={styles.contentWrapper}>

                    <DefaultPageContentContainer title={"Storage"}>
                        <div className={styles.fieldValue}>
                            <div>
                                <span><img className={styles.storageLogo} src="/storage.svg" alt=""/></span>
                                <span
                                    className={styles.mapPoint}>{storage ? storage.latitude : ""}, </span>
                                <span
                                    className={styles.mapPoint}>{storage ? storage.longitude : ""}</span>
                            </div>
                        </div>
                    </DefaultPageContentContainer>
                    <DefaultPageContentContainer title={"Vehicles"}>
                        <table className={styles.dataTable}>
                            <thead>
                            <tr className={styles.headerRow}>
                                <th className=""></th>
                                <th className="">Qr code</th>
                                <th className="">External ID</th>
                                <th className="">Operation Condition</th>
                                <th className="">Transferred at</th>
                                <th className="">Transferred by</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                storage
                                    ? storage.vehicles.map((vehicle) =>
                                        <tr>
                                            <th>
                                                <label className={styles.checkbox}>
                                                    <input type="checkbox"
                                                           onChange={val => onClickCheckbox(val, vehicle.id)}/>
                                                </label>
                                            </th>
                                            <th className=""><Link to={"/vehicle/" + vehicle.id}>{vehicle.qrCode}</Link>
                                            </th>
                                            <th className=""><Link to={"/vehicle/" + vehicle.id}>{vehicle.externalId}</Link>
                                            </th>
                                            <th className="">{createStatusStyle(styles, vehicle.status)}</th>
                                            <th className="">{formatDateTime(vehicle.lastChangedAt)}</th>
                                            <th className="">{vehicle.lastChangedByFullName}</th>
                                        </tr>
                                    )
                                    : <></>
                            }
                            </tbody>
                        </table>

                        {
                            storage.vehicles?.length > 0
                            ?
                                <Button title={"Release chosen vehicles"} onClick={releaseChosenVehicles}/>
                                : <></>
                        }

                    </DefaultPageContentContainer>
                </div>
            </MainContainer>
        </>
    )
}

const createStatusStyle = (styles: any, status?: string): React.ReactNode => {
    console.log()
    if (!status) {
        return <span className={styles.tag + " " + styles.redTag}>Wrong status</span>
    }
    if (status.toUpperCase() === VehicleStatus.AVAILABLE) {
        return <span className={styles.tag + " " + styles.greenTag}>{status}</span>
    }
    if (status.toUpperCase() === VehicleStatus.IN_MAINTENANCE) {
        return <span className={styles.tag + " " + styles.yellowTag}>{status}</span>
    }
    if (status.toUpperCase() === VehicleStatus.RENTED) {
        return <span className={styles.tag + " " + styles.yellowTag}>{status}</span>
    }

    return <span className={styles.tag + " " + styles.redTag}>Wrong status</span>
}

