import * as React from 'react'
import {useEffect, useState} from 'react'
import {TitleWrapper} from "../common/TitleWrapper/TitleWrapper";
import {getUserFromLocalStorage, isAccessRoles, PageResponse, Role} from "../../util/common";
import {DefaultPageContentContainer} from "../common/TableContainer/DefaultPageContentContainer";
import {PagingSelector} from "../common/PagingSelector/PagingSelector";
import {MainContainer} from "../common/MainContainer/MainContainer";
import {Link} from "react-router-dom";
import {getAdmins, getFullName, UserAdminRs} from "../../api/UserApi";
import {SortField} from "../common/SortButton/MultipleSortButton";
import {formatDateTime} from "../../util/dateUtils";
import {createSortString, SortButton} from "../common/SortButton/SortButton";
import adminsStyles from "./AdminsStyles";
import {LoadingBar} from "../common/LoadingBar/LoadingBar";
import {SelectFilter} from "../common/SelectFilter/SelectFilter";
import {ActionButton} from "../common/ActionButton/ActionButton";
import {AvatarCircle} from "../common/AvatarCircle/AvatarCircle";


export type AdminsProperties = {
    roles: Array<Role>
}

export function Admins(props: AdminsProperties) {
    const styles = adminsStyles();
    const {roles} = props;
    const [usersPage, setUsersPage] = useState<PageResponse<UserAdminRs> | undefined>(undefined);
    const [filterRole, setFilterRole] = useState<string>("");
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<SortField>();
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const user = getUserFromLocalStorage();

    const writeAccess = isAccessRoles(user.roles, roles, "WRITE");

    const createDataRow = (user: UserAdminRs): React.ReactElement => {
        return <>
            <tr className={styles.dataRow}>
                <th className=""><AvatarCircle user={user}/></th>
                <th className=""><a href={"/user/" + user.id}>{getFullName(user)}</a></th>
                <th className="">{user.email}</th>
                <th className="">{user.roles.join(', ')}</th>
                <th className="">{formatDateTime(user.changedAt)}</th>
                <th className="">{formatDateTime(user.lastSignIn)}</th>
                <th className={styles.iconContainer}>
                    {writeAccess && <Link to={"/admin/" + user.id}><img className={styles.icon} src="/eye.svg" alt=""/></Link>}
                    {writeAccess && <Link to={"/admin/edit/" + user.id}><img className={styles.icon} src="/edit.svg" alt=""/></Link>}
                </th>
            </tr>
        </>
    }

    const createDataHeader = (): React.ReactElement => {
        return <>
            <thead>
            <tr className={styles.headerRow}>
                <th className=""></th>
                <th className="">
                    <SortButton title={"Full name"} sortField={sortField} setSortField={setSortField}
                                fieldNameTarget={"fullName"}/>
                </th>
                <th className="">
                    <SortButton title={"Email"} sortField={sortField} setSortField={setSortField}
                                fieldNameTarget={"email"}/>
                </th>
                <th className="">Role</th>
                <th className="">
                    <SortButton title={"Last changed at"} sortField={sortField} setSortField={setSortField}
                                fieldNameTarget={"changedAt"}/>
                </th>
                <th className="">
                    <SortButton title={"Last sign in"} sortField={sortField} setSortField={setSortField}
                                fieldNameTarget={"lastSignIn"}/>
                </th>
                <th></th>
            </tr>
            </thead>
        </>
    }

    useEffect(() => {
        getAdmins(page, createSortString(sortField), filterRole)
            .then((result: PageResponse<UserAdminRs>) => {
                setUsersPage(result);
                setDataLoaded(true);
            })
    }, [filterRole, page, sortField]);

    return (
        <MainContainer>
            <TitleWrapper title={"Admin panel users - " + (usersPage ? usersPage.content.length : "")}>
                {writeAccess && <ActionButton title={"New Admin user"} url={"/admin/edit/new"} isLink={true}/>}
            </TitleWrapper>
            {dataLoaded ?
                <DefaultPageContentContainer title={"Users"} pageNumber={page} pageContent={usersPage}
                                             headerContent={
                                                 <div className={styles.filtersContainer}>
                                                     <SelectFilter title={"Role:"} value={filterRole}
                                                                   setValue={setFilterRole}
                                                                   options={[
                                                                       {
                                                                           label: "ALL",
                                                                           value: ""
                                                                       },
                                                                       {
                                                                           label: "Operator",
                                                                           value: "OPERATOR"
                                                                       },
                                                                       {
                                                                           label: "Supervisor",
                                                                           value: "SUPERVISOR"
                                                                       },
                                                                       {
                                                                           label: "Admin",
                                                                           value: "ADMIN"
                                                                       }]}/>

                                                 </div>
                                             }>
                    <table className={styles.dataTable}>
                        {createDataHeader()}
                        <tbody>
                        {usersPage && usersPage.content && usersPage.content.length > 0
                            ? usersPage.content.map((user, index) => createDataRow(user))
                            : <tr className={styles.noDataBlock}>
                                <th>No users found</th>
                            </tr>}
                        </tbody>
                    </table>
                    {
                        usersPage && usersPage.totalPages > 1
                            ? <PagingSelector page={page} pageNumber={usersPage.totalPages} setPage={setPage}/>
                            : <></>
                    }
                </DefaultPageContentContainer>
                : <LoadingBar/>
            }
        </MainContainer>
    )
}


