import * as React from 'react'
import {useEffect, useState} from 'react'
import {TitleWrapper} from "../../common/TitleWrapper/TitleWrapper";
import {DefaultPageContentContainer} from "../../common/TableContainer/DefaultPageContentContainer";
import {NavigateFunction} from "react-router-dom";
import {useNavigate, useParams} from "react-router-dom";
import {MainContainer} from "../../common/MainContainer/MainContainer";
import {
    getVehicleModel,
    updateVehicleModel,
    VehicleModelUpdateRequest,
    VehicleModelResponse
} from "../../../api/VehicleModelApi";
import {Message} from "../../common/Message/Message";
import {Field, Form, Formik} from "formik";
import editVehicleModelStyles from "./EditVehicleModelStyles";


let formDefaultValues = {
    vehicleModelId: "",
    name: '',
}

export function EditVehicleModel() {
    const styles = editVehicleModelStyles();
    const {id} = useParams();
    const [vehicleModels, setVehicleModels] = useState<Array<VehicleModelResponse>>([]);
    const navigate = useNavigate();
    const [message, setMessage] = useState<string>("");
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const onSubmitHandle = (values: VehicleModelUpdateRequest, navigate: NavigateFunction, id?: string) => {
        updateVehicleModel(values)
            .then((result: any) => {

                navigate("/vehicle-models/" + result.id);
            })
            .catch((result: any) => {
                setMessage("Error occurred while saving vehicle");
                setTimeout(() => {
                    setMessage("")
                }, 3000)
            });
    }

    useEffect(() => {

        getVehicleModel(id).then((vehicleResponse: VehicleModelResponse) => {
            formDefaultValues = {
                vehicleModelId: vehicleResponse.id,
                name: vehicleResponse.name || "",
            }
            setIsLoaded(true);
        })

    }, []);
    return (
        <>
            <Message message={message} isError={true}/>
            <MainContainer>
                <TitleWrapper title={"Edit vehicle"}>
                </TitleWrapper>
                <div className={styles.mainWrapper}>
                    <div className={styles.contentWrapper}>
                        <DefaultPageContentContainer title={"Details"}>
                            {
                                isLoaded
                                    ? <Formik
                                        initialValues={formDefaultValues}
                                        onSubmit={(values => onSubmitHandle(values, navigate))}>
                                        {(props) => {
                                            const {errors, touched, isValid} = props;
                                            return (<>
                                                    <Form>

                                                        <div className={styles.inputContainers}>
                                                            <div className={styles.inputContainer}>
                                                                <label htmlFor="vehicleModelId">ID</label>
                                                                <Field id="vehicleModelId"
                                                                       name="vehicleModelId"
                                                                       disabled={true}
                                                                       className={errors.vehicleModelId && touched.vehicleModelId ? styles.inputFieldError : styles.inputField}>
                                                                </Field>
                                                            </div>
                                                            <div className={styles.inputContainer}>
                                                                <label htmlFor="name">Name</label>
                                                                <Field id="name"
                                                                       name="name" placeholder={""}
                                                                       className={errors.name && touched.name ? styles.inputFieldError : styles.inputField}
                                                                />
                                                            </div>


                                                            <button className={styles.submitButton} type="submit">
                                                                Save
                                                            </button>
                                                        </div>

                                                    </Form>
                                                </>
                                            )
                                        }

                                        }
                                    </Formik>
                                    : <img src={"/loading.gif"}/>
                            }
                        </DefaultPageContentContainer>
                    </div>
                </div>

            </MainContainer>
        </>
    )
}

